import React from "react";
import { useSelector } from "react-redux";
import LikedListingCard from "./LikedListingCard";
import LoadingSpinner from "../LoadingSpinner";

const ProfileFavorites = () => {
  const userLikes = useSelector((state) => state.userLikes);
  const { loading: userLikesLoading, likedListings } = userLikes;

  return (
    <div className="container ms-sm-5">
      <h4 className="mb-3 px-0">Favorites</h4>

      <div className="">
        <div className="row row-cols-2 row-cols-lg-4 row-cols-xl-5">
          {userLikesLoading ? (
            <>
              <div className="col mt-3 g-1 g-md-3">
                <div class="card ms-3">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                    <a
                      class="btn btn-primary disabled placeholder col-6"
                      aria-disabled="true"
                    ></a>
                  </div>
                </div>
              </div>
              <div className="col mt-3 g-1 g-md-3">
                <div class="card ms-3">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                    <a
                      class="btn btn-primary disabled placeholder col-6"
                      aria-disabled="true"
                    ></a>
                  </div>
                </div>
              </div>
              <div className="col mt-3 g-1 g-md-3">
                <div class="card ms-3">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                    <a
                      class="btn btn-primary disabled placeholder col-6"
                      aria-disabled="true"
                    ></a>
                  </div>
                </div>
              </div>
              <div className="col mt-3 g-1 g-md-3">
                <div class="card ms-3">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                    <a
                      class="btn btn-primary disabled placeholder col-6"
                      aria-disabled="true"
                    ></a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {likedListings.map(
                (listing, index) =>
                  listing.listingId && (
                    <LikedListingCard
                      listingInformation={listing.listingId}
                      key={index}
                    ></LikedListingCard>
                  )
              )}
            </>
          )}
          {likedListings.length === 0 && userLikesLoading === false && (
            <section class="py-5 text-center container">
              <div class="row py-lg-5">
                <div class=" mx-auto">
                  <p class="lead text-body-secondary">No favorited items</p>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileFavorites;
