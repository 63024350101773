import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";
import AddressForm from "./AddressForm";
import { getSetupIntent, getStripeCustomer } from "../actions/stripeActions";
import LoadingSpinner from "./LoadingSpinner";

const SetupIntent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  const stripeSetupIntent = useSelector((state) => state.stripeSetupIntent);
  const { loading: clientSecretLoading, client_secret } = stripeSetupIntent;

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === true) {
      // dispatch(getStripeCustomer());
      // if (!client_secret) {
      //   dispatch(getSetupIntent());
      // }

      if (!stripePromise) {
        axios
          .get("/api/stripe/config")
          .then((response) => {
            setStripePromise(loadStripe(response.data.publishableKey));
          })
          .catch((error) => {
          });
      }
    }
  }, []);

  return (
    <>
      {clientSecretLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        stripePromise &&
        client_secret && (
          <Elements
            stripe={stripePromise}
            options={{
              // passing the SetupIntent's client secret
              clientSecret: client_secret,
              // Fully customizable with appearance API.
              appearance: {
                /*...*/
              },
            }}
          >
            <SetupForm></SetupForm>
          </Elements>
        )
      )}
    </>
  );
};

export default SetupIntent;
