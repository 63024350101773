import React from "react";
import SetupIntent from "../components/SetupIntent";

const ProfileSellerInformationScreen = () => {
  return (
    <div className="container">
      <div className="row justify-content-center mt-3">
        <div className="col-lg-7">
          <SetupIntent></SetupIntent>
        </div>
      </div>
    </div>
  );
};

export default ProfileSellerInformationScreen;
