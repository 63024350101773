export const MARKETPLACE_LIST_REQUEST = "MARKETPLACE_LIST_REQUEST";
export const MARKETPLACE_LIST_SUCCESS = "MARKETPLACE_LIST_SUCCESS";
export const MARKETPLACE_LIST_FAIL = "MARKETPLACE_LIST_FAIL";

export const MARKETPLACE_FILTER_REQUEST = "MARKETPLACE_FILTER_REQUEST";
export const MARKETPLACE_FILTER_MORE_REQUEST =
  "MARKETPLACE_FILTER_MORE_REQUEST";
export const MARKETPLACE_FILTER_SUCCESS = "MARKETPLACE_FILTER_SUCCESS";
export const MARKETPLACE_FILTER_MORE_SUCCESS =
  "MARKETPLACE_FILTER_MORE_SUCCESS";
export const MARKETPLACE_FILTER_RESET = "MARKETPLACE_FILTER_RESET";
export const MARKETPLACE_FILTER_FAIL = "MARKETPLACE_FILTER_FAIL";

export const SELLER_LISTINGS_FILTER_REQUEST = "SELLER_LISTINGS_FILTER_REQUEST";
export const SELLER_LISTINGS_FILTER_SUCCESS = "SELLER_LISTINGS_FILTER_SUCCESS";
export const SELLER_LISTINGS_FILTER_MORE_REQUEST =
  "SELLER_LISTINGS_FILTER_MORE_REQUEST";
export const CHEAPEST_SELLER_LISTINGS_REQUEST =
  "CHEAPEST_SELLER_LISTINGS_REQUEST";
export const CHEAPEST_SELLER_LISTINGS_SUCCESS =
  "CHEAPEST_SELLER_LISTINGS_SUCCESS";
export const CHEAPEST_SELLER_LISTINGS_FAIL = "CHEAPEST_SELLER_LISTINGS_FAIL";
export const SELLER_LISTINGS_FILTER_MORE_SUCCESS =
  "SELLER_LISTINGS_FILTER_MORE_SUCCESS";
export const SELLER_LISTINGS_FILTER_RESET = "SELLER_LISTINGS_FILTER_RESET";
export const SELLER_LISTINGS_FILTER_FAIL = "SELLER_LISTINGS_FILTER_FAIL";

export const SELLER_LISTING_REQUEST = "SELLER_LISTING_REQUEST";
export const SELLER_LISTING_SUCCESS = "SELLER_LISTING_SUCCESS";
export const SELLER_LISTING_FAIL = "SELLER_LISTING_FAIL";

export const EDITORS_PICKS_REQUEST = "EDITORS_PICKS_REQUEST";
export const EDITORS_PICKS_SUCCESS = "EDITORS_PICKS_SUCCESS";
export const EDITORS_PICKS_FAIL = "EDITORS_PICKS_FAIL";

export const MARKETPLACE_BRAND_REQUEST = "MARKETPLACE_BRAND_REQUEST";
export const MARKETPLACE_BRAND_SUCCESS = "MARKETPLACE_BRAND_SUCCESS";
export const MARKETPLACE_BRAND_FAIL = "MARKETPLACE_BRAND_FAIL";

export const MARKETPLACE_LISTING_REQUEST = "MARKETPLACE_LISTING_REQUEST";
export const MARKETPLACE_LISTING_SUCCESS = "MARKETPLACE_LISTING_SUCCESS";
export const MARKETPLACE_LISTING_FAIL = "MARKETPLACE_LISTING_FAIL";

export const MARKETPLACE_LISTING_SELLING_REQUEST =
  "MARKETPLACE_LISTING_SELLING_REQUEST";
export const MARKETPLACE_LISTING_SELLING_SUCCESS =
  "MARKETPLACE_LISTING_SELLING_SUCCESS";
export const MARKETPLACE_LISTING_SELLING_FAIL =
  "MARKETPLACE_LISTING_SELLING_FAIL";

export const MARKETPLACE_PRODUCT_CREATE_REQUEST =
  "MARKETPLACE_PRODUCT_CREATE_REQUEST";
export const MARKETPLACE_PRODUCT_CREATE_SUCCESS =
  "MARKETPLACE_PRODUCT_CREATE_SUCCESS";
export const MARKETPLACE_PRODUCT_CREATE_FAIL =
  "MARKETPLACE_PRODUCT_CREATE_FAIL";
export const MARKETPLACE_PRODUCT_CREATE_RESET =
  "MARKETPLACE_PRODUCT_CREATE_RESET";

export const MARKETPLACE_PRODUCT_DELETE_REQUEST =
  "MARKETPLACE_PRODUCT_DELETE_REQUEST";
export const MARKETPLACE_PRODUCT_DELETE_SUCCESS =
  "MARKETPLACE_PRODUCT_DELETE_SUCCESS";
export const MARKETPLACE_PRODUCT_DELETE_FAIL =
  "MARKETPLACE_PRODUCT_DELETE_FAIL";
export const MARKETPLACE_PRODUCT_DELETE_RESET =
  "MARKETPLACE_PRODUCT_DELETE_RESET";

export const MARKETPLACE_SELLERLISTINGS_REQUEST =
  "MARKETPLACE_SELLERLISTINGS_REQUEST";
export const MARKETPLACE_SELLERLISTINGS_SUCCESS =
  "MARKETPLACE_SELLERLISTINGS_SUCCESS";
export const MARKETPLACE_SELLERLISTINGS_FAIL =
  "MARKETPLACE_SELLERLISTINGS_FAIL";

export const MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST =
  "MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST";
export const MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS =
  "MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS";
export const MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL =
  "MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL";

export const MARKETPLACE_ROOT_LISTINGS_REQUEST =
  "MARKETPLACE_ROOT_LISTINGS_REQUEST";
export const MARKETPLACE_ROOT_LISTINGS_SUCCESS =
  "MARKETPLACE_ROOT_LISTINGS_SUCCESS";
export const MARKETPLACE_ROOT_LISTINGS_FAIL = "MARKETPLACE_ROOT_LISTINGS_FAIL";

export const MARKETPLACE_ROOT_LISTING_GET_REQUEST =
  "MARKETPLACE_ROOT_LISTING_GET_REQUEST";
export const MARKETPLACE_ROOT_LISTING_GET_SUCCESS =
  "MARKETPLACE_ROOT_LISTING_GET_SUCCESS";
export const MARKETPLACE_ROOT_LISTING_GET_FAIL =
  "MARKETPLACE_ROOT_LISTING_GET_FAIL";

export const MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST =
  "MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST";
export const MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS =
  "MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS";
export const MARKETPLACE_ROOT_LISTING_UPSERT_FAIL =
  "MARKETPLACE_ROOT_LISTING_UPSERT_FAIL";
export const MARKETPLACE_ROOT_LISTING_UPSERT_RESET =
  "MARKETPLACE_ROOT_LISTING_UPSERT_RESET";
