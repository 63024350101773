import React, { useEffect, useRef } from "react";
import { env_name } from "./../config/env.js";

const CloudinaryUploadWidget = ({
  returnPhoto,
  folderName,
  className,
  content,
  croppingAspectRatio,
}) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dszd4owdm",
        uploadPreset: "hemu5l3h",
        sources: ["local", "camera"],
        folder: `${env_name}/${folderName}`,
        maxImageFileSize: 5000000,
        cropping: true,
        multiple: false,
        // multiple: true,
        showSkipCropButton: false,
        croppingAspectRatio: croppingAspectRatio,
        clientAllowedFormats: ["png", "jpg", "jpeg"], // Add the image formats you want to allow
        styles: {
          palette: {
            action: "#007BFF",
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          returnPhoto(result.info.secure_url);
        }
      }
    );
  });
  return (
    <button
      type="button"
      className={className}
      onClick={() => widgetRef.current.open()}
    >
      {content}
    </button>
  );
};

export default CloudinaryUploadWidget;
