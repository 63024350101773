import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ProfileNavbar from "../../components/Profile_Components/ProfileNavbar";
import BreadcrumbLocation from "../../components/BreadcrumbLocation";
import { Link } from "react-router-dom";
import { getAdminOrders } from "../../actions/orderActions";

const ListOrdersScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthenticated, user } = useSelector((state) => state.userLogin);
  const { orders } = useSelector((state) => state.adminOrders);

  useEffect(async () => {
    if (isAuthenticated === true && user && user.isAdmin) {
      dispatch(getAdminOrders());
    }
    if (isAuthenticated === false || (user && !user.isAdmin)) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Edit Profile"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar></ProfileNavbar>
        <>
          <div className="container ms-sm-5">
            <h4 className="mb-4 px-0">Admin Orders</h4>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-12">
                  <div class="list-group mx-0">
                    <div class="list-group ">
                      {orders.map((order, index) => (
                        <Link
                          to={`/admin/order/${order._id}`}
                          class="list-group-item list-group-item-action"
                          key={`${index}`}
                        >
                          <div class="hstack gap-3">
                            <div class="">
                              <img
                                src={order.thumbnail}
                                class="img-fluid"
                                alt="..."
                                style={{ maxWidth: 140 }}
                              ></img>
                            </div>
                            <div class="text-truncate">
                              <h6 class=" mb-1 text-truncate">{order.title}</h6>

                              <p class="m-0">
                                <small>
                                  Order Id:{" "}
                                  <span class="badge text-bg-light">
                                    {order._id}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  Ordered:{" "}
                                  <span class="badge text-bg-light">
                                    {order.orderDateFormatted}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  Status:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    {order.shippingStatus.status}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  Order price:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    ${order.price}
                                  </span>
                                </small>
                              </p>
                              <p>
                                <small>
                                  Size:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    {order.size}
                                  </span>
                                </small>
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ListOrdersScreen;
