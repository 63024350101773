import React from "react";
import { Link } from "react-router-dom";
import AboutNavigation from "../components/AboutNavigation";

const GuideScreen = () => {
  return (
    <div className="bg-light">
      <div className="container d-flex flex-row">
        <div className="mt-4 me-0 me-lg-4">
          <AboutNavigation></AboutNavigation>
        </div>
        <div className="col-lg-7 mt-4 ms-sm-5">
          <h4>Guide to ReShup</h4>
          <h6>Browse the ReShup most frequently asked questions</h6>
          <div class="list-group mx-0 mt-3">
            <Link
              to={`/guide/getting_started`}
              class="list-group-item list-group-item-action px-0"
            >
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
            <Link to={`#`} class="list-group-item list-group-item-action px-0">
              <div class="hstack gap-3">
                <div class="p-3">
                  <img
                    src="https://picsum.photos/180/140"
                    class="img-fluid"
                    alt="..."
                  ></img>
                </div>
                <div class="">
                  <h6 class=" mb-1 ">Get Started on ReShup Today!</h6>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideScreen;
