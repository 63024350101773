import axios from "axios";
import {
  DISPLAY_ERROR_REQUEST,
  DISPLAY_MESSAGE_REQUEST,
  HIDE_MESSAGE_REQUEST,
} from "../constants/alertConstants";
import {
  USER_INFORMATION_FAIL,
  USER_INFORMATION_REQUEST,
  USER_INFORMATION_SUCCESS,
  USER_STRIPE_ACCOUNT_FAIL,
  USER_STRIPE_ACCOUNT_REQUEST,
  USER_STRIPE_ACCOUNT_SUCCESS,
  USER_STRIPE_LINK_FAIL,
  USER_STRIPE_LINK_REQUEST,
  USER_STRIPE_LINK_SUCCESS,
  USER_LOCATION_FAIL,
  USER_LOCATION_REQUEST,
  USER_LOCATION_SUCCESS,
  USER_LISTINGS_FAIL,
  USER_LISTINGS_REQUEST,
  USER_LISTINGS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LIKE_REQUEST,
  USER_LIKED_STATUS_REQUEST,
  USER_LIKED_LISTINGS_REQUEST,
  USER_LIKE_SUCCESS,
  USER_LIKED_STATUS_SUCCESS,
  USER_LIKED_LISTINGS_SUCCESS,
  USER_LIKE_FAIL,
  USER_UNLIKE_SUCCESS,
  USER_INQUIRY_REQUEST,
  USER_INQUIRY_SUCCESS,
  USER_INQUIRY_FAIL,
} from "../constants/userConstants";

export const login = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const { data } = await axios.get("/api/users/login/");
    if (data) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: USER_LOGOUT });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  // localStorage.removeItem("userLogin");
  const { data } = await axios.get("/api/logout/");
  dispatch({ type: USER_LOGOUT });
};

const getCoords = async () => {
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  return [pos.coords.longitude, pos.coords.latitude];
};
export const setLocation =
  (defaultCoords = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LOCATION_REQUEST,
      });
      var coords =
        defaultCoords === null ? null : defaultCoords ?? (await getCoords());

      dispatch({
        type: USER_LOCATION_SUCCESS,
        payload: coords,
      });
      localStorage.setItem("userLocation", JSON.stringify(coords));
    } catch (error) {
      dispatch({
        type: USER_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getUserInformation =
  (id = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_INFORMATION_REQUEST,
      });

      if (id != "") {
        id += "/";
      }
      const { data } = await axios.get("/api/users/" + id + "profile");

      dispatch({
        type: USER_INFORMATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_LOGOUT });
      dispatch({
        type: USER_INFORMATION_FAIL,
        payload: message,
      });
    }
  };

export const getStripeAccount = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_STRIPE_ACCOUNT_REQUEST,
    });

    const { data } = await axios.get("/api/users/profile/stripeaccount/");
    // If there is a new user Created then there will be a message returned. The store will not add the message to intial state.
    dispatch({
      type: USER_STRIPE_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: USER_STRIPE_ACCOUNT_FAIL,
      payload: message,
    });
  }
};
export const getStripeAccountLink = (accountId) => async (dispatch) => {
  try {
    dispatch({
      type: USER_STRIPE_LINK_REQUEST,
    });

    const { data } = await axios.post("/api/users/profile/stripelink/", {
      account: accountId,
    });
    // If there is a new user Created then there will be a message returned. The store will not add the message to intial state.
    dispatch({
      type: USER_STRIPE_LINK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_STRIPE_LINK_FAIL,
      payload: message,
    });
  }
};

export const getListings =
  (id = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LISTINGS_REQUEST });

      if (id != "") {
        id += "/";
      }
      const { data } = await axios({
        method: "get",
        url: "/api/users/" + id + "listings",
      });
      dispatch({ type: USER_LISTINGS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: USER_LOGOUT });
      dispatch({
        type: USER_LISTINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateProfile = (info) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST });
    const { data } = await axios({
      method: "put",
      url: `/api/users/profile`,
      data: info,
    });
    dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLikedStatus = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: USER_LIKE_REQUEST });
    const { data } = await axios.get(`/api/users/likedstatus/${listingId}`);
    dispatch({ type: USER_LIKED_STATUS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const likeItem = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: USER_LIKE_REQUEST });
    const { data } = await axios.get(`/api/users/like/${listingId}`);
    dispatch({ type: USER_LIKE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unlikeItem = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: USER_LIKE_REQUEST });
    const { data } = await axios.get(`/api/users/unlike/${listingId}`);
    dispatch({ type: USER_UNLIKE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserLikedItems = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: USER_LIKE_REQUEST });
    const { data } = await axios.get("/api/users/liked-items");
    dispatch({ type: USER_LIKED_LISTINGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendReshupInquiry = (inquiryInformation) => async (dispatch) => {
  try {
    dispatch({ type: USER_INQUIRY_REQUEST });

    const { data } = await axios.post(
      "/api/users/send-inquiry",
      inquiryInformation
    );

    dispatch({ type: USER_INQUIRY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_INQUIRY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
