var baseUrl = "http://localhost:5000";
var env_name = "test";

if (window.location.hostname === "test.reshup.com") {
  baseUrl = "https://test.api.reshup.com";
} else if (window.location.hostname === "reshup.com") {
  baseUrl = "https://api.reshup.com";
  env_name = "prod";
}

exports.baseUrl = baseUrl;
exports.env_name = env_name;
