import React from "react";

const BreadcrumbLocation = ({ location }) => {
  return (
    <div className="container mt-3 d-flex justify-content-center d-none d-lg-block">
      <div className="row justify-content-between">
        <div className="col">
          <nav className="">
            {location && (
              <ol className="breadcrumb mb-0">
                {location.map((value, index) => (
                  <li className="breadcrumb-item active" key={index}>
                    {value}
                  </li>
                ))}
              </ol>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbLocation;
