import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getStripeCustomer,
  getStripePaymentMethods,
  getStripeAccount,
  removeStripePaymentMethod,
} from "../../actions/stripeActions";
import { Tooltip, ScrollSpy } from "bootstrap";
import ShippingModal from "../ShippingModal";
import PaymentModal from "../PaymentModal";
import LoadingSpinner from "../LoadingSpinner";

const ProfileSettings = () => {
  const location = useLocation();

  const cardBrandToUrl = {
    visa: "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.40.50_PM_rztnse.png",
    mastercard:
      "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.04_PM_alvl6t.png",
    amex: "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.33_PM_uuhxfl.png",
    discover:
      "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.22_PM_mczwbs.png",
  };
  const dispatch = useDispatch();
  const [paymentMethodId, setPaymentMethodId] = useState("");

  // User Information request was done above this component
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, token } = userLogin;
  const stripeCustomer = useSelector((state) => state.stripeCustomer);
  const { loading: customerLoading, customer } = stripeCustomer;

  const stripePaymentMethods = useSelector(
    (state) => state.stripePaymentMethods
  );
  const { loading: paymentMethodsLoading, paymentMethods } =
    stripePaymentMethods;

  const stripeAccount = useSelector((state) => state.stripeAccount);
  const {
    loading: stripeAccountLoading,
    stripeAccount: account,
    accountLink,
  } = stripeAccount;
  // const shippingTooltipRef = useRef();
  // const paymentTooltipRef = useRef();
  // const stripeAccountTooltipRef = useRef();
  // const shippingTooltipRef = useRef();

  useEffect(() => {
    // Access the hash from the location object
    const hash = location.hash;

    // Check if the hash contains 'scrollspyPayoutSettings'
    if (hash && hash.includes("scrollspyPayoutSettings")) {
      const srollButton = document.getElementById("payoutSettingScrollButton");
      srollButton.click();
    }
    // var shippingTooltip = shippingTooltipRef.current;
    // new Tooltip(shippingTooltip);
    // var shippingTooltip = shippingTooltipRef.current;
    // var paymentTooltip = paymentTooltipRef.current;
    // var stripeAccountTooltip = stripeAccountTooltipRef.current;
    // new Tooltip(shippingTooltip);
    // new Tooltip(paymentTooltip);
    // new Tooltip(stripeAccountTooltip);
    // Need to look into only getting this information when needed
    dispatch(getStripeCustomer());
    dispatch(getStripePaymentMethods());
    dispatch(getStripeAccount());
  }, [dispatch]);

  const generateStripeLink = (accountId) => {
    if (isAuthenticated) {
      const config = {
        headers: {
          session_token: token,
        },
      };
      if (stripeAccount.charges_enabled) {
        axios
          .post(
            "/api/users/profile/stripelogin/",
            {
              account: accountId,
            },
            config
          )
          .then((response) => {
            // Opens new tab
            window.open(response.data.url, "_blank");
          });
      } else {
        axios
          .post(
            "/api/users/profile/stripelink/",
            { account: accountId },
            config
          )
          .then((response) => {
            // Opens on the current tab
            window.location.replace(response.data.url);
          });
      }
    }
  };

  const handleDeletePaymentMethod = async () => {
    if (paymentMethodId) {
      // Remove the payment method
      dispatch(removeStripePaymentMethod(paymentMethodId));
      setPaymentMethodId("");
      closeDeletePaymentMethodModal();
    }
  };
  const closeDeletePaymentMethodModal = () => {
    let deletePaymentMethodModal = document.getElementById(
      "deletePaymentMethodModalCloseButton"
    );
    deletePaymentMethodModal.click();
  };
  return (
    <div className="container ms-sm-5">
      <h4 className="mb-4 px-0">Profile Settings</h4>
      {/* <p className="fw-light lh-sm px-0 mb-4">
        <small>
          Here you can add addresses payment methods and configure your payout
          settings for a seller account.
        </small>
      </p> */}
      <a id="payoutSettingScrollButton" href="#scrollspyPayoutSettings"></a>
      <div className="col-lg-10">
        <div className="mb-3">
          <div className="row border-bottom">
            <div className="col-auto me-auto ">
              <h5 className="me-auto">Shipping Info</h5>
            </div>
            <div className="col-auto">
              <a
                class="link-primary text-decoration-none"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#shippingModal"
              >
                Edit
              </a>
            </div>
          </div>
          <div className="row mt-3">
            {customerLoading ? (
              <>
                {" "}
                <p class="placeholder-glow">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </>
            ) : customer && customer.shipping ? (
              <>
                {/* <h5>Address</h5> */}

                <div class="border rounded shadow-sm p-3">
                  <h6 class="mb-1">{customer.shipping.name}</h6>
                  <p class="text-body-secondary fw-light mb-0">
                    {customer.shipping.address.line1}
                  </p>
                  <p class="text-body-secondary fw-light mb-1">
                    {`${customer.shipping.address.city}, ${customer.shipping.address.state} ${customer.shipping.address.postal_code} US`}
                  </p>
                </div>
                {/* <div>
                  <h6 class="mb-0">{customer.shipping.name}</h6>
                  <p class="opacity-75">
                    {`${customer.shipping.address.line1} ${customer.shipping.address.city}, ${customer.shipping.address.state} ${customer.shipping.address.postal_code}`}
                  </p>
                </div> */}
              </>
            ) : (
              <p class="opacity-75 p-2">
                You do not have any shipping information on file.
              </p>
            )}
          </div>
        </div>

        <div>
          <div className="row border-bottom">
            <h5>Payment Info</h5>
            {/* <div className="col-auto me-auto ">
            </div>
            <div className="col-auto">
              <a
                class="link-primary text-decoration-none"
                style={{ cursor: "pointer" }}
              >
                + Add a new payment method
              </a>
            </div> */}
          </div>
          <div className="row mt-3">
            {paymentMethodsLoading ? (
              <>
                {" "}
                <p class="placeholder-glow">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </>
            ) : (
              paymentMethods && (
                <>
                  <div class="vstack gap-3 px-0">
                    {paymentMethods.map((paymentMethod, index) => (
                      <div
                        class="container border rounded shadow-sm p-3"
                        key={index}
                      >
                        <div class="hstack gap-3">
                          <div>
                            <img
                              className="img-fluid"
                              style={{ width: 48 }}
                              src={cardBrandToUrl[paymentMethod.card.brand]}
                            ></img>
                          </div>
                          <div>
                            <p class="text-body-secondary fw-light mb-0 text-capitalize">
                              {paymentMethod.card.brand} ****
                              {paymentMethod.card.last4}{" "}
                              {paymentMethod.card.exp_month}/
                              {paymentMethod.card.exp_year}
                            </p>
                            <p class="text-body-secondary fw-light mb-1">
                              {`${paymentMethod.billing_details.address.line1}, ${paymentMethod.billing_details.address.city} ${paymentMethod.billing_details.address.state} ${paymentMethod.billing_details.address.postal_code} US`}
                            </p>
                          </div>
                          <div className="ms-auto d-none d-md-block">
                            <a
                              href=""
                              class="link-primary text-decoration-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deletePaymentMethodModal"
                              onClick={() =>
                                setPaymentMethodId(paymentMethod.id)
                              }
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                        <div className="ms-auto d-md-none">
                          <a
                            href=""
                            class="link-primary text-decoration-none"
                            data-bs-toggle="modal"
                            data-bs-target="#deletePaymentMethodModal"
                            onClick={() => setPaymentMethodId(paymentMethod.id)}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-8"></div>
                  {/* {paymentMethods.map((paymentMethod, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-4 mt-2">
                      <h5>Payment</h5>
                      <p className="opacity-75">{`${paymentMethod.card.brand} ending in ${paymentMethod.card.last4}, expires ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</p>
                    </div>
                    <div className="col-md-4 mt-2">
                      <h5>Billing Address</h5>
                      <div>
                        <h6 class="mb-0">
                          {paymentMethod.billing_details.name}
                        </h6>
                        <p class="opacity-75">
                          {`${paymentMethod.billing_details.address.line1} ${paymentMethod.billing_details.address.city}, ${paymentMethod.billing_details.address.state} ${paymentMethod.billing_details.address.postal_code}`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))} */}
                  {paymentMethods.length === 0 && (
                    <p class="opacity-75 p-2">
                      You do not have any payment methods on file.
                    </p>
                  )}
                </>
              )
            )}

            <p className="mt-3">
              <a
                href=""
                class="link-primary text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#paymentModal"
              >
                + Add a new payment method
              </a>
            </p>
          </div>
        </div>

        <div>
          <div className="row border-bottom">
            <div className="col-auto me-auto">
              <h5 id="scrollspyPayoutSettings">Payout settings</h5>
            </div>
          </div>
          <div className="row mt-3">
            <div className="">
              <img
                src="https://stripe.com/img/about/logos/logos/blue@2x.png"
                alt="Stripe logo"
                style={{ width: 92, height: 38 }}
              />
              <p class="opacity-75 ">
                To create listings and receive payments, sellers must configure
                their payout settings. This can be done conveniently in a
                dedicated window powered by Stripe. Sellers can manage their
                payout transfer methods, set preferences, and track payments
                from ReShup within this window.
              </p>
            </div>
            {stripeAccountLoading ? (
              <>
                {" "}
                <p class="placeholder-glow">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </>
            ) : account ? (
              <div>
                {account.charges_enabled ? (
                  <>
                    <p>
                      Payout: <strong>Active, Seller level 1</strong>
                    </p>
                    {accountLink && (
                      <button
                        type="button"
                        className=" btn btn-sm btn-dark"
                        onClick={() => window.open(accountLink, "_blank")}
                      >
                        Manage Account
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <p>
                      Payout: <strong>Not Linked</strong>
                    </p>
                    <p class="card-text" style={{ fontSize: 13 }}>
                      {account.requirements.errors.map((issue, index) => (
                        <small class="text-muted" key={index}>
                          {issue.reason}
                        </small>
                      ))}
                      {/* {account.requirements.errors.length === 0 && (
                        <small class="text-muted">
                          If you have completed the onboarding flow it can take
                          a few days for your account to be approved.
                        </small>
                      )} */}
                    </p>
                    {accountLink && (
                      <button
                        type="button"
                        className=" btn btn-sm btn-dark"
                        onClick={() => window.open(accountLink, "_blank")}
                      >
                        Become a ReShup seller
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="bg-light rounded shadow-sm">
                <p className="pt-2 fst-italic">
                  Enter your shipping address to become a ReShup seller. We need
                  this information so we can print shipping labels.
                </p>
              </div>
            )}
          </div>
          {/* <div className="row mt-3">
            <div className="">
              <img
                src="https://stripe.com/img/about/logos/logos/blue@2x.png"
                alt="Stripe logo"
                style={{ width: 92, height: 38 }}
              />
              <p class="opacity-75 ">
                Payout settings will be managed in a separate window powered by
                Stripe. From here, sellers can manage payout transfer methods,
                select preferences, and track payments from StockX.
              </p>
              <p>
                Payout: <strong>Active</strong>
              </p>

              <>
                <p className="mb-0">Not Linked</p>
                <p class="card-text" style={{ fontSize: 13 }}>
                  <small class="text-muted">
                    reason this has not been approved
                  </small>

                  <small class="text-muted">
                    If you have completed the onboarding flow it can take a few
                    days for your account to be approved.
                  </small>
                </p>
              </>

              <button
                type="button"
                className=" btn btn-sm btn-light"
                onClick={() => window.open(accountLink, "_blank")}
              >
                Manage Account
              </button>
            </div>
            <div className="col-md-3 mt-2"></div>
          </div> */}
        </div>
      </div>
      <div className="modal fade" id="deletePaymentMethodModal">
        <div className="modal-dialog modal-dialog-centered">
          <div class="modal-content rounded-3 shadow">
            <div class="modal-body p-4 text-center">
              <h5 class="mb-0">Delete Payment Method</h5>
              <p class="mb-0">
                Are you sure you want to delete this payment method?
              </p>
            </div>
            <div class="modal-footer flex-nowrap p-0">
              <button
                type="button"
                class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0"
                data-bs-dismiss="modal"
                id="deletePaymentMethodModalCloseButton"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end"
                onClick={() => handleDeletePaymentMethod()}
              >
                <strong>Yes, Delete</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ShippingModal></ShippingModal>
      <PaymentModal></PaymentModal>
    </div>
  );
};

export default ProfileSettings;
