import React from "react";
import { Link } from "react-router-dom";

const GettingStartedGuideScreen = () => {
  return (
    <div className="bg-light py-5">
      <div className="container bg-white col-lg-6 p-3">
        <h3 className="text-center">Guide to ReShup</h3>
        <hr></hr>
        <Link to="/guide" class="link-primary text-decoration-none">
          <small> &#8592; Back to guide</small>
        </Link>
        <h4>Get started on ReShup today!</h4>
        <p>lorem</p>
        <div class="row ">
          <div class="col-md-7 order-md-2">
            <h4 class="fw-normal lh-1">
              {/* Discover <span class="text-body-secondary">Your Sole Mate</span> */}
              <span class="badge bg-secondary">Step 1</span>
            </h4>
            <h5>Create Your Seller Account</h5>
            <p class="lead">
              Begin your journey on ReShup by signing up for a seller account.
              Click on "Sign Up" and follow the prompts to create a personalized
              account. This will be your dedicated space for managing and
              showcasing your sneaker collection.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr></hr>
        <div class="row ">
          <div class="col-md-7 order-md-2">
            <h4 class="fw-normal lh-1">
              {/* Discover <span class="text-body-secondary">Your Sole Mate</span> */}
              <span class="badge bg-secondary">Step 2</span>
            </h4>
            <h5>Link Your Payout Settings with Stripe</h5>
            <p class="lead">
              To ensure a seamless selling experience, link your preferred
              payout settings using Stripe. Access the payout settings from your
              dashboard, where you can manage transfer methods, set your payment
              preferences, and track payments from ReShup.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr></hr>
        <div class="row ">
          <div class="col-md-7 order-md-2">
            <h4 class="fw-normal lh-1">
              {/* Discover <span class="text-body-secondary">Your Sole Mate</span> */}
              <span class="badge bg-secondary">Step 3</span>
            </h4>
            <h5>List Your Sneakers</h5>
            <p class="lead">
              Click on "Sell" within your dashboard to start the listing
              process. Capture high-quality images of your sneakers, provide
              detailed descriptions, including brand, size, and condition, and
              set a competitive price.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr></hr>
        <div class="row ">
          <div class="col-md-7 order-md-2">
            <h4 class="fw-normal lh-1">
              {/* Discover <span class="text-body-secondary">Your Sole Mate</span> */}
              <span class="badge bg-secondary">Step 4</span>
            </h4>
            <h5>Engage with Potential Buyers</h5>
            <p class="lead">
              As potential buyers show interest in your listings, engage with
              them through the platform's offering system. Answer inquiries,
              negotiate securely, and finalize transactions within the
              user-friendly interface.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr></hr>
        <div class="row ">
          <div class="col-md-7 order-md-2">
            <h4 class="fw-normal lh-1">
              {/* Discover <span class="text-body-secondary">Your Sole Mate</span> */}
              <span class="badge bg-secondary">Step 5</span>
            </h4>
            <h5>Monitor Sales and Payouts</h5>
            <p class="lead">
              Keep a close eye on your seller dashboard to monitor sales, track
              payouts, and manage your overall selling experience. ReShup
              provides real-time insights to ensure you have full visibility
              into your transactions.
            </p>
            <p class="lead">
              Congratulations! With your seller account on ReShup, you've taken
              the first steps towards showcasing and selling your sneakers in a
              secure and user-friendly environment. Happy selling!
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <svg
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              width="500"
              height="500"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
              <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                500x500
              </text>
            </svg>
          </div>
        </div>

        <hr></hr>
      </div>
    </div>
  );
};

export default GettingStartedGuideScreen;
