import React from "react";
import { Link } from "react-router-dom";
const MarketplaceListingCard = ({ listingData }) => {
  return (
    <div className="col mt-0 p-2">
      <div className="card p-2 position-relative h-100 ">
        {/* <span class=" mt-1 me-1 badge bg-secondary position-absolute top-0 end-0">
          New
        </span> */}
        {listingData.dateString && (
          <small className="mt-1 me-1 position-absolute top-0 end-0 text-muted">
            {listingData.dateString}
          </small>
        )}
        <img
          src={listingData["photo"]}
          className="card-img-top"
          alt="..."
        ></img>
        <div className="card-body p-1">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              ${Math.floor(listingData["lowestPrice"])}
            </h5>
            {/* <h5 className="card-title">${listingData["releaseDate"]}</h5> */}
          </div>
          <p
            className="card-text lh-sm text-capitalize p-0 m-1 "
            style={{ fontSize: "smaller" }}
          >
            {listingData["listingName"]}
          </p>
          <Link
            to={`/listing/${listingData["_id"]}`}
            className="stretched-link p-0 m-0"
          ></Link>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceListingCard;
