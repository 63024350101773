import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Photo } from "./Photo";

const SortablePhoto = (props) => {
  const sortable = useSortable({ id: props.url });
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    // <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
    //   <div className="border m-3">
    //     <img src={props.url} style={{ height: 100 }}></img>
    //   </div>
    // </div>
    <div
      class="d-flex justify-content-center align-items-center"
      // style={{ maxWidth: 150, maxHeight: 150 }}
    >
      <div
        className="p-0 m-0 position-relative"
        // style={{ maxWidth: 150, maxHeight: 150 }}
        style={{ width: 150, height: 150 }}
      >
        {props.index == 0 && (
          <div class="position-absolute top-0 start-0 text-primary">
            <span class="badge text-bg-primary">Cover Photo</span>
          </div>
        )}
        {props.index > 0 && props.numPhotos > 1 && (
          <div class="position-absolute top-0 end-0">
            <span
              class="badge btn btn-danger"
              onClick={() =>
                props.setPhotos((prev) => prev.toSpliced(props.index, 1))
              }
            >
              X
            </span>
          </div>
        )}
        <Photo
          ref={setNodeRef}
          style={style}
          {...props}
          {...attributes}
          {...listeners}
        ></Photo>
      </div>
    </div>
  );
};

export default SortablePhoto;
