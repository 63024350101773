import {
  MARKETPLACE_BRAND_FAIL,
  MARKETPLACE_BRAND_REQUEST,
  MARKETPLACE_BRAND_SUCCESS,
  MARKETPLACE_FILTER_FAIL,
  MARKETPLACE_FILTER_REQUEST,
  MARKETPLACE_FILTER_SUCCESS,
  MARKETPLACE_LISTING_REQUEST,
  MARKETPLACE_LISTING_SUCCESS,
  MARKETPLACE_LISTING_FAIL,
  MARKETPLACE_PRODUCT_CREATE_REQUEST,
  MARKETPLACE_PRODUCT_CREATE_SUCCESS,
  MARKETPLACE_PRODUCT_CREATE_FAIL,
  MARKETPLACE_PRODUCT_CREATE_RESET,
  MARKETPLACE_PRODUCT_DELETE_REQUEST,
  MARKETPLACE_PRODUCT_DELETE_SUCCESS,
  MARKETPLACE_PRODUCT_DELETE_FAIL,
  MARKETPLACE_PRODUCT_DELETE_RESET,
  MARKETPLACE_LISTING_SELLING_REQUEST,
  MARKETPLACE_LISTING_SELLING_SUCCESS,
  MARKETPLACE_LISTING_SELLING_FAIL,
  SELLER_LISTINGS_FILTER_REQUEST,
  SELLER_LISTINGS_FILTER_SUCCESS,
  SELLER_LISTINGS_FILTER_FAIL,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL,
  SELLER_LISTING_REQUEST,
  SELLER_LISTING_SUCCESS,
  SELLER_LISTING_FAIL,
  MARKETPLACE_FILTER_RESET,
  MARKETPLACE_FILTER_MORE_SUCCESS,
  MARKETPLACE_FILTER_MORE_REQUEST,
  SELLER_LISTINGS_FILTER_MORE_REQUEST,
  SELLER_LISTINGS_FILTER_MORE_SUCCESS,
  SELLER_LISTINGS_FILTER_RESET,
  EDITORS_PICKS_REQUEST,
  EDITORS_PICKS_SUCCESS,
  EDITORS_PICKS_FAIL,
  MARKETPLACE_ROOT_LISTINGS_REQUEST,
  MARKETPLACE_ROOT_LISTINGS_SUCCESS,
  MARKETPLACE_ROOT_LISTINGS_FAIL,
  MARKETPLACE_ROOT_LISTING_GET_REQUEST,
  MARKETPLACE_ROOT_LISTING_GET_SUCCESS,
  MARKETPLACE_ROOT_LISTING_GET_FAIL,
  MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST,
  MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS,
  MARKETPLACE_ROOT_LISTING_UPSERT_FAIL,
  MARKETPLACE_ROOT_LISTING_UPSERT_RESET,
  CHEAPEST_SELLER_LISTINGS_REQUEST,
  CHEAPEST_SELLER_LISTINGS_SUCCESS,
  CHEAPEST_SELLER_LISTINGS_FAIL,
} from "../constants/marketplaceConstants";

export const marketplaceListingsReducer = (
  state = {
    listingsLoading: false,
    listings: [],
    totalDocuments: 0,
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_FILTER_REQUEST:
    case MARKETPLACE_FILTER_MORE_REQUEST:
      return {
        listingsLoading: true,
        listings: state.listings,
        totalDocuments: state.totalDocuments,
      };
    case MARKETPLACE_FILTER_SUCCESS:
      return {
        listingsLoading: false,
        listings: action.payload.listings,
        totalDocuments: action.payload.totalDocuments,
      };
    case MARKETPLACE_FILTER_MORE_SUCCESS:
      return {
        ...state,
        listingsLoading: false,
        listings: [...state.listings, ...action.payload.listings],
        // totalDocuments: action.payload.totalDocuments,
      };
    case MARKETPLACE_FILTER_RESET:
      return {
        listingsLoading: false,
        listings: [],
        totalDocuments: 0,
      };
    case MARKETPLACE_FILTER_FAIL:
      return {
        ...state,
        listingsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const marketplaceBrandsReducer = (
  state = {
    brandsLoading: false,
    brands: [],
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_BRAND_REQUEST:
      return {
        ...state,
        brandsLoading: true,
      };
    case MARKETPLACE_BRAND_SUCCESS:
      return {
        brandsLoading: false,
        brands: action.payload,
      };
    case MARKETPLACE_BRAND_FAIL:
      return {
        ...state,
        brandsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const marketplaceListingReducer = (
  state = {
    listingLoading: false,
    listingInformation: {},
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_LISTING_REQUEST:
      return {
        ...state,
        listingLoading: true,
      };
    case MARKETPLACE_LISTING_SUCCESS:
      return {
        listingLoading: false,
        listingInformation: action.payload,
      };
    case MARKETPLACE_LISTING_FAIL:
      return {
        ...state,
        listingLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const marketplaceListingSellingReducer = (
  state = {
    listingLoading: false,
    listingInformation: {},
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_LISTING_SELLING_REQUEST:
      return {
        listingLoading: true,
      };
    case MARKETPLACE_LISTING_SELLING_SUCCESS:
      return {
        listingLoading: false,
        listingInformation: action.payload,
      };
    case MARKETPLACE_LISTING_SELLING_FAIL:
      return {
        listingLoading: false,
        listingInformation: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
export const createMarketplaceListingReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case MARKETPLACE_PRODUCT_CREATE_SUCCESS:
      return {
        loading: false,
        redirect: true,
        successMessage: action.payload.message,
        listingId: action.payload.listingId,
        patch: action.payload.patch,
      };
    case MARKETPLACE_PRODUCT_CREATE_FAIL:
      return {
        loading: false,
        redirect: action.code == 404,
        listingId: action.listingId,
        error: action.payload,
        patch: action.listingId !== undefined,
      };
    case MARKETPLACE_PRODUCT_CREATE_RESET:
      return {
        loading: false,
        redirect: false,
        error: state.error,
        successMessage: state.successMessage,
        patch: state.patch,
      };
    default:
      return state;
  }
};
export const deleteMarketplaceListingReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case MARKETPLACE_PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        successMessage: action.payload.message,
      };
    case MARKETPLACE_PRODUCT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case MARKETPLACE_PRODUCT_DELETE_RESET:
      return {
        loading: false,
        success: false,
        successMessage: state.successMessage,
        patch: state.patch,
      };
    default:
      return state;
  }
};
export const sellerListingsReducer = (
  state = {
    listingsLoading: false,
    sellerListings: [],
    totalDocuments: 0,
  },
  action
) => {
  switch (action.type) {
    case SELLER_LISTINGS_FILTER_REQUEST:
    case SELLER_LISTINGS_FILTER_MORE_REQUEST:
      return {
        ...state,
        listingsLoading: true,
      };
    case EDITORS_PICKS_REQUEST:
      return {
        listingsLoading: true,
        sellerListings: [],
        totalDocuments: 0,
      };

    case SELLER_LISTINGS_FILTER_SUCCESS:
      return {
        listingsLoading: false,
        sellerListings: action.payload.sellerListings,
        totalDocuments: action.payload.totalDocuments,
      };
    case SELLER_LISTINGS_FILTER_MORE_SUCCESS:
      return {
        listingsLoading: false,
        sellerListings: [
          ...state.sellerListings,
          ...action.payload.sellerListings,
        ],
        totalDocuments: action.payload.totalDocuments,
      };
    case EDITORS_PICKS_SUCCESS:
      return {
        listingsLoading: false,
        sellerListings: action.payload.sellerListings,
        totalDocuments: 0,
      };
    case SELLER_LISTINGS_FILTER_RESET:
      return {
        listingsLoading: false,
        sellerListings: [],
        totalDocuments: 0,
      };

    case SELLER_LISTINGS_FILTER_FAIL:
    case EDITORS_PICKS_FAIL:
      return {
        sellerListings: [],
        totalDocuments: 0,
        listingsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const sellerListingReducer = (
  state = {
    listingLoading: false,
    sellerListing: null,
  },
  action
) => {
  switch (action.type) {
    case SELLER_LISTING_REQUEST:
      return {
        listingLoading: true,
        sellerListing: state.sellerListing,
      };
    case SELLER_LISTING_SUCCESS:
      return {
        listingLoading: false,
        sellerListing: action.payload,
      };

    case SELLER_LISTING_FAIL:
      return {
        listingLoading: false,
        sellerListing: null,
        error: action.error,
      };
    default:
      return state;
  }
};
export const sellerListingsSizesReducer = (
  state = {
    listingsLoading: true,
    mostAvailableSize: "",
    sizes: [],
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST:
      return {
        ...state,
        listingsLoading: true,
      };
    case MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS:
      return {
        listingsLoading: false,
        mostAvailableSize: action.payload.mostAvailableShoe,
        sizes: action.payload.sizesAvailable,
      };

    case MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL:
      return {
        ...state,
        listingsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminRootMarketplaceListingsReducer = (
  state = { rootMarketplaceListings: [] },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_ROOT_LISTINGS_REQUEST:
      return { rootMarketplaceListings: state.rootMarketplaceListings };
    case MARKETPLACE_ROOT_LISTINGS_SUCCESS:
      return { rootMarketplaceListings: action.payload };
    case MARKETPLACE_ROOT_LISTINGS_FAIL:
      return {
        rootMarketplaceListings: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminRootMarketplaceListingInformationReducer = (
  state = { rootMarketplaceListing: null, redirect: false },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_ROOT_LISTING_GET_REQUEST:
    case MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST:
      return { rootMarketplaceListing: state.rootMarketplaceListing };
    case MARKETPLACE_ROOT_LISTING_GET_SUCCESS:
      return { rootMarketplaceListing: action.payload };
    case MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS:
      return {
        redirect: true,
        rootMarketplaceListing: action.payload,
      };
    case MARKETPLACE_ROOT_LISTING_GET_FAIL:
    case MARKETPLACE_ROOT_LISTING_UPSERT_FAIL:
      return {
        rootMarketplaceListing: null,
        error: action.payload,
      };
    case MARKETPLACE_ROOT_LISTING_UPSERT_RESET:
      return {
        rootMarketplaceListing: null,
        redirect: false,
      };
    default:
      return state;
  }
};

export const cheapestSellerListingsReducer = (
  state = {
    listingsLoading: false,
    listings: [],
  },
  action
) => {
  switch (action.type) {
    case CHEAPEST_SELLER_LISTINGS_REQUEST:
      return {
        listingsLoading: true,
        listings: [],
      };
    case CHEAPEST_SELLER_LISTINGS_SUCCESS:
      return {
        listingsLoading: false,
        listings: action.payload,
      };
    case CHEAPEST_SELLER_LISTINGS_FAIL:
      return {
        ...state,
        listingsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
