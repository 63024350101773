import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_MAPS_API_KEY } from "../config/apiKeys";
import {
  getStripeCustomer,
  updateCustomerShipping,
} from "../actions/stripeActions";

import {
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";

const ShippingForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const stripeUpdatedCustomerShipping = useSelector(
    (state) => state.stripeUpdatedCustomerShipping
  );
  const { loading } = stripeUpdatedCustomerShipping;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    var addressElement = elements.getElement("address");

    const { complete, value } = await addressElement.getValue();

    if (complete) {
      dispatch(updateCustomerShipping(value));
      // dispatch(getStripeCustomer());
    }
  };

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <AddressElement
        options={{
          mode: "shipping",
          // autocomplete: {
          //   mode: "google_maps_api",
          //   // apiKey: "AIzaSyA1PnZA_zFAS2B36pflpfyk7vlHXago02o",
          //   // apiKey: "AIzaSyCbQiS1l7jZLICasyQfSZsgjg0iYJrO9Wk",
          //   apiKey: GOOGLE_MAPS_API_KEY,
          // },
          allowedCountries: ["US"],
          fields: {
            phone: "always",
          },
          validation: {
            phone: {
              required: "always",
            },
          },
        }}
      />
      <div class="d-grid mt-3">
        {loading ? (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></span>
            Save Shipping Information
          </button>
        ) : (
          <button disabled={!stripe} type="submit" className="btn btn-primary">
            Save Shipping Information
          </button>
        )}
      </div>
    </form>
  );
};

export default ShippingForm;
