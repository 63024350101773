import {
  CREATE_OFFER_FAIL,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_RESET,
  FOR_PURCHASE_OFFERS_REQUEST,
  FOR_PURCHASE_OFFERS_SUCCESS,
  FOR_PURCHASE_OFFERS_FAIL,
  FOR_SALE_OFFERS_REQUEST,
  FOR_SALE_OFFERS_SUCCESS,
  FOR_SALE_OFFERS_FAIL,
  GET_OFFER_REQUEST,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAIL,
  CANCEL_OFFER_SUCCESS,
  CANCEL_OFFER_REQUEST,
  CANCEL_OFFER_FAIL,
  OFFER_STATUS_RESET,
  ACCEPT_OFFER_REQUEST,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_FAIL,
  POTENTIAL_OFFERS_REQUEST,
  POTENTIAL_OFFERS_SUCCESS,
  POTENTIAL_OFFERS_FAIL,
} from "../constants/offerConstants";

export const offerReducer = (
  state = { offer: null, loading: false },
  action
) => {
  switch (action.type) {
    case CREATE_OFFER_REQUEST:
    case GET_OFFER_REQUEST:
      return {
        offer: null,
        loading: true,
      };
    case GET_OFFER_SUCCESS:
      return {
        offer: action.payload.offer,
        loading: false,
      };
    case CREATE_OFFER_SUCCESS:
      return {
        success: true,
        offer: action.payload.offer,
        loading: false,
      };
    case CREATE_OFFER_FAIL:
    case GET_OFFER_FAIL:
      return {
        loading: false,
        offer: null,
        error: action.payload,
      };
    case CREATE_OFFER_RESET:
      return {
        offer: null,
        loading: false,
      };
    default:
      return state;
  }
};

export const offerStatusReducer = (
  state = { offerCanceled: null, offerAccepted: null, loading: false },
  action
) => {
  switch (action.type) {
    case CANCEL_OFFER_REQUEST:
    case ACCEPT_OFFER_REQUEST:
      return {
        offerCanceled: null,
        offerAccepted: null,
        loading: true,
      };
    case CANCEL_OFFER_SUCCESS:
      return {
        offerCanceled: action.payload,
        offerAccepted: null,
        loading: false,
      };
    case ACCEPT_OFFER_SUCCESS:
      return {
        offerCanceled: false,
        offerAccepted: action.payload,
        loading: false,
      };
    case CANCEL_OFFER_FAIL:
    case ACCEPT_OFFER_FAIL:
      return {
        offerCanceled: null,
        offerAccepted: null,
        loading: false,
        error: action.payload,
      };
    case OFFER_STATUS_RESET:
      return {
        offerCanceled: false,
        offerAccepted: false,
        loading: false,
      };
    default:
      return state;
  }
};

export const forPurchaseOffersReducer = (
  state = { forPurchaseOffers: null, loading: false },
  action
) => {
  switch (action.type) {
    case FOR_PURCHASE_OFFERS_REQUEST:
      return {
        forPurchaseOffers: null,
        loading: true,
      };
    case FOR_PURCHASE_OFFERS_SUCCESS:
      return {
        forPurchaseOffers: action.payload.forPurchaseOffers,
        loading: false,
      };
    case FOR_PURCHASE_OFFERS_FAIL:
      return {
        loading: false,
        forPurchaseOffers: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const forSaleOffersReducer = (
  state = { forSaleOffers: null, loading: false, potentialOffers: 0 },
  action
) => {
  switch (action.type) {
    case FOR_SALE_OFFERS_REQUEST:
      return {
        potentialOffers: state.potentialOffers,
        forSaleOffers: null,
        loading: true,
      };
    case POTENTIAL_OFFERS_REQUEST:
      return {
        forSaleOffers: state.forSaleOffers,
        potentialOffers: state.potentialOffers,
        loading: true,
      };
    case POTENTIAL_OFFERS_SUCCESS:
      return {
        forSaleOffers: state.forSaleOffers,
        potentialOffers: action.payload.forSaleOffers.length,
        loading: false,
      };
    case FOR_SALE_OFFERS_SUCCESS:
      return {
        potentialOffers: state.potentialOffers,
        forSaleOffers: action.payload.forSaleOffers,
        loading: false,
      };
    case FOR_SALE_OFFERS_FAIL:
    case POTENTIAL_OFFERS_FAIL:
      return {
        loading: false,
        forSaleOffers: null,
        potentialOffers: 0,
        error: action.payload,
      };
    default:
      return state;
  }
};
