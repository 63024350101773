import React from "react";
import { Link } from "react-router-dom";

const EditListingCard = ({ listingInformation }) => {
  const {
    _id,
    photos,
    price,
    size,
    title,
    sellerInformation,
    shupShoeId,
    dateString,
  } = listingInformation;
  return (
    <div className="col mt-3 g-1 g-md-3">
      <div className="card p-2 h-100">
        <img src={photos[0]} className="card-img-top" alt="..."></img>
        <div className="card-body mt-1 bg-light p-1">
          <Link
            to={`/sellerlisting/${_id}`}
            className="text-reset text-decoration-none"
          >
            <h5 className="card-title">$ {price}</h5>
            <p className="card-text lh-sm mb-1" style={{ fontSize: 13 }}>
              {title}
            </p>
            <p className="card-text mb-1" style={{ fontSize: 13 }}>
              Size: <small className="text-muted">{size}</small>
            </p>
            <div className="d-flex flex-row">
              <img
                src={sellerInformation.photo}
                className="rounded-circle me-2"
                style={{ height: 25, width: 25, zIndex: 30 }}
              ></img>
              <p className="card-text" style={{ fontSize: 13 }}>
                <small className="text-muted">{sellerInformation.name}</small>
              </p>
            </div>
          </Link>
        </div>
        <Link
          to={`/sell/${shupShoeId}/${_id}`}
          className="btn btn-outline-secondary mt-2"
        >
          Edit listing
        </Link>
      </div>
    </div>
  );
};

export default EditListingCard;
