import React from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const MarketplaceSortDropdown = () => {
  const possibleSorts = {
    popular: "Popular",
    new: "New",
    "high-low": "Price (High-Low)",
    "low-high": "Price (Low-High)",
    "date-new": "Date (New)",
    "date-old": "Date (Old)",
  };
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSortOption = (sort) => {
    var tempSearchParams = searchParams;
    tempSearchParams.set("sort", sort);
    setSearchParams(tempSearchParams);
  };

  return (
    <div className="dropdown">
      <Link
        to="#"
        className="text-decoration-none text-reset dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        Sort By: {possibleSorts[searchParams.get("sort")] || "Popular"}
      </Link>
      <ul className="dropdown-menu text-small shadow">
        <li>
          <button
            className="dropdown-item"
            href="#"
            onClick={() => updateSortOption("popular")}
          >
            Popular
          </button>
        </li>
        {/* <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={() => updateSortOption("new")}
          >
            New
          </button>
        </li> */}
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={() => updateSortOption("high-low")}
          >
            Price (High-Low)
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={() => updateSortOption("low-high")}
          >
            Price (Low-High)
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={() => updateSortOption("date-new")}
          >
            Date (New)
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={() => updateSortOption("date-old")}
          >
            Date (Old)
          </button>
        </li>
      </ul>
    </div>
  );
};

export default MarketplaceSortDropdown;
