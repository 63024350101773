import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";

const ProfileSellingCompleted = () => {
  const [showCurrent, setShowCurrent] = useState(false);
  const sellerOrders = useSelector((state) => state.sellerOrders);
  const { orders: currentOrders, loading: currentOrdersLoading } = sellerOrders;
  const sellerOrdersCompleted = useSelector(
    (state) => state.sellerOrdersCompleted
  );
  const {
    orders: completedSellerOrders,
    loading: completedSellerOrdersLoading,
  } = sellerOrdersCompleted;
  return (
    <>
      <div className="container ms-sm-5">
        <h4 className="mb-4 px-0">Selling</h4>
        <div className="col-lg-10">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-dark active"
                    id="ordersSellingCurrent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ordersSellingCurrent"
                    type="button"
                    role="tab"
                    onClick={() => setShowCurrent(false)}
                  >
                    Current
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link link-dark"
                    id="ordersSellingCompleted-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ordersSellingCompleted"
                    type="button"
                    role="tab"
                    onClick={() => setShowCurrent(true)}
                  >
                    Completed
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`tab-pane ${showCurrent ? "d-none" : ""}`}
            id="ordersSellingCurrent"
            role="tabpanel"
          >
            <div class="list-group mx-0">
              {currentOrders &&
                currentOrders.map((order, index) => (
                  <Link
                    to={`/profile/selling/${order._id}`}
                    class="list-group-item list-group-item-action"
                    key={`ordersSellingCurrent${index}`}
                  >
                    <div class="hstack gap-3">
                      <div class="">
                        <img
                          src={order.thumbnail}
                          class="img-fluid"
                          alt="..."
                          style={{ maxWidth: 140 }}
                        ></img>
                      </div>
                      <div class="text-truncate">
                        <h6 class=" mb-1 text-truncate">{order.title}</h6>

                        <p class="m-0">
                          <small>
                            Sold:{" "}
                            <span class="badge text-bg-light">
                              {order.dateString}
                            </span>
                          </small>
                        </p>
                        <p class="m-0">
                          <small>
                            Status:{" "}
                            <span class="badge text-bg-light text-capitalize ">
                              {order.shippingStatus.status}
                            </span>
                          </small>
                        </p>
                        <p class="m-0">
                          <small>
                            Order price:{" "}
                            <span class="badge text-bg-light text-capitalize ">
                              ${order.price}
                            </span>
                          </small>
                        </p>
                        <p>
                          <small>
                            Size:{" "}
                            <span class="badge text-bg-light text-capitalize ">
                              {order.size}
                            </span>
                          </small>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            {currentOrdersLoading && (
              <>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentOrders && currentOrders.length === 0 && (
              <section class="text-center container">
                <div class="row py-lg-5">
                  <p class=" text-muted">
                    You have no items that have recently sold.
                  </p>
                  <p>
                    <Link to="/sell" class="btn btn-primary my-2">
                      Sell now!
                    </Link>
                  </p>
                </div>
              </section>
            )}
          </div>
          <div
            className={`tab-pane ${showCurrent ? "" : "d-none"}`}
            id="ordersSellingCompleted"
            role="tabpanel"
          >
            <div class="list-group mx-0">
              <div class="list-group mx-0">
                {completedSellerOrders &&
                  completedSellerOrders.map((order, index) => (
                    <Link
                      to={`/profile/selling/${order._id}`}
                      class="list-group-item list-group-item-action "
                      key={`ordersSellingCurrent${index}`}
                    >
                      <div class="hstack gap-3">
                        <div class="">
                          <img
                            src={order.thumbnail}
                            class="img-fluid"
                            alt="..."
                            style={{ maxWidth: 140 }}
                          ></img>
                        </div>
                        <div class="text-truncate">
                          <h6 class=" mb-1 text-truncate">{order.title}</h6>

                          <p class="m-0">
                            <small>
                              Sold:{" "}
                              <span class="badge text-bg-light">
                                {order.dateString}
                              </span>
                            </small>
                          </p>
                          <p class="m-0">
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light text-capitalize ">
                                {order.shippingStatus.status}
                              </span>
                            </small>
                          </p>
                          <p class="m-0">
                            <small>
                              Order price:{" "}
                              <span class="badge text-bg-light text-capitalize ">
                                ${order.price}
                              </span>
                            </small>
                          </p>
                          <p>
                            <small>
                              Size:{" "}
                              <span class="badge text-bg-light text-capitalize ">
                                {order.size}
                              </span>
                            </small>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
              {completedSellerOrdersLoading && (
                <>
                  <div className="col mt-0 p-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                          <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                          <span class="placeholder col-7"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-6"></span>
                          <span class="placeholder col-8"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 p-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                          <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                          <span class="placeholder col-7"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-6"></span>
                          <span class="placeholder col-8"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 p-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                          <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                          <span class="placeholder col-7"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-6"></span>
                          <span class="placeholder col-8"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-0 p-2">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                          <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                          <span class="placeholder col-7"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-4"></span>
                          <span class="placeholder col-6"></span>
                          <span class="placeholder col-8"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {completedSellerOrders && completedSellerOrders.length === 0 && (
                <section class="text-center container">
                  <div class="row py-lg-5">
                    <p class=" text-muted">
                      You have no items that have recently sold.
                    </p>
                    <p>
                      <Link to="/sell" class="btn btn-primary my-2">
                        Sell now!
                      </Link>
                    </p>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
      <h4>My Sold Items</h4>
      <div className="table-responsive">
        {sellerOrdersLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : orders && orders.length > 0 ? (
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Purchase Date</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>
                    <div class="row">
                      <div class="col-4 align-self-center">
                        <div class="">
                          <img
                            src={order.thumbnail}
                            class="img-fluid ps-2"
                            alt="..."
                            style={{ maxWidth: 140 }}
                          ></img>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="card-body">
                          <h6 class="card-title text-truncate">
                            {order.title}
                          </h6>
                          <p class="card-text m-0">
                            <small class="text-body-secondary">
                              Size: {order.size}
                            </small>
                          </p>
                          <Link
                            to={`/profile/selling/${order._id}`}
                            className="btn btn-primary btn-sm mt-0 mt-md-0"
                          >
                            View order
                          </Link>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{order.dateString}</td>
                  <td>${order.price}</td>
                  <td>{order.shippingStatus.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p class="opacity-75 p-2">You do not have any orders on file.</p>
        )}
      </div>
    </div> */}
    </>
  );
};

export default ProfileSellingCompleted;
