import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSetupIntent } from "../actions/stripeActions";
import SetupIntent from "./SetupIntent";

const PaymentModal = () => {
  const dispatch = useDispatch();
  const stripePaymentMethods = useSelector(
    (state) => state.stripePaymentMethods
  );
  const { paymentMethods } = stripePaymentMethods;

  const stripeSetupIntent = useSelector((state) => state.stripeSetupIntent);
  const { client_secret } = stripeSetupIntent;

  useEffect(() => {
    // if (paymentMethods) {
    //   closePaymentModal();
    // }
    if (!client_secret) {
      dispatch(getSetupIntent());
    }
  }, [client_secret]);

  // const closePaymentModal = () => {
  //   let paymentModal = document.getElementById("paymentModalCloseButton");
  //   paymentModal.click();
  // };
  return (
    <>
      {client_secret && (
        <div className="modal fade" id="paymentModal" key={client_secret}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="paymentModalLabel">
                  Add new payment method
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="paymentModalCloseButton"
                ></button>
              </div>
              <div className="modal-body">
                <SetupIntent></SetupIntent>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentModal;
