import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ProfileNavbar from "../../components/Profile_Components/ProfileNavbar";
import BreadcrumbLocation from "../../components/BreadcrumbLocation";
import { Link } from "react-router-dom";
import { getRootMarketplaceListings } from "../../actions/marketplaceActions";

const ListRootMarketplaceListingsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthenticated, user } = useSelector((state) => state.userLogin);
  const { rootMarketplaceListings } = useSelector((state) => state.adminRootMarketplaceListings);

  useEffect(async () => {
    if (isAuthenticated === true && user && user.isAdmin) {
      dispatch(getRootMarketplaceListings());
    }
    if (isAuthenticated === false || (user && !user.isAdmin)) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Edit Profile"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar></ProfileNavbar>
        <>
          <div className="container ms-sm-5">
            <h4 className="mb-4 px-0">Admin Root Marketplace Listings</h4>
            <button
              type="button"
              class="btn btn-primary mb-3"
              onClick={() => navigate("/admin/create-root-marketplace-listing")}
            >
              Create new Root Marketplace Listing +
            </button>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-12">
                  <div class="list-group mx-0">
                    <div class="list-group ">
                      {rootMarketplaceListings.map((rootMarketplaceListing, index) => (
                        <Link
                          to={`/admin/create-root-marketplace-listing/${rootMarketplaceListing._id}`}
                          class="list-group-item list-group-item-action"
                          key={`${index}`}
                        >
                          <div class="hstack gap-3">
                            <div class="text-truncate">
                              <h6 class=" mb-1 text-truncate">{rootMarketplaceListing.slug}</h6>

                              <p class="m-0">
                                <small>
                                  Id:{" "}
                                  <span class="badge text-bg-light">
                                    {rootMarketplaceListing._id}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  Description:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    ${rootMarketplaceListing.description}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  SizeType:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    {rootMarketplaceListing.sizeType}
                                  </span>
                                </small>
                              </p>
                              <p class="m-0">
                                <small>
                                  Enabled:{" "}
                                  <span class="badge text-bg-light text-capitalize ">
                                    {rootMarketplaceListing.enabled.toString()}
                                  </span>
                                </small>
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ListRootMarketplaceListingsScreen;
