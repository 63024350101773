import React from "react";

const TermsScreen = () => {
  return (
    <div className="container mt-sm-5">
      <h1 className="text-center">Terms of Use</h1>
      <div className="my-3">
        <h6>Effective March 1, 2024</h6>
        <p>
          Welcome to ReShup. Before using our service, it is important to
          understand our terms, policies and rules.
        </p>
      </div>
      <div className="my-3">
        <h4>Accepting these Terms</h4>
        <p>
          This document, along with our policies, rules, and other referenced
          documents, constitute our Terms of Service ("Terms"). These Terms form
          a legally binding contract between you and ReShup ("we", "us", or
          "our"). By using our website reshup.com and our services (collectively
          referred to as the "Service"), including any associated mobile
          applications, you agree to comply with all the Terms outlined herein.
        </p>
        <p>
          BY USING OUR SERVICE, YOU’RE CREATING A LEGALLY BINDING CONTRACT TO
          COMPLY WITH ALL THE TERMS.
        </p>
        <p>
          Our platform provides users with access to frequently asked questions
          ("FAQ") and our Privacy Policy. The FAQ section offers clarifications
          on various topics related to our service, including guidelines,
          policies, and common inquiries.We maintain a Privacy Policy that
          outlines how we collect, use, and protect user information. This
          document is designed to provide transparency regarding our data
          practices and compliance with applicable privacy laws and regulations.
        </p>
        <p>
          We reserve the right to amend the Terms at any time by posting a
          revised version on our website. The revised Terms will become
          effective upon posting. If any changes to the Terms result in a
          reduction of your rights or an increase in your responsibilities, we
          will provide you with prior notification.
        </p>
      </div>
      <div className="my-3">
        <h4>1. About ReShup</h4>
        <p>
          The ReShup Live Marketplace operates exclusively within the United
          States, offering a platform for third-party sellers to list and sell
          their products. By accessing and using the Site and Services, you
          confirm that you are at least 18 years old. If you are under 18, you
          must have permission from your parent or guardian to use the platform.
          Sellers can list their products for sale, which are then made
          available on the ReShup website. Both buyers and sellers are required
          to create an account to engage in transactions on the ReShup platform.
          Account deletion or deactivation options are available through our
          Contact page.
        </p>
        <p>
          <strong>Offers:&nbsp;</strong>
          Sellers can receive offers from buyers, which they can choose to
          accept or decline. When a seller accepts an offer, it constitutes a
          binding agreement between the buyer and seller.
        </p>
        <p>
          <strong>Buy Now:&nbsp;</strong>
          Sellers also offer a "buy now" option, allowing buyers to instantly
          purchase items at a set price with a single click.
        </p>
        <p>
          All transactions are subject to ReShup's approval of the item's
          authenticity. More details about the live marketplace process and
          rules for buyers and sellers can be found in our FAQ section. While
          ReShup facilitates transactions on its platform, it is not the buyer
          or seller of the products. The contract formed upon completion of a
          sale is solely between the buyer and seller. ReShup acts as a
          commercial agent to conclude the sale on behalf of each buyer and
          seller involved in each transaction. Prices set by sellers may exceed
          retail value, and any valuations displayed on the platform are
          estimates only. ReShup does not guarantee the sale of any item. We
          reserve the right to investigate and take appropriate actions against
          violations of our Terms, including suspending accounts and charging
          costs incurred due to violations. Please note that the platform is
          accessible only within the United States, and a mobile application is
          not currently available.
        </p>
      </div>
      <div className="my-3">
        <h4>2. Accounts and Security</h4>
        <p>
          To access the live marketplace, you must register for an account using
          either an email address and password or other account creation tools
          provided on the Services, such as a social media account. For
          activities like making offers or purchasing items using the "Buy Now"
          feature, you must have a valid account with a credit card on file,
          processed securely through our payment partner, Stripe. By registering
          an account, you authorize us to charge your credit card for any
          amounts owed as outlined in these terms, as well as for any associated
          costs or losses resulting from your violation of the Terms. You agree
          to provide accurate information about yourself during the registration
          process and must not register under false pretenses.
        </p>
        <p>
          Maintaining the security of your account is your responsibility. You
          agree to keep your account credentials confidential and to prevent
          unauthorized access to your computer or device. You are liable for all
          activities that occur under your account, and you must promptly notify
          us if you suspect any unauthorized use or breach of security.
          Unauthorized use of your account or password may result in losses for
          both you and ReShup. Therefore, you may be held responsible for such
          losses. Under no circumstances are you permitted to use another user's
          account or password without their explicit consent. Additionally, you
          are not allowed to transfer or assign your account to any other
          individual or entity.
        </p>
      </div>
      <div className="my-3">
        <h4>3. Seller Obligations</h4>
        <p>
          By listing an item for sale on ReShup (each listing, a “New Listing”),
          you are making a binding offer to sell that specific item to a buyer
          who purchases the item for the price you have specified and to ship
          the item in accordance with our guidelines as updated from time to
          time. When a buyer accepts your offer by purchasing your item through
          our platform, you are contractually and legally bound to deliver that
          exact item for the specified price.
        </p>
        <p>
          <strong>Shipping Requirements:&nbsp;</strong>
          Sellers must ship items via the designated shipping carrier within the
          specified timeframe provided in the sale confirmation email and must
          obtain a receipt from the shipping carrier to verify shipment of the
          item. Sellers must ship items from their address on file with ReShup.
          If a seller fails to ship the item within the specified timeframe,
          ReShup reserves the right to charge a fee to the seller or cancel the
          item.
        </p>
        <p>
          <strong>Listing Accuracy:&nbsp;</strong>
          Sellers are obligated to ensure that all listings are accurate and
          that the item listed exactly matches the description provided.
        </p>
        <p>
          <strong>Cancellation Policy:&nbsp;</strong>
          Once a listing is matched with a buyer, sellers are prohibited from
          canceling the listing. Failure to fulfill orders may result in
          additional charges including but not limited to a service fee of up to
          the full amount of the listing.
        </p>
        <p>
          <strong>Non-Compliance Penalties:&nbsp;</strong>
          If a seller fails to comply with these Terms, ReShup reserves the
          right to take action, including but not limited to charging the seller
          a service fee, removing listings, canceling orders, withholding
          payments, placing limits on buying and selling privileges, and
          suspending the seller's account.
        </p>
        <p>
          <strong>Item Authenticity:&nbsp;</strong>
          Sellers are required to ensure that the item listed matches the
          provided image and meets any applicable condition standards. If ReShup
          or a buyer reasonably determines that the item does not conform to the
          description or is counterfeit, ReShup may charge the seller a service
          fee, plus additional costs as determined by ReShup, and may not return
          the item to the seller.
        </p>
        <p>
          <strong>Payment and Fees:&nbsp;</strong>
          ReShup will pay the seller within a reasonable amount of time
          following completion of the sale, which occurs when the buyer receives
          the item. Sellers are encouraged to ship items promptly to expedite
          payment. Fee information will be provided to sellers via email
          notification and displayed during the listing process.
        </p>
        <p>
          <strong>Legal Compliance:&nbsp;</strong>
          Sellers must comply with all laws and regulations applicable to the
          sale of their products on ReShup's marketplace.
        </p>
        <p>
          ReShup acts solely as an intermediary between sellers and buyers and
          does not assume liability regarding the proper performance of purchase
          agreements between sellers and buyers, except for ReShup's specific
          services as described in these Terms of Service. This addition ensures
          that sellers understand the consequences of not shipping items within
          the specified timeframe and underscores ReShup's authority to enforce
          this policy.
        </p>
      </div>
      <div className="my-3">
        <h4>4. Buyer Obligations</h4>
        <p>
          By listing an item for sale on ReShup (each listing, a “New Listing”),
          you are making a binding offer to sell that specific item to a buyer
          who purchases the item for the price you have specified and to ship
          the item in accordance with our guidelines as updated from time to
          time. When a buyer accepts your offer by purchasing your item through
          our platform, you are contractually and legally bound to deliver that
          exact item for the specified price.
        </p>
        <p>
          <strong>Placing Offers and Immediate Purchase:&nbsp;</strong>
          Buyers on our platform have the option to either place an offer on an
          item or purchase it immediately (Buy Now). When placing an offer, you
          commit to purchasing the item if your offer matches the seller's ask
          price. Similarly, when choosing to buy now, you agree to proceed with
          the purchase immediately.
        </p>
        <p>
          <strong>Commitment to Purchase:&nbsp;</strong>
          By placing an offer or choosing to buy now, you commit to purchasing
          the item as soon as your offer matches the seller's ask price or when
          you select the Buy Now option. You acknowledge that payment will be
          taken from your payment method upon completion of the transaction.
        </p>
        <p>
          <strong>Eligibility and Shipping:&nbsp;</strong>
          To be an eligible buyer, you must have a billing address and shipping
          address within the United States. If your address is within the United
          States, you are eligible to proceed with purchases and shipping within
          the country.
        </p>
        <p>
          <strong>Payment and Fees:&nbsp;</strong>
          We generally charge your payment method immediately upon purchase and
          pay the seller upon completion of the transaction. You may be required
          to pay certain fees as described when placing your offer or selecting
          the Buy Now option, which may be updated from time to time. In the
          event that your payment fails or is rejected for any reason, we
          reserve the right to take appropriate actions, including charging a
          service fee, removing bids, canceling orders, withholding refunds or
          payments, placing limits on buying and selling privileges, and
          suspending your account.
        </p>
        <p>
          <strong>Final Sale Policy:&nbsp;</strong>
          All sales are final once the item is authenticated and delivered. No
          refund requests will be honored, regardless of the current value of
          the item. If you experience a problem with your purchase or an item,
          please contact us within 3 days of receiving the item with a detailed
          description of the problem. Items must not have their verification
          tags or stickers removed to be eligible for return or exchange under
          any circumstances, including damage in transit.
        </p>
      </div>
      <div className="my-3">
        <h4>5. Authentication; Shipping and Handling</h4>
        <p>
          As part of the purchase transaction, the seller will ship the item to
          ReShup. ReShup will visually inspect the item and make commercially
          reasonable efforts to confirm its authenticity and ensure it meets our
          condition standards. If the item is determined to be authentic and
          meets our condition standards, ReShup will proceed to ship the item to
          the buyer. However, it's important to note that ReShup cannot
          guarantee the authenticity of every product listed on our platform.
          While we make every effort to authenticate items, we cannot provide an
          absolute guarantee of authenticity.
        </p>
        <p>
          <strong>Rejection Policy:&nbsp;</strong>
          ReShup reserves the right to reject any item for any reason, including
          but not limited to authenticity concerns, failure to meet our
          condition standards, or damage to the item.
        </p>
        <p>
          <strong>Shipping Policy:&nbsp;</strong>
          Sellers are required to ship items within the specified timeframe
          provided in the sale confirmation email after purchase. ReShup cannot
          control the shipping time from the seller to our facilities, and
          therefore, we cannot guarantee a specific delivery timeframe for any
          items. Buyers are not permitted to cancel a purchase if the item does
          not ship from the seller within the specified timeframe provided in
          the sale confirmation email. Upon receiving the item from the seller,
          ReShup typically takes 1-2 business days to authenticate the item and
          ship it to the buyer.
        </p>
        <p>
          <strong>Returns, Issues, Refunds:&nbsp;</strong>
          ReShup reserves the right to refuse returns or other refunds and may
          charge restocking fees for returned or abandoned items. If a buyer or
          seller refuses to accept delivery of a package sent to their address
          on file, ReShup will consider the property abandoned after thirty (30)
          days and may charge the buyer or seller reasonable expenses incurred
          due to such refusal.If a buyer does not receive an item, they must
          promptly report the issue to ReShup within 2 days of the latest
          estimated delivery date.
        </p>
      </div>
      <div className="my-3">
        <h4>6. Limitations and Restrictions</h4>

        <p>
          <strong>Conduct Restrictions:&nbsp;</strong>
          Users of our services must not engage in the following activities: Use
          our services to solicit sales outside of ReShup or to contact any user
          of the services. Use our services if you are not able to form legally
          binding contracts, such as if you are under 18 years old, or are
          temporarily or indefinitely suspended from using our services. Upload
          or transmit any unlawful, harmful, threatening, abusive, harassing,
          defamatory, or otherwise objectionable content. Engage in abusive
          treatment of other users of the services or any ReShup employee.
          Create a false identity for the purpose of misleading others or
          impersonate any person or entity. Upload or transmit any material that
          infringes upon any patent, trademark, copyright, or other proprietary
          rights of any party. Use any robot, spider, scraper, or other
          automated or manual means to access our services without our express
          written permission. Take any action that may impose an unreasonable or
          disproportionately large load on our infrastructure or otherwise
          interfere with the functioning of the services. Attempt to gain
          unauthorized access to the sites or the services, including computer
          systems, software, or networks. Users must not attempt to gain
          unauthorized access to the sites or the services, including computer
          systems, software, or networks. This includes probing, scanning,
          testing the vulnerability of, or breaching the authentication measures
          of the sites or any related networks or systems.
        </p>
        <p>
          <strong>Enforcement:&nbsp;</strong>
          ReShup reserves the right to take lawful actions in response to
          violations of these terms, including the suspension or termination of
          access and/or accounts. We may cooperate with legal authorities and/or
          third parties in the investigation of any suspected or alleged crime
          or civil wrong. ReShup also reserves the right to disclose any
          information as deemed necessary to satisfy applicable laws or legal
          processes. Users agree to reimburse ReShup for any damage, loss, cost,
          or expense incurred due to their unlawful or prohibited use of the
          services.
        </p>
      </div>
      <div className="my-3">
        <h4>7. Fees and Taxes</h4>
        <p>
          <strong>Fees:&nbsp;</strong>
          There is no fee to list an item on our marketplace. Upon the
          successful sale of an item, a transaction fee will be charged based on
          seller standing. See FAQs for seller levels. This fee is automatically
          deducted from the proceeds of the sale. Any applicable shipping fees
          will also be outlined in our FAQ section. Sellers are responsible for
          covering the shipping costs associated with sending items to our
          platform. By using our marketplace, you agree to abide by the fee
          structure as described in the FAQ.
        </p>
        <p>
          <strong>Seller Responsibilities:&nbsp;</strong>
          As a seller on our platform, you are responsible for collecting,
          paying, reporting, and remitting any and all local, state, provincial,
          federal, or international taxes (including VAT and sales tax) that may
          be due with respect to your sales transactions.
        </p>
        <p>
          <strong>Buyer Responsibilities:&nbsp;</strong>
          As a buyer on our platform, you agree to pay any and all local, state,
          federal, or international taxes (including sales taxes) that may be
          due with respect to your purchase transactions.
        </p>
        <p>
          <strong>ReShup Responsibilities:&nbsp;</strong>
          ReShup is not responsible for reporting, collecting, or remitting
          taxes on behalf of users, except where required as a marketplace
          facilitator in certain jurisdictions.
        </p>
        <p>
          <strong>Tax Information Disclosure:&nbsp;</strong>
          In accordance with our Privacy Policy and only to the extent necessary
          to complete a transaction, you agree to provide ReShup with all
          relevant tax information. This information may be provided to tax
          authorities in connection with payments received from us. You further
          authorize ReShup to release this information to tax authorities or
          other competent governmental bodies where applicable.
        </p>
        <p>
          <strong>Account Suspension:&nbsp;</strong>
          Failure to provide ReShup with all relevant tax information to support
          filings to the appropriate tax authority may result in the suspension
          of your account.
        </p>
      </div>
      <div className="my-3">
        <h4>8. Indemnity</h4>
        You agree to indemnify, defend, and hold harmless ReShup and its
        affiliates, and their respective officers, directors, agents, and
        employees from and against all claims, demands, suits, or other
        proceedings, and resulting loss, damage, liability, costs, interest, and
        expenses (including reasonable attorneys’ fees) brought by any third
        party or governmental entity that involves, relates to, or concerns:
        <ul>
          <li>
            {" "}
            a. Your listing or sale of any counterfeit, stolen, or illegal
            merchandise or goods.
          </li>
          <li>b. Your breach of any provision of the Terms of Service.</li>
          <li>
            c. Your improper use of the Services, including any improper use
            related to specific features or services offered by ReShup.
          </li>
          <li>d. Your violation of any law or the rights of a third party.</li>
          <li>
            e. Federal, state, or local tax obligations or amounts due under any
            tax regulation, law, order, or decree.
          </li>
        </ul>
      </div>
      <div className="my-3">
        <h4>9. Combatting Counterfeits, Fraud, and Market Manipulation</h4>
        At our marketplace, we maintain a zero-tolerance policy towards
        counterfeiting, fraud, and market manipulation. If a seller is found to
        be providing counterfeit items or engaging in fraudulent activities,
        ReShup reserves the right to take appropriate action, including but not
        limited to:
        <ol>
          <li> Removing the seller's listings from our platform.</li>
          <li>Cancelling any pending orders associated with the seller.</li>
          <li>Withholding payments due to the seller.</li>
          <li>
            Imposing limits on the seller's buying and selling privileges.
          </li>
          <li>
            Charging the seller's payment method for costs and expenses incurred
            by ReShup.
          </li>
          <li>
            Requiring the seller to cover the cost of returning the counterfeit
            items.
          </li>
          <li>
            Providing counterfeit items to law enforcement or disposing of them.
          </li>
          <li>Temporarily or permanently suspending the seller's account.</li>
          <li>Charging the seller a service fee, at our discretion.</li>
        </ol>
        The seller acknowledges and agrees to these remedies, even in cases
        where the counterfeit or fraudulent nature of the items was not
        initially known to the seller (e.g., negligent fraud). Additionally,
        ReShup may utilize fraud protection measures, such as algorithms and
        automated systems, to monitor for fraudulent activities, which may
        result in the cancellation of listings or sales, or the suspension of
        accounts. If a buyer receives an item believed to be counterfeit, they
        must notify ReShup within 3 days of receipt. ReShup will then
        investigate the matter and work with the buyer to determine the
        appropriate course of action, which may include providing evidence,
        returning the item, or destroying it. ReShup will refund all fees and
        costs paid by the buyer for the item, including shipping and handling.
        We also monitor the integrity of our marketplace and take action against
        any users suspected of market manipulation or fraud. This may include
        removing listings, cancelling orders, withholding payments, limiting
        buying and selling privileges, notifying law enforcement, suspending
        accounts, and charging additional fees to cover investigation costs. We
        are committed to maintaining a safe and fair marketplace for all users,
        and we appreciate your cooperation in upholding these standards. If you
        have any concerns or encounter any issues, please contact us through our
        help center.
      </div>
      <div className="my-3">
        <h4>10. Warranties; Liability</h4>
        <p>
          <strong>User Content and Transactions:&nbsp;</strong>
          You acknowledge that ReShup is a marketplace where users transact with
          each other. While ReShup may assist in resolving disputes, we do not
          control the accuracy, quality, safety, or legality of user content,
          listings, or items sold on the platform. In the event of a dispute
          with another user, you release ReShup from any claims or damages
          arising from such dispute. We do not warrant that our services will be
          uninterrupted, error-free, secure, or free of viruses or other harmful
          components. ReShup periodically updates and amends our services
          without notice, and we assume no liability for errors or omissions in
          the content.
        </p>
        <p>
          <strong>Use at Your Own Risk:&nbsp;</strong>
          You agree to use our services at your own risk. ReShup provides our
          services on an "as is" and "as available" basis, without any
          warranties or guarantees. We exclude all express or implied
          warranties, including but not limited to warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>
        <p>
          <strong>Limitation of Liability:&nbsp;</strong>
          To the extent permitted by applicable law, ReShup and its affiliates,
          officers, directors, agents, and suppliers are not liable for any
          damages or losses arising directly or indirectly from your use of our
          services. This includes, but is not limited to, loss of money,
          goodwill, profits, or other intangible losses. Under no circumstances
          shall ReShup's liability exceed the total fees paid by you to us in
          the 12 months prior to the action giving rise to the liability, or USD
          $100, whichever is lower.
        </p>
        <p>
          <strong>Indemnity:&nbsp;</strong>
          You agree to indemnify, defend, and hold ReShup and our affiliates,
          officers, directors, agents, and employees harmless from any claims,
          demands, suits, or proceedings, including legal fees, arising from
          your breach of these terms, your use of our services, or your
          violation of any law or the rights of a third party.
        </p>
      </div>
      <div className="my-3">
        <h4>11. Stripe Accounts</h4>
        <p>
          To utilize the bank transfer functionality within the ReShup
          application, you are required to activate an account provided by
          Stripe Inc. ("Stripe") and must agree to the Stripe Services Agreement
          (https://stripe.com/legal) and Privacy Policy
          (https://stripe.com/privacy). Any funds held in the Stripe account are
          maintained by Stripe's financial institution partners as outlined in
          the Stripe Services Agreement. By using the ReShup platform, you
          authorize ReShup to share your identity and account data with Stripe
          for the purpose of establishing and supporting your Stripe account.
          You are responsible for ensuring the accuracy and completeness of the
          information provided. Your Stripe account will be accessed and managed
          through your ReShup account. Notifications regarding your Stripe
          account will be sent by Stripe.
        </p>
      </div>
      <div className="my-3">
        <h4>12. Ownership of Intellectual Property</h4>
        <p>
          You acknowledge and agree that all patents, copyrights, trademarks,
          service marks, trade secrets, and other intellectual property
          (collectively referred to as "Intellectual Property") associated with
          our platform are the sole property of our company. These Terms do not
          grant you any ownership rights or license to our Intellectual
          Property. You also agree not to contest the validity of our
          Intellectual Property. The Services, including all text, graphics,
          logos, buttons, icons, images, audio clips, and computer programs, are
          the property of ReShup or its licensors or suppliers, protected by
          U.S. and international intellectual property laws. The compilation of
          all content associated with the Services is the exclusive property of
          ReShup, protected by U.S. and international intellectual property
          laws. Any unauthorized reproduction, modification, distribution,
          transmission, republication, display, or performance of the software
          or content through our Services is strictly prohibited.
        </p>
        <p>
          ReShup grants you a personal, non-exclusive, non-transferable,
          revocable, limited license to use the Sites and the Services solely
          for your personal or internal use. This license is subject to the
          condition that you do not copy, modify, create derivative works from,
          reverse engineer, reverse assemble, or otherwise attempt to discover
          any source code. You also agree not to sell, assign, sublicense, grant
          a security interest in, or otherwise transfer any right in or access
          to the Sites, the Services, or any other content available via the
          Sites or the Services. All rights not expressly granted to you in
          these Terms are reserved and retained by ReShup.
        </p>
      </div>
    </div>
  );
};

export default TermsScreen;
