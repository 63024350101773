import React, { useMemo, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { extractPublicId } from "cloudinary-build-url";

const CloudinaryGallery = ({ photos }) => {
  const id = useMemo(() => `pgw-${uuid()}`, []);
  useEffect(() => {
    const widget = window.cloudinary.galleryWidget({
      container: `#${id}`,
      cloudName: "dszd4owdm",
      mediaAssets: photos.map((photo) => extractPublicId(photo)),
      aspectRatio: "14:10",
    });

    widget.render();

    return () => {
      widget.destroy();
    };
  }, [id, photos]);

  return <div id={id} />;
};

export default CloudinaryGallery;
