export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAIL = "CREATE_OFFER_FAIL";
export const POTENTIAL_OFFERS_REQUEST = "POTENTIAL_OFFERS_REQUEST";
export const POTENTIAL_OFFERS_SUCCESS = "POTENTIAL_OFFERS_SUCCESS";
export const POTENTIAL_OFFERS_FAIL = "POTENTIAL_OFFERS_FAIL";
export const OFFER_STATUS_RESET = "OFFER_STATUS_RESET";
export const CANCEL_OFFER_REQUEST = "CANCEL_OFFER_REQUEST";
export const CANCEL_OFFER_SUCCESS = "CANCEL_OFFER_SUCCESS";
export const CANCEL_OFFER_FAIL = "CANCEL_OFFER_FAIL";
export const ACCEPT_OFFER_REQUEST = "ACCEPT_OFFER_REQUEST";
export const ACCEPT_OFFER_SUCCESS = "ACCEPT_OFFER_SUCCESS";
export const ACCEPT_OFFER_FAIL = "ACCEPT_OFFER_FAIL";
export const GET_OFFER_REQUEST = "GET_OFFER_REQUEST";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_FAIL = "GET_OFFER_FAIL";
export const CREATE_OFFER_RESET = "CREATE_OFFER_RESET";
export const FOR_PURCHASE_OFFERS_REQUEST = "FOR_PURCHASE_OFFERS_REQUEST";
export const FOR_PURCHASE_OFFERS_SUCCESS = "FOR_PURCHASE_OFFERS_SUCCESS";
export const FOR_PURCHASE_OFFERS_FAIL = "FOR_PURCHASE_OFFERS_FAIL";
export const FOR_SALE_OFFERS_REQUEST = "FOR_SALE_OFFERS_REQUEST";
export const FOR_SALE_OFFERS_SUCCESS = "FOR_SALE_OFFERS_SUCCESS";
export const FOR_SALE_OFFERS_FAIL = "FOR_SALE_OFFERS_FAIL";
