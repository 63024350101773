import React from "react";
import { Link } from "react-router-dom";
import { unlikeItem, getUserLikedItems } from "../../actions/userActions";
import { useDispatch } from "react-redux";

const LikedListingCard = ({ listingInformation }) => {
  const dispatch = useDispatch();
  const {
    _id,
    photos,
    price,
    size,
    title,
    sellerInformation,
    productAvailable,
    dateString,
  } = listingInformation;
  const removeAndUpdate = async () => {
    try {
      await dispatch(unlikeItem(_id));
      dispatch(getUserLikedItems());
    } catch (error) {
    }
  };
  return (
    <div className="col p-1 p-md-2">
      <div className="card p-2 position-relative h-100">
        {productAvailable != null && !productAvailable && (
          <span class="position-absolute top-0 start-0 badge text-bg-dark m-1">
            SOLD
          </span>
        )}
        <span
          onClick={() => removeAndUpdate()}
          class="badge bg-danger position-absolute top-0 end-0 mt-1 me-1"
          style={{ cursor: "pointer" }}
        >
          X
        </span>
        <img src={photos[0]} className="card-img-top" alt="..."></img>
        <div className="card-body mt-1 bg-light p-1">
          <Link
            to={`/sellerlisting/${_id}`}
            className="text-reset text-decoration-none"
          >
            <h5 className="card-title">$ {price}</h5>
            <p className="card-text lh-sm mb-1" style={{ fontSize: 13 }}>
              {title}
            </p>
            <p className="card-text mb-1" style={{ fontSize: 13 }}>
              Size: <small className="text-muted">{size}</small>
            </p>
            <div className="d-flex flex-row">
              <img
                src={sellerInformation.photo}
                className="rounded-circle me-2"
                style={{ height: 25, width: 25, zIndex: 30 }}
              ></img>
              <p className="card-text" style={{ fontSize: 13 }}>
                <small className="text-muted">{sellerInformation.name}</small>
              </p>
            </div>
          </Link>
        </div>
        {/* <button type="button" class="btn btn-outline-secondary mt-2">
          Buy Now
        </button> */}
        <Link to={`/buy/${_id}`} className="btn btn-outline-secondary mt-2">
          Buy Now
        </Link>
      </div>
    </div>
  );
};

export default LikedListingCard;
