import React from "react";
import { Link } from "react-router-dom";

const OfferAcceptedScreen = () => {
  return (
    <section class="py-5 text-center container">
      <div class="row py-lg-5">
        <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="fw-light">Accepted offer!</h1>
          <p class="lead text-muted">
            You have successfully accepted an offer. Now as the seller it is
            your job to ship your item to us for verification, then you will
            recieve a payout in your payout account. You can print your shipping
            label by going to your sold item. You also will recieve an email
            confirmation shortly, make sure to check your spam.
          </p>
          <p>
            <Link to="/profile/selling/completed" class="btn btn-primary my-2">
              View sales
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default OfferAcceptedScreen;
