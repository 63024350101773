import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";

const ProfileSellingInformation = () => {
  const grayedOutDiv = "d-flex align-items-start opacity-25";
  const activeDiv = "d-flex align-items-start";
  const grayedOutIcon = "badge text-bg-dark mt-1 me-2";
  const activeOutIcon = "badge text-bg-success mt-1 me-2";
  const [error, setError] = useState("");
  const sellerOrderInformation = useSelector(
    (state) => state.sellerOrderInformation
  );
  const { order, loading: orderLoading } = sellerOrderInformation;

  return (
    <div className="col-md-5">
      {orderLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        order && (
          <>
            {order && order.shippingStatus.status == "Seller Shipping Item" && (
              <>
                <h4>Shipping Instructions</h4>
                <p className="mb-3">
                  <small>
                    We want to ensure a smooth and hassle-free delivery
                    experience for you. Please follow these steps to avoid fees
                    and ensure that your order is packaged correctly.
                  </small>
                </p>
                <p className="mb-2">
                  1:{" "}
                  <small>
                    Print this invoice and include it <strong>inside</strong>{" "}
                    your products packaging.
                  </small>
                </p>
                <button
                  type="button"
                  class="btn btn-sm btn-subtleprimary text-primary mb-2"
                  onClick={() => window.print()}
                >
                  Print invoice
                </button>
                <p className="mb-2">
                  2:{" "}
                  <small>
                    Please take your item to the nearest FedEx by{" "}
                    <strong>
                      {order.sellerShippingDeadline &&
                        order.sellerShippingDeadline}
                    </strong>
                    . You must ship your product by this deadline to avoid fees.
                  </small>
                </p>
                <button
                  type="button"
                  class="btn btn-sm btn-subtleprimary text-primary mb-3"
                  onClick={() =>
                    window.open(
                      `/order/seller-shipping-label/${order._id}`,
                      "_blank"
                    )
                  }
                >
                  Print shipping label
                </button>
              </>
            )}
            <div className="container text-center">
              <img
                className="img-fluid shrink-image-print"
                src={order.thumbnail}
              ></img>
              <h5 className="mt-3">{order.title}</h5>
              <p>
                US Men's Size: {order.size} | Condition: New | ReShup Verified ✓
              </p>
            </div>
            {(order.shippingStatus.status == "Cancelled" ||
              order.shippingStatus.status == "Rejected") && (
              <div class="alert alert-danger" role="alert">
                {order.shippingStatus.description}
              </div>
            )}
            <div className="bg-light p-3">
              <h5 className="mb-4">Order # {order._id}</h5>
              <div className="container hide-during-print">
                <div class="d-flex align-items-start ">
                  <span class="badge text-bg-success mt-1 me-2">✓</span>
                  <div>
                    <h6 class="fw-bold mb-0">Order Confirmed</h6>
                    <p className="text-secondary">
                      <small>
                        Congratulations! You’ve matched with a Buyer. Print your
                        shipping label and please ship your item within 3
                        business days to avoid fees.
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  class={
                    order.shippingStatus.step > 1 ? activeDiv : grayedOutDiv
                  }
                >
                  <span
                    class={
                      order.shippingStatus.step > 1
                        ? activeOutIcon
                        : grayedOutIcon
                    }
                  >
                    ✓
                  </span>
                  <div>
                    <h6 class="fw-bold mb-0">Order Arrived at ReShup</h6>
                    <p className="text-secondary">
                      <small>
                        Thank you for shipping your item! Your order is at one
                        of our authentication centers waiting for verification!
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  class={
                    order.shippingStatus.step > 2 ? activeDiv : grayedOutDiv
                  }
                >
                  <span
                    class={
                      order.shippingStatus.step > 2
                        ? activeOutIcon
                        : grayedOutIcon
                    }
                  >
                    ✓
                  </span>
                  <div>
                    <h6 class="fw-bold mb-0">ReShup Verified + Shipped</h6>
                    <p className="text-secondary">
                      <small>
                        Congrats, your order is ReShup Verified! You should have
                        recieved a payment now in your stripe account. If not
                        this can take a few days to process.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="border-bottom my-3"></div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">Your Sale Price:</p>
                <p class="mb-1">${(order.sellingPrice / 100).toFixed(2)}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">ReShup Fee:</p>
                <p class="mb-1">${(order.sellerFee / 100).toFixed(2)}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">Shipping:</p>
                <p class="mb-1">
                  ${(order.sellerShippingPrice / 100).toFixed(2)}
                </p>
              </div>
              <div class="border-bottom my-3"></div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Total Payout:</p>
                <h5 class="mb-1">${(order.netProfit / 100).toFixed(2)}</h5>
              </div>
            </div>
            <div className="container text-center mt-2"></div>
          </>
        )
      )}
    </div>
  );
};

export default ProfileSellingInformation;
