import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPotentialOffers } from "../actions/offerActions.js";
import SearchOffcanvas from "./Navbar_Components/SearchOffcanvas.js";
import NavigationOffcanvas from "./Navbar_Components/NavigationOffcanvas.js";
import AccountDropdown from "./AccountDropdown";
import SearchBar from "./SearchBar";
import LoadingSpinner from "./LoadingSpinner";
import logo from "../images/ReShupLogo1.svg";

const Navbar = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, isAuthenticated } = userLogin;

  const forSaleOffers = useSelector((state) => state.forSaleOffers);
  const { potentialOffers } = forSaleOffers;

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getPotentialOffers());
    }
  }, [isAuthenticated]);
  return (
    <>
      {/* {loading ? (
        // <LoadingSpinner></LoadingSpinner>
        <div className="py-2 py-md-3 placeholder-wave py-3 placeholder col-12">
        </div>
      ) : isAuthenticated ? ( */}
      {isAuthenticated ? (
        <>
          <header className="navbar navbar-expand-md bg-white mb-0 py-2 py-md-3 sticky-top border-bottom shadow-sm">
            <div className="container d-flex">
              <Link
                className="nav-link d-block d-lg-none text-secondary"
                to="#"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBarsLeft"
              >
                <i className="fas fa-bars"></i>
              </Link>
              <Link className="navbar-brand fw-bold me-0 me-md-3" to="/">
                <img
                  className="img-fluid"
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1704516499/ReShup_5_eusbab.png"
                  style={{ width: 110 }}
                ></img>
              </Link>
              <Link
                className="nav-link d-block d-lg-none text-secondary"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#mobileSearchModal"
              >
                <i className="fas fa-search"></i>
              </Link>

              <SearchBar
                formClassAttributes={"d-none d-lg-flex flex-grow-1 me-4"}
                heightAttributes={{ height: 400 }}
                searchType={"listing"}
              ></SearchBar>
              <ul className="navbar-nav d-flex flex-row d-none d-lg-flex">
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/marketplace" className="nav-link">
                    Marketplace
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile/offers" className="nav-link">
                    Offers
                    <span class="badge bg-primary ms-1">{potentialOffers}</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <AccountDropdown></AccountDropdown>
                </li>
                <li className="nav-item flex-grow-1 ms-2">
                  <Link className="btn btn-primary" to="sell">
                    Sell
                  </Link>
                </li>
              </ul>
            </div>
          </header>
        </>
      ) : (
        <>
          {loading && (
            <p class="placeholder-glow m-0 p-0">
              <span class="placeholder col-12 placeholder-xs bg-secondary"></span>
            </p>
          )}
          <header className="navbar navbar-expand-md bg-white mb-0 py-2 py-md-3 sticky-top border-bottom shadow-sm">
            <div className="container d-flex">
              <Link
                className="nav-link d-block d-md-none"
                to="#"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBarsLeft"
              >
                <i className="fas fa-bars"></i>
              </Link>
              <Link className="navbar-brand fw-bold me-0 me-md-3" to="/">
                <img
                  className="img-fluid"
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1704516499/ReShup_5_eusbab.png"
                  style={{ width: 110 }}
                ></img>
              </Link>
              <SearchBar
                formClassAttributes={"d-none d-md-flex flex-grow-1 me-4"}
                heightAttributes={{ height: 400 }}
                searchType={"listing"}
              ></SearchBar>
              <Link
                className="nav-link d-flex flex-row d-block d-md-none"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#mobileSearchModal"
              >
                <i className="fas fa-search"></i>
              </Link>

              <ul className="navbar-nav d-flex flex-row d-none d-md-flex">
                <li className="nav-item mx-2">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item mx-2">
                  <Link to="/marketplace" className="nav-link">
                    Marketplace
                  </Link>
                </li>
                <li className="nav-item mx-2">
                  <Link
                    id="loginButton"
                    to="/login"
                    className="nav-link"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                  >
                    Login/Register
                  </Link>
                </li>
                <li className="nav-item flex-grow-1">
                  <Link className="btn btn-primary" to="/sell">
                    Sell
                  </Link>
                </li>
              </ul>
            </div>
          </header>
        </>
      )}

      <SearchOffcanvas></SearchOffcanvas>
      <NavigationOffcanvas></NavigationOffcanvas>
    </>
  );
};

export default Navbar;
