import {
  USER_INFORMATION_FAIL,
  USER_INFORMATION_REQUEST,
  USER_INFORMATION_SUCCESS,
  USER_STRIPE_ACCOUNT_FAIL,
  USER_STRIPE_ACCOUNT_REQUEST,
  USER_STRIPE_ACCOUNT_SUCCESS,
  USER_STRIPE_LINK_FAIL,
  USER_STRIPE_LINK_REQUEST,
  USER_STRIPE_LINK_SUCCESS,
  USER_LOCATION_FAIL,
  USER_LOCATION_REQUEST,
  USER_LOCATION_SUCCESS,
  USER_LISTINGS_FAIL,
  USER_LISTINGS_REQUEST,
  USER_LISTINGS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_LIKE_REQUEST,
  USER_LIKED_STATUS_REQUEST,
  USER_LIKED_LISTINGS_REQUEST,
  USER_LIKE_SUCCESS,
  USER_LIKED_STATUS_SUCCESS,
  USER_LIKED_LISTINGS_SUCCESS,
  USER_LIKE_FAIL,
  USER_UNLIKE_SUCCESS,
  USER_LIKE_RESET,
  USER_INQUIRY_REQUEST,
  USER_INQUIRY_SUCCESS,
  USER_INQUIRY_FAIL,
  USER_INQUIRY_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};
export const userLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOCATION_REQUEST:
      return { loading: true };
    case USER_LOCATION_SUCCESS:
      return {
        loading: false,
        location: action.payload,
      };
    case USER_LOCATION_FAIL:
      return {
        loading: false,
        location: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const userInformationReducer = (
  state = { userInformation: {} },
  action
) => {
  switch (action.type) {
    case USER_INFORMATION_REQUEST:
      return { loading: true, userInformation: {} };
    case USER_INFORMATION_SUCCESS:
      return {
        loading: false,
        userInformation: action.payload,
      };
    case USER_INFORMATION_FAIL:
      return {
        loading: false,
        userInformation: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userStripeAccountReducer = (
  state = { stripeAccount: {} },
  action
) => {
  switch (action.type) {
    case USER_STRIPE_ACCOUNT_REQUEST:
      return { loading: true };
    case USER_STRIPE_ACCOUNT_SUCCESS:
      return {
        loading: false,
        stripeAccount: action.payload,
      };
    case USER_STRIPE_ACCOUNT_FAIL:
      return {
        loading: false,
        stripeAccount: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
export const userStripeAccountLink = (state = { stripeLink: null }, action) => {
  switch (action.type) {
    case USER_STRIPE_LINK_REQUEST:
      return { loading: true };
    case USER_STRIPE_LINK_SUCCESS:
      return {
        loading: false,
        stripeLink: action.payload,
      };
    case USER_STRIPE_LINK_FAIL:
      return {
        loading: false,
        stripeLink: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userListingsReducer = (
  state = {
    listingsLoading: false,
    userListings: [],
  },
  action
) => {
  switch (action.type) {
    case USER_LISTINGS_REQUEST:
      return {
        ...state,
        listingsLoading: true,
      };
    case USER_LISTINGS_SUCCESS:
      return {
        listingsLoading: false,
        userListings: action.payload.userListings,
      };

    case USER_LISTINGS_FAIL:
      return {
        ...state,
        listingsLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userProfileReducer = (
  state = {
    profileUpdating: false,
  },
  action
) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        profileUpdating: true,
      };
    case USER_PROFILE_SUCCESS:
      return {
        profileUpdating: false,
        successMessage: action.payload.message,
        profile: action.payload.profile,
      };
    case USER_PROFILE_FAIL:
      return {
        profileUpdating: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userLikesReducer = (
  state = {
    liked: false,
    message: "",
    likedListings: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case USER_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LIKE_RESET:
      return {
        ...state,
        message: "",
      };
    case USER_LIKE_SUCCESS:
      return {
        ...state,
        liked: true,
        message: action.payload,
        loading: false,
      };
    case USER_UNLIKE_SUCCESS:
      return {
        ...state,
        liked: false,
        message: action.payload,
        loading: false,
      };
    case USER_LIKED_LISTINGS_SUCCESS:
      return {
        ...state,
        likedListings: action.payload,
        loading: false,
      };
    case USER_LIKED_STATUS_SUCCESS:
      return {
        ...state,
        liked: action.payload.status,
        loading: false,
      };
    case USER_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userInquiryReducer = (
  state = {
    inquiry: null,
    message: null,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case USER_INQUIRY_REQUEST:
      return {
        inquiry: null,
        message: null,
        loading: true,
      };
    case USER_INQUIRY_RESET:
      return {
        inquiry: null,
        message: null,
        loading: false,
      };
    case USER_INQUIRY_SUCCESS:
      return {
        inquiry: null,
        message: "Successfully sent inquiry",
        loading: false,
      };
    case USER_INQUIRY_FAIL:
      return {
        inquiry: null,
        message: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
