import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faShield, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePaymentIntentShipping,
  updatePaymentIntentPaymentMethod,
} from "../actions/stripeActions";
import { createOffer } from "../actions/offerActions";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { CREATE_OFFER_RESET } from "../constants/offerConstants";
import PaymentModal from "./PaymentModal";

const OfferCheckoutForm = () => {
  const cardBrandToUrl = {
    visa: "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.40.50_PM_rztnse.png",
    mastercard:
      "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.04_PM_alvl6t.png",
    amex: "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.33_PM_uuhxfl.png",
    discover:
      "https://res.cloudinary.com/dszd4owdm/image/upload/v1696045370/Screenshot_2023-09-29_at_11.41.22_PM_mczwbs.png",
  };

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editShipping, setEditShipping] = useState(true);
  const [editPaymentMethod, setEditPaymentMethod] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const stripePaymentIntent = useSelector((state) => state.stripePaymentIntent);
  const { loading: paymentIntentLoading, paymentIntent } = stripePaymentIntent;
  const stripeCustomer = useSelector((state) => state.stripeCustomer);
  const { loading: customerLoading, customer } = stripeCustomer;
  const sellerListing = useSelector((state) => state.sellerListing);
  const {
    listingLoading: sellerListingLoading,
    sellerListing: sellerListingInformation,
  } = sellerListing;

  const marketplaceListing = useSelector((state) => state.marketplaceListing);
  const { listingInformation: marketplaceListingInformation } =
    marketplaceListing;

  const stripePaymentMethods = useSelector(
    (state) => state.stripePaymentMethods
  );
  const { paymentMethods } = stripePaymentMethods;

  const offers = useSelector((state) => state.offers);
  const { success: createOfferSuccess, loading: offerLoading } = offers;

  useEffect(() => {
    if (createOfferSuccess) {
      navigate("/offerdetails");
      dispatch({ type: CREATE_OFFER_RESET });
    }
    if (paymentIntent && paymentIntent.shipping) {
      setEditShipping(false);
    }

    // The user just added a card and this should be the new payment method
    if (
      paymentIntent &&
      paymentIntent.payment_method == null &&
      paymentMethods.length > 0
    ) {
      const mostRecentPaymentMethod = paymentMethods[0];
      dispatch(
        updatePaymentIntentPaymentMethod(
          paymentIntent.id,
          mostRecentPaymentMethod.id
        )
      );
      setSelectedPaymentMethod(mostRecentPaymentMethod);
      setEditPaymentMethod(false);
      setSelectedPaymentMethodId(mostRecentPaymentMethod.id);
      // updatePaymentIntentPaymentMethodHandler();
    }

    // If the payment intent was created and the cusomer had a card on file.
    if (
      paymentIntent &&
      paymentIntent.payment_method &&
      paymentMethods.length > 0
    ) {
      // const paymentMethodObject = paymentMethods.find(
      //   (obj) => obj.id === paymentIntent.payment_method
      // );
      const mostRecentPaymentMethod = paymentMethods[0];

      if (mostRecentPaymentMethod.id == paymentIntent.payment_method) {
        setSelectedPaymentMethodId(paymentIntent.payment_method);
        setSelectedPaymentMethod(mostRecentPaymentMethod);
        setEditPaymentMethod(false);
      }
    }
  }, [createOfferSuccess, paymentIntent, paymentMethods]);

  const updatePaymentIntentShippingHandler = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    var addressElement = elements.getElement("address");

    const { complete, value } = await addressElement.getValue();

    if (complete) {
      dispatch(updatePaymentIntentShipping(paymentIntent.id, value));
      setEditShipping(false);
    }
  };

  const updatePaymentIntentPaymentMethodHandler = async (event) => {
    event.preventDefault();
    if (selectedPaymentMethodId) {
      dispatch(
        updatePaymentIntentPaymentMethod(
          paymentIntent.id,
          selectedPaymentMethodId
        )
      );
      const paymentMethodObject = paymentMethods.find(
        (obj) => obj.id === selectedPaymentMethodId
      );
      setSelectedPaymentMethod(paymentMethodObject);
      setEditPaymentMethod(false);
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    dispatch(createOffer(paymentIntent.id, sellerListingInformation.updatedAt));
  };

  return (
    <>
      <div className="row justify-content-evenly">
        <div className="col-lg-5">
          <form onSubmit={handleSubmit}>
            <div className="container bg-white p-3 mb-4 rounded">
              {paymentIntentLoading ? (
                <>
                  <div class="hstack gap-3 border-bottom mb-3 pb-2">
                    <h4 className="me-auto">Shipping</h4>
                  </div>{" "}
                  <p class="placeholder-glow">
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-9"></span>
                    <span class="placeholder col-12"></span>
                  </p>
                </>
              ) : (
                <>
                  <div class="hstack gap-3 border-bottom mb-3 pb-2">
                    <h4 className="me-auto">Shipping</h4>
                    {paymentIntent &&
                      paymentIntent.shipping &&
                      (editShipping ? (
                        <a
                          class="link-primary text-decoration-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => setEditShipping(false)}
                        >
                          Cancel
                        </a>
                      ) : (
                        <a
                          class="link-primary text-decoration-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditShipping(true);
                          }}
                        >
                          Edit
                        </a>
                      ))}
                  </div>
                  {paymentIntent &&
                    paymentIntent.shipping &&
                    paymentIntent.shipping.address &&
                    !editShipping && (
                      <div class="container border rounded shadow-sm p-3">
                        <h6 class="mb-1">{paymentIntent.shipping.name}</h6>
                        <p class="text-body-secondary fw-light mb-0">
                          {paymentIntent.shipping.address.line1}
                        </p>
                        <p class="text-body-secondary fw-light mb-1">
                          {`${paymentIntent.shipping.address.city}, ${paymentIntent.shipping.address.state} ${paymentIntent.shipping.address.postal_code} US`}
                        </p>
                      </div>
                    )}

                  {editShipping && (
                    <>
                      <AddressElement
                        options={{
                          mode: "shipping",
                          allowedCountries: ["US"],

                          fields: {
                            phone: "always",
                          },
                          validation: {
                            phone: {
                              required: "always",
                            },
                          },
                        }}
                      />
                      <button
                        disabled={!stripe}
                        onClick={updatePaymentIntentShippingHandler}
                        className="btn btn-primary mt-3"
                      >
                        Use this address
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="container bg-white p-3 mb-4 rounded">
              <h4 className="border-bottom mb-3 pb-3">Delivery method</h4>
              {paymentIntentLoading ? (
                <>
                  <p class="placeholder-glow">
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-9"></span>
                    <span class="placeholder col-12"></span>
                  </p>
                </>
              ) : (
                <>
                  <h5 class="mb-1">Fedex Ground</h5>
                  {paymentIntent &&
                  paymentIntent.metadata &&
                  paymentIntent.metadata.buyerShipping ? (
                    <p class="text-body-secondary fw-light mb-0">{`$${(
                      Number(paymentIntent.metadata.buyerShipping) / 100
                    ).toFixed(2)} Estimated delivery 5-7 business days.`}</p>
                  ) : (
                    <p class="text-body-secondary fw-light mb-0">
                      Estimated delivery 5-7 business days.
                    </p>
                  )}
                </>
              )}
            </div>

            <div className="container bg-white p-3 rounded">
              {paymentIntentLoading ? (
                <>
                  <div class="hstack gap-3 border-bottom mb-3 pb-2">
                    <h4 className="me-auto">Payment Method</h4>
                  </div>{" "}
                  <p class="placeholder-glow">
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-9"></span>
                    <span class="placeholder col-12"></span>
                  </p>
                </>
              ) : (
                <>
                  <div class="hstack gap-3 border-bottom mb-3 pb-2">
                    <h4 className="me-auto">Payment Method</h4>
                    {paymentIntent &&
                      paymentIntent.payment_method &&
                      (editPaymentMethod ? (
                        <a
                          class="link-primary text-decoration-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => setEditPaymentMethod(false)}
                        >
                          Cancel
                        </a>
                      ) : (
                        <a
                          class="link-primary text-decoration-none"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditPaymentMethod(true);
                          }}
                        >
                          Edit
                        </a>
                      ))}
                  </div>
                  {paymentIntent &&
                    paymentIntent.payment_method &&
                    !editPaymentMethod && (
                      <div class="container border rounded shadow-sm p-3">
                        <div class="hstack gap-3">
                          <div>
                            <img
                              className="img-fluid"
                              style={{ width: 48 }}
                              src={
                                cardBrandToUrl[selectedPaymentMethod.card.brand]
                              }
                            ></img>
                          </div>
                          <div>
                            <p class="text-body-secondary fw-light mb-0 text-capitalize">
                              {selectedPaymentMethod.card.brand} ****
                              {selectedPaymentMethod.card.last4}{" "}
                              {selectedPaymentMethod.card.exp_month}/
                              {selectedPaymentMethod.card.exp_year}
                            </p>
                            <p class="text-body-secondary fw-light mb-1">
                              {`${selectedPaymentMethod.billing_details.address.line1}, ${selectedPaymentMethod.billing_details.address.city} ${selectedPaymentMethod.billing_details.address.state} ${selectedPaymentMethod.billing_details.address.postal_code} US`}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                  {editPaymentMethod && (
                    <>
                      <div class="d-flex flex-column gap-4 align-items-center ">
                        <div class="list-group list-group-radio gap-2">
                          {paymentMethods.map((paymentMethod, index) => (
                            <div
                              class="position-relative rounded"
                              key={paymentMethod.id}
                            >
                              <input
                                class="form-check-input position-absolute top-50 end-0 me-3 fs-5"
                                type="radio"
                                name="listGroupRadioGrid"
                                value={paymentMethod.id}
                                id={`paymentScreenRadio${index}`}
                                checked={
                                  selectedPaymentMethodId == paymentMethod.id
                                }
                                onChange={(e) => {
                                  setSelectedPaymentMethodId(e.target.value);
                                }}
                              ></input>
                              <label
                                class="list-group-item py-3 pe-5"
                                htmlFor={`paymentScreenRadio${index}`}
                              >
                                <div class="hstack gap-3">
                                  <div>
                                    <img
                                      className="img-fluid"
                                      style={{ width: 48 }}
                                      src={
                                        cardBrandToUrl[paymentMethod.card.brand]
                                      }
                                    ></img>
                                  </div>
                                  <div>
                                    <strong class="fw-semibold text-capitalize">
                                      {paymentMethod.card.brand} ****
                                      {paymentMethod.card.last4}{" "}
                                      {paymentMethod.card.exp_month}/
                                      {paymentMethod.card.exp_year}
                                    </strong>
                                    <span class="d-block small opacity-75">
                                      {`${paymentMethod.billing_details.address.line1}, ${paymentMethod.billing_details.address.city} ${paymentMethod.billing_details.address.state} ${paymentMethod.billing_details.address.postal_code} US`}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <p className="mt-3">
                        <a
                          href=""
                          class="link-primary text-decoration-none"
                          data-bs-toggle="modal"
                          data-bs-target="#paymentModal"
                        >
                          + Add a new payment method
                        </a>
                      </p>
                      <button
                        disabled={!stripe}
                        onClick={updatePaymentIntentPaymentMethodHandler}
                        className="btn btn-primary mt-2"
                      >
                        Use this payment method
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
        <div className="col-lg-4 mt-4 mt-lg-0">
          <div
            className={`container bg-white p-3 rounded position-relative ${
              paymentIntentLoading || sellerListingLoading || offerLoading
                ? "opacity-25"
                : ""
            }`}
          >
            {paymentIntentLoading || sellerListingLoading || offerLoading ? (
              <div class="position-absolute top-50 start-50 translate-middle">
                <LoadingSpinner></LoadingSpinner>
              </div>
            ) : null}
            <h4 className="border-bottom mb-3 pb-3">Offer details</h4>
            {sellerListingInformation && (
              <>
                <div className="row mt-3">
                  <div className="col-4">
                    {sellerListingInformation.photos[0] && (
                      <img
                        src={sellerListingInformation.photos[0]}
                        className="img-fluid img-thumbnail"
                        alt="..."
                      ></img>
                    )}
                  </div>
                  <div className="col-8">
                    <h6 className="mb-1">
                      {sellerListingInformation.title &&
                        sellerListingInformation.title}
                    </h6>
                    <p className="text-primary mb-0">
                      <small>
                        {marketplaceListingInformation &&
                          marketplaceListingInformation.make &&
                          marketplaceListingInformation.make}
                      </small>
                    </p>
                    <p className="text-secondary">
                      US Size:&nbsp;
                      {sellerListingInformation.size &&
                        sellerListingInformation.size}
                    </p>
                    <div className="d-flex flex-row">
                      <span className="badge text-bg-light p-1">
                        ReShup Certified
                      </span>
                      <span className="badge text-bg-light p-1 ms-2">
                        Condition: <span className="text-success">New</span>
                      </span>
                    </div>
                  </div>
                </div>

                {paymentIntent && paymentIntent.metadata && (
                  <div>
                    <div className="border-bottom my-3"></div>
                    <div class="d-flex justify-content-between">
                      <p className="text-muted mb-1">Subtotal:</p>
                      <p className="mb-1">
                        $
                        {paymentIntent.metadata.salePrice
                          ? (
                              Number(paymentIntent.metadata.salePrice) / 100
                            ).toFixed(2)
                          : Number(0).toFixed(2)}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p className="text-muted mb-1">Shipping:</p>
                      <p className="mb-1">
                        $
                        {paymentIntent.metadata.buyerShipping
                          ? (
                              Number(paymentIntent.metadata.buyerShipping) / 100
                            ).toFixed(2)
                          : Number(0).toFixed(2)}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p className="text-muted mb-1">Tax:</p>
                      <p className="mb-1">
                        $
                        {paymentIntent.metadata.buyerTax
                          ? (
                              Number(paymentIntent.metadata.buyerTax) / 100
                            ).toFixed(2)
                          : Number(0).toFixed(2)}
                      </p>
                    </div>
                    <div className="border-bottom my-3"></div>
                    <div class="d-flex justify-content-between">
                      <p className="mb-1">If seller accepts, you pay:</p>
                      <h5 className="mb-1">
                        $
                        {paymentIntent.metadata.buyerPaymentTotal
                          ? (
                              Number(paymentIntent.metadata.buyerPaymentTotal) /
                              100
                            ).toFixed(2)
                          : paymentIntent.metadata.salePrice
                          ? (
                              Number(paymentIntent.metadata.salePrice) / 100
                            ).toFixed(2)
                          : Number(0).toFixed(2)}
                      </h5>
                    </div>
                    <div class="p-3 bg-primary-subtle border rounded-3 mt-3">
                      <p className="mb-1">
                        <strong>After sending an offer, what's next?</strong>
                      </p>
                      <p className="mb-0">
                        The seller has 24 hours to make a move. You'll only be
                        charged if they accept.
                      </p>
                    </div>
                    <div class="d-grid col-12 mx-auto mt-3">
                      {!editShipping && !editPaymentMethod ? (
                        <button
                          class="btn btn-primary p-2"
                          type="button"
                          onClick={(event) => handleSubmit(event)}
                        >
                          {paymentLoading && (
                            <span class="spinner-border spinner-border-sm me-2"></span>
                          )}
                          <span className="me-2">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                          Send offer
                        </button>
                      ) : (
                        <button
                          class="btn btn-secondary p-2"
                          type="button"
                          disabled={true}
                        >
                          <span className="me-2">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                          Send offer
                        </button>
                      )}
                    </div>
                  </div>
                )}

                <div className="d-flex align-items-center mt-3">
                  <h2 className="me-3">
                    {/* <i class="bi bi-bag-check"></i> */}
                    <i class="bi bi-shield-check"></i>
                  </h2>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Authenticity promised</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        All products will be sent to the ReShup warehouse for
                        verification.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h2 className="me-3">
                    <i class="bi bi-box2-heart"></i>
                  </h2>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Buyer protection</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Get what you paid for or your money back its that
                        simple.
                      </small>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <PaymentModal></PaymentModal>
    </>
  );
};

export default OfferCheckoutForm;
