import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import LoginScreen from "./screens/LoginScreen";
import MarketplaceScreen from "./screens/MarketplaceScreen";
import HomeScreen from "./screens/HomeScreen";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoginModal from "./components/Navbar_Components/LoginModal";
import MobileSearchModal from "./components/MobileSearchModal";
import ToastAlert from "./components/ToastAlert";
import AlertsContainer from "./components/AlertsContainer";
import ProfileSettingsScreen from "./screens/ProfileSettingsScreen";
import ProfileInformationScreen from "./screens/ProfileInformationScreen";
import ProfileBuyingScreen from "./screens/ProfileBuyingScreen";
import ProfileSellingScreen from "./screens/ProfileSellingScreen";
import ProfileSellingCompletedScreen from "./screens/ProfileSellingCompletedScreen";
import ProfileFavoritesScreen from "./screens/ProfileFavoritesScreen";
import ProfileSellerInformationScreen from "./screens/ProfileSellerInformationScreen";
import ProfileBuyingInformationScreen from "./screens/ProfileBuyingInformationScreen";
import ProfileSellingInformationScreen from "./screens/ProfileSellingInformationScreen";
import ProfileShippingScreen from "./screens/ProfileShippingScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import PublicProfileListingsScreen from "./screens/PublicProfileListingsScreen";
import ScrollToTop from "./components/ScrollToTop";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./css/main.min.css";
import "./css/inputNumberArrows.css";
import { login } from "./actions/userActions";
import ListingScreen from "./screens/ListingScreen";
import SellerListingScreen from "./screens/SellerListingScreen";
import SellingScreen from "./screens/SellingScreen";
import ListOrdersScreen from "./screens/admin/ListOrdersScreen";
import PurchaseCompleteScreen from "./screens/PurchaseCompleteScreen";
import PaymentScreen from "./screens/PaymentScreen";
import OfferScreen from "./screens/OfferScreen";
import UserSellingScreen from "./screens/UserSellingScreen";
import FAQScreen from "./screens/FAQScreen";
import TermsScreen from "./screens/TermsScreen";
import ContactScreen from "./screens/ContactScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ProfileOffersScreen from "./screens/ProfileOffersScreen";
import ProfileOfferScreen from "./screens/ProfileOfferScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import LoginErrorScreen from "./screens/LoginErrorScreen";
import PlaygroundScreen from "./screens/PlaygroundScreen";
import OfferModal from "./components/OfferModal";
import OfferCreatedScreen from "./screens/OfferCreatedScreen";
import OfferAcceptedScreen from "./screens/OfferAcceptedScreen";
import AboutScreen from "./screens/AboutScreen";
import GuideScreen from "./screens/GuideScreen";
import AuthenticationScreen from "./screens/AuthenticationScreen";
import GettingStartedGuideScreen from "./screens/GettingStartedGuideScreen";
import AdminOrderInformationScreen from "./screens/admin/AdminOrderInformationScreen";
import SellerShippingLabel from "./components/SellerShippingLabel";
import BuyerShippingLabel from "./components/BuyerShippingLabel";
import ListRootMarketplaceListingsScreen from "./screens/admin/ListRootMarketplaceListingsScreen";
import CreateRootMarketplaceListing from "./screens/admin/CreateRootMarketplaceListing";
function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading } = userLogin;
  useEffect(() => {
    // document.body.style = "background: #f8f9fa;";
  }, []);

  return (
    <>
      <BrowserRouter>
        {/* <LoadScript googleMapsApiKey="AIzaSyCbQiS1l7jZLICasyQfSZsgjg0iYJrO9Wk"> */}
        <ScrollToTop />
        <Navbar></Navbar>
        <LoginModal></LoginModal>
        <OfferModal></OfferModal>
        <MobileSearchModal></MobileSearchModal>
        <AlertsContainer></AlertsContainer>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/login" element={<HomeScreen />} />
          <Route path="/sell" element={<SellingScreen />} />
          <Route path="/orderdetails" element={<PurchaseCompleteScreen />} />
          <Route path="/offerdetails" element={<OfferCreatedScreen />} />
          <Route path="/offeraccepted" element={<OfferAcceptedScreen />} />
          <Route path="/buy/:id" element={<PaymentScreen />} />
          <Route path="/offer/:id" element={<OfferScreen />} />
          <Route path="/marketplace" element={<MarketplaceScreen />} />
          <Route path="/seller/:id" element={<PublicProfileListingsScreen />} />

          <Route path="/profile/settings" element={<ProfileSettingsScreen />} />
          <Route path="/profile/offers" element={<ProfileOffersScreen />} />
          <Route
            path="/profile/offer/:offerId"
            element={<ProfileOfferScreen />}
          />
          <Route
            path="/profile/settings/seller"
            element={<ProfileSellerInformationScreen />}
          />
          <Route
            path="/profile/settings/shipping"
            element={<ProfileShippingScreen />}
          />
          <Route path="/profile/buying" element={<ProfileBuyingScreen />} />
          <Route
            path="/profile/buying/:orderNumber"
            element={<ProfileBuyingInformationScreen />}
          />
          <Route path="/profile/selling" element={<ProfileSellingScreen />} />
          <Route
            path="/profile/selling/:orderNumber"
            element={<ProfileSellingInformationScreen />}
          />
          <Route
            path="/profile/selling/completed"
            element={<ProfileSellingCompletedScreen />}
          />
          <Route
            path="/profile/favorites"
            element={<ProfileFavoritesScreen />}
          />
          <Route
            path="/profile/information"
            element={<ProfileInformationScreen />}
          />
          <Route
            path="/order/seller-shipping-label/:orderNumber"
            element={<SellerShippingLabel />}
          />
          <Route
            path="/order/buyer-shipping-label/:orderNumber"
            element={<BuyerShippingLabel />}
          />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route
            path="/reshup_authentication"
            element={<AuthenticationScreen />}
          />
          <Route path="/guide" element={<GuideScreen />} />
          <Route
            path="/guide/getting_started"
            element={<GettingStartedGuideScreen />}
          />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
          <Route path="/policy" element={<PrivacyPolicyScreen />} />
          <Route path="/oautherror" element={<LoginErrorScreen />} />
          <Route path="/listing/:id" element={<ListingScreen />} />
          <Route path="/sellerlisting/:id" element={<SellerListingScreen />} />
          <Route path="/sell/:id" element={<UserSellingScreen />} />
          <Route path="/sell/:id/:sid" element={<UserSellingScreen />} />
          <Route
            path="/admin/orders"
            element={<ListOrdersScreen></ListOrdersScreen>}
          />
          <Route
            path="/admin/root-marketplace-listings"
            element={
              <ListRootMarketplaceListingsScreen></ListRootMarketplaceListingsScreen>
            }
          />
          <Route
            path="/admin/create-root-marketplace-listing"
            element={
              <CreateRootMarketplaceListing></CreateRootMarketplaceListing>
            }
          />
          <Route
            path="/admin/create-root-marketplace-listing/:id"
            element={
              <CreateRootMarketplaceListing></CreateRootMarketplaceListing>
            }
          />
          <Route
            path="/admin/order/:orderNumber"
            element={
              <AdminOrderInformationScreen></AdminOrderInformationScreen>
            }
          />

          <Route
            path="/marketplace/:listingType"
            element={<MarketplaceScreen />}
          />
          <Route
            path="/playground"
            element={<PlaygroundScreen></PlaygroundScreen>}
          />
          <Route path="/*" element={<NotFoundScreen></NotFoundScreen>} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </>
  );
}

export default App;
