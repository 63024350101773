import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

const SharingModel = ({ listingInformation }) => {
  const { title, photos } = listingInformation;
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess(true);
      })
      .catch(() => {
        setCopySuccess(false);
      });
  };

  const handleTwitterShare = () => {
    const content = `Check out what I just found on ReShup: ${title}`;
    const imageUrl = photos && photos[0];
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      content
    )}&url=${encodeURIComponent(window.location.href)}`;
    window.open(twitterUrl, "_blank");
  };
  const handleFacebookShare = () => {
    const testing = "https://www.mercari.com/us/item/m54963582384/";
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(facebookUrl, "_blank");
  };
  return (
    <div className="modal fade" id="sharingModal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="sharingModalLabel">
              Share
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            {copySuccess && (
              <div
                class="alert alert-primary alert-dismissible fade show"
                role="alert"
              >
                URL copied to clipboard!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                ></button>
              </div>
            )}
            <div class="hstack gap-2">
              <div class="p-2">
                <img
                  style={{ width: 150 }}
                  src={photos && photos.length > 0 ? photos[0] : ""}
                  className="img-fluid"
                ></img>
              </div>
              <div class="p-2 ms-auto">
                <h5>{title && title}</h5>
              </div>
            </div>
            <hr></hr>
            <div class="d-grid gap-3 text-start">
              <button
                class="btn btn-light border"
                type="button"
                onClick={copyToClipboard}
              >
                <FontAwesomeIcon icon={faClipboard} /> Copy
              </button>
              <div class="hstack gap-2">
                <button
                  class="btn  border col-6"
                  type="button"
                  style={{ backgroundColor: "#ecedf1" }}
                  onClick={handleTwitterShare}
                >
                  <TwitterIcon size={20} round={true} /> Twitter
                </button>
                <button
                  class="btn btn-light border col-6"
                  type="button"
                  onClick={handleFacebookShare}
                >
                  <FacebookIcon size={20} round={true} /> Facebook
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingModel;
