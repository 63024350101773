import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";
import { env_name } from "./config/env.js";

import {
  userLoginReducer,
  userLocationReducer,
  userInformationReducer,
  userStripeAccountReducer,
  userStripeAccountLink,
  userListingsReducer,
  userProfileReducer,
  userLikesReducer,
  userInquiryReducer,
} from "./reducers/userReducers";
import {
  marketplaceListingsReducer,
  marketplaceBrandsReducer,
  marketplaceListingReducer,
  marketplaceListingSellingReducer,
  createMarketplaceListingReducer,
  deleteMarketplaceListingReducer,
  sellerListingsReducer,
  sellerListingsSizesReducer,
  sellerListingReducer,
  adminRootMarketplaceListingsReducer,
  adminRootMarketplaceListingInformationReducer,
  cheapestSellerListingsReducer,
} from "./reducers/marketplaceReducers";
import {
  stripeSetupIntentReducer,
  stripePaymentIntentReducer,
  stripeCustomerReducer,
  stripePaymentMethodsReducer,
  stripeAccountReducer,
  stripeUpdatedCustomerShippingReducer,
  stripeTransferSellerPayoutReducer,
} from "./reducers/stripeReducers";
import {
  buyerOrdersReducer,
  sellerOrdersReducer,
  buyerOrderInformationReducer,
  sellerOrderInformationReducer,
  buyerOrdersCompletedReducer,
  sellerOrdersCompletedReducer,
  adminOrdersReducer,
  adminOrderInformationReducer,
} from "./reducers/orderReducers";
import {
  offerReducer,
  offerStatusReducer,
  forPurchaseOffersReducer,
  forSaleOffersReducer,
} from "./reducers/offerReducers";
import { messageReducer } from "./reducers/alertReucers";
import { login } from "./actions/userActions";
import { baseUrl } from "./config/env.js";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userInformation: userInformationReducer,
  userStripeAccount: userStripeAccountReducer,
  userStripeLink: userStripeAccountLink,
  userListings: userListingsReducer,
  userProfile: userProfileReducer,
  userLikes: userLikesReducer,
  userInquiry: userInquiryReducer,
  offers: offerReducer,
  offerStatus: offerStatusReducer,
  forPurchaseOffers: forPurchaseOffersReducer,
  forSaleOffers: forSaleOffersReducer,
  buyerOrders: buyerOrdersReducer,
  buyerOrdersCompleted: buyerOrdersCompletedReducer,
  sellerOrders: sellerOrdersReducer,
  sellerOrdersCompleted: sellerOrdersCompletedReducer,
  buyerOrderInformation: buyerOrderInformationReducer,
  sellerOrderInformation: sellerOrderInformationReducer,
  location: userLocationReducer,
  marketplaceListings: marketplaceListingsReducer,
  marketplaceListing: marketplaceListingReducer,
  marketplaceBrands: marketplaceBrandsReducer,
  marketplaceListingSelling: marketplaceListingSellingReducer,
  createdMarketplaceListing: createMarketplaceListingReducer,
  deletedMarketplaceListing: deleteMarketplaceListingReducer,
  cheapestSellerListings: cheapestSellerListingsReducer,
  sellerListings: sellerListingsReducer,
  sellerListing: sellerListingReducer,
  sellerListingsSizes: sellerListingsSizesReducer,
  alerts: messageReducer,
  stripeSetupIntent: stripeSetupIntentReducer,
  stripePaymentIntent: stripePaymentIntentReducer,
  stripeCustomer: stripeCustomerReducer,
  stripePaymentMethods: stripePaymentMethodsReducer,
  stripeAccount: stripeAccountReducer,
  stripeUpdatedCustomerShipping: stripeUpdatedCustomerShippingReducer,
  stripeTransferSellerPayout: stripeTransferSellerPayoutReducer,
  adminOrders: adminOrdersReducer,
  adminOrderInformation: adminOrderInformationReducer,
  adminRootMarketplaceListings: adminRootMarketplaceListingsReducer,
  adminRootMarketplaceListingInformation:
    adminRootMarketplaceListingInformationReducer,
});

const userLocaitionFromStorage = localStorage.getItem("userLocation")
  ? {
      loading: false,
      location: JSON.parse(localStorage.getItem("userLocation")),
    }
  : {
      loading: false,
      location: null,
    };

const initialState = {
  location: userLocaitionFromStorage,
};

const middleware = [thunk];
const middlewareEnhancer = applyMiddleware(...middleware);
const composedEnhancers =
  env_name === "prod"
    ? compose(middlewareEnhancer)
    : composeWithDevTools(middlewareEnhancer);

const store = createStore(reducer, initialState, composedEnhancers);

if (window.location.hostname !== "localhost") {
  axios.defaults.baseURL = baseUrl;
}
axios.defaults.withCredentials = true;

store.dispatch(login());

export default store;
