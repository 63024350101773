export const STRIPE_CREATE_SETUPINTENT_REQUEST =
  "STRIPE_CREATE_SETUPINTENT_REQUEST";
export const STRIPE_CREATE_SETUPINTENT_SUCCESS =
  "STRIPE_CREATE_SETUPINTENT_SUCCESS";
export const STRIPE_CREATE_SETUPINTENT_FAIL = "STRIPE_CREATE_SETUPINTENT_FAIL";
export const STRIPE_PAYMENTINTENT_RESET = "STRIPE_PAYMENTINTENT_RESET";
export const STRIPE_CREATE_PAYMENTINTENT_REQUEST =
  "STRIPE_CREATE_PAYMENTINTENT_REQUEST";
export const STRIPE_CREATE_PAYMENTINTENT_SUCCESS =
  "STRIPE_CREATE_PAYMENTINTENT_SUCCESS";
export const STRIPE_CREATE_PAYMENTINTENT_FAIL =
  "STRIPE_CREATE_PAYMENTINTENT_FAIL";
export const STRIPE_UPDATE_PAYMENTINTENT_REQUEST =
  "STRIPE_UPDATE_PAYMENTINTENT_REQUEST";
export const STRIPE_UPDATE_PAYMENTINTENT_SUCCESS =
  "STRIPE_UPDATE_PAYMENTINTENT_SUCCESS";
export const STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS =
  "STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS";
export const STRIPE_UPDATE_PAYMENTINTENT_FAIL =
  "STRIPE_UPDATE_PAYMENTINTENT_FAIL";
export const STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST =
  "STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST";
export const STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS =
  "STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS";
export const STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL =
  "STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL";
export const STRIPE_GET_CUSTOMER_REQUEST = "STRIPE_GET_CUSTOMER_REQUEST";
export const STRIPE_GET_CUSTOMER_SUCCESS = "STRIPE_GET_CUSTOMER_SUCCESS";
export const STRIPE_GET_CUSTOMER_FAIL = "STRIPE_GET_CUSTOMER_FAIL";
export const STRIPE_GET_PAYMENTMETHODS_REQUEST =
  "STRIPE_GET_PAYMENTMETHODS_REQUEST";
export const STRIPE_GET_PAYMENTMETHODS_SUCCESS =
  "STRIPE_GET_PAYMENTMETHODS_SUCCESS";
export const STRIPE_REMOVE_PAYMENTMETHOD_REQUEST =
  "STRIPE_REMOVE_PAYMENTMETHOD_REQUEST";
export const STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS =
  "STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS";
export const STRIPE_GET_PAYMENTMETHODS_FAIL = "STRIPE_GET_PAYMENTMETHODS_FAIL";
export const STRIPE_GET_ACCOUNT_REQUEST = "STRIPE_GET_ACCOUNT_REQUEST";
export const STRIPE_GET_ACCOUNT_SUCCESS = "STRIPE_GET_ACCOUNT_SUCCESS";
export const STRIPE_GET_ACCOUNT_FAIL = "STRIPE_GET_ACCOUNT_FAIL";
export const STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST =
  "STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST";
export const STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS =
  "STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS";
export const STRIPE_TRANSFER_SELLER_PAYOUT_FAIL =
  "STRIPE_TRANSFER_SELLER_PAYOUT_FAIL";
