import React from "react";
import { Link } from "react-router-dom";

const PurchaseCompleteScreen = () => {
  return (
    <section class="py-5 text-center container">
      <div class="row py-lg-5">
        <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="fw-light">Your order is on the way!</h1>
          <p class="lead text-muted">
            Your order is on the way. The seller will ship your item to our
            facility and we will verify its authenticity. To track and view your
            order click the link below. You also will recieve an email
            confirmation shortly, make sure to check your spam.
          </p>
          <p>
            <Link to="/profile/buying" class="btn btn-primary my-2">
              View order details
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PurchaseCompleteScreen;
