import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ShippingForm from "./ShippingForm";

const ShippingIntent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === true) {
      axios
        .get("/api/stripe/config")
        .then((response) => {
          setStripePromise(loadStripe(response.data.publishableKey));
        })
        .catch((error) => {
        });
    }
  }, []);

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <ShippingForm></ShippingForm>
        </Elements>
      )}
    </>
  );
};

export default ShippingIntent;
