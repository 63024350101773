import axios from "axios";
import {
  GET_ADMIN_ORDERS_FAIL,
  GET_ADMIN_ORDERS_REQUEST,
  GET_ADMIN_ORDERS_SUCCESS,
  GET_ADMIN_ORDER_INFORMATION_FAIL,
  GET_ADMIN_ORDER_INFORMATION_REQUEST,
  GET_ADMIN_ORDER_INFORMATION_SUCCESS,
  GET_BUYER_ORDERS_COMPLETED_FAIL,
  GET_BUYER_ORDERS_COMPLETED_REQUEST,
  GET_BUYER_ORDERS_COMPLETED_SUCCESS,
  GET_BUYER_ORDERS_FAIL,
  GET_BUYER_ORDERS_INFORMATION_FAIL,
  GET_BUYER_ORDERS_REQUEST,
  GET_BUYER_ORDERS_SUCCESS,
  GET_BUYER_ORDER_INFORMATION_FAIL,
  GET_BUYER_ORDER_INFORMATION_REQUEST,
  GET_BUYER_ORDER_INFORMATION_SUCCESS,
  GET_SELLER_ORDERS_COMPLETED_FAIL,
  GET_SELLER_ORDERS_COMPLETED_REQUEST,
  GET_SELLER_ORDERS_COMPLETED_SUCCESS,
  GET_SELLER_ORDERS_FAIL,
  GET_SELLER_ORDERS_REQUEST,
  GET_SELLER_ORDERS_SUCCESS,
  GET_SELLER_ORDER_INFORMATION_FAIL,
  GET_SELLER_ORDER_INFORMATION_REQUEST,
  GET_SELLER_ORDER_INFORMATION_SUCCESS,
  UPDATE_ADMIN_ORDER_INFORMATION_FAIL,
  UPDATE_ADMIN_ORDER_INFORMATION_REQUEST,
  UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS,
} from "../constants/orderConstants";
import { logout } from "./userActions";

export const getBuyerOrderInformation = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_ORDER_INFORMATION_REQUEST,
    });

    const { data } = await axios.get(`/api/orders/buyer-order/${orderId}`);
    dispatch({
      type: GET_BUYER_ORDER_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_BUYER_ORDER_INFORMATION_FAIL,
      payload: message,
    });
  }
};

export const getBuyerOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_ORDERS_REQUEST,
    });

    const { data } = await axios.get("/api/orders/buyer-orders");
    dispatch({
      type: GET_BUYER_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_BUYER_ORDERS_FAIL,
      payload: message,
    });
  }
};

export const getBuyerOrdersCompleted = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_ORDERS_COMPLETED_REQUEST,
    });

    const { data } = await axios.get("/api/orders/buyer-orders-completed");
    dispatch({
      type: GET_BUYER_ORDERS_COMPLETED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_BUYER_ORDERS_COMPLETED_FAIL,
      payload: message,
    });
  }
};
export const getSellerOrderInformation = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_ORDER_INFORMATION_REQUEST,
    });

    const { data } = await axios.get(`/api/orders/seller-order/${orderId}`);
    dispatch({
      type: GET_SELLER_ORDER_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_SELLER_ORDER_INFORMATION_FAIL,
      payload: message,
    });
  }
};

export const getSellerOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_ORDERS_REQUEST,
    });

    const { data } = await axios.get("/api/orders/seller-orders");
    dispatch({
      type: GET_SELLER_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_SELLER_ORDERS_FAIL,
      payload: message,
    });
  }
};
export const getSellerOrdersCompleted = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_ORDERS_COMPLETED_REQUEST,
    });

    const { data } = await axios.get("/api/orders/seller-orders-completed");
    dispatch({
      type: GET_SELLER_ORDERS_COMPLETED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_SELLER_ORDERS_COMPLETED_FAIL,
      payload: message,
    });
  }
};

export const getAdminOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADMIN_ORDERS_REQUEST,
    });
    const { data } = await axios.get("/api/orders/admin/get-all-orders");
    dispatch({
      type: GET_ADMIN_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_ADMIN_ORDERS_FAIL,
      payload: message,
    });
  }
};

export const getAdminOrderInformation = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADMIN_ORDER_INFORMATION_REQUEST,
    });
    const { data } = await axios.get(`/api/orders/admin/${orderId}`);
    dispatch({
      type: GET_ADMIN_ORDER_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: GET_ADMIN_ORDER_INFORMATION_FAIL,
      payload: message,
    });
  }
};

export const updateAdminOrderInformation = (orderId, update) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ADMIN_ORDER_INFORMATION_REQUEST,
    });
    const { data } = await axios.post(`/api/orders/admin/${orderId}`, update);
    dispatch({
      type: UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: UPDATE_ADMIN_ORDER_INFORMATION_FAIL,
      payload: message,
    });
  }
};
