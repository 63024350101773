import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";

const SellerShippingLabel = () => {
  const params = useParams();
  const orderNumber = params.orderNumber;
  const navigate = useNavigate();
  const location = useLocation();
  const [pdfData, setPdfData] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;

  useEffect(() => {
    if (isAuthenticated === true) {
      // Fetch PDF label data from backend
      setLoading(true);
      axios
        .get(`/api/orders/generate-seller-shipping-label/${orderNumber}`)
        .then((response) => {
          // Decode the Base64 string
          const decodedPdf = atob(response.data);

          // Convert the decoded PDF data to a blob
          const pdfBlob = new Blob([decodedPdf], { type: "application/pdf" });

          // Create a URL for the blob
          const pdfUrl = URL.createObjectURL(pdfBlob);

          setPdfData(pdfUrl);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching PDF label:", error);
          setError(error.message);
          setLoading(false);
        });
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {loading && (
        <div class="d-flex justify-content-center mt-4">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {error && (
        <div class="d-flex justify-content-center mt-4">
          <div class="alert alert-danger col-md-6 mx-2" role="alert">
            There was an issue displaying your shipping label. {error}
          </div>
        </div>
      )}
      {pdfData && (
        <iframe
          title="Shipping Label"
          src={pdfData}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default SellerShippingLabel;
