import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../actions/userActions";
import LoadingSpinner from "../LoadingSpinner";
const LocationModal = ({ distance, setDistance }) => {
  //   const [location, setLocation] = useState([]);
  const zoomLevels = {
    1: "13",
    2: "13",
    5: "12",
    10: "11",
    20: "10",
    40: "10",
    50: "9",
    100: "8",
    250: "8",
    500: "7",
  };
  const dispatch = useDispatch();
  const locationInformation = useSelector((state) => state.location);
  const { loading, location } = locationInformation;
  //   const [distance, setDistance] = useState("50");
  useEffect(() => {
    {
    }
  }, []);
  //   const getLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         getCoordinates,
  //         handleLocationError
  //       );
  //     } else {
  //     }
  //   };
  const handleLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Please allow location information to use this feature.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("Location information is unavailable.");
        break;
      default:
        alert("Location information is unavailable.");
    }
  };
  const getCoordinates = (position) => {
    setLocation([position.coords.longitude, position.coords.latitude]);
  };

  return (
    <div className="container">
      <div className="modal fade" id="locationModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="locationModalLabel">
                Change Location
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                {/* <label htmlFor="floatingInput" className="form-label">
                  Search by city, neighborhood or ZIP code.
                </label>
                <div className="form-floating">
                  <input
                    type="input"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value="Random, Maryland"
                  ></input>
                  <label htmlFor="floatingInput">Location</label>
                </div> */}
                <button
                  className="btn btn-outline-primary w-100"
                  type="button"
                  onClick={() => dispatch(setLocation())}
                >
                  Use Current Location
                </button>
                <div className="form-floating mt-3">
                  <select
                    className="form-select"
                    id="floatingSelect"
                    value={distance}
                    onChange={(event) => setDistance(event.target.value)}
                  >
                    <option value="1">1 mile</option>
                    <option value="2">2 miles</option>
                    <option value="5">5 miles</option>
                    <option value="10">10 miles</option>
                    <option value="20">20 miles</option>
                    <option value="40">40 miles</option>
                    <option value="50">50 miles</option>
                    <option value="100">100 miles</option>
                    <option value="250">250 miles</option>
                    <option value="500">500 miles</option>
                  </select>
                  <label htmlFor="floatingSelect">Radius</label>
                </div>
                <div className="container mt-3">
                  <div className="ratio ratio-16x9">
                    {loading ? (
                      <LoadingSpinner></LoadingSpinner>
                    ) : location ? (
                      <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${location[1]},${location[0]}&zoom=${zoomLevels[distance]}&size=450x250&markers=${location[1]},${location[0]}&key=AIzaSyCbQiS1l7jZLICasyQfSZsgjg0iYJrO9Wk`}
                      ></img>
                    ) : (
                      <p className="fw-light mt-4">
                        Select get "Use Current Location" to view map.
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <p className="fw-lighter mt-3">
                Location along with local buying and messaging will be available
                soon!
              </p>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => {
                  dispatch(setLocation(null));
                  localStorage.removeItem("userLocation");
                }}
              >
                Stop Using Location
              </button>

              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
