import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInformation } from "../actions/userActions";
import { getBuyerOrderInformation } from "../actions/orderActions";

import ProfileBuyingInformation from "../components/Profile_Components/ProfileBuyingInformation";

const ProfileBuyingInformationScreen = () => {
  const params = useParams();
  const orderNumber = params.orderNumber;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getUserInformation());
      dispatch(getBuyerOrderInformation(orderNumber));
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated, orderNumber]);
  return (
    <>
      <div className="container d-flex justify-content-center mt-4 ">
        <ProfileBuyingInformation></ProfileBuyingInformation>
      </div>
    </>
  );
};

export default ProfileBuyingInformationScreen;
