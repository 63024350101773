import axios from "axios";
import {
  STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS,
  STRIPE_CREATE_PAYMENTINTENT_FAIL,
  STRIPE_CREATE_PAYMENTINTENT_REQUEST,
  STRIPE_CREATE_PAYMENTINTENT_SUCCESS,
  STRIPE_CREATE_SETUPINTENT_FAIL,
  STRIPE_CREATE_SETUPINTENT_REQUEST,
  STRIPE_CREATE_SETUPINTENT_SUCCESS,
  STRIPE_GET_ACCOUNT_FAIL,
  STRIPE_GET_ACCOUNT_REQUEST,
  STRIPE_GET_ACCOUNT_SUCCESS,
  STRIPE_GET_CUSTOMER_FAIL,
  STRIPE_GET_CUSTOMER_REQUEST,
  STRIPE_GET_CUSTOMER_SUCCESS,
  STRIPE_GET_PAYMENTMETHODS_FAIL,
  STRIPE_GET_PAYMENTMETHODS_REQUEST,
  STRIPE_GET_PAYMENTMETHODS_SUCCESS,
  STRIPE_REMOVE_PAYMENTMETHOD_REQUEST,
  STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS,
  STRIPE_TRANSFER_SELLER_PAYOUT_FAIL,
  STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST,
  STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS,
  STRIPE_UPDATE_PAYMENTINTENT_FAIL,
  STRIPE_UPDATE_PAYMENTINTENT_REQUEST,
  STRIPE_UPDATE_PAYMENTINTENT_SUCCESS,
} from "../constants/stripeConstants";
import { USER_LOGOUT } from "../constants/userConstants";

export const getSetupIntent = () => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_CREATE_SETUPINTENT_REQUEST,
    });
    const { data } = await axios.get("/api/stripe/create-setup-intent/");

    dispatch({
      type: STRIPE_CREATE_SETUPINTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: STRIPE_CREATE_SETUPINTENT_FAIL,
      payload: message,
    });
  }
};

export const getPaymentIntent = (listingId) => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_REQUEST,
    });
    const { data } = await axios.post("/api/stripe/create-payment-intent/", {
      listingId: listingId,
    });

    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_FAIL,
      payload: message,
    });
  }
};

export const getPaymentIntentOffer = (listingId, offer) => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_REQUEST,
    });
    const { data } = await axios.post(
      "/api/stripe/create-payment-intent-offer/",
      {
        listingId: listingId,
        offer: offer,
      }
    );

    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: STRIPE_CREATE_PAYMENTINTENT_FAIL,
      payload: message,
    });
  }
};

export const updatePaymentIntentShipping =
  (paymentIntentId, addressInformation) => async (dispatch) => {
    try {
      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_REQUEST,
      });
      const { data } = await axios.post(
        "/api/stripe/update-payment-intent-shipping/",
        {
          paymentIntentId,
          addressInformation,
        }
      );

      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_SUCCESS,
        payload: data,
      });

      dispatch(getStripeCustomer());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_FAIL,
        payload: message,
      });
    }
  };

export const updatePaymentIntentPaymentMethod =
  (paymentIntentId, paymentMethod) => async (dispatch) => {
    try {
      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_REQUEST,
      });
      const { data } = await axios.post(
        "/api/stripe/update-payment-intent-payment-method/",
        {
          paymentIntentId,
          paymentMethod,
        }
      );

      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_FAIL,
        payload: message,
      });
    }
  };

export const confirmPaymentIntent =
  (paymentIntentId, updatedAt) => async (dispatch) => {
    try {
      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_REQUEST,
      });
      const { data } = await axios.post("/api/stripe/confirm-payment-intent/", {
        paymentIntentId,
        updatedAt,
      });

      dispatch({
        type: STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: STRIPE_UPDATE_PAYMENTINTENT_FAIL,
        payload: message,
      });
    }
  };

export const getStripeCustomer = () => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_GET_CUSTOMER_REQUEST,
    });
    const { data } = await axios.get("/api/stripe/customer");

    dispatch({
      type: STRIPE_GET_CUSTOMER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    // Any route accessing data from a logged in user should logout if any failure.
    dispatch({ type: USER_LOGOUT });
    dispatch({
      type: STRIPE_GET_CUSTOMER_FAIL,
      payload: message,
    });
  }
};

export const getStripePaymentMethods = () => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_GET_PAYMENTMETHODS_REQUEST,
    });
    const { data } = await axios.get("/api/stripe/payment-methods");

    dispatch({
      type: STRIPE_GET_PAYMENTMETHODS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    // Any route accessing data from a logged in user should logout if any failure.
    dispatch({ type: USER_LOGOUT });
    dispatch({
      type: STRIPE_GET_PAYMENTMETHODS_FAIL,
      payload: message,
    });
  }
};

export const removeStripePaymentMethod =
  (paymentMethodId) => async (dispatch) => {
    try {
      dispatch({
        type: STRIPE_REMOVE_PAYMENTMETHOD_REQUEST,
      });
      const { data } = await axios.get(
        `/api/stripe/delete-payment-method/${paymentMethodId}`
      );

      dispatch({
        type: STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      // Any route accessing data from a logged in user should logout if any failure.
      dispatch({ type: USER_LOGOUT });
      dispatch({
        type: STRIPE_GET_PAYMENTMETHODS_FAIL,
        payload: message,
      });
    }
  };

export const getStripeAccount = () => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_GET_ACCOUNT_REQUEST,
    });
    const { data } = await axios.get("/api/stripe/account");

    dispatch({
      type: STRIPE_GET_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    // Any route accessing data from a logged in user should logout if any failure.
    dispatch({ type: USER_LOGOUT });
    dispatch({
      type: STRIPE_GET_ACCOUNT_FAIL,
      payload: message,
    });
  }
};

export const transferSellerPayout = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST,
    });
    const { data } = await axios.get(
      `/api/stripe/transfer-seller-payout/${orderId}`
    );

    dispatch({
      type: STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: STRIPE_TRANSFER_SELLER_PAYOUT_FAIL,
      payload: message,
    });
  }
};

export const updateCustomerShipping = (address) => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST,
    });
    const { data } = await axios.post(
      "/api/stripe/update-customer-shipping",
      address
    );

    dispatch({
      type: STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    // Any route accessing data from a logged in user should logout if any failure.
    if (message === "Not Authorized") {
      dispatch({ type: USER_LOGOUT });
    }
    dispatch({
      type: STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL,
      payload: message,
    });
  }
};
