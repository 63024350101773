import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import OfferCheckoutForm from "../components/OfferCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSellerListing } from "../actions/marketplaceActions";
import {
  getPaymentIntentOffer,
  getStripeCustomer,
  getStripePaymentMethods,
} from "../actions/stripeActions";

const OfferScreen = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const listingId = params.id;
  let [searchParams, setSearchParams] = useSearchParams();
  const offer = searchParams.get("offer");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  const sellerListing = useSelector((state) => state.sellerListing);
  const { sellerListing: sellerListingInformation } = sellerListing;

  const [stripePromise, setStripePromise] = useState(null);
  const stripePaymentIntent = useSelector((state) => state.stripePaymentIntent);
  const {
    clientSecret,
    error,
    success: stripePaymentIntentSuccess,
  } = stripePaymentIntent;

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}?offer=${offer}`);
    }
    if (isAuthenticated === true) {
      dispatch(getPaymentIntentOffer(listingId, offer));
      dispatch(getSellerListing(listingId));
      dispatch(getStripeCustomer());
      dispatch(getStripePaymentMethods());
    }

    // Also dispatch getting the user
  }, [isAuthenticated, listingId, dispatch]);
  useEffect(() => {
    if (isAuthenticated === true) {
      axios
        .get("/api/stripe/config")
        .then((response) => {
          setStripePromise(loadStripe(response.data.publishableKey));
        })
        .catch((error) => {
        });
    }
  }, [isAuthenticated]);
  return (
    <div className="bg-light py-2 py-lg-5">
      <div className="container-fluid">
        <>
          {error ? (
            <section class="py-5 text-center container">
              <div class="row py-lg-5">
                <div class="col-lg-6 col-md-8 mx-auto">
                  <h1 class="fw-light">
                    There was an issue while trying to purchase this item.
                  </h1>
                  <p class="lead text-muted">{error}</p>
                  <p>
                    <Link to="/marketplace" class="btn btn-primary my-2">
                      Go back to marketplace
                    </Link>
                  </p>
                </div>
              </div>
            </section>
          ) : (
            stripePromise && (
              <Elements
                stripe={stripePromise}
                options={{
                  // clientSecret: clientSecret,
                  // mode: "payment",
                  // currency: "usd",
                  paymentMethodCreation: "manual",
                  // amount: 2999,
                }}
              >
                {/* <CheckoutForm clientSecret={clientSecret}></CheckoutForm> */}
                <OfferCheckoutForm></OfferCheckoutForm>
              </Elements>
            )
          )}
        </>
      </div>
    </div>
  );
};

export default OfferScreen;
