import React, { useRef, useEffect } from "react";
import SearchBar from "../SearchBar";
// import { Offcanvas } from "bootstrap";

const SearchOffcanvas = () => {
  // const offCanvasRef = useRef();
  // useEffect(() => {
  //   var myOffCanvas = offCanvasRef.current;
  //   var bsOffCanvas = new Offcanvas(myOffCanvas);
  // }, []);
  return (
    <div className="container">
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasSearch"
        // ref={offCanvasRef}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Search</h5>

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="">
          <SearchBar
            formClassAttributes={""}
            heightAttributes={{ height: 500 }}
            searchType={"listing"}
          ></SearchBar>
        </div>
      </div>
    </div>
  );
};

export default SearchOffcanvas;
