import React, { useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { baseUrl } from "./../../config/env.js";

const LoginModal = () => {
  const modalRef = useRef();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let [redirect, setRedirect] = useState("");

  const redirectGoogle = () => {
    if (redirect) {
      window.location.href = `${baseUrl}/api/auth/google?redirect=${redirect.substring(
        1
      )}`;
    } else {
      window.location.href = `${baseUrl}/api/auth/google`;
    }
  };
  const redirectMicrosoft = () => {
    if (redirect) {
      window.location.href = `${baseUrl}/api/auth/microsoft?redirect=${redirect.substring(
        1
      )}`;
    } else {
      window.location.href = `${baseUrl}/api/auth/microsoft`;
    }
  };
  const redirectFacebook = () => {
    if (redirect) {
      window.location.href = `${baseUrl}/api/auth/facebook?redirect=${redirect.substring(
        1
      )}`;
    } else {
      window.location.href = `${baseUrl}/api/auth/facebook`;
    }
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    setRedirect(searchParams.get("redirect"));
    if (location.pathname === "/login") {
      bsModal.show();
    }
  }, [location]);

  const closeLoginModal = () => {
    let shippingModal = document.getElementById("LoginModalDismissButton");
    shippingModal.click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        ref={modalRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="loginModalLabel">
                Continue to ReShup
              </h5>
              <button
                type="button"
                id="LoginModalDismissButton"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div class="vstack gap-2 ">
                <button
                  type="button"
                  // class="btn btn-light text-dark border-dark "
                  class="btn btn-light-gray p-2"
                  onClick={() => redirectGoogle()}
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <img
                      src="https://res.cloudinary.com/dszd4owdm/image/upload/v1709773598/kisspng-g-suite-pearl-river-middle-school-google-docs-soft-google-plus-5ad4f155b36555.6827254815239048537348_eunaou.jpg"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      // className="me-2"
                    ></img>
                    Continue with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </button>
                {/* <button
                  type="button"
                  class="btn btn-facebook "
                  onClick={() => redirectFacebook()}
                >
                  <img
                    src="https://res.cloudinary.com/theshup/image/upload/v1676827974/5296499_fb_facebook_facebook_logo_icon_ghtcqg.png"
                    style={{ width: 20, height: 20 }}
                    className="me-2"
                  ></img>
                  Continue with Facebook
                </button> */}
                <button
                  type="button"
                  class="btn btn-light-gray p-2"
                  onClick={() => redirectMicrosoft()}
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <img
                      src="https://res.cloudinary.com/dszd4owdm/image/upload/v1709773598/microsoft-windows-logo-scalable-vector-graphics-microsoft-new-logo-simple_dcx7xp.jpg"
                      style={{ width: 20, height: 20, marginRight: 10 }}
                      // className="me-2"
                    ></img>
                    Continue with Microsoft
                  </div>
                </button>
                <p className="fw-light mt-3">
                  <small>
                    By logging in, you agree to the{" "}
                    <span>
                      <Link to="/terms" onClick={() => closeLoginModal()}>
                        Terms of Service
                      </Link>
                    </span>{" "}
                    and{" "}
                    <span>
                      <Link to="/policy" onClick={() => closeLoginModal()}>
                        Privacy Policy
                      </Link>
                    </span>
                  </small>
                </p>
              </div>
              {/* <p className="fw-lighter mt-3">
                For security reasons we only allow the following logins. Don't
                worry we will not post anything anywhere.
              </p> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-subtleprimary text-primary"
                data-bs-dismiss="modal"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
