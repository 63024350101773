import axios from "axios";
import {
  MARKETPLACE_FILTER_REQUEST,
  MARKETPLACE_FILTER_SUCCESS,
  MARKETPLACE_FILTER_FAIL,
  MARKETPLACE_BRAND_REQUEST,
  MARKETPLACE_BRAND_SUCCESS,
  MARKETPLACE_BRAND_FAIL,
  MARKETPLACE_LISTING_REQUEST,
  MARKETPLACE_LISTING_SUCCESS,
  MARKETPLACE_LISTING_FAIL,
  MARKETPLACE_PRODUCT_CREATE_REQUEST,
  MARKETPLACE_PRODUCT_CREATE_SUCCESS,
  MARKETPLACE_PRODUCT_CREATE_FAIL,
  MARKETPLACE_PRODUCT_DELETE_REQUEST,
  MARKETPLACE_PRODUCT_DELETE_SUCCESS,
  MARKETPLACE_PRODUCT_DELETE_FAIL,
  MARKETPLACE_LISTING_SELLING_REQUEST,
  MARKETPLACE_LISTING_SELLING_SUCCESS,
  MARKETPLACE_LISTING_SELLING_FAIL,
  SELLER_LISTINGS_FILTER_REQUEST,
  SELLER_LISTINGS_FILTER_SUCCESS,
  SELLER_LISTINGS_FILTER_FAIL,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS,
  MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL,
  SELLER_LISTING_REQUEST,
  SELLER_LISTING_SUCCESS,
  SELLER_LISTING_FAIL,
  MARKETPLACE_FILTER_MORE_REQUEST,
  MARKETPLACE_FILTER_MORE_SUCCESS,
  SELLER_LISTINGS_FILTER_MORE_REQUEST,
  SELLER_LISTINGS_FILTER_MORE_SUCCESS,
  EDITORS_PICKS_REQUEST,
  EDITORS_PICKS_SUCCESS,
  EDITORS_PICKS_FAIL,
  MARKETPLACE_ROOT_LISTINGS_REQUEST,
  MARKETPLACE_ROOT_LISTINGS_SUCCESS,
  MARKETPLACE_ROOT_LISTINGS_FAIL,
  MARKETPLACE_ROOT_LISTING_GET_REQUEST,
  MARKETPLACE_ROOT_LISTING_GET_SUCCESS,
  MARKETPLACE_ROOT_LISTING_GET_FAIL,
  MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST,
  MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS,
  MARKETPLACE_ROOT_LISTING_UPSERT_FAIL,
  CHEAPEST_SELLER_LISTINGS_REQUEST,
  CHEAPEST_SELLER_LISTINGS_SUCCESS,
  CHEAPEST_SELLER_LISTINGS_FAIL,
} from "../constants/marketplaceConstants";
import { logout } from "../actions/userActions";

export const getMarketplaceBrands = (listingType) => async (dispatch) => {
  try {
    dispatch({ type: MARKETPLACE_BRAND_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `/api/marketplace/brands`,
      data: {
        listingType: listingType,
      },
    });
    dispatch({ type: MARKETPLACE_BRAND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MARKETPLACE_BRAND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const filterMarketplaceListings =
  (listingType, brands, sort, price, query, sizeType, page) =>
  async (dispatch) => {
    try {
      dispatch({ type: MARKETPLACE_FILTER_REQUEST });
      const { data } = await axios({
        method: "post",
        url: "/api/marketplace/filter",
        data: {
          listingType: listingType,
          brands: brands,
          sort: sort,
          price: price,
          page: page,
          query: query,
          sizeType: sizeType,
        },
      });
      dispatch({ type: MARKETPLACE_FILTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: MARKETPLACE_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const filterMoreMarketplaceListings =
  (listingType, brands, sort, price, query, sizeType, page) =>
  async (dispatch) => {
    try {
      dispatch({ type: MARKETPLACE_FILTER_MORE_REQUEST });
      const { data } = await axios({
        method: "post",
        url: "/api/marketplace/filter",
        data: {
          listingType: listingType,
          brands: brands,
          sort: sort,
          price: price,
          page: page,
          query: query,
          sizeType: sizeType,
        },
      });
      dispatch({ type: MARKETPLACE_FILTER_MORE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: MARKETPLACE_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getMarketplaceListing = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: MARKETPLACE_LISTING_REQUEST });

    const { data } = await axios.get(
      `/api/marketplace/listingdetails/${listingId}`
    );

    dispatch({ type: MARKETPLACE_LISTING_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MARKETPLACE_LISTING_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCheapestSellerListings = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: CHEAPEST_SELLER_LISTINGS_REQUEST });

    const { data } = await axios.get(
      `/api/marketplace/cheapest-seller-listings/${listingId}`
    );

    dispatch({ type: CHEAPEST_SELLER_LISTINGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CHEAPEST_SELLER_LISTINGS_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSellerListing = (listingId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SELLER_LISTING_REQUEST });

    const { data } = await axios.get(
      `/api/marketplace/listingdetails/sellerlisting/${listingId}`
    );

    dispatch({ type: SELLER_LISTING_SUCCESS, payload: data });
    const {
      sellerListing: {
        sellerListing: { shupShoeId },
      },
    } = getState();
    dispatch(getMarketplaceListing(shupShoeId));
  } catch (error) {
    dispatch({
      type: SELLER_LISTING_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const clearSellerListing = () => async (dispatch) => {
  dispatch({ type: SELLER_LISTING_SUCCESS, payload: null });
};

export const getMarketplaceListingSelling =
  (listingId) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKETPLACE_LISTING_SELLING_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const config = {
        headers: {
          session_token: token,
        },
      };

      const { data } = await axios.get(
        `/api/marketplace/sell/${listingId}`,
        config
      );

      dispatch({ type: MARKETPLACE_LISTING_SELLING_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "User signed out. Please log in again") {
        dispatch(logout());
      }
      dispatch({
        type: MARKETPLACE_LISTING_SELLING_FAIL,
        payload: message,
      });
    }
  };
export const createMarketplaceListing =
  (listingId, sellingInformation) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKETPLACE_PRODUCT_CREATE_REQUEST });

      const { data } = await axios.post(
        `/api/marketplace/sell/${listingId}`,
        sellingInformation
      );

      dispatch({ type: MARKETPLACE_PRODUCT_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      if (message === "Not Authorized") {
        dispatch(logout());
      }
      dispatch({
        type: MARKETPLACE_PRODUCT_CREATE_FAIL,
        payload: message,
        listingId: sellingInformation._id,
        code: error.response.status,
      });
    }
  };
export const deleteMarketplaceListing =
  (listingId, sellerListingId, updatedAt) => async (dispatch) => {
    try {
      dispatch({ type: MARKETPLACE_PRODUCT_DELETE_REQUEST });

      const { data } = await axios.delete(
        `/api/marketplace/sell/${listingId}`,
        { data: { _id: sellerListingId, updatedAt: updatedAt } }
      );

      dispatch({ type: MARKETPLACE_PRODUCT_DELETE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      if (message === "Not Authorized") {
        dispatch(logout());
      }
      dispatch({
        type: MARKETPLACE_PRODUCT_DELETE_FAIL,
        payload: message,
      });
    }
  };
export const filterSellerListings =
  (listingId, sizes, price, sort, page) => async (dispatch) => {
    try {
      dispatch({ type: SELLER_LISTINGS_FILTER_REQUEST });
      const { data } = await axios({
        method: "post",
        url: `/api/marketplace/filter/sellerlistings/${listingId}`,
        data: {
          sizes: sizes,
          price: price,
          sort: sort,
          page: page,
        },
      });
      dispatch({ type: SELLER_LISTINGS_FILTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SELLER_LISTINGS_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEditorsPicks = () => async (dispatch) => {
  try {
    dispatch({ type: EDITORS_PICKS_REQUEST });
    const { data } = await axios.get("/api/marketplace/editors-picks");
    dispatch({ type: EDITORS_PICKS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDITORS_PICKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const filterMoreSellerListings =
  (listingId, sizes, price, sort, page) => async (dispatch) => {
    try {
      dispatch({ type: SELLER_LISTINGS_FILTER_MORE_REQUEST });
      const { data } = await axios({
        method: "post",
        url: `/api/marketplace/filter/sellerlistings/${listingId}`,
        data: {
          sizes: sizes,
          price: price,
          sort: sort,
          page: page,
        },
      });
      dispatch({ type: SELLER_LISTINGS_FILTER_MORE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SELLER_LISTINGS_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSellerListingsSizes = (listingId) => async (dispatch) => {
  try {
    dispatch({ type: MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_REQUEST });
    const { data } = await axios({
      method: "get",
      url: `/api/marketplace/filter/sellerlistings/sizes/${listingId}`,
    });
    dispatch({
      type: MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKETPLACE_SELLERLISTINGS_SHOE_SIZES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRootMarketplaceListings = () => async (dispatch) => {
  try {
    dispatch({
      type: MARKETPLACE_ROOT_LISTINGS_REQUEST,
    });
    const { data } = await axios.get("/api/marketplace/admin/root");
    dispatch({
      type: MARKETPLACE_ROOT_LISTINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: MARKETPLACE_ROOT_LISTINGS_FAIL,
      payload: message,
    });
  }
};

export const getRootMarketplaceListing = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_GET_REQUEST,
    });
    const { data } = await axios.get(`/api/marketplace/admin/root/${id}`);
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_GET_FAIL,
      payload: message,
    });
  }
};

export const upsertRootMarketplaceListing = (update) => async (dispatch) => {
  try {
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_UPSERT_REQUEST,
    });
    const { data } = await axios.post("/api/marketplace/admin/root", update);
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_UPSERT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not Authorized") {
      dispatch(logout());
    }
    dispatch({
      type: MARKETPLACE_ROOT_LISTING_UPSERT_FAIL,
      payload: message,
    });
  }
};
