import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "bootstrap";

const CheapestSellerListingsOffcanvas = () => {
  const navigate = useNavigate();
  const offcanvasRef = useRef();

  const cheapestSellerListings = useSelector(
    (state) => state.cheapestSellerListings
  );
  const { listingsLoading, listings, error } = cheapestSellerListings;
  useEffect(() => {
    var myOffcanvas = offcanvasRef.current;
    var bsOffcanvas = new Offcanvas(myOffcanvas);
  }, [listings]);

  const closeOffcanvasModal = () => {
    let closeModal = document.getElementById(
      "cheapestSellerListingsOffcanvasCloseButton"
    );
    closeModal.click();
  };
  const handleListingRedirect = (listing) => {
    navigate(`/sellerlisting/${listing.listingId}`);
    closeOffcanvasModal();
  };
  return (
    <div
      class="offcanvas offcanvas-bottom h-50"
      tabIndex="-1"
      id="cheapestSellerListingsOffcanvas"
      aria-labelledby="cheapestSellerListingsOffcanvasLabel"
      ref={offcanvasRef}
      // style={{ height: 350 }}
    >
      <div class="offcanvas-header pb-0">
        <h6 class="offcanvas-title" id="cheapestSellerListingsOffcanvasLabel">
          Available listings
        </h6>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="cheapestSellerListingsOffcanvasCloseButton"
        ></button>
      </div>
      <div class="offcanvas-body small pt-0">
        <p className="mb-1">
          <small>
            These can be purchased directly or you can make an{" "}
            <strong>offer</strong>.
          </small>
        </p>
        {listings.length > 0 && (
          <div className="row row-cols-3">
            {listings.map((listing) => (
              <div
                class="col p-1"
                key={"cheapestSellerListingPrice" + listing.listingId}
              >
                <div
                  className=" border"
                  onClick={() => handleListingRedirect(listing)}
                >
                  <div class="text-center pt-1">
                    US {listing.sizeType[0] === "Mens" ? "M" : "W"}{" "}
                    {listing.size}
                  </div>
                  <div class="text-center text-success pb-1">
                    ${listing.price}
                  </div>
                  {/* <Link
                    to={`/sellerlisting/${listing.listingId}`}
                    className="stretched-link"
                  ></Link> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheapestSellerListingsOffcanvas;
