import React, { useEffect, useState, useRef } from "react";
import { Modal } from "bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const MobileSearchModal = () => {
  const modalRef = useRef();
  const navigate = useNavigate();

  const [listings, setListings] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    const recentlySearched = getRecentlySearched("recentlySearched");
    setRecentSearches(recentlySearched);
  }, []);
  const searchDatabase = (query) => {
    let cancel;
    if (query !== "") {
      // setQuery(query);
      // searchParams.set("query", query);
      // setSearchParams(searchParams);
      axios({
        method: "get",
        url: `/api/marketplace/search?query=${query}`,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((response) => setListings(response.data))
        .catch((error) => {
          if (error.isCancel(error)) {
            return;
          }
        });
    } else {
      setListings([]);
    }
  };
  const addToLocalStorage = (key, value, limit = 7) => {
    if (localStorage) {
      const existingItems = localStorage.getItem(key);
      let items = existingItems ? JSON.parse(existingItems) : [];

      // Check if the item with the same search already exists
      const existingItem = items.find((item) => item.search === value.search);

      if (!existingItem) {
        // Add the new item to the beginning of the list
        items.unshift(value);

        // Limit the list to the specified maximum number of items
        if (items.length > limit) {
          items = items.slice(0, limit);
        }

        localStorage.setItem(key, JSON.stringify(items));
      }
    }
  };

  const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  const getRecentlySearched = (key) => {
    if (localStorage) {
      const items = localStorage.getItem(key);
      if (items) {
        return JSON.parse(items);
      }
    }
    return [];
  };
  const updateRecentlySearched = () => {
    const recentlySearched = getRecentlySearched("recentlySearched");
    setRecentSearches(recentlySearched);
  };
  const closeMobileSearchModal = () => {
    let mobileSearchModal = document.getElementById(
      "mobileSearchModalCloseButton"
    );
    mobileSearchModal.click();
  };

  return (
    <div class="modal fade" id="mobileSearchModal" ref={modalRef}>
      <div class="modal-dialog modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="">
            {/* <h1 class="modal-title fs-5" id="mobileSearchModalLabel">
                Modal title
              </h1> */}
            <button
              type="button"
              class="btn-close float-end p-3"
              data-bs-dismiss="modal"
              id="mobileSearchModalCloseButton"
            ></button>
          </div>
          <div class="modal-body p-1">
            <form className="position-relative">
              <div className="input-group">
                <input
                  id="mobileSearchBar"
                  autoComplete="off"
                  className="form-control flex-grow-1"
                  type="search"
                  placeholder="I'm looking for ..."
                  onChange={(event) => {
                    searchDatabase(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && event.target.value !== "") {
                      event.preventDefault();
                      setListings([]);
                      navigate(`/marketplace?query=${event.target.value}`);
                      addToLocalStorage("recentlySearched", {
                        search: event.target.value,
                        route: null,
                      });
                      updateRecentlySearched();
                      event.target.value = "";
                      closeMobileSearchModal();
                    }
                  }}
                ></input>
                {/* <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span> */}
                <ul className="list-group position-absolute top-100 mt-1 w-100 overflow-auto z-3">
                  {listings &&
                    listings.length > 0 &&
                    listings.map((item, index) => (
                      <Link
                        to={`/listing/${item["_id"]}`}
                        className="text-decoration-none"
                        key={"MobileSearchListings" + index}
                        onClick={() => {
                          setListings([]);
                          addToLocalStorage("recentlySearched", {
                            search: item.listingName,
                            route: `/listing/${item["_id"]}`,
                          });
                          updateRecentlySearched();
                          document.getElementById("mobileSearchBar").value = "";
                          closeMobileSearchModal();
                        }}
                      >
                        <li className="list-group-item">
                          <div className="row">
                            <div className=" col col-8 col-md-auto me-md-auto">
                              <div className="d-flex flex-column">
                                <div className="fw-light">{item.brand}</div>
                                <div className="fs-5 text-truncate">
                                  {item.listingName}
                                </div>
                                <div className="fs-6 text-truncate">
                                  {item.colorway}
                                </div>
                              </div>
                            </div>
                            <div className=" col col-4 col-md-auto">
                              <img
                                src={item["photo"]}
                                alt="Shoe"
                                className="img-fluid"
                                style={{ maxWidth: 100 }}
                              ></img>
                            </div>
                          </div>
                        </li>
                      </Link>
                    ))}
                </ul>
              </div>
            </form>
            {listings && listings.length === 0 && (
              <>
                {recentSearches && recentSearches.length > 0 && (
                  <div className="ms-1 mt-3">
                    <div class="d-flex justify-content-between">
                      <p class="mb-1">
                        <small>Recent searches</small>
                      </p>
                      <p class="mb-1 me-2">
                        <small
                          className="text-primary"
                          onClick={() => {
                            removeFromLocalStorage("recentlySearched");
                            updateRecentlySearched();
                          }}
                        >
                          Clear search history
                        </small>
                      </p>
                    </div>
                    <div class="d-flex flex-wrap">
                      {recentSearches.map((search, index) => (
                        <Link
                          to={
                            search["route"]
                              ? search["route"]
                              : `/marketplace?query=${search.search}`
                          }
                          onClick={() => closeMobileSearchModal()}
                          key={"MobileSearchRecents" + index}
                        >
                          <span
                            class="badge text-bg-light mx-2 my-1"
                            key={search.search + index}
                          >
                            {search.search}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                <div className="ms-1 mt-3">
                  <p class="mb-1">
                    <small>Popular searches</small>
                  </p>

                  <div class="d-flex flex-wrap">
                    <Link
                      to="/marketplace"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">
                        Shop all items
                      </span>
                    </Link>
                    <Link
                      to="/marketplace?brand=Jordan"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">Jordans</span>
                    </Link>
                    <Link
                      to="/marketplace?price=0-200"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">
                        Shoes under $200
                      </span>
                    </Link>
                    <Link
                      to="/marketplace?sizeType=Mens"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">
                        Mens shoes
                      </span>
                    </Link>
                    <Link
                      to="/marketplace?sizeType=Womens"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">
                        Womens shoes
                      </span>
                    </Link>
                    <Link
                      to="/marketplace?brand=Nike"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">Nike</span>
                    </Link>
                    <Link
                      to="/marketplace?brand=New+Balance"
                      onClick={() => closeMobileSearchModal()}
                    >
                      <span class="badge text-bg-light mx-2 my-1">
                        New Balance
                      </span>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchModal;
