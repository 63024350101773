import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInformation } from "../actions/userActions";
import {
  getSellerOrders,
  getSellerOrdersCompleted,
} from "../actions/orderActions";
import ProfileNavbar from "../components/Profile_Components/ProfileNavbar";
import ProfileSellingCompleted from "../components/Profile_Components/ProfileSellingCompleted";
import BreadcrumbLocation from "../components/BreadcrumbLocation";

const ProfileSellingCompletedScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, name } = userLogin;
  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getUserInformation());
      dispatch(getSellerOrders());
      dispatch(getSellerOrdersCompleted());
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Selling"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar name={name}></ProfileNavbar>
        <ProfileSellingCompleted></ProfileSellingCompleted>
      </div>
    </>
  );
};

export default ProfileSellingCompletedScreen;
