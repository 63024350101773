import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInformation, getUserLikedItems } from "../actions/userActions";
import ProfileNavbar from "../components/Profile_Components/ProfileNavbar";
import ProfileFavorites from "../components/Profile_Components/ProfileFavorites";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
const ProfileFavoritesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, isAuthenticated, error, token, name } = userLogin;
  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getUserInformation());
      dispatch(getUserLikedItems());
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated, dispatch]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Favorites"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar name={name}></ProfileNavbar>

        <ProfileFavorites></ProfileFavorites>
      </div>
    </>
  );
};

export default ProfileFavoritesScreen;
