import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getOffersForPurchase,
  getOffersForSale,
} from "../../actions/offerActions";

const ProfileOffers = () => {
  const dispatch = useDispatch();
  const [showForPurchase, setShowForPurchase] = useState(false);
  const [offerFilter, setOfferFilter] = useState("All");
  const forPurchaseOffers = useSelector((state) => state.forPurchaseOffers);
  const {
    forPurchaseOffers: offersForPurchase,
    loading: offersForPurchaseLoading,
  } = forPurchaseOffers;
  const forSaleOffers = useSelector((state) => state.forSaleOffers);
  const {
    forSaleOffers: offersForSale,
    loading: offersForSaleLoading,
    potentialOffers,
  } = forSaleOffers;
  useEffect(() => {
    if (offerFilter == "All") {
      dispatch(getOffersForPurchase());
      dispatch(getOffersForSale());
    } else {
      dispatch(getOffersForPurchase(offerFilter));
      dispatch(getOffersForSale(offerFilter));
    }
  }, [offerFilter]);

  const millisecondsToHoursAndMinutes = (milliseconds) => {
    const totalMinutes = Math.floor(milliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}h and ${minutes}m`;
  };

  return (
    <div className="container ms-sm-5">
      <div className="col-lg-10">
        <div className="d-flex flex-row">
          <h4 className="mb-4 px-0 me-auto">Offers</h4>
          <div className="dropdown">
            <Link
              to="#"
              className="text-decoration-none text-reset dropdown-toggle align-middle mt-2"
              data-bs-toggle="dropdown"
            >
              Filter By: {offerFilter}
            </Link>
            <ul className="dropdown-menu text-small shadow">
              <li>
                <button
                  className="dropdown-item"
                  href="#"
                  onClick={() => setOfferFilter("All")}
                >
                  All
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  to="#"
                  onClick={() => setOfferFilter("Pending")}
                >
                  Pending
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  to="#"
                  onClick={() => setOfferFilter("Canceled")}
                >
                  Canceled
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  to="#"
                  onClick={() => setOfferFilter("Declined")}
                >
                  Declined
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  to="#"
                  onClick={() => setOfferFilter("Expired")}
                >
                  Expired
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12">
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link link-dark active"
                  id="offersForPurchase-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#offersForPurchase"
                  type="button"
                  role="tab"
                  onClick={() => setShowForPurchase(false)}
                >
                  Sent
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link link-dark"
                  id="offersForSale-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#offersForSale"
                  type="button"
                  role="tab"
                  onClick={() => setShowForPurchase(true)}
                >
                  Recieved
                  <span class="badge bg-primary ms-1">{potentialOffers}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`tab-pane ${showForPurchase ? "d-none" : ""}`}
          id="offersForPurchase"
          role="tabpanel"
        >
          <div class="list-group mx-0">
            {offersForPurchase &&
              offersForPurchase.map((offer, index) => (
                <Link
                  to={`/profile/offer/${offer._id}`}
                  class="list-group-item list-group-item-action"
                  key={`offersForPurchase${index}`}
                >
                  <div class="hstack gap-3">
                    <div class="">
                      <img
                        src={offer.thumbnail}
                        class="img-fluid"
                        alt="..."
                        style={{ maxWidth: 140 }}
                      ></img>
                    </div>
                    <div class="text-truncate">
                      <h6 class="mb-1 text-truncate">{offer.title}</h6>

                      <p class="m-0">
                        <small>
                          Sent:{" "}
                          <span class="badge text-bg-light">
                            {offer.dateString}
                          </span>
                        </small>
                      </p>
                      {offer.offerAvailable ? (
                        <>
                          <p class="m-0">
                            <small>
                              Expires:{" "}
                              <span class="badge text-bg-light">
                                {millisecondsToHoursAndMinutes(
                                  offer.remainingTime
                                )}
                              </span>
                            </small>
                          </p>
                          <p class="m-0">
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light">
                                {offer.offerStatus}
                              </span>
                            </small>
                          </p>
                        </>
                      ) : (
                        <p class="m-0">
                          {offer.offerStatus == "Accepted" ? (
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light text-success">
                                {offer.offerStatus}
                              </span>
                            </small>
                          ) : (
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light">
                                {offer.offerStatus}
                              </span>
                            </small>
                          )}
                        </p>
                      )}

                      <p class="m-0">
                        <small>
                          Offer price:{" "}
                          <span class="badge text-bg-light text-capitalize ">
                            ${offer.price}
                          </span>
                        </small>
                      </p>
                      <p>
                        <small>
                          Size:{" "}
                          <span class="badge text-bg-light text-capitalize ">
                            {offer.size}
                          </span>
                        </small>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          {offersForPurchaseLoading && (
            <>
              <div className="col mt-0 p-2">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 p-2">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 p-2">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mt-0 p-2">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {offersForPurchase && offersForPurchase.length === 0 && (
            <section class="text-center container">
              <div class="row py-lg-5">
                <p class=" text-muted">
                  You currently have sent out no offers to purchase an item.
                </p>
                <p>
                  <Link to="/marketplace" class="btn btn-primary my-2">
                    View marketplace!
                  </Link>
                </p>
              </div>
            </section>
          )}
        </div>
        <div
          className={`tab-pane ${showForPurchase ? "" : "d-none"}`}
          id="offersForSale"
          role="tabpanel"
        >
          <div class="list-group mx-0">
            {offersForSale &&
              offersForSale.map((offer, index) => (
                <Link
                  to={`/profile/offer/${offer._id}`}
                  class="list-group-item list-group-item-action"
                  key={`offersForPurchase${index}`}
                >
                  <div class="hstack gap-3">
                    <div class="">
                      <img
                        src={offer.thumbnail}
                        class="img-fluid"
                        alt="..."
                        style={{ maxWidth: 140 }}
                      ></img>
                    </div>
                    <div class="text-truncate">
                      <h6 class="mb-1 text-truncate">{offer.title}</h6>

                      <p class="m-0">
                        <small>
                          Recieved:{" "}
                          <span class="badge text-bg-light">
                            {offer.dateString}
                          </span>
                        </small>
                      </p>

                      {/* <p class="m-0">
                        <small>
                          Expires:{" "}
                          <span class="badge text-bg-light">
                            {millisecondsToHoursAndMinutes(offer.remainingTime)}
                          </span>
                        </small>
                      </p> */}
                      {offer.offerAvailable ? (
                        <>
                          <p class="m-0">
                            <small>
                              Expires:{" "}
                              <span class="badge text-bg-light">
                                {millisecondsToHoursAndMinutes(
                                  offer.remainingTime
                                )}
                              </span>
                            </small>
                          </p>
                          <p class="m-0">
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light">
                                {offer.offerStatus}
                              </span>
                            </small>
                          </p>
                        </>
                      ) : (
                        <p class="m-0">
                          {offer.offerStatus == "Accepted" ? (
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light text-success">
                                {offer.offerStatus}
                              </span>
                            </small>
                          ) : (
                            <small>
                              Status:{" "}
                              <span class="badge text-bg-light">
                                {offer.offerStatus}
                              </span>
                            </small>
                          )}
                        </p>
                      )}

                      <p class="m-0">
                        <small>
                          Offer price:{" "}
                          <span class="badge text-bg-light text-capitalize ">
                            ${offer.price}
                          </span>
                        </small>
                      </p>
                      <p>
                        <small>
                          Size:{" "}
                          <span class="badge text-bg-light text-capitalize ">
                            {offer.size}
                          </span>
                        </small>
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            {offersForSaleLoading && (
              <>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mt-0 p-2">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {offersForSale && offersForSale.length === 0 && (
              <section class="text-center container">
                <div class="row py-lg-5">
                  <p class=" text-muted">
                    You currently have no offers on any of your listings.
                  </p>
                  <p>
                    <Link to="/marketplace" class="btn btn-primary my-2">
                      View marketplace!
                    </Link>
                  </p>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
    // <div className="container">
    // </div>
  );
};

export default ProfileOffers;
