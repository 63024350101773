import React, { forwardRef } from "react";

export const Photo = forwardRef(
  ({ url, index, faded, style, numPhotos, setPhotos, ...props }, ref) => {
    const inlineStyles = {
      opacity: faded ? "0.2" : "1",
      //   transformOrigin: "0 0",
      maxWidth: 150,
      maxHeight: 150,
      //   gridRowStart: index === 0 ? "span 2" : null,
      //   gridColumnStart: index === 0 ? "span 2" : null,
      //   backgroundImage: `url("${url}")`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      // backgroundColor: "grey",
      ...style,
    };

    return (
      <img
        ref={ref}
        style={inlineStyles}
        {...props}
        src={url}
        classname={`img-fluid`}
      ></img>
      //   <div ref={ref} style={inlineStyles} {...props} className="m-1">
      //   </div>
    );
  }
);
