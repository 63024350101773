import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const ProfileNavbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  return (
    <ul class="nav flex-column nav-underline d-none d-sm-block text-nowrap">
      {user && user.name && <h4 className="mb-2">{user.name}</h4>}
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/information"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/information"
        >
          Profile Information
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/settings"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/settings"
        >
          Profile Settings
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/selling"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/selling"
        >
          Current listings
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/selling/completed"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/selling/completed"
        >
          Selling
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/buying"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/buying"
        >
          My orders
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/favorites"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/favorites"
        >
          Favorites
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/profile/offers"
              ? "active"
              : "link-secondary"
          }`}
          to="/profile/offers"
        >
          Offers
        </Link>
      </li>
      {user && user.isAdmin && (
        <>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/admin/orders"
                  ? "active"
                  : "link-secondary"
              }`}
              to="/admin/orders"
            >
              Admin Orders
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/admin/root-marketplace-listings"
                  ? "active"
                  : "link-secondary"
              }`}
              to="/admin/root-marketplace-listings"
            >
              Admin Root Marketplace Listings
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default ProfileNavbar;
