import React from "react";

const PrivacyPolicyScreen = () => {
  return (
    <div className="container mt-3">
      <h1>Privacy Policy</h1>
      <p>Last updated: March 1, 2024</p>
      <p>
        To enhance your privacy, we want to inform you about our online
        information practices and the options available to you regarding the
        collection and utilization of your information when accessing ReShup's
        website or mobile app (referred to as the "Website"). At ReShup, we are
        dedicated to safeguarding your privacy and advancing technology to
        provide you with a secure and empowering online experience.
      </p>
      <h2>Information Collection</h2>
      <p>
        This privacy notice addresses personally identifiable information,
        anonymous data collection, and aggregate reporting. Personally
        identifiable information refers to any data linked to your name or
        personal identity.
      </p>
      <h3>What information do we collect?</h3>
      <p>
        During the registration process, you may provide us with personal
        information such as your name, address, phone number, email address,
        username, and password. If you're not a registered user and you make a
        purchase on ReShup, you'll need to provide shipping and email addresses,
        which will be shared with the Seller. The Seller's shipping details will
        be included in the receipt provided to the Buyer.
      </p>
      <p>
        When you browse our website, you do so anonymously. We log your IP
        address (the Internet address of your computer) to understand which
        parts of our website you visit and for how long. However, we don't link
        your IP address to any personal information unless you're logged in to
        our website. Like many other commercial websites, the Website may
        utilize a standard technology called a "cookie" to gather information
        about your website usage. Please refer to the "Use of Cookies" section
        below for more details.
      </p>
      <h2>Usage of Information</h2>
      <p>We utilize your personal information for the following purposes:</p>
      <ul>
        <li>
          Simplifying the website usage by minimizing the need for repeated
          personal information entry.
        </li>
        <li>Enabling Sellers to ship items to Buyers.</li>
        <li>
          Assisting in creating and publishing content most relevant to you.
        </li>
        <li>
          Notifying you about product upgrades, special offers, updated
          information, and other new services provided by ReShup.
        </li>
        <li>Collecting feedback through online surveys.</li>
        <li>Handling support requests or assistance inquiries.</li>
      </ul>
      <h2>Information Sharing</h2>
      <p>
        We do not sell or rent your personal information to third parties.
        However, we may disclose your personal information in response to legal
        processes, to protect the rights and property of ReShup, or to address
        fraudulent activity or deceptive practices.
      </p>
      <p>
        When you register, we won't share your information with third parties
        without your consent, except for the limited exceptions mentioned. Your
        personal information may be shared with our agents or contractors to
        perform services for ReShup.
      </p>
      <h2>Stripe</h2>
      <p>
        All transactions on ReShup are processed by Stripe, and your usage of
        Stripe's services is governed by the Stripe Terms of Service and Privacy
        Policy.
      </p>
      <h2>Security</h2>
      <p>
        We employ strict measures to protect the security of your personal
        information, preventing loss, misuse, unauthorized access, disclosure,
        alteration, or destruction.
      </p>
      <p>
        Your personal information is kept confidential within the company and is
        stored in password-controlled servers with limited access.
      </p>
      <p>
        You play a vital role in safeguarding your information by keeping your
        username and password confidential, as no one can view or modify your
        personal information without these credentials.
      </p>
      <p>
        We utilize SSL encryption when collecting or transferring sensitive
        data.
      </p>
      <h2>Access to Personal Information</h2>
      <p>
        You have the ability to ensure that your personal information is
        accurate and up-to-date by reviewing and updating it anytime through
        your account settings.
      </p>
      <p>
        To ensure your privacy and security, we verify your identity, such as
        password and username, before granting access to your data.
      </p>
      <h2>Use of Cookies</h2>
      <p>
        Our websites are not configured to track, collect, or distribute
        personal information. We generate non-identifying site usage data, such
        as the number of hits and visits, for internal purposes only. This data
        doesn't contain personal information and cannot be used to gather such
        information.
      </p>
      <p>
        The Website utilizes "cookies" to personalize your online experience. A
        cookie is a text file placed on your hard disk by a Web page server. You
        have the option to accept or decline cookies, although declining them
        may limit your experience of the interactive features of the Website or
        our services.
      </p>
      <h2>Concerns about Our Privacy Policy</h2>
      <p>
        We welcome your feedback. If you have questions, comments, or complaints
        regarding compliance with this privacy policy, please don't hesitate to
        contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicyScreen;
