import React from "react";
import { Link } from "react-router-dom";
const FAQScreen = () => {
  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="border-bottom">
            <h2 className="text-center mb-4">Seller FAQ</h2>
          </div>
          <div className="border-bottom my-3">
            <h6>How do I become a seller on ReShup?</h6>
            <p>
              To become a seller on ReShup, you need to register for an account
              on our platform. Next link your Stipe account, which is required
              for processing payments. Once your account is set up, you can
              start listing your items for sale on our marketplace.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>Do I need a Stripe account to sell on ReShup?</h6>
            <p>
              Yes, all sellers on ReShup are required to have a Stripe account.
              This is necessary for processing payments securely and
              efficiently. You can easily set up a Stripe account by going to
              your profile settings.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>What is the process for listing items for sale on ReShup?</h6>
            <p>
              To list an item for sale on ReShup, you need to create a detailed
              listing with accurate descriptions and clear photos of your item.
              Once your listing is complete, it will be made available on our
              marketplace for potential buyers to see and purchase.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>Are there any fees associated with selling on ReShup?</h6>
            <p>
              ReShup charges a transaction fee based on seller standing. You can
              see your fee amount when creating a listing.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>How and when do I get paid for my sales on ReShup?</h6>
            <p>
              ReShup will pay you within a reasonable amount of time following
              the completion of the sale, which occurs when the buyer receives
              the item or we verify your item. Payment will be processed through
              your Stripe account, and you can expect to receive your funds
              according to Stripe's payout schedule.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>What happens if a buyer purchases my item?</h6>
            <p>
              Once a buyer purchases your item, you are contractually obligated
              to deliver the exact item for the specified price. You must ship
              the item within the specified timeframe provided.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>What are my responsibilities as a seller on ReShup?</h6>
            <p>
              As a seller on ReShup, you are responsible for ensuring that all
              your listings are accurate and comply with our policies. You must
              also ship items promptly and by the specified deadline.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>How do I print shipping labels for my orders?</h6>
            <p>
              Once a sale is completed, you'll receive an email with a shipping
              deadline. You can view the shipping label for your order on the
              site under the "Selling" section. This is where you can generate
              and print the shipping label to ship your product to us.
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>Do I need to include an invoice with my shipment?</h6>
            <p>
              Yes, when shipping your product to us, you need to print out the
              invoice of the order and include it in your packaging. This helps
              us process your shipment more efficiently and ensures a smooth
              transaction. You will find this invoice where the shipping label
              is.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>What are the shipping options available for sellers?</h6>
            <p>
              Sellers on ReShup have several shipping options:
              <ul>
                <li>
                  <strong>Standard Shipping:</strong> Best for items across the
                  US. We'll email you a label, and when your item sells, you'll
                  ship it to us for verification.
                </li>
                <li>
                  <strong>Local Selling:</strong> Skip the shipping and fees and
                  meet up locally to sell your kicks! This option will be
                  available in the future.
                </li>
                <li>
                  <strong>Local Selling and Shipping:</strong> Reach the widest
                  range of buyers. Buyers will have the choice of purchasing
                  through shipping and also will be able to locally meet. This
                  option will be available in the future.
                </li>
                <li>
                  <strong>ReShup Verified Partner:</strong> Skip the ReShup
                  verification step shipping and ship directly to your customer!
                  Only the top and most trusted sellers will have this option.
                  This option will be available in the future.
                </li>
              </ul>
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>
              What are the different seller levels and associated fees on
              ReShup?
            </h6>
            <p>
              ReShup offers sellers different levels based on their performance
              and trustworthiness:
              <ul>
                <li>
                  <strong>Seller Level 1:</strong> At this level, sellers incur
                  a 8.5% fee for each sale.
                </li>
                <li>
                  <strong>Seller Level 2:</strong> Sellers who achieve Level 2
                  status enjoy reduced fees, typically around 8% per sale.
                </li>
                <li>
                  <strong>Seller Level 3:</strong> At the highest level, sellers
                  also pay a 8% fee per sale, but they have the added benefit of
                  being able to skip the shipping step to us, allowing them to
                  ship directly to their customer.
                </li>
              </ul>
            </p>
          </div>

          <div className="border-bottom my-3">
            <h6>How many products can I upload?</h6>
            <p>There is no limit to how many products you can upload.</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="border-bottom">
            <h2 className="text-center mb-4">Buyer FAQ</h2>
          </div>
          <div className="border-bottom my-3">
            <h6>How do I make a purchase on the ReShup?</h6>
            <p>
              To make a purchase, simply navigate to the product you're
              interested in, click the "Buy Now" or "Make Offer" button, and
              follow the checkout process.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>What payment methods are accepted?</h6>
            <p>
              We currently accept payments through Stripe. You can use major
              credit/debit cards for a secure and seamless transaction.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>How can I track my order?</h6>
            <p>
              Once your order is processed, you will receive an email
              confirmation. You can view the status of your order by going to
              "My orders". Once we verify your item we will provide you with a
              tracking number.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>What should I do if I encounter an issue with my order?</h6>
            <p>
              If you experience any issues with your order, please contact our
              customer support team. Use the "Contact" form. We are here to
              assist you and resolve any concerns.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>Can I cancel or modify my order after placing it?</h6>
            <p>
              Once an order is placed, it enters the processing stage quickly.
              Please contact our support team immediately if you need to make
              changes, and we'll do our best to assist you.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>Are there any additional fees beyond the product price?</h6>
            <p>
              The total cost of your purchase includes the product price and any
              applicable taxes or shipping fees. You will see the complete
              breakdown during the checkout process. These can change but you
              will always see fees we are charging you.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>
              Can I trust the authenticity of the products on this platform?
            </h6>
            <p>
              We take great care to ensure the authenticity of the products
              listed. Sellers are vetted, and we have measures in place to
              prevent the listing of counterfeit items and items are shipped to
              us for verification. If you have authenticity concerns, please
              contact our support team.
            </p>
          </div>
          <div className="border-bottom my-3">
            <h6>How can I delete my account?</h6>
            <p>
              Account deletion can be requested by contacting our support team
              directly. To initiate the account deletion process, please reach
              out to us.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-7 text-center">
          <h1 className="">Got more questions? Just Ask!?</h1>
          <p>
            Contact us <Link to="/contact">here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQScreen;
