import axios from "axios";

import {
  CREATE_OFFER_FAIL,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  FOR_PURCHASE_OFFERS_REQUEST,
  FOR_PURCHASE_OFFERS_SUCCESS,
  FOR_PURCHASE_OFFERS_FAIL,
  FOR_SALE_OFFERS_REQUEST,
  FOR_SALE_OFFERS_SUCCESS,
  FOR_SALE_OFFERS_FAIL,
  GET_OFFER_REQUEST,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAIL,
  CANCEL_OFFER_REQUEST,
  CANCEL_OFFER_SUCCESS,
  CANCEL_OFFER_FAIL,
  ACCEPT_OFFER_REQUEST,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_FAIL,
  POTENTIAL_OFFERS_REQUEST,
  POTENTIAL_OFFERS_SUCCESS,
  POTENTIAL_OFFERS_FAIL,
} from "../constants/offerConstants";

export const createOffer =
  (paymentIntentId, offerCreatedAt) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_OFFER_REQUEST,
      });
      const { data } = await axios.post("/api/offers/create-offer/", {
        paymentIntentId,
        offerCreatedAt,
      });

      dispatch({
        type: CREATE_OFFER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: CREATE_OFFER_FAIL,
        payload: message,
      });
    }
  };

export const getOffer = (offerId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFER_REQUEST,
    });
    const { data } = await axios.get(`/api/offers/get-offer/${offerId}`);

    dispatch({
      type: GET_OFFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_OFFER_FAIL,
      payload: message,
    });
  }
};

export const cancelOffer = (offerId, updatedAt) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_OFFER_REQUEST,
    });

    const { data } = await axios.post(`/api/offers/cancel-offer/${offerId}`, {
      updatedAt: updatedAt,
    });

    dispatch({
      type: CANCEL_OFFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: CANCEL_OFFER_FAIL,
      payload: message,
    });
  }
};

export const confirmOffer = (offerId, updatedAt) => async (dispatch) => {
  try {
    dispatch({
      type: ACCEPT_OFFER_REQUEST,
    });
    const { data } = await axios.post(`/api/offers/confirm-offer/${offerId}`, {
      updatedAt,
    });

    dispatch({
      type: ACCEPT_OFFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: ACCEPT_OFFER_FAIL,
      payload: message,
    });
  }
};

export const getOffersForPurchase = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: FOR_PURCHASE_OFFERS_REQUEST,
    });
    const { data } = await axios.post("/api/offers/get-offers-for-purchase/", {
      filter: filter,
    });

    dispatch({
      type: FOR_PURCHASE_OFFERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: FOR_PURCHASE_OFFERS_FAIL,
      payload: message,
    });
  }
};

export const getOffersForSale = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: FOR_SALE_OFFERS_REQUEST,
    });
    const { data } = await axios.post("/api/offers/get-offers-for-sale/", {
      filter: filter,
    });

    dispatch({
      type: FOR_SALE_OFFERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: FOR_SALE_OFFERS_FAIL,
      payload: message,
    });
  }
};

export const getPotentialOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: POTENTIAL_OFFERS_REQUEST,
    });

    // This will always be pending to see what offers you can accept.
    const { data } = await axios.post("/api/offers/get-offers-for-sale/", {
      filter: "Pending",
    });

    dispatch({
      type: POTENTIAL_OFFERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: POTENTIAL_OFFERS_FAIL,
      payload: message,
    });
  }
};
