import React, { useEffect, useState, useRef } from "react";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

const OfferModal = () => {
  const navigate = useNavigate();
  const modalRef = useRef();

  const [offerPrice, setOfferPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountArray, setDiscountArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const discountKey = {
    0: "10%",
    1: "7%",
    2: "3%",
  };

  const sellerListing = useSelector((state) => state.sellerListing);
  const {
    sellerListing: sellerListingInformation,
    loading: sellerListingLoading,
  } = sellerListing;

  const marketplaceListing = useSelector((state) => state.marketplaceListing);
  const { listingInformation: marketplaceListingInformation } =
    marketplaceListing;

  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    if (sellerListingInformation && sellerListingInformation.price) {
      const price = Number(sellerListingInformation.price);
      setOriginalPrice(price);
      setOfferPrice(Math.ceil(price * 0.93));
      const tempDiscountArray = [
        Math.ceil(price * 0.9),
        Math.ceil(price * 0.93),
        Math.ceil(price * 0.97),
      ];
      setDiscountArray(tempDiscountArray);
    }
  }, [sellerListingInformation]);

  const handleOfferRedirect = () => {
    if (!offerPrice) {
      return;
    }

    const targetNumber = originalPrice;
    const tolerance = targetNumber * 0.25;
    const inputValue = parseFloat(offerPrice);

    if (isNaN(inputValue)) {
      setErrorMessage("Please enter a valid number.");
      return;
    } else if (!/^\d+(\.\d{1,2})?$/.test(offerPrice)) {
      setErrorMessage(
        "Please enter a valid number with up to two decimal places."
      );
      return;
    } else if (
      inputValue < targetNumber - tolerance ||
      inputValue > targetNumber + tolerance
    ) {
      setErrorMessage(
        `Your offer needs to be between $${
          targetNumber - tolerance
        } and ${targetNumber}`
      );
      return;
    } else {
      navigate(`/offer/${sellerListingInformation._id}?offer=${offerPrice}`);
      closeOfferModal();
    }
  };

  const closeOfferModal = () => {
    let shippingModal = document.getElementById("offerModalCloseButton");
    shippingModal.click();
  };

  return (
    <div className="modal fade" id="offerModal" ref={modalRef}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center" id="offerModalLabel">
              Make an offer
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="offerModalCloseButton"
            ></button>
          </div>
          {sellerListingLoading && <LoadingSpinner></LoadingSpinner>}
          {sellerListingInformation && (
            <div className="modal-body">
              <div className="row mt-3">
                <div className="col-4">
                  {sellerListingInformation.photos[0] && (
                    <img
                      src={sellerListingInformation.photos[0]}
                      className="img-fluid img-thumbnail"
                      alt="..."
                    ></img>
                  )}
                </div>
                <div className="col-8">
                  <h6 className="mb-1">
                    {sellerListingInformation.title &&
                      sellerListingInformation.title}
                  </h6>
                  <h6 className="mb-0">
                    {"$"}
                    {sellerListingInformation.price &&
                      sellerListingInformation.price}
                  </h6>
                  <p className="text-primary mb-0">
                    <small>
                      {marketplaceListingInformation &&
                        marketplaceListingInformation.make &&
                        marketplaceListingInformation.make}
                    </small>
                  </p>
                  <p className="text-secondary">
                    US Size:&nbsp;
                    {sellerListingInformation.size &&
                      sellerListingInformation.size}
                  </p>
                  <div className="d-flex flex-row">
                    <span className="badge text-bg-light p-1">
                      ReShup Certified
                    </span>
                    <span className="badge text-bg-light p-1 ms-2">
                      Condition: <span className="text-success">New</span>
                    </span>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="container mt-3">
                <div className="border rounded mb-3">
                  <div class="hstack">
                    <div class="p-2 me-auto">
                      <strong>
                        <p className="mb-0">Your offer</p>
                      </strong>
                    </div>
                    <div class="p-2">
                      <div class="input-group me-5">
                        <span class="input-group-text">$</span>
                        <input
                          type="number"
                          class="form-control"
                          min="1"
                          max="3000"
                          step="1"
                          value={offerPrice}
                          onChange={(event) =>
                            setOfferPrice(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group list-group-horizontal list-group-radio gap-2 d-flex justify-content-between mx-0">
                  {discountArray.map((amount, index) => (
                    <div
                      class="position-relative rounded"
                      key={"offerModalArrayKey" + index}
                    >
                      <input
                        class="form-check-input position-absolute top-50 end-0 me-3 fs-5"
                        type="radio"
                        name="offerGroupRadio"
                        value={amount}
                        checked={amount == offerPrice}
                        id={"offerGroupRadioLabel" + index}
                        onChange={(e) => {
                          setOfferPrice(e.target.value);
                        }}
                      ></input>
                      <label
                        class="list-group-item py-3 pe-5"
                        htmlFor={"offerGroupRadioLabel" + index}
                      >
                        <div class="hstack gap-3">
                          <div>
                            <strong class="fw-semibold text-capitalize">
                              ${amount}
                            </strong>
                            <span class="d-block small opacity-75">
                              {discountKey[index]} off
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <hr class="border border-5 opacity-75"></hr>
              <div className="container">
                <div className="bg-light p-3 mb-3">
                  <div class="d-flex justify-content-between">
                    <p className=" mb-1">
                      <strong>Your offer:</strong>
                    </p>
                    <p className="mb-1">
                      <strong>${offerPrice}</strong>
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p className=" mb-1">
                      <small>Tax & shipping:</small>
                    </p>
                    <p className="mb-1">
                      <small>Calculated at next step</small>
                    </p>
                  </div>
                </div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <div class="d-grid">
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => handleOfferRedirect()}
                  >
                    Review your offer
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferModal;
