import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendReshupInquiry } from "../actions/userActions";
import { USER_INQUIRY_RESET } from "../constants/userConstants";
import { useNavigate, useLocation } from "react-router-dom";
import AboutNavigation from "../components/AboutNavigation";

const ContactScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [messageSent, setMessageSent] = useState(null);
  const [category, setCategory] = useState("");
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [message, setMessage] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isMessageValid, setIsMessageValid] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  const userInquiry = useSelector((state) => state.userInquiry);
  const { loading, error: inquiryError, message: inquiryMessage } = userInquiry;
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
    if (inquiryError) {
      setError(inquiryError);
    }
    if (inquiryMessage) {
      setMessageSent(true);
      setCategory("");
      setMessage("");
      setOrderId("");
      dispatch({ type: USER_INQUIRY_RESET });
    }
  }, [inquiryError, inquiryMessage]);
  const submitHandler = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const categoryValidation = validateCategory();
    const messageValidation = validateMessage();

    if (!categoryValidation || !messageValidation) {
      return;
    }

    const data = { category: category, message: message, orderId: orderId };

    dispatch(sendReshupInquiry(data));
  };
  const validateCategory = () => {
    const valid = category.trim() !== "";
    setIsCategoryValid(valid);
    return valid;
  };
  const validateMessage = () => {
    const valid = message.trim() !== "";
    setIsMessageValid(valid);
    return valid;
  };
  return (
    <div className="bg-light pb-5">
      <div className="container d-flex flex-row">
        <div className="mt-4 me-0 me-lg-4">
          <AboutNavigation></AboutNavigation>
        </div>
        <div className="col-lg-7 mt-4 ms-sm-5">
          <h4>Contact us</h4>
          <h6>Any question or concern you may have ask us!</h6>
          <form class="row g-sm-3 mt-3" noValidate>
            <div class="col-md-12">
              <div className="row">
                <label htmlFor="inputcategory" class="col-sm-2 col-form-label">
                  Category
                </label>
                <div class="col-sm-10">
                  <select
                    id="inputcategory"
                    className={`form-select ${
                      isCategoryValid ? "" : "is-invalid"
                    }`}
                    onChange={(event) => {
                      setCategory(event.target.value);
                      setIsCategoryValid(true);
                    }}
                    value={category}
                    required
                  >
                    <option disabled value="">
                      Choose...
                    </option>
                    <option>Request to add new listing</option>
                    <option>General Question</option>
                    <option>Business Request</option>
                    <option>Support</option>
                  </select>
                  <div className="invalid-feedback">Select category.</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <label
                  htmlFor="inputOrderNumber"
                  class="col-sm-2 col-form-label"
                >
                  Order Number (not required)
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="inputOrderNumber"
                    onBlur={(e) => setOrderId(e.target.value)}
                    defaultValue={orderId}
                  ></input>
                </div>
              </div>
            </div>
            {/* <div class="col-md-12">
              <div class="row">
                <label for="inputEmail" class="col-sm-2 col-form-label">
                  Email
                </label>
                <div class="col-sm-10">
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail"
                  ></input>
                </div>
              </div>
            </div> */}

            <div class="col-md-12">
              <div className="row">
                <label htmlFor="inputMessage" class="col-sm-2 col-form-label">
                  Message
                </label>
                <div class="col-sm-10">
                  <textarea
                    className={`form-control ${
                      isMessageValid ? "" : "is-invalid"
                    }`}
                    id="inputMessage"
                    rows="6"
                    onBlur={(e) => {
                      setMessage(e.target.value);
                      setIsMessageValid(true);
                    }}
                    defaultValue={message}
                    required
                  ></textarea>
                  <div className="invalid-feedback">A message is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-12 d-flex justify-content-end">
              {loading ? (
                <button class="btn btn-primary mt-3" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm me-2"
                    aria-hidden="true"
                  ></span>
                  <span role="status">Sending...</span>
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-primary mt-3"
                  onClick={(event) => submitHandler(event)}
                >
                  Send Message
                </button>
              )}
            </div>
          </form>
          {messageSent && (
            <>
              <div
                class="alert alert-success alert-dismissible fade show mt-3"
                role="alert"
              >
                <strong>Email Sent!</strong> We will get back to you as soon as
                we can. We also have this request on file.
                <button
                  type="button"
                  class="btn-close"
                  onClick={() => setMessageSent(null)}
                ></button>
              </div>
            </>
          )}
          {error && (
            <>
              <div
                class="alert alert-warning alert-dismissible fade show mt-3"
                role="alert"
              >
                <strong>Email Failed.</strong> {error}.
                <button
                  type="button"
                  class="btn-close"
                  onClick={() => setError(null)}
                ></button>
              </div>
              <div class="col-md-12">
                <p className="mt-3">
                  Having issues with this form? Email reshup2023@gmail.com.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
