import axios from "axios";
import {
  GET_ADMIN_ORDERS_FAIL,
  GET_ADMIN_ORDERS_REQUEST,
  GET_ADMIN_ORDERS_SUCCESS,
  GET_ADMIN_ORDER_INFORMATION_FAIL,
  GET_ADMIN_ORDER_INFORMATION_REQUEST,
  GET_ADMIN_ORDER_INFORMATION_SUCCESS,
  GET_BUYER_ORDERS_COMPLETED_FAIL,
  GET_BUYER_ORDERS_COMPLETED_REQUEST,
  GET_BUYER_ORDERS_COMPLETED_SUCCESS,
  GET_BUYER_ORDERS_FAIL,
  GET_BUYER_ORDERS_REQUEST,
  GET_BUYER_ORDERS_SUCCESS,
  GET_BUYER_ORDER_INFORMATION_FAIL,
  GET_BUYER_ORDER_INFORMATION_REQUEST,
  GET_BUYER_ORDER_INFORMATION_SUCCESS,
  GET_SELLER_ORDERS_COMPLETED_FAIL,
  GET_SELLER_ORDERS_COMPLETED_REQUEST,
  GET_SELLER_ORDERS_COMPLETED_SUCCESS,
  GET_SELLER_ORDERS_FAIL,
  GET_SELLER_ORDERS_REQUEST,
  GET_SELLER_ORDERS_SUCCESS,
  GET_SELLER_ORDER_INFORMATION_FAIL,
  GET_SELLER_ORDER_INFORMATION_REQUEST,
  GET_SELLER_ORDER_INFORMATION_SUCCESS,
  UPDATE_ADMIN_ORDER_INFORMATION_FAIL,
  UPDATE_ADMIN_ORDER_INFORMATION_REQUEST,
  UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS,
} from "../constants/orderConstants";

export const buyerOrdersReducer = (
  state = { orders: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_BUYER_ORDERS_REQUEST:
      return { loading: true, orders: state.orders };
    case GET_BUYER_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case GET_BUYER_ORDERS_FAIL:
      return {
        loading: false,
        orders: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const buyerOrdersCompletedReducer = (
  state = { orders: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_BUYER_ORDERS_COMPLETED_REQUEST:
      return { loading: true };
    case GET_BUYER_ORDERS_COMPLETED_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case GET_BUYER_ORDERS_COMPLETED_FAIL:
      return {
        loading: false,
        orders: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const buyerOrderInformationReducer = (
  state = { order: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_BUYER_ORDER_INFORMATION_REQUEST:
      return { loading: true };
    case GET_BUYER_ORDER_INFORMATION_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case GET_BUYER_ORDER_INFORMATION_FAIL:
      return {
        loading: false,
        order: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const sellerOrdersReducer = (
  state = { orders: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_SELLER_ORDERS_REQUEST:
      return { loading: true, orders: state.orders };
    case GET_SELLER_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case GET_SELLER_ORDERS_FAIL:
      return {
        loading: false,
        orders: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const sellerOrdersCompletedReducer = (
  state = { orders: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_SELLER_ORDERS_COMPLETED_REQUEST:
      return { loading: true };
    case GET_SELLER_ORDERS_COMPLETED_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case GET_SELLER_ORDERS_COMPLETED_FAIL:
      return {
        loading: false,
        orders: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const sellerOrderInformationReducer = (
  state = { order: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_SELLER_ORDER_INFORMATION_REQUEST:
      return { loading: true };
    case GET_SELLER_ORDER_INFORMATION_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case GET_SELLER_ORDER_INFORMATION_FAIL:
      return {
        loading: false,
        order: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminOrdersReducer = (
  state = { orders: [], loading: false },
  action
) => {
  switch (action.type) {
    case GET_ADMIN_ORDERS_REQUEST:
      return { loading: true, orders: state.orders };
    case GET_ADMIN_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case GET_ADMIN_ORDERS_FAIL:
      return {
        loading: false,
        orders: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminOrderInformationReducer = (
  state = { order: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_ADMIN_ORDER_INFORMATION_REQUEST:
    case UPDATE_ADMIN_ORDER_INFORMATION_REQUEST:
      return { loading: true, order: state.order };
    case GET_ADMIN_ORDER_INFORMATION_SUCCESS:
    case UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case GET_ADMIN_ORDER_INFORMATION_FAIL:
    case UPDATE_ADMIN_ORDER_INFORMATION_FAIL:
      return {
        loading: false,
        order: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
