import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getStripeCustomer, getStripeAccount } from "../actions/stripeActions";
import ShippingForm from "./ShippingForm";

const ShippingModal = () => {
  const dispatch = useDispatch();
  const stripeUpdatedCustomerShipping = useSelector(
    (state) => state.stripeUpdatedCustomerShipping
  );
  const { successMessage } = stripeUpdatedCustomerShipping;

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (successMessage) {
      closeShippingModal();
      // dispatch(getStripeAccount());
      dispatch(getStripeCustomer());
    }
  }, [successMessage]);

  useEffect(() => {
    axios
      .get("/api/stripe/config")
      .then((response) => {
        setStripePromise(loadStripe(response.data.publishableKey));
      })
      .catch((error) => {
      });
  }, []);

  const closeShippingModal = () => {
    let shippingModal = document.getElementById("shippingModalCloseButton");
    shippingModal.click();
  };
  return (
    <div className="modal fade" id="shippingModal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="shippingModalLabel">
              Add new address
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="shippingModalCloseButton"
            ></button>
          </div>
          <div className="modal-body">
            {stripePromise && (
              <Elements stripe={stripePromise}>
                <ShippingForm></ShippingForm>
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingModal;
