import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { useParams } from "react-router";
import {
  getAdminOrderInformation,
  updateAdminOrderInformation,
} from "../../actions/orderActions";
import { transferSellerPayout } from "../../actions/stripeActions";

const AdminOrderInformationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [description, setDescription] = useState(true);

  const { isAuthenticated, user } = useSelector((state) => state.userLogin);
  const { order } = useSelector((state) => state.adminOrderInformation);

  const params = useParams();
  const orderNumber = params.orderNumber;

  const grayedOutDiv = "d-flex align-items-start opacity-25";
  const activeDiv = "d-flex align-items-start";
  const grayedOutIcon = "badge text-bg-dark mt-1 me-2";
  const activeOutIcon = "badge text-bg-success mt-1 me-2";
  useEffect(async () => {
    if (isAuthenticated === true && user && user.isAdmin) {
      dispatch(getAdminOrderInformation(orderNumber));
    }
    if (isAuthenticated === false || (user && !user.isAdmin)) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setDescription(order ? order.shippingStatus.description : "");
  }, [order]);
  return (
    <>
      {!order ? (
        <></>
      ) : (
        <>
          <div className="container d-flex justify-content-center mt-4 ">
            <div className="col-md-5">
              {order && (
                <>
                  <div className="container text-center">
                    <img className="img-fluid" src={order.thumbnail}></img>
                    <h5 className="mt-3">{order.title}</h5>
                    <p>
                      US Men's Size: {order.size} | Condition: New | ReShup
                      Verified ✓
                    </p>
                  </div>
                  {(order.shippingStatus.status == "Rejected" ||
                    order.shippingStatus.status == "Cancelled") && (
                    <div class="alert alert-danger" role="alert">
                      {order.shippingStatus.description}
                    </div>
                  )}

                  <h4 className="mb-2">Order # {order._id}</h4>
                  <div class="alert alert-primary" role="alert">
                    Current order Status:{" "}
                    <strong>{order.shippingStatus.status}</strong>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="container">
            <div className="row g-1 justify-content-center">
              <div className="col-md-5 p-2">
                <div className="p-3 border rounded h-100">
                  {order && (
                    <>
                      <h5 class="fw-bold mb-3">Buyer Information</h5>
                      <div class="border rounded shadow-sm p-3">
                        <h6 class="mb-1">{order.buyerShipping.name}</h6>
                        <p class="text-body-secondary fw-light mb-0">
                          {order.buyerShipping.address.line1}
                        </p>
                        <p class="text-body-secondary fw-light mb-1">
                          {`${order.buyerShipping.address.city}, ${order.buyerShipping.address.state} ${order.buyerShipping.address.postal_code} US`}
                        </p>
                      </div>
                      <div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Purchase Price:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.salePrice / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Sales Tax:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.buyerTax / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Shipping:</p>
                          <p class="mb-1">
                            $
                            {(
                              order.paymentInformation.buyerShipping / 100
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="mb-1">Total:</p>
                          <h5 class="mb-1">
                            $
                            {(
                              order.paymentInformation.buyerPaymentTotal / 100
                            ).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5 p-2">
                <div className="p-3 border rounded">
                  {order && (
                    <>
                      <h5 class="fw-bold mb-3">Seller Information</h5>
                      <div class="border rounded shadow-sm p-3">
                        <h6 class="mb-1">{order.sellerShipping.name}</h6>
                        <p class="text-body-secondary fw-light mb-0">
                          {order.sellerShipping.address.line1}
                        </p>
                        <p class="text-body-secondary fw-light mb-1">
                          {`${order.sellerShipping.address.city}, ${order.sellerShipping.address.state} ${order.sellerShipping.address.postal_code} US`}
                        </p>
                      </div>
                      <div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Seller Sale Price:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.salePrice / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">ReShup Fee:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.sellerFee / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Seller Shipping:</p>
                          <p class="mb-1">
                            $
                            {(
                              order.paymentInformation.sellerShipping / 100
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="mb-1">Seller Payout:</p>
                          <h5 class="mb-1">
                            $
                            {(
                              order.paymentInformation.sellerPayoutTotal / 100
                            ).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                      <div class="d-grid gap-2 mx-auto mt-3">
                        <button
                          type="button"
                          class="btn btn-subtleprimary text-primary"
                          onClick={() =>
                            dispatch(transferSellerPayout(order._id))
                          }
                        >
                          <strong>
                            Transfer $
                            {(
                              order.paymentInformation.sellerPayoutTotal / 100
                            ).toFixed(2)}{" "}
                            to sellers Stripe account.
                          </strong>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5 p-2">
                <div className="p-3 border rounded h-100">
                  {order && (
                    <>
                      <h5 class="fw-bold mb-3">Buyer Shipping Information</h5>
                      <h6 class="mb-3">ReShup ------&#62; Buyer</h6>

                      <div class="mb-3">
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Tracking Number:</p>
                          <p class="mb-1">
                            {order.buyerTrackingNumber
                              ? order.buyerTrackingNumber
                              : "Generate label"}
                          </p>
                        </div>
                      </div>
                      <div class="d-grid gap-2 mx-auto">
                        <button
                          type="button"
                          class="btn btn-subtleprimary text-primary"
                          onClick={() =>
                            window.open(
                              `/order/buyer-shipping-label/${order._id}`,
                              "_blank"
                            )
                          }
                        >
                          View / Generate shipping label to buyer
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5 p-2">
                <div className="p-3 border rounded">
                  {order && (
                    <>
                      <h5 class="fw-bold mb-3">Seller Shipping Information</h5>
                      <h6 class="mb-3">Seller ------&#62; Reshup</h6>

                      <div class="mb-3">
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">
                            Seller Shipping Deadline:
                          </p>
                          <p class="mb-1">
                            {order.sellerShippingDeadlineFormatted}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Seller Tracking Number:</p>
                          <p class="mb-1">{order.sellerTrackingNumber}</p>
                        </div>
                      </div>
                      <div class="d-grid gap-2 mx-auto">
                        {order.sellerTrackingNumber && (
                          <button
                            type="button"
                            class="btn btn-subtleprimary text-primary"
                            onClick={() => {
                              window.open(
                                `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${order.sellerTrackingNumber}`,
                                "_blank"
                              );
                            }}
                          >
                            Track seller package
                          </button>
                        )}
                        <button
                          type="button"
                          class="btn btn-subtleprimary text-primary"
                          onClick={() => {
                            navigate(
                              `/order/seller-shipping-label/${order._id}`
                            );
                          }}
                        >
                          View Seller Shipping Label
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-5 p-2">
                <div className="p-3 border rounded">
                  {order && (
                    <>
                      <h5 class="fw-bold mb-3">ReShup Payment Information</h5>

                      <div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Shipping:</p>
                          <p class="mb-1">
                            $
                            {(
                              order.paymentInformation.buyerShipping / 100
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Buyer Tax:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.buyerTax / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">ReShup Fee:</p>
                          <p class="mb-1">
                            $
                            {(order.paymentInformation.sellerFee / 100).toFixed(
                              2
                            )}
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="text-muted mb-1">Seller Shipping:</p>
                          <p class="mb-1">
                            $
                            {(
                              order.paymentInformation.sellerShipping / 100
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div class="border-bottom my-3"></div>
                        <div class="d-flex justify-content-between">
                          <p class="mb-1">Total ReShup Collected:</p>
                          <h5 class="mb-1">
                            $
                            {(
                              order.paymentInformation.totalFeeAmount / 100
                            ).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5 p-2">
                <div className="p-3 border rounded">
                  <h5 class="fw-bold mb-3">Set Order Status</h5>
                  <div class="alert alert-primary" role="alert">
                    Current order Status:{" "}
                    <strong>{order.shippingStatus.status}</strong>
                  </div>

                  <div class="mb-3">
                    <label htmlFor="adminOrderDescription" class="form-label">
                      Order Status Description
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="adminOrderDescription"
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      value={description}
                    ></input>
                    <div id="orderDescription" class="form-text">
                      Description is only required for "Rejected" and
                      "Cancelled" orders.
                    </div>
                  </div>

                  <div class="d-grid gap-2 mx-auto">
                    <button
                      type="button"
                      class="btn btn-subtleprimary text-primary"
                      onClick={() => {
                        dispatch(
                          updateAdminOrderInformation(order._id, {
                            shippingStatus: {
                              status: "Arrived at ReShup",
                              description: description,
                              step: 2,
                            },
                          })
                        );
                      }}
                    >
                      Set order to "Arrived at ReShup"
                    </button>
                    <button
                      type="button"
                      class="btn btn-subtleprimary text-primary"
                      onClick={() => {
                        dispatch(
                          updateAdminOrderInformation(order._id, {
                            shippingStatus: {
                              status: "ReShup Verified + Shipped",
                              description: description,
                              step: 3,
                            },
                          })
                        );
                      }}
                    >
                      Set order to "ReShup Verified + Shipped"
                    </button>
                    <button
                      type="button"
                      class="btn btn-subtleprimary text-primary"
                      onClick={() => {
                        dispatch(
                          updateAdminOrderInformation(order._id, {
                            orderComplete: true,
                            shippingStatus: {
                              status: "Delivered",
                              description: description,
                              step: 4,
                            },
                          })
                        );
                      }}
                    >
                      Set order to "Delivered"
                    </button>
                    <button
                      type="button"
                      class="btn btn-dark"
                      onClick={() => {
                        dispatch(
                          updateAdminOrderInformation(order._id, {
                            shippingStatus: {
                              status: "Rejected",
                              description: description,
                              step: 1,
                            },
                          })
                        );
                      }}
                    >
                      Set order to "Rejected"
                    </button>
                    <button
                      type="button"
                      class="btn btn-dark"
                      onClick={() => {
                        dispatch(
                          updateAdminOrderInformation(order._id, {
                            shippingStatus: {
                              status: "Cancelled",
                              description: description,
                              step: 1,
                            },
                          })
                        );
                      }}
                    >
                      Set order to "Cancelled"
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4"></div>
        </>
      )}
    </>
  );
};

export default AdminOrderInformationScreen;
