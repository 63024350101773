import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faStore,
  faMoneyBill,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";

// This component lives in the Navbar component so dont need to re dispatch user info.
const NavigationOffcanvas = ({}) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user } = userLogin;
  const forSaleOffers = useSelector((state) => state.forSaleOffers);
  const { potentialOffers } = forSaleOffers;

  const closeOffCanvas = () => {
    let closeCanvas = document.getElementById("NavigationOffcanvasButton");
    closeCanvas.click();
  };
  const closeLoginModal = () => {
    let closeLoginModal = document.getElementById("LoginModalDismissButton");
    closeLoginModal.click();
    // closeOffCanvas();
  };

  useEffect(() => {}, [user]);

  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasBarsLeft"
      >
        <div className="offcanvas-header border-bottom">
          <h5 id="offcanvasRightLabel">ReShup</h5>
          <button
            type="button"
            id="NavigationOffcanvasButton"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav nav-pills flex-column mb-auto border-bottom pb-3">
            <Link
              to="/"
              className="btn  d-inline-flex align-items-center rounded border-0"
              onClick={closeOffCanvas}
            >
              <strong>Home</strong>
            </Link>
            <div>
              <button
                class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
              >
                <strong>About</strong>
              </button>
              <div class="collapse" id="home-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <Link
                      to="/about"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      What is ReShup
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reshup_authentication"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      ReShup authentication
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faq"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      FAQs
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/guide"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Guide to Reshup
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/contact"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <Link
              to="/marketplace"
              className="btn  d-inline-flex align-items-center rounded border-0"
              onClick={closeOffCanvas}
            >
              <strong>Marketplace</strong>
            </Link>

            <div>
              <button
                class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                data-bs-toggle="collapse"
                data-bs-target="#mens-shoes-collapse"
              >
                <strong>Men's shoes</strong>
              </button>
              <div class="collapse" id="mens-shoes-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <Link
                      to="/marketplace?brand=Nike&sizeType=Mens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Nike
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=Jordan&sizeType=Mens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Jordan
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=New+Balance&sizeType=Mens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      New Balance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=adidas&sizeType=Mens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Adidas
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <button
                class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                data-bs-toggle="collapse"
                data-bs-target="#womens-shoes-collapse"
              >
                <strong>Women's shoes</strong>
              </button>
              <div class="collapse" id="womens-shoes-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <Link
                      to="/marketplace?brand=Nike&sizeType=Womens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Nike
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=Jordan&sizeType=Womens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Jordan
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=adidas&sizeType=Womens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      Adidas
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/marketplace?brand=UGG&sizeType=Womens"
                      className="link-dark d-inline-flex text-decoration-none rounded"
                      onClick={closeOffCanvas}
                    >
                      UGG
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link
              to="/sell"
              className="btn btn-subtleprimary btn-sm mx-2 mt-2 text-primary"
              onClick={closeOffCanvas}
            >
              <strong>List an item</strong>
            </Link>
          </ul>
          <div class="row mt-3">
            {isAuthenticated && user ? (
              <div class="mb-1">
                <button
                  class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#account-collapse"
                >
                  <div class="d-flex flex-row">
                    <div className="position-relative">
                      <img
                        src={user.photo && user.photo}
                        class="bd-placeholder-img rounded-circle"
                        alt=""
                        style={{ width: 40, height: 40 }}
                      ></img>
                    </div>
                    <div class="container">
                      <h6 className="mt-1">{user.name && user.name}</h6>
                      {/* <small>View details</small> */}
                    </div>
                  </div>
                  {/* <strong>Account</strong> */}
                </button>
                <div class="collapse" id="account-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <Link
                        to="/profile/information"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        Profile information
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/settings"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        Profile settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/selling"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        My listings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/selling/completed"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        Selling
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/buying"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        My orders
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/favorites"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        Favorites
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile/offers"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={closeOffCanvas}
                      >
                        Offers
                        <span class="badge bg-primary ms-1 pb-0">
                          {potentialOffers}
                        </span>
                      </Link>
                    </li>
                    <li>
                      {/* <Link
                        to="/sell"
                        className="link-dark d-inline-flex text-decoration-none rounded"
                        onClick={() => dispatch(logout())}
                      >
                        <strong>Sign out</strong>
                      </Link> */}
                      <Link
                        to="/"
                        className="btn btn-subtleprimary btn-sm d-inline-flex ms-4 mt-2 text-primary"
                        onClick={() => dispatch(logout())}
                      >
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <>
                <p className="mb-1 ms-2">
                  <strong>Create an account today</strong>
                </p>
                <p className="fw-light ms-2 lh-sm">
                  <small>
                    Browse your favorite shoes, personalize your marketplace
                    expericence, get access to the cheapest verification and
                    fees.
                  </small>
                </p>
                <div class="col ms-2">
                  <Link
                    className="btn btn-sm btn-subtleprimary w-100 h-100 text-primary"
                    to="/login"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                    onClick={closeLoginModal}
                  >
                    <strong>Log In</strong>
                  </Link>
                </div>
                <div class="col me-2">
                  <Link
                    className="btn btn-sm btn-primary w-100 h-100"
                    to="/login"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                    // onClick={closeLoginModal}
                  >
                    <strong>Sign Up</strong>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationOffcanvas;
