import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { getRootMarketplaceListing, upsertRootMarketplaceListing } from "../../actions/marketplaceActions";
import { MARKETPLACE_ROOT_LISTING_UPSERT_RESET } from "../../constants/marketplaceConstants";

const CreateRootMarketplaceListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { isAuthenticated, user } = useSelector((state) => state.userLogin);
  var { rootMarketplaceListing, redirect } = useSelector((state) => state.adminRootMarketplaceListingInformation);

  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [sizeType, setSizeType] = useState("");
  const [enabled, setEnabled] = useState(false);

  useEffect(async () => {
    if (isAuthenticated === true && user && user.isAdmin) {
      if (params.id) {
        dispatch(getRootMarketplaceListing(params.id));
      } else {
        rootMarketplaceListing = { slug: "", description: "", sizeType: "", enabled: false };
      }
    }
    if (isAuthenticated === false || (user && !user.isAdmin)) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (rootMarketplaceListing) {
      setSlug(rootMarketplaceListing.slug);
      setDescription(rootMarketplaceListing.description);
      setSizeType(rootMarketplaceListing.sizeType);
      setEnabled(rootMarketplaceListing.enabled);
    }
  }, [rootMarketplaceListing]);

  useEffect(() => {
    if (redirect) {
      navigate("/admin/root-marketplace-listings");
      dispatch({ type: MARKETPLACE_ROOT_LISTING_UPSERT_RESET });
    }
  }, [redirect]);

  const submitHandler = (event) => {
    event.preventDefault();

    const listing = {
      _id: params.id,
      slug: slug,
      description: description,
      sizeType: sizeType,
      enabled: enabled,
    };
    dispatch(upsertRootMarketplaceListing(listing));
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="col-lg-7 mt-4 ms-sm-5">
        <h4>Create/Update New Root Marketplace Listing</h4>
        <h6>
          Add or update a root marketplace listing. Keep in mind the update will
          only take effect once the scraper runs again.
        </h6>
        <form class="row g-sm-3 mt-3" onSubmit={(event) => submitHandler(event)} noValidate>
          <div class="col-md-12">
            <div class="row">
              <label htmlFor="inputOrderNumber" class="col-sm-2 col-form-label">
                Slug (from StockX)
              </label>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control"
                  id="inputOrderNumber"
                  onBlur={(e) => setSlug(e.target.value)}
                  defaultValue={slug}
                ></input>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div className="row">
              <label htmlFor="inputcategory" class="col-sm-2 col-form-label">
                Size Type
              </label>
              <div class="col-sm-10">
                <select id="inputcategory" className="form-select"
                onChange={(event) => {
                  setSizeType(event.target.value);
                }}
                value={sizeType}>
                  <option value="">Choose...</option>
                  <option value="Mens">Mens</option>
                  <option value="Womens">Womens</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div className="row">
              <label htmlFor="inputMessage" class="col-sm-2 col-form-label">
                Description
              </label>
              <div class="col-sm-10">
                <textarea
                  className="form-control"
                  id="inputMessage"
                  rows="6"
                  onBlur={(e) => {
                    setDescription(e.target.value);
                  }}
                  defaultValue={description}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h5>Listing Enabled</h5>
            <hr className="my-2"></hr>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="orderNotificationsSwitch"
                checked={enabled}
                onChange={() =>
                  setEnabled(!enabled)
                }
              ></input>
              <label
                class="form-check-label"
                htmlFor="orderNotificationsSwitch"
              >
                This will enable the scraper to get the current prices on StockX
              </label>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary mt-3">
              Create/Update Root Marketplace Listing
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRootMarketplaceListing;
