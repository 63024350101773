import React, { useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSellerListing } from "../actions/marketplaceActions";
import { likeItem, unlikeItem, getLikedStatus } from "../actions/userActions";
import { USER_LIKE_RESET } from "../constants/userConstants";
import LoadingSpinner from "../components/LoadingSpinner";
import SharingModel from "../components/SharingModel";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
import CloudinaryGallery from "../components/CloudinaryGallery";
import NotFoundScreen from "../screens/NotFoundScreen";

import GoogleMapComponent from "../components/GoogleMapComponent";

import { GoogleMap, Circle } from "@react-google-maps/api";
// import OfferModal from "../components/OfferModal";

const SellerListingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const listingId = params.id;
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user } = userLogin;
  const sellerListing = useSelector((state) => state.sellerListing);
  const {
    listingLoading: sellerListingLoading,
    sellerListing: sellerListingInformation,
    error: error,
  } = sellerListing;
  const marketplaceListing = useSelector((state) => state.marketplaceListing);
  const { listingInformation: marketplaceListingInformation } =
    marketplaceListing;
  const userLikes = useSelector((state) => state.userLikes);
  const { loading: userLikesLoading, liked } = userLikes;

  const rgbaValues = {
    red: 255,
    green: 193,
    blue: 7,
    alpha: 0.7, // Adjust this for the desired transparency
  };
  const divStyle = {
    background: `rgba(${rgbaValues.red}, ${rgbaValues.green}, ${rgbaValues.blue}, ${rgbaValues.alpha})`,
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // if (
    //   sellerListingInformation &&
    //   sellerListingInformation._id === listingId
    // ) {
    //   // dispatch(getLikedStatus(listingId));
    // } else {
    // }
    dispatch(getSellerListing(listingId));
    dispatch(getLikedStatus(listingId));
  }, [dispatch, listingId]);

  const addOrRemoveFromFavorites = () => {
    if (isAuthenticated) {
      if (liked) {
        dispatch(unlikeItem(listingId));
      } else {
        dispatch(likeItem(listingId));
      }
      dispatch({ type: USER_LIKE_RESET });
    } else {
      navigate(`/login?redirect=${location.pathname}`);
    }
  };

  return (
    <>
      {error ? (
        <NotFoundScreen></NotFoundScreen>
      ) : (
        <div className="container">
          {sellerListingLoading ||
          (sellerListingInformation &&
            sellerListingInformation._id != listingId) ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            sellerListingInformation && (
              <>
                <SharingModel
                  listingInformation={sellerListingInformation}
                ></SharingModel>
                {/* <OfferModal></OfferModal> */}
                <div className="row mt-4">
                  {marketplaceListingInformation && (
                    <BreadcrumbLocation
                      location={[
                        "Home",
                        "Sneakers",
                        "Seller Listings",
                        marketplaceListingInformation.listingName,
                      ]}
                    ></BreadcrumbLocation>
                  )}
                  <div className=" col-lg-6 mt-lg-0">
                    <div class="position-relative pb-2 border-bottom">
                      {sellerListingInformation && (
                        <CloudinaryGallery
                          photos={sellerListingInformation.photos}
                        ></CloudinaryGallery>
                      )}
                      {sellerListingInformation.productAvailable === false && (
                        <div
                          class="position-absolute top-50 start-50 translate-middle w-100 text-center"
                          style={divStyle}
                        >
                          <p className="mb-0 ">
                            <strong>SOLD</strong>
                          </p>
                          <p className="my-1 p-0">
                            <small>Sorry, this item has been sold.</small>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="row d-none d-lg-block mt-3">
                      <h5 className="mb-3">Seller Information</h5>

                      <div class="d-flex flex-row">
                        <div className="position-relative">
                          <img
                            src={
                              sellerListingInformation.sellerInformation.photo
                            }
                            class="bd-placeholder-img rounded-circle"
                            alt=""
                            style={{ width: 75, height: 75 }}
                          ></img>
                          {/* <span class="position-absolute bottom-0 end-0 bg-success ">✓</span> */}
                        </div>
                        <div class="container">
                          <h5>
                            {sellerListingInformation.sellerInformation.name}
                          </h5>

                          <div class="d-flex mb-1">
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                          </div>
                          <p class="mb-1 fw-light">
                            <small>
                              Joined in{" "}
                              {
                                sellerListingInformation.sellerInformation
                                  .yearJoined
                              }
                            </small>
                          </p>
                        </div>

                        <div className="container">
                          <Link
                            className="btn btn-light"
                            to={
                              sellerListingInformation.sellerInformation.name !=
                              "ReShup"
                                ? `/seller/${sellerListingInformation.userId}`
                                : "/marketplace"
                            }
                          >
                            View Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-5 offset-lg-1">
                    <p className="card-text mb-2 mt-md-0">
                      <small className="text-muted">
                        {`Listing posted on ${sellerListingInformation.dateString}`}
                      </small>
                    </p>
                    <h3 className="mb-2 text-capitalize">
                      {sellerListingInformation.title}
                    </h3>
                    {marketplaceListingInformation && (
                      <h5 className="text-primary text-capitalize">
                        {marketplaceListingInformation.make}
                      </h5>
                    )}
                    <h3 className="mt-3">${sellerListingInformation.price}</h3>
                    <div className="d-flex flex-row mb-3">
                      <span className="badge text-bg-light p-1">
                        + Shipping
                      </span>
                      <span className="badge text-bg-light p-1 ms-2">
                        ReShup Certified &#x2713;
                      </span>
                      <span className="badge text-bg-light p-1 ms-2">
                        Condition: <span className="text-success">New</span>
                      </span>
                    </div>
                    {sellerListingInformation.productAvailable === false ? (
                      <>
                        <div className="row">
                          <div className="col">
                            <button
                              type="button"
                              class="btn btn-secondary w-100 h-100"
                              disabled={true}
                            >
                              Item sold.
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="row mb-3">
                          <div class="col">
                            <button
                              type="button"
                              className="btn btn-light w-100 h-100"
                              onClick={() => addOrRemoveFromFavorites()}
                            >
                              {liked ? (
                                <>
                                  Like <i className="fa fa-heart"></i>
                                </>
                              ) : (
                                <>
                                  Like <i className="far fa-heart"></i>
                                </>
                              )}
                            </button>
                          </div>
                          <div class="col">
                            <button
                              type="button"
                              class="btn btn-light w-100 h-100"
                              data-bs-toggle="modal"
                              data-bs-target="#sharingModal"
                            >
                              Share <i className="fas fa-share"></i>
                            </button>
                          </div>
                        </div>
                        {isAuthenticated &&
                        user._id === sellerListingInformation.userId ? (
                          ""
                        ) : (
                          <div class="row">
                            <div class="col">
                              <button
                                type="button"
                                class="btn btn-primary w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#offerModal"
                              >
                                Make offer
                              </button>
                            </div>
                          </div>
                        )}

                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <hr></hr>
                          </div>
                          <div class="mx-2">
                            <small className="text-muted">or</small>
                          </div>
                          <div class="flex-grow-1">
                            <hr></hr>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            {isAuthenticated &&
                            user._id == sellerListingInformation.userId ? (
                              <Link
                                to={`/sell/${marketplaceListingInformation._id}/${listingId}`}
                                className="btn btn-dark w-100 h-100"
                              >
                                Edit Listing
                              </Link>
                            ) : (
                              <Link
                                to={`/buy/${listingId}`}
                                className="btn btn-dark w-100 h-100"
                              >
                                Buy Now
                              </Link>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-center mt-2 pb-3 border-bottom">
                          <div class="col-md-8">
                            <small className="text-muted">
                              *By continuing to checkout, you agree to the
                              ReShup Privacy Policy and Terms of Service.
                            </small>
                          </div>
                        </div>
                      </>
                    )}

                    <h5 className="mt-4">Product Details</h5>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-muted mb-1">Size:</p>
                      </div>
                      <div className="col-7">
                        <p className="mb-1">
                          {sellerListingInformation.size}{" "}
                          {marketplaceListingInformation.sizeType == "Mens"
                            ? "Men's"
                            : "Women's"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-muted mb-1">Condition:</p>
                      </div>
                      <div className="col-7">
                        <p className="mb-1">New with box</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-muted mb-1">Proof of Purchase:</p>
                      </div>
                      <div className="col-7">
                        <p className="mb-1">Yes</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-muted mb-1">Colorway:</p>
                      </div>
                      <div className="col-7">
                        {marketplaceListingInformation && (
                          <p className="mb-1">
                            {marketplaceListingInformation.colorway}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-muted mb-1">Date Posted:</p>
                      </div>
                      <div className="col-7">
                        <p className="mb-1">
                          {sellerListingInformation.dateString}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <h5 className="mt-4">Seller Description</h5>
                      <p className="">{sellerListingInformation.description}</p>
                    </div>

                    <div className="row d-lg-none border-bottom">
                      <h5 className="mb-3 mt-2">Seller Information</h5>

                      <div class="d-flex flex-row mb-3">
                        <div className="position-relative">
                          <img
                            src={
                              sellerListingInformation.sellerInformation.photo
                            }
                            class="bd-placeholder-img rounded-circle"
                            alt=""
                            style={{ width: 75, height: 75 }}
                          ></img>
                          {/* <span class="position-absolute bottom-0 end-0 bg-success ">✓</span> */}
                        </div>
                        <div class="container">
                          <h5>
                            {sellerListingInformation.sellerInformation.name}
                          </h5>

                          <div class="d-flex mb-1">
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                            <i
                              class="fas fa-star"
                              aria-hidden="true"
                              style={{ color: "gold" }}
                            ></i>
                          </div>
                          <p class="mb-1 fw-light">
                            <small>
                              Joined in{" "}
                              {
                                sellerListingInformation.sellerInformation
                                  .yearJoined
                              }
                            </small>
                          </p>
                        </div>

                        <div className="container">
                          <Link
                            className="btn btn-light"
                            to={
                              sellerListingInformation.sellerInformation.name !=
                              "ReShup"
                                ? `/seller/${sellerListingInformation.userId}`
                                : "/marketplace"
                            }
                          >
                            View Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <h2 className="me-3">
                        {/* <i class="bi bi-bag-check"></i> */}
                        <i class="bi bi-shield-check"></i>
                      </h2>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0">Authenticity promised</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            All products will be sent to the ReShup warehouse
                            for verification.
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <h2 className="me-3">
                        <i class="bi bi-box2-heart"></i>
                      </h2>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0">Buyer protection</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            You get what you paid for or your money back its
                            that simple.
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex  align-items-center mt-2">
                      <h2 className="me-3">
                        <i class="bi bi-cash-coin"></i>
                      </h2>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0">Make your best offer</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            Why pay more? Make your best offer and get
                            unbeatable deals.
                          </small>
                        </p>
                      </div>
                    </div>

                    {sellerListingInformation &&
                      sellerListingInformation.location && (
                        <div className="row mt-2 pb-2 border-bottom border-top">
                          <div className="mt-3 col">
                            <GoogleMapComponent
                              latitude={
                                sellerListingInformation.location.coordinates[1]
                              }
                              longitude={
                                sellerListingInformation.location.coordinates[0]
                              }
                            ></GoogleMapComponent>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      )}
    </>
  );
};

export default SellerListingScreen;
// https://maps.googleapis.com/maps/api/staticmap?center=39.4430128,-76.6424326&zoom=10&size=450x250&markers=39.4430128,-76.6424326&key=AIzaSyCbQiS1l7jZLICasyQfSZsgjg0iYJrO9Wk
// https://maps.googleapis.com/maps/api/staticmap?center=1600+Amphitheatre+Parkway,+Mountain+View,+CA&zoom=14&size=400x400&markers=color:red%7C1600+Amphitheatre+Parkway,+Mountain+View,+CA&path=fillcolor:0xAA000033%7Ccolor:0xAA0000%7Cenc:e~isFvnwtL&key=AIzaSyCbQiS1l7jZLICasyQfSZsgjg0iYJrO9Wk
