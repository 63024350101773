import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInformation } from "../actions/userActions";
import { getSellerOrderInformation } from "../actions/orderActions";
import ProfileSellingInformation from "../components/Profile_Components/ProfileSellingInformation";

const ProfileSellingInformationScreen = () => {
  const params = useParams();
  const orderNumber = params.orderNumber;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, name } = userLogin;
  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getUserInformation());
      dispatch(getSellerOrderInformation(orderNumber));
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated, orderNumber]);
  return (
    <>
      <div className="container d-flex justify-content-center mt-4">
        <ProfileSellingInformation></ProfileSellingInformation>
      </div>
    </>
  );
};

export default ProfileSellingInformationScreen;
