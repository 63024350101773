import React from "react";

const LoginScreen = () => {
  return (
    <div className="container">
      <ul
        className="nav nav-tabs justify-content-center"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="listings-tab"
            data-bs-toggle="tab"
            data-bs-target="#signuptab"
            type="button"
            role="tab"
          >
            Login
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="followers-tab"
            data-bs-toggle="tab"
            data-bs-target="#logintab"
            type="button"
            role="tab"
          >
            Login
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="logintab"
          role="tabpanel"
        >
          Login
        </div>
        <div className="tab-pane fade show" id="signuptab" role="tabpanel">
          Sign UP
        </div>
      </div>
      <div className="d-flex flex-column bd-highlight mb-3">
        <div className="p-2 bd-highlight">Flex item 1</div>
        <div className="p-2 bd-highlight">Flex item 2</div>
        <div className="p-2 bd-highlight">Flex item 3</div>
      </div>
    </div>
  );
};

export default LoginScreen;
