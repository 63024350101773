import React from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";

const ProfileBuyingInformation = () => {
  const grayedOutDiv = "d-flex align-items-start opacity-25";
  const activeDiv = "d-flex align-items-start";
  const grayedOutIcon = "badge text-bg-dark mt-1 me-2";
  const activeOutIcon = "badge text-bg-success mt-1 me-2";
  const buyerOrderInformation = useSelector(
    (state) => state.buyerOrderInformation
  );
  const { order, loading: orderLoading } = buyerOrderInformation;
  return (
    <div className="col-md-5">
      {orderLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        order && (
          <>
            {order.buyerTrackingNumber && (
              <>
                <h5>Tracking #{order.buyerTrackingNumber}</h5>

                <button
                  type="button"
                  class="btn btn-sm btn-subtleprimary text-primary mb-2"
                  onClick={() =>
                    window.open(
                      `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${order.buyerTrackingNumber}`,
                      "_blank"
                    )
                  }
                >
                  Track My Order
                </button>
              </>
            )}
            <div className="container text-center">
              <img className="img-fluid" src={order.thumbnail}></img>
              <h5 className="mt-3">{order.title}</h5>
              <p>
                US Men's Size: {order.size} | Condition: New | ReShup Verified ✓
              </p>
            </div>
            {(order.shippingStatus.status == "Rejected" ||
              order.shippingStatus.status == "Cancelled") && (
              <div class="alert alert-danger" role="alert">
                {order.shippingStatus.description}
              </div>
            )}
            <div className="bg-light p-3">
              <h5 className="mb-4">Order # {order._id}</h5>
              <div className="container">
                <div class="d-flex align-items-start ">
                  <span class="badge text-bg-success mt-1 me-2">✓</span>
                  <div>
                    <h6 class="fw-bold mb-0">Order Confirmed</h6>
                    <p className="text-secondary">
                      <small>
                        Congratulations! You’ve matched with a seller.
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  class={
                    order.shippingStatus.step > 1 ? activeDiv : grayedOutDiv
                  }
                >
                  <span
                    class={
                      order.shippingStatus.step > 1
                        ? activeOutIcon
                        : grayedOutIcon
                    }
                  >
                    ✓
                  </span>
                  <div>
                    <h6 class="fw-bold mb-0">Order Arrived at ReShup</h6>
                    <p className="text-secondary">
                      <small>
                        One step closer! Your order arrived at one of ReShup's
                        authentication centers. Once we verify your item we will
                        ship it to you!
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  class={
                    order.shippingStatus.step > 2 ? activeDiv : grayedOutDiv
                  }
                >
                  <span
                    class={
                      order.shippingStatus.step > 2
                        ? activeOutIcon
                        : grayedOutIcon
                    }
                  >
                    ✓
                  </span>
                  <div>
                    <h6 class="fw-bold mb-0">ReShup Verified + Shipped</h6>
                    <p className="text-secondary">
                      <small>
                        Congrats, your order is ReShup Verified! We packed it up
                        for you and are shipping it to you now.
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  class={
                    order.shippingStatus.step > 3 ? activeDiv : grayedOutDiv
                  }
                >
                  <span
                    class={
                      order.shippingStatus.step > 3
                        ? activeOutIcon
                        : grayedOutIcon
                    }
                  >
                    ✓
                  </span>
                  <div>
                    <h6 class="fw-bold mb-0">Order Delivered</h6>
                    <p className="text-secondary">
                      <small>
                        We hope you enjoy your item. Make sure to tag us
                        @re_shup on Instagram for any unboxing pics!
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="border-bottom my-3"></div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">Your Purchase Price:</p>
                <p class="mb-1">${(order.purchasePrice / 100).toFixed(2)}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">Sales Tax:</p>
                <p class="mb-1">${(order.taxPrice / 100).toFixed(2)}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-muted mb-1">Shipping:</p>
                <p class="mb-1">${(order.shippingPrice / 100).toFixed(2)}</p>
              </div>
              <div class="border-bottom my-3"></div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Order Total:</p>
                <h5 class="mb-1">${(order.totalPrice / 100).toFixed(2)}</h5>
              </div>
            </div>
            <div className="container text-center mt-2">
              <p>
                <strong>Payment </strong>
                {order.paymentDetails.card.brand}
                {" ending in "}
                {order.paymentDetails.card.last4}
              </p>
              <p>
                <strong>Ship to </strong>
                {order.shippingAddress.address.line1}{" "}
                {order.shippingAddress.address.city}{" "}
                {order.shippingAddress.address.state},{" "}
                {order.shippingAddress.address.postal_code}{" "}
                {order.shippingAddress.address.country}
              </p>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ProfileBuyingInformation;
