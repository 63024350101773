import React from "react";
import ShippingIntent from "../components/ShippingIntent";

const ProfileShippingScreen = () => {
  return (
    <div className="container">
      <div className="row justify-content-center mt-3">
        <div className="col-lg-7">
          <ShippingIntent></ShippingIntent>
        </div>
      </div>
    </div>
  );
};

export default ProfileShippingScreen;
