import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditListingCard from "./EditListingCard";
import LoadingSpinner from "../LoadingSpinner";

const ProfileSelling = () => {
  const userListings = useSelector((state) => state.userListings);
  const {
    listingsLoading: userListingsLoading,
    userListings: userListingsReturned,
  } = userListings;
  return (
    <div className="container ms-sm-5">
      <h4 className="mb-4 px-0">My listings</h4>

      <div className="row row-cols-2 row-cols-lg-4 row-cols-xl-5 g-1">
        {userListingsLoading ? (
          <>
            <div className="col mt-3 g-1 g-md-3">
              <div class="card ms-3">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
            <div className="col mt-3 g-1 g-md-3">
              <div class="card ms-3">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {userListingsReturned.map((listing, index) => (
              <EditListingCard
                listingInformation={listing}
                key={index}
              ></EditListingCard>
            ))}
          </>
        )}
      </div>
      {userListingsReturned.length === 0 && !userListingsLoading && (
        <section class="py-5 text-center container">
          <div class="row py-lg-5">
            <div class="">
              <p class="lead text-body-secondary">No listings. Create one?</p>
              <Link to={`/sell/`} className="btn btn-primary">
                Create listing
              </Link>
            </div>
          </div>
        </section>
      )}

      {/* <div className="col-lg-8">
        {userListingsLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          userListingsReturned.map((element, index) => (
            <div className="row">
              <div class="border rounded shadow-sm p-3">
                <div class="hstack gap-3">
                  <div class="">
                    <img
                      src={element.photos[0]}
                      class="img-fluid ps-2"
                      alt="..."
                      style={{ maxWidth: 140 }}
                    ></img>
                  </div>
                  <div class="">
                    <h6 class="text-truncate">{element.title}</h6>
                    <h6 class="">${element.price}</h6>
                    <p class=" m-0">
                      <small class="text-body-secondary">
                        Size: {element.size}
                      </small>
                    </p>
                    <p class=" mb-2">
                      <small class="text-body-secondary">
                        Listed on {element.dateString}
                      </small>
                    </p>
                    <div class="d-grid gap-2 d-flex">
                      <Link
                        to={`/sell/${element.shupShoeId}/${element._id}`}
                        className="btn btn-primary btn-sm me-md-2"
                      >
                        Edit this listing
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div class="vstack gap-3">
        <div class="card shadow-sm">
          <div class="card-body">
            <h4 class="card-title">Your listings</h4>
          </div>
        </div>
        {userListingsLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          userListingsReturned.map((element, index) => (
            <div class="card shadow-sm" key={index}>
              <div class="row">
                <div class="col-4 align-self-center">
                  <div class="">
                    <img
                      src={element.photos[0]}
                      class="img-fluid ps-2"
                      alt="..."
                    ></img>
                  </div>
                </div>
                <div class="col-8">
                  <div class="card-body">
                    <h6 class="card-title text-truncate">{element.title}</h6>
                    <h6 class="card-subtitle">${element.price}</h6>
                    <p class="card-text m-0">
                      <small class="text-body-secondary">
                        Size: {element.size}
                      </small>
                    </p>
                    <p class="card-text mb-2">
                      <small class="text-body-secondary">
                        Listed on {element.dateString}
                      </small>
                    </p>
                    <div class="d-grid gap-2 d-flex">
                      <Link
                        to={`/sell/${element.shupShoeId}/${element._id}`}
                        className="btn btn-primary btn-sm me-md-2"
                      >
                        Edit this listing
                      </Link>

                      <button class="btn btn-secondary btn-sm" type="button">
                        Share
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div> */}
    </div>
  );
};

export default ProfileSelling;
