import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";

const SellerListingsFilterOffcanvas = () => {
  const sellerListingsMostAvailableSize = useSelector(
    (state) => state.sellerListingsSizes
  );
  const { listingsLoading: mostAvailableSizeLoading, sizes } =
    sellerListingsMostAvailableSize;

  let [searchParams, setSearchParams] = useSearchParams();

  const updatePriceOption = (price) => {
    var tempSearchParams = searchParams;
    let currentPrice = tempSearchParams.get("price");
    if (price === currentPrice) {
      tempSearchParams.delete("price");
    } else {
      tempSearchParams.set("price", price);
    }
    setSearchParams(tempSearchParams);
  };

  const updateSizeOption = (size) => {
    var tempSearchParams = searchParams;
    let tempSizes = tempSearchParams.getAll("sizes");
    if (tempSizes.includes(size)) {
      tempSearchParams.delete("sizes");
      tempSizes.splice(tempSizes.indexOf(size), 1);
      tempSizes.map((value) => {
        tempSearchParams.append("sizes", value);
      });
    } else {
      tempSearchParams.append("sizes", size);
    }
    setSearchParams(tempSearchParams);
  };
  return (
    <div className="container">
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasFilterSellerListings"
      >
        <div className="offcanvas-header border-bottom">
          <p className="fs-4 m-0 ">Filters</p>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="">
            <div className="container mt-2">
              {searchParams.getAll("sizes").map((x, index) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mb-1 me-1"
                  value={x}
                  key={index}
                  onClick={(event) => updateSizeOption(event.target.value)}
                >
                  {x} X
                </button>
              ))}
              {searchParams.getAll("price").map((x, index) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mb-1 me-1"
                  value={x}
                  key={index}
                  onClick={(event) => updatePriceOption(event.target.value)}
                >
                  {x} X
                </button>
              ))}
            </div>
            <hr></hr>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpenConditionHeading"
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-conditionOffcanvas"
                  >
                    Condition
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-conditionOffcanvas"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="conditionRadioDesktopOffcanvas"
                        id="conditionRadioDesktopOffcanvas1"
                        value="reShupVerified"
                        defaultChecked
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="conditionRadioDesktopOffcanvas1"
                      >
                        New with box
                      </label>
                      <div class="form-text" id="basic-addon4">
                        <small>Unused, unworn with original tags/box.</small>
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="conditionRadioDesktopOffcanvas"
                        id="conditionRadioDesktopOffcanvas2"
                        value="reshupVerifiedPartner"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="conditionRadioDesktopOffcanvas2"
                      >
                        New without box
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="conditionRadioDesktopOffcanvas"
                        id="conditionRadioDesktopOffcanvas3"
                        value="reshupVerifiedPartner"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="conditionRadioDesktopOffcanvas3"
                      >
                        New with defects
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="conditionRadioDesktopOffcanvas"
                        id="conditionRadioDesktopOffcanvas4"
                        value="reshupVerifiedPartner"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="conditionRadioDesktopOffcanvas4"
                      >
                        Used
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingOneOffCanvas"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOneOffcanvas"
                  >
                    Size
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOneOffcanvas"
                  className="accordion-collapse collapse"
                >
                  <div
                    className="accordion-body overflow-auto"
                    style={{ height: 200 }}
                  >
                    {mostAvailableSizeLoading ? (
                      <LoadingSpinner></LoadingSpinner>
                    ) : (
                      sizes.map((element, index) => (
                        <div
                          className="form-check"
                          key={element._id + "_" + index + "desktop"}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={element._id}
                            id={`${element._id}sizesAvailableDesktopCheckbox`}
                            onChange={(e) => updateSizeOption(e.target.value)}
                            checked={searchParams
                              .getAll("sizes")
                              .includes(element._id)}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor={`${element._id}sizesAvailableDesktopCheckbox`}
                          >
                            {element._id}
                            <span
                              className="text-muted ms-1"
                              style={{ fontSize: "smaller" }}
                            >
                              ({element.count})
                            </span>
                          </label>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingTwoOffCanvas"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwoOffcanvas"
                  >
                    Price
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwoOffcanvas"
                  className="accordion-collapse collapse"
                >
                  <div className="accordion-body">
                    <div className="container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="0-200"
                          id="0-200Checkbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "0-200"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="0-200Checkbox"
                        >
                          Under $200
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="200-500"
                          id="200-500Checkbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "200-500"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="200-500Checkbox"
                        >
                          $200-$500
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="500-Max"
                          id="500-MaxCheckbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "500-Max"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="500-MaxCheckbox"
                        >
                          $500+
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThreeOffCanvas"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThreeOffcanvas"
                  >
                    Delivery
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThreeOffcanvas"
                  className="accordion-collapse collapse"
                >
                  <div className="accordion-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deliveryRadioMobile"
                        id="deliveryRadioMobile1"
                        value="reShupVerified"
                        defaultChecked
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="deliveryRadioMobile1"
                      >
                        ReShup Verified
                      </label>
                      <div class="form-text" id="basic-addon4">
                        <small>Sent to ReShup for verification.</small>
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deliveryRadioMobile"
                        id="deliveryRadioMobile2"
                        value="reshupVerifiedPartner"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="deliveryRadioMobile2"
                      >
                        reShup Verified Partner
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="deliveryRadioMobile"
                        id="deliveryRadioMobile3"
                        value="reshupVerifiedPartner"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="deliveryRadioMobile3"
                      >
                        ReShup Local Listings
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div class="d-grid gap-2 col-10 mx-auto">
              <button
                class="btn btn-primary"
                type="button"
                data-bs-dismiss="offcanvas"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerListingsFilterOffcanvas;
