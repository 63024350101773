import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

const SearchBar = ({ formClassAttributes, heightAttributes, searchType }) => {
  const [listings, setListings] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const searchDatabase = (query) => {
    let cancel;
    if (query !== "") {
      // setQuery(query);
      // searchParams.set("query", query);
      // setSearchParams(searchParams);
      axios({
        method: "get",
        url: `/api/marketplace/search?query=${query}`,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((response) => setListings(response.data))
        .catch((error) => {
          if (error.isCancel(error)) {
            return;
          }
        });
    } else {
      setListings([]);
    }
  };

  return (
    <form className={formClassAttributes + "position-relative"}>
      <div className="input-group">
        <span className="input-group-text d-none d-md-block">
          <i className="fas fa-search"></i>
        </span>
        <input
          id="searchBar"
          autoComplete="off"
          className="form-control flex-grow-1 rounded-end"
          type="search"
          placeholder="I'm looking for ..."
          onChange={(event) => {
            searchDatabase(event.target.value);
          }}
          onKeyDown={(event) => {
            if (
              event.key === "Enter" &&
              event.target.value !== "" &&
              searchType !== "sell"
            ) {
              event.preventDefault();
              setListings([]);
              navigate(`/marketplace?query=${event.target.value}`);
              event.target.value = "";
            }
          }}
        ></input>
        <ul
          className={
            listings.length === 0
              ? "list-group position-absolute top-100 mt-1 w-100 overflow-auto d-none"
              : "list-group position-absolute top-100 mt-1 w-100 overflow-auto z-3"
          }
          // className= "list-group position-absolute top-100 mt-1 w-100 overflow-auto d-none"
          style={heightAttributes}
          id="searchBarList"
        >
          {listings.length === 0 ? (
            <div></div>
          ) : (
            listings.map((item, index) => (
              <Link
                to={`/${searchType}/${item["_id"]}`}
                className="text-decoration-none"
                key={index}
                onClick={(event) => {
                  setListings([]);
                  document.getElementById("searchBar").value = "";
                }}
              >
                <li className="list-group-item">
                  <div className="row">
                    {/* <div className=" col col-auto me-auto"> */}
                    <div className=" col col-8 col-md-auto me-md-auto">
                      {/* {item.shoeName
                        .split(" ")
                        .filter((word, index) => index < 4)
                        .join(" ") + "..."} */}
                      <div className="d-flex flex-column">
                        <div className="fw-light">{item.brand}</div>
                        <div className="fs-5 text-truncate">
                          {item.listingName}
                        </div>
                        <div className="fs-6 text-truncate">
                          {item.colorway}
                        </div>
                      </div>
                    </div>
                    {/* <div className=" col col-auto"> */}
                    <div className=" col col-4 col-md-auto">
                      <img
                        src={item["photo"]}
                        alt="Shoe"
                        className="img-fluid"
                        style={{ maxWidth: 100 }}
                      ></img>
                    </div>
                  </div>
                </li>
              </Link>
            ))
          )}
        </ul>
      </div>
    </form>
  );
};

export default SearchBar;
