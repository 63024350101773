import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import LoadingSpinner from "../LoadingSpinner";

import MarketplaceFilters from "./MarketplaceFilters";
const FilterAndSortOffcanvas = () => {
  const marketplaceBrands = useSelector((state) => state.marketplaceBrands);
  const { brandsLoading, brands } = marketplaceBrands;

  let [searchParams, setSearchParams] = useSearchParams();

  const updatePriceOption = (price) => {
    var tempSearchParams = searchParams;
    let currentPrice = tempSearchParams.get("price");
    if (price === currentPrice) {
      tempSearchParams.delete("price");
    } else {
      tempSearchParams.set("price", price);
    }
    setSearchParams(tempSearchParams);
  };

  const updateSizeTypeOption = (sizeType) => {
    var tempSearchParams = searchParams;
    let currentSizeType = tempSearchParams.get("sizeType");
    if (sizeType === currentSizeType || sizeType === "All") {
      tempSearchParams.delete("sizeType");
    } else {
      tempSearchParams.set("sizeType", sizeType);
    }
    setSearchParams(tempSearchParams);
  };

  const updateBrandOption = (brand) => {
    var tempSearchParams = searchParams;
    var tempBrands = tempSearchParams.getAll("brand");
    if (tempBrands.includes(brand)) {
      tempSearchParams.delete("brand");
      tempBrands.splice(tempBrands.indexOf(brand), 1);
      tempBrands.map((value) => {
        tempSearchParams.append("brand", value);
      });
    } else {
      tempSearchParams.append("brand", brand);
    }
    setSearchParams(tempSearchParams);
  };
  return (
    <div className="container">
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasFilter"
      >
        <div className="offcanvas-header border-bottom">
          {/* <h5 id="offcanvasRightLabel">Filters</h5> */}
          <p className="fs-4 m-0 ">Filters</p>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="p-3 mr-3">
            {/* <MarketplaceFilters></MarketplaceFilters> */}
            <div className="container mt-2">
              {searchParams.getAll("brand").map((x, index) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mb-1 me-1"
                  value={x}
                  key={index}
                  onClick={(event) => updateBrandOption(event.target.value)}
                >
                  {x} X
                </button>
              ))}
              {searchParams.getAll("price").map((x, index) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mb-1 me-1"
                  value={x}
                  key={index}
                  onClick={(event) => updatePriceOption(event.target.value)}
                >
                  {x} X
                </button>
              ))}
              {searchParams.getAll("sizeType").map((x, index) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mb-1 me-1"
                  value={x}
                  key={index}
                  onClick={(event) => updateSizeTypeOption(event.target.value)}
                >
                  {x} X
                </button>
              ))}
            </div>
            <hr className=""></hr>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                  >
                    Wardrobe
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="wardrobeRadioMobile"
                        id={"sneakersRadioMobile"}
                        value="sneakers"
                        defaultChecked={true}
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor={"sneakersRadioMobile"}
                      >
                        Sneakers
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="wardrobeRadioMobile"
                        id={"apparelRadioMobile"}
                        value="apparel"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor={"apparelRadioMobile"}
                      >
                        Apparel
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="wardrobeRadioMobile"
                        id={"electronicsRadioMobile"}
                        value="electronics"
                        // onClick={(event) => updateListingOption(event)}
                        disabled
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor={"electronicsRadioMobile"}
                      >
                        Electonics
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                  >
                    Brand
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <div className="container ">
                      {brandsLoading ? (
                        <LoadingSpinner></LoadingSpinner>
                      ) : (
                        brands.sort().map((element, index) => (
                          <div
                            className="form-check"
                            key={element + "_" + index}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={element}
                              id={`${element}Checkbox`}
                              onChange={(e) =>
                                updateBrandOption(e.target.value)
                              }
                              checked={searchParams
                                .getAll("brand")
                                .includes(element)}
                            ></input>
                            <label
                              className="form-check-label text-capitalize"
                              htmlFor={`${element}Checkbox`}
                            >
                              {element}
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                  >
                    Price
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                >
                  <div className="accordion-body">
                    <div className="container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="0-200"
                          id="0-200Checkbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "0-200"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="0-200Checkbox"
                        >
                          Under $200
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="200-500"
                          id="200-500Checkbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "200-500"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="200-500Checkbox"
                        >
                          $200-$500
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="500-Max"
                          id="500-MaxCheckbox"
                          onChange={(e) => updatePriceOption(e.target.value)}
                          checked={searchParams.get("price") === "500-Max"}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="500-MaxCheckbox"
                        >
                          $500+
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                  >
                    Type
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body">
                    <div className="container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sizeTypeRadioMobile"
                          id="allRadioButtonMobile"
                          value="All"
                          checked={searchParams.getAll("sizeType").length === 0}
                          onChange={(e) => updateSizeTypeOption(e.target.value)}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="allRadioButtonMobile"
                        >
                          All
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sizeTypeRadioMobile"
                          id="menRadioButtonMobile"
                          value="Mens"
                          checked={searchParams.get("sizeType") === "Mens"}
                          onChange={(e) => updateSizeTypeOption(e.target.value)}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="menRadioButtonMobile"
                        >
                          Men
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sizeTypeRadioMobile"
                          id="womenRadioMobile"
                          value="Womens"
                          checked={searchParams.get("sizeType") === "Womens"}
                          onChange={(e) => updateSizeTypeOption(e.target.value)}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="womenRadioMobile"
                        >
                          Women
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            {/* <a
              href="#"
              class="link-primary text-decoration-none"
              onClick={() => navigate("/contact")}
            >
              <small>Don't see what you are looking for?</small>
            </a> */}
          </div>
          <div class="d-grid gap-2 col-10 mx-auto">
            <button
              class="btn btn-primary"
              type="button"
              data-bs-dismiss="offcanvas"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterAndSortOffcanvas;
