import React, { useEffect } from "react";
import SearchBar from "../components/SearchBar";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const SellingScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <div className="contianer mt-5 vh-100">
      <div className="row justify-content-center text-center">
        <div className="col-md-6">
          <h2 className="">
            Sell. <span className="text-muted">Choose a product</span>
          </h2>
          <p className="lead">Find the product you're looking to sell</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-11 col-lg-6">
          <SearchBar
            formClassAttributes={""}
            heightAttributes={{ height: 400 }}
            searchType={"sell"}
          ></SearchBar>
        </div>
      </div>
      <div className="row text-center mt-2">
        <Link to="/contact" class="link-primary text-decoration-none">
          <small>Don't see what you are looking for?</small>
        </Link>
      </div>
    </div>
  );
};

export default SellingScreen;
