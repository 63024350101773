import React from "react";
import AboutNavigation from "../components/AboutNavigation";

const AuthenticationScreen = () => {
  return (
    <div className="bg-light pb-5">
      <div className="container d-flex flex-row">
        <div className="mt-4 me-0 me-lg-4">
          <AboutNavigation></AboutNavigation>
        </div>
        <div className="col-lg-7 mt-4 ms-sm-5">
          <div className="px-lg-3 pt-2 mb-4 border rounded-3 bg-body-secondary">
            <div className="container-fluid  p-3 p-md-4">
              <h4 className="display-6 text-primary mb-0">
                ReShup{" "}
                <span>
                  <i class="bi bi-shield-check"></i>
                </span>
              </h4>
              <h4 className="display-6 text-primary">
                <strong>Authentication</strong>
              </h4>
              <p className="lead mt-4 ">
                Authenticity guaranteed, or your money back.
              </p>
            </div>
          </div>
          <h4 className="">How does ReShup authentication work?</h4>
          <p>
            When sellers list their items on ReShup, they participate in a
            hands-on authentication process. By physically sending us their
            sneakers, sellers demonstrate their dedication to transparency and
            authenticity. Our team of experts examines every intricate detail,
            from the stitching patterns to the logos, ensuring that each pair
            aligns with the authenticity expected by our discerning community.
          </p>
          <p>
            This physical authentication process not only guarantees the
            credibility of sellers' listings but also serves to enhance the
            trust of potential buyers. We recognize the value of every sneaker's
            narrative, and by maintaining the integrity of each pair, we
            preserve the legacy behind iconic brands like Jordan and Nike.
          </p>
          <p>
            Join our exclusive community, where every step is taken to ensure
            your passion for premium footwear is met with a genuine, trustworthy
            experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationScreen;
