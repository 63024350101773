import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileNavbar from "../components/Profile_Components/ProfileNavbar";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
import SellerCreatedListingCard from "../components/Listing_Components/SellerCreatedListingCard";
import LoadingSpinner from "../components/LoadingSpinner";
import { getListings } from "../actions/userActions";
import ProfileSelling from "../components/Profile_Components/ProfileSelling";

const ProfileSellingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, name } = userLogin;

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
    if (isAuthenticated === true) {
      dispatch(getListings());
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "My Listings"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar name={name}></ProfileNavbar>
        <ProfileSelling></ProfileSelling>
      </div>
    </>
  );
};

export default ProfileSellingScreen;
