import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import SellerCreatedListingCard from "../components/Listing_Components/SellerCreatedListingCard";
import { getUserInformation, getListings } from "../actions/userActions";
import NotFoundScreen from "../screens/NotFoundScreen";
import LoadingSpinner from "../components/LoadingSpinner";

const PublicProfileListingsScreen = () => {
  const params = useParams();
  const userId = params.id;
  const dispatch = useDispatch();

  const userInformation = useSelector((state) => state.userInformation);
  const {
    userInformation: userDetails,
    loading: userInformationLoading,
    error: userInformationError } =
    userInformation;

  const userListings = useSelector((state) => state.userListings);
  const {
    listingsLoading: userListingsLoading,
    userListings: userListingsReturned,
    error: userListingsError,
  } = userListings;

  useEffect(() => {
    dispatch(getUserInformation(userId));
    dispatch(getListings(userId));
  }, []);

  return (
    <>
      {userInformationLoading || userListingsLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : userInformationError || userListingsError ? (
        <NotFoundScreen></NotFoundScreen>
      ) : (
        <div>
          <BreadcrumbLocation location={["ReShup", "Seller"]}></BreadcrumbLocation>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-5 ">
                <div class="d-flex flex-row">
                  <div className="position-relative">
                    <img
                      src={userDetails.photo}
                      class="bd-placeholder-img rounded-circle"
                      alt=""
                      style={{ width: 75, height: 75 }}
                    ></img>
                    {/* <span class="position-absolute bottom-0 end-0 bg-success ">✓</span> */}
                  </div>
                  <div class="container">
                    <h5>{userDetails.name}</h5>
                    <div class="d-flex mb-1">
                      <i
                        class="fas fa-star"
                        aria-hidden="true"
                        style={{ color: "gold" }}
                      ></i>
                      <i
                        class="fas fa-star"
                        aria-hidden="true"
                        style={{ color: "gold" }}
                      ></i>
                      <i
                        class="fas fa-star"
                        aria-hidden="true"
                        style={{ color: "gold" }}
                      ></i>
                      <i
                        class="fas fa-star"
                        aria-hidden="true"
                        style={{ color: "gold" }}
                      ></i>
                      <i
                        class="fas fa-star"
                        aria-hidden="true"
                        style={{ color: "gold" }}
                      ></i>
                    </div>
                    <p class="mb-1 fw-light">
                      <small>Joined in {userDetails.yearJoined}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 ">
                <p>{userDetails.sellerBio}</p>
                <a
                  class="link-dark text-decoration-none dropdown-toggle"
                  data-bs-toggle="collapse"
                  data-bs-target="#publicProfileMoreCollapse"
                  style={{ cursor: "pointer" }}
                >
                  <strong>
                    <small>More about seller</small>
                  </strong>
                </a>
                <div class="collapse" id="publicProfileMoreCollapse">
                  <div className="container mt-2">
                    {userDetails.sellerTwitter && (
                      <p>
                      <i class="fab fa-twitter me-2"></i>
                      @{userDetails.sellerTwitter}
                      </p>
                    )}
                    {userDetails.sellerInstagram && (
                      <p>
                        <i class="fab fa-instagram me-2"></i>
                        @{userDetails.sellerInstagram}
                      </p>
                    )}
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                      Confirmed Email
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <h5>{userListingsReturned.length} {userListingsReturned.length == 1 ? "item" : "items"} for sale</h5>
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-1">
              {userListingsReturned.map((listing, index) => (
                <SellerCreatedListingCard
                  listingInformation={listing}
                  key={index}
                ></SellerCreatedListingCard>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicProfileListingsScreen;
