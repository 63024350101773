export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_INQUIRY_REQUEST = "USER_INQUIRY_REQUEST";
export const USER_INQUIRY_RESET = "USER_INQUIRY_RESET";
export const USER_INQUIRY_SUCCESS = "USER_INQUIRY_SUCCESS";
export const USER_INQUIRY_FAIL = "USER_INQUIRY_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOCATION_REQUEST = "USER_LOCATION_REQUEST";
export const USER_LOCATION_SUCCESS = "USER_LOCATION_SUCCESS";
export const USER_LOCATION_FAIL = "USER_LOCATION_FAIL";
export const USER_INFORMATION_REQUEST = "USER_INFORMATION_REQUEST";
export const USER_INFORMATION_SUCCESS = "USER_INFORMATION_SUCCESS";
export const USER_INFORMATION_FAIL = "USER_INFORMATION_FAIL";
export const USER_STRIPE_ACCOUNT_FAIL = "USER_STRIPE_ACCOUNT_FAIL";
export const USER_STRIPE_ACCOUNT_REQUEST = "USER_STRIPE_ACCOUNT_REQUEST";
export const USER_STRIPE_ACCOUNT_SUCCESS = "USER_STRIPE_ACCOUNT_SUCCESS";
export const USER_STRIPE_LINK_FAIL = "USER_STRIPE_LINK_FAIL";
export const USER_STRIPE_LINK_REQUEST = "USER_STRIPE_LINK_REQUEST";
export const USER_STRIPE_LINK_SUCCESS = "USER_STRIPE_LINK_SUCCESS";
export const USER_LISTINGS_FAIL = "USER_LISTINGS_FAIL";
export const USER_LISTINGS_REQUEST = "USER_LISTINGS_REQUEST";
export const USER_LISTINGS_SUCCESS = "USER_LISTINGS_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_LIKE_REQUEST = "USER_LIKE_REQUEST";
export const USER_LIKE_RESET = "USER_LIKE_RESET";
export const USER_LIKED_STATUS_REQUEST = "USER_LIKED_STATUS_REQUEST";
export const USER_LIKED_LISTINGS_REQUEST = "USER_LIKED_LISTINGS_REQUEST";
export const USER_LIKE_SUCCESS = "USER_LIKE_SUCCESS";
export const USER_UNLIKE_SUCCESS = "USER_UNLIKE_SUCCESS";
export const USER_LIKED_STATUS_SUCCESS = "USER_LIKED_STATUS_SUCCESS";
export const USER_LIKED_LISTINGS_SUCCESS = "USER_LIKED_LISTINGS_SUCCESS";
export const USER_LIKE_FAIL = "USER_LIKE_FAIL";
