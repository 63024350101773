export const GET_BUYER_ORDERS_REQUEST = "GET_BUYER_ORDERS_REQUEST";
export const GET_BUYER_ORDERS_SUCCESS = "GET_BUYER_ORDERS_SUCCESS";
export const GET_BUYER_ORDERS_FAIL = "GET_BUYER_ORDERS_FAIL";
export const GET_ADMIN_ORDERS_REQUEST = "GET_ADMIN_ORDERS_REQUEST";
export const GET_ADMIN_ORDERS_SUCCESS = "GET_ADMIN_ORDERS_SUCCESS";
export const GET_ADMIN_ORDERS_FAIL = "GET_ADMIN_ORDERS_FAIL";
export const GET_BUYER_ORDERS_COMPLETED_REQUEST =
  "GET_BUYER_ORDERS_COMPLETED_REQUEST";
export const GET_BUYER_ORDERS_COMPLETED_SUCCESS =
  "GET_BUYER_ORDERS_COMPLETED_SUCCESS";
export const GET_BUYER_ORDERS_COMPLETED_FAIL =
  "GET_BUYER_ORDERS_COMPLETED_FAIL";
export const GET_BUYER_ORDER_INFORMATION_REQUEST =
  "GET_BUYER_ORDER_INFORMATION_REQUEST";
export const GET_BUYER_ORDER_INFORMATION_SUCCESS =
  "GET_BUYER_ORDER_INFORMATION_SUCCESS";
export const GET_BUYER_ORDER_INFORMATION_FAIL =
  "GET_BUYER_ORDER_INFORMATION_FAIL";
export const GET_ADMIN_ORDER_INFORMATION_REQUEST =
  "GET_ADMIN_ORDER_INFORMATION_REQUEST";
export const GET_ADMIN_ORDER_INFORMATION_SUCCESS =
  "GET_ADMIN_ORDER_INFORMATION_SUCCESS";
export const GET_ADMIN_ORDER_INFORMATION_FAIL =
  "GET_ADMIN_ORDER_INFORMATION_FAIL";
export const UPDATE_ADMIN_ORDER_INFORMATION_REQUEST =
  "UPDATE_ADMIN_ORDER_INFORMATION_REQUEST";
export const UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS =
  "UPDATE_ADMIN_ORDER_INFORMATION_SUCCESS";
export const UPDATE_ADMIN_ORDER_INFORMATION_FAIL =
  "UPDATE_ADMIN_ORDER_INFORMATION_FAIL";
export const GET_SELLER_ORDERS_REQUEST = "GET_SELLER_ORDERS_REQUEST";
export const GET_SELLER_ORDERS_SUCCESS = "GET_SELLER_ORDERS_SUCCESS";
export const GET_SELLER_ORDERS_FAIL = "GET_SELLER_ORDERS_FAIL";
export const GET_SELLER_ORDERS_COMPLETED_REQUEST =
  "GET_SELLER_ORDERS_COMPLETED_REQUEST";
export const GET_SELLER_ORDERS_COMPLETED_SUCCESS =
  "GET_SELLER_ORDERS_COMPLETED_SUCCESS";
export const GET_SELLER_ORDERS_COMPLETED_FAIL =
  "GET_SELLER_ORDERS_COMPLETED_FAIL";
export const GET_SELLER_ORDER_INFORMATION_REQUEST =
  "GET_SELLER_ORDER_INFORMATION_REQUEST";
export const GET_SELLER_ORDER_INFORMATION_SUCCESS =
  "GET_SELLER_ORDER_INFORMATION_SUCCESS";
export const GET_SELLER_ORDER_INFORMATION_FAIL =
  "GET_SELLER_ORDERS_INFORMATION_FAIL";
