import React from "react";
import { Link } from "react-router-dom";
import AboutNavigation from "../components/AboutNavigation";

const AboutScreen = () => {
  return (
    <div className=" pb-5">
      <div className="container d-flex flex-row">
        <div className="mt-4 me-0 me-lg-4">
          <AboutNavigation></AboutNavigation>
        </div>
        <div className="col-lg-7 mt-4 ms-sm-5">
          <div className="px-lg-5 pt-5  mb-4  border rounded-3 about-jumbotron-cover">
            <div className="container-fluid pt-5 my-5">
              {/* <h3 className="display-5 fw-bold">What is ReShup</h3>
              <p className="">
                ReShup is a virtual marketplace tailored for sneaker
                enthusiasts, offering a seamless platform to buy and sell
                coveted footwear, creating a community-driven ecosystem.
              </p> */}
              <h3 className="display-5 fw-bold text-dark my-5"></h3>
              <p className="my-5"></p>
            </div>
          </div>
          <h3>What is ReShup?</h3>
          <p>
            <small>
              ReShup is an exclusive online platform providing a niche
              marketplace for high-end sneakers, where sellers can list items,
              upload photos, and set prices. Our rigorous verification process
              ensures the authenticity of each pair, providing buyers with a
              curated selection of footwear they can trust.
            </small>
          </p>
          <p>
            <small>
              Buyers on ReShup enjoy the flexibility to make offers, fostering
              fair negotiations in our dynamic marketplace. With a user-friendly
              interface, we simplify transactions and cultivate a vibrant
              community of sneaker enthusiasts who share a passion for
              authenticity and style. Join ReShup to experience luxury and trust
              in the world of exclusive footwear.
            </small>
          </p>
          {/* <h4 className="">Turn Your Passion into Profit</h4> */}
          {/* <div className="row justify-content-center">
            <div className="col-md-6 mb-3">
              <div className="p-3 bg-subtleprimary rounded h-100">
                <h4>Sellers</h4>

                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Find Your Sneakers</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Browse our curated database to find the exact shoes
                        you're selling. Our comprehensive collection ensures
                        authenticity and trust for every pair.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Take Some Photos</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Take high-quality photos of your sneakers to showcase
                        their unique features. Craft a compelling listing by
                        providing essential details and setting a competitive
                        price.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Your Selling Experience</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Engage with potential buyers by accepting offers and
                        negotiating securely. Manage your listings and
                        preferences effortlessly in your dashboard.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-subtleprimary rounded">
                <h4>Buyers</h4>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Explore Your Favorites</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Dive into our curated selection to discover the sneakers
                        you've been dreaming of. With a diverse range of
                        options, finding your perfect pair is just a few clicks
                        away.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Shop with Confidence</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Trust in our authentication process. Every pair listed
                        on our platform will undergo rigorous scrutiny,
                        guaranteeing that what you see is what you get.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Connect with Sellers</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Engage directly with sellers to discover the perfect
                        pair of sneakers for you. Whether you want to make an
                        offer or purchase instantly.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-12 mt-3">
            <div className="row justify-content-center g-3">
              <div className="col-md-4 ">
                <div className="d-flex align-items-start">
                  
                  <h4 className="p-2">
                    <i class="bi bi-phone"></i>
                  </h4>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Sign Up</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Kickstart your selling journey by signing up on our
                        marketplace. Seamlessly link your Stripe account to
                        ensure secure and swift payouts directly into your bank
                        account.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="d-flex align-items-start">
                  <h4 className="p-2">
                    <i class="bi bi-bag-check"></i>
                  </h4>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Sell It</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Take some time to photograph your sneakers. Provide
                        prospective buyers with details such as the brand,
                        model, size, and condition to create a compelling
                        listing.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="d-flex align-items-start">
                  <h4 className="p-2">
                    <i class="bi bi-cash"></i>
                  </h4>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Get Paid</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Celebrate a successful sale! Your hard-earned earnings
                        will be deposited into your linked Stripe account,
                        providing you with a hassle-free and secure payout
                        experience.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <h3 className="text-center"> How does ReShup work?</h3> */}
          {/* <div class="row mt-lg-5 mt-3">
            <div className="col-md-6">
              <div className="p-3 bg-subtleprimary rounded">
                <h3>Sellers</h3>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Find Your Sneakers</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Browse our curated database to find the exact shoes
                        you're selling. Our comprehensive collection ensures
                        authenticity and trust.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Take Some Photos</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Take high-quality photos of your sneakers to showcase
                        their unique features. Craft a compelling listing by
                        providing essential details and setting a competitive
                        price.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">
                      Customize Your Selling Experience
                    </p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Engage with potential buyers by accepting offers and
                        negotiating securely. Manage your listings and
                        preferences effortlessly in your dashboard.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-body-secondary rounded">
                <h3>Buyers</h3>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Find Your Sneakers</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Browse our curated database to find the exact shoes
                        you're selling. Our comprehensive collection ensures
                        authenticity and trust.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Take Some Photos</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Take high-quality photos of your sneakers to showcase
                        their unique features. Craft a compelling listing by
                        providing essential details and setting a competitive
                        price.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">
                      Customize Your Selling Experience
                    </p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Engage with potential buyers by accepting offers and
                        negotiating securely. Manage your listings and
                        preferences effortlessly in your dashboard.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div>
            <div class="row mt-lg-5 mt-3">
              <div class="col-md-7">
                <h4 class="fw-normal lh-1">
                  Easy Listing{" "}
                  <span class="text-body-secondary">in a Snap</span>
                </h4>
                <p class="lead">
                  ReShup makes listing as easy as 1-2-3! Snap a photo of the
                  item you want to sell with your phone or upload a photo from
                  your computer, fill in a description, set the price, and
                  you're done!
                </p>
              </div>
              <div class="col-md-5">
                <svg
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect
                    width="100%"
                    height="100%"
                    fill="var(--bs-secondary-bg)"
                  />
                  <text
                    x="50%"
                    y="50%"
                    fill="var(--bs-secondary-color)"
                    dy=".3em"
                  >
                    500x500
                  </text>
                </svg>
              </div>
            </div>
            <hr></hr>
            <div class="row featurette">
              <div class="col-md-7 order-md-2">
                <h4 class="featurette-heading fw-normal lh-1">
                  Discover{" "}
                  <span class="text-body-secondary">Your Sole Mate</span>
                </h4>
                <p class="lead">
                  Explore a curated collection of premium sneakers on ReShup.
                  Scroll through detailed listings, view high-quality images,
                  and find the perfect pair that matches your style.
                </p>
              </div>
              <div class="col-md-5 order-md-1">
                <svg
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect
                    width="100%"
                    height="100%"
                    fill="var(--bs-secondary-bg)"
                  />
                  <text
                    x="50%"
                    y="50%"
                    fill="var(--bs-secondary-color)"
                    dy=".3em"
                  >
                    500x500
                  </text>
                </svg>
              </div>
            </div>
            <hr></hr>
            <div class="row featurette">
              <div class="col-md-7">
                <h4 class="featurette-heading fw-normal lh-1">
                  Ship with <span class="text-body-secondary">Ease</span>
                </h4>
                <p class="lead">
                  Selling on ReShup means hassle-free shipping. Upon sale,
                  generate a shipping label effortlessly, ensuring a smooth and
                  secure delivery process for both buyers and sellers.
                </p>
              </div>
              <div class="col-md-5">
                <svg
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect
                    width="100%"
                    height="100%"
                    fill="var(--bs-secondary-bg)"
                  />
                  <text
                    x="50%"
                    y="50%"
                    fill="var(--bs-secondary-color)"
                    dy=".3em"
                  >
                    500x500
                  </text>
                </svg>
              </div>
            </div>
            <hr></hr>
            <div class="row featurette">
              <div class="col-md-7 order-md-2">
                <h4 class="featurette-heading fw-normal lh-1">
                  Seal the <span class="text-body-secondary">Deal</span>
                </h4>
                <p class="lead">
                  Want to negotiate? No problem. ReShup allows buyers to make
                  secure offers, fostering fair negotiations and ensuring that
                  both parties are satisfied with the deal.
                </p>
              </div>
              <div class="col-md-5 order-md-1">
                <svg
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect
                    width="100%"
                    height="100%"
                    fill="var(--bs-secondary-bg)"
                  />
                  <text
                    x="50%"
                    y="50%"
                    fill="var(--bs-secondary-color)"
                    dy=".3em"
                  >
                    500x500
                  </text>
                </svg>
              </div>
            </div>
            <hr></hr>
            <div class="row featurette">
              <div class="col-md-7">
                <h4 class="featurette-heading fw-normal lh-1">
                  Only the Real <span class="text-body-secondary">Deal</span>
                </h4>
                <p class="lead">
                  Trust ReShup for authenticity. Every pair of sneakers
                  undergoes a rigorous verification process, ensuring that you
                  receive only genuine, high-end footwear. Buy and sell with
                  confidence in our exclusive sneaker community.
                </p>
              </div>
              <div class="col-md-5">
                <svg
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect
                    width="100%"
                    height="100%"
                    fill="var(--bs-secondary-bg)"
                  />
                  <text
                    x="50%"
                    y="50%"
                    fill="var(--bs-secondary-color)"
                    dy=".3em"
                  >
                    500x500
                  </text>
                </svg>
              </div>
            </div>
          </div> */}
          <div class="table-responsive">
            <table class="table text-center">
              <thead>
                <tr>
                  <th></th>
                  <th>Seller fee</th>
                  <th>Commission fee</th>
                  <th>Processing fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="text-start">
                    ReShup
                  </th>
                  <td>$5</td>
                  <td>5.5%</td>
                  <td>3.0%</td>
                </tr>
                <tr>
                  <th scope="row" class="text-start">
                    StockX
                  </th>
                  <td>$4</td>
                  <td>9.0%</td>
                  <td>3.0%</td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <th scope="row" class="text-start">
                    GOAT
                  </th>
                  <td>$5</td>
                  <td>9.5%</td>
                  <td>2.9%</td>
                </tr>
                <tr>
                  <th scope="row" class="text-start">
                    Stadium Goods
                  </th>
                  <td>$15</td>
                  <td>20%</td>
                  <td>1.0%</td>
                </tr>
                <tr>
                  <th scope="row" class="text-start">
                    Ebay
                  </th>
                  <td>No prepaid label</td>
                  <td>8%</td>
                  <td>No prepaid label</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row row-cols-1 row-cols-md-3 mb-3 text-center mt-3">
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-2">
                  <h5 class="my-0 fw-normal">Level 1</h5>
                </div>
                <div class="card-body">
                  {/* <h1 class="card-title pricing-card-title">
                    $0<small class="text-body-secondary fw-light">/mo</small>
                  </h1> */}
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>$5 Seller fee</li>
                    <li>5.5% Commission fee</li>
                    <li>3% Processing fee</li>
                    <li>Prepaid labels</li>
                    <li>Unlimited listings</li>
                  </ul>
                  <Link to="/login" className="w-100 btn btn-outline-primary">
                    Get started
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-2">
                  <h5 class="my-0 fw-normal">Level 2</h5>
                </div>
                <div class="card-body">
                  {/* <h1 class="card-title pricing-card-title">
                    $15<small class="text-body-secondary fw-light">/mo</small>
                  </h1> */}
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>$5 Seller fee</li>
                    <li>5.0% Commission fee</li>
                    <li>3% Processing fee</li>
                    <li>Prepaid labels</li>
                    <li>Unlimited listings</li>
                  </ul>
                  <Link to="/login" className="w-100 btn btn-primary">
                    Get started
                  </Link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-4 rounded-3 shadow-sm border-primary">
                <div class="card-header py-2 text-bg-primary border-primary">
                  <h5 class="my-0 fw-normal">Level 3</h5>
                </div>
                <div class="card-body">
                  {/* <h1 class="card-title pricing-card-title">
                    $29<small class="text-body-secondary fw-light">/mo</small>
                  </h1> */}
                  <ul class="list-unstyled mt-2 mb-4">
                    <li>$5 Seller fee</li>
                    <li>5.0% Commission fee</li>
                    <li>3% Processing fee</li>
                    <li>Ship directly to customers</li>
                    <li>Unlimited listings</li>
                  </ul>
                  <Link to="/login" className="w-100 btn btn-primary">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutScreen;
