import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketplaceListing,
  filterSellerListings,
  filterMoreSellerListings,
  getSellerListingsSizes,
  getCheapestSellerListings,
} from "../actions/marketplaceActions";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import SellerListingsFilterOffcanvas from "../components/Listing_Components/SellerListingsFilterOffcanvas";
import CheapestSellerListingsOffcanvas from "../components/CheapestSellerListingsOffcanvas";
import SellerCreatedListingCard from "../components/Listing_Components/SellerCreatedListingCard";
import LocationModal from "../components/Listing_Components/LocationModal";
import LoadingSpinner from "../components/LoadingSpinner";
import PaginationComponent from "../components/PaginationComponent";
import SellerListingsFiltersDesktop from "../components/Listing_Components/SellerListingsFiltersDesktop";
import ListingSortDropdownDesktop from "../components/Listing_Components/ListingSortDropdownDesktop";
import NotFoundScreen from "../screens/NotFoundScreen";
import { SELLER_LISTINGS_FILTER_RESET } from "../constants/marketplaceConstants";
const ListingScreen = () => {
  const observer = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const listingId = params.id;
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [distance, setDistance] = useState("20");
  const locationInformation = useSelector((state) => state.location);
  const { location } = locationInformation;

  const marketplaceListing = useSelector((state) => state.marketplaceListing);
  const { listingLoading, listingInformation, error } = marketplaceListing;

  const sellerListingsMostAvailableSize = useSelector(
    (state) => state.sellerListingsSizes
  );
  const {
    listingLoadings: mostAvailableSizeLoading,
    mostAvailableSize,
    sizes,
  } = sellerListingsMostAvailableSize;

  const sellerListings = useSelector((state) => state.sellerListings);
  const {
    listingsLoading: sellerListingsLoading,
    sellerListings: filteredSellerListings,
    totalDocuments,
  } = sellerListings;

  const lastListingRef = useCallback(
    (listingNode) => {
      const hasMore = !(filteredSellerListings.length === totalDocuments);
      if (sellerListingsLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (listingNode) observer.current.observe(listingNode);
    },
    [sellerListingsLoading, filteredSellerListings]
  );

  useEffect(() => {
    // if (sizes == null || mostAvailableSize == null) {
    // }
    setPageNumber(1);
    if (observer.current) observer.current.disconnect();
    // dispatch(getSellerListingsSizes(listingId));
    // dispatch(getMarketplaceListing(listingId));
    dispatch(getSellerListingsSizes(listingId));
    dispatch(getMarketplaceListing(listingId));
    dispatch(getCheapestSellerListings(listingId));
    dispatch({ type: SELLER_LISTINGS_FILTER_RESET });
    dispatch(
      filterSellerListings(
        listingId,
        searchParams.getAll("sizes"),
        searchParams.getAll("price"),
        searchParams.getAll("sort"),
        1
      )
    );
    // // Get the element's position relative to the viewport
    // const { top } = elementRef.current.getBoundingClientRect();

    // // Calculate the desired scroll position with an offset (e.g., 50 pixels)
    // const newScrollPosition = top + window.scrollY - 50;

    // // Scroll to the element with the offset
    // window.scroll({ top: newScrollPosition, left: 0, behavior: "auto" });
  }, [dispatch, searchParams, listingId]);

  useEffect(() => {
    if (pageNumber > 1) {
      dispatch(
        filterMoreSellerListings(
          listingId,
          searchParams.getAll("sizes"),
          searchParams.getAll("price"),
          searchParams.getAll("sort"),
          pageNumber
        )
      );
    }
  }, [pageNumber]);
  const addToLocalStorage = (key, value, limit = 6) => {
    if (localStorage) {
      const existingItems = localStorage.getItem(key);
      let items = existingItems ? JSON.parse(existingItems) : [];

      // Check if the item with the same _id already exists
      const existingItem = items.find((item) => item._id === value._id);

      if (!existingItem) {
        // Add the new item to the beginning of the list
        items.unshift(value);

        // Limit the list to the specified maximum number of items
        if (items.length > limit) {
          items = items.slice(0, limit);
        }

        localStorage.setItem(key, JSON.stringify(items));
      }
    }
  };
  return (
    <>
      {error ? (
        <NotFoundScreen></NotFoundScreen>
      ) : (
        <div>
          <LocationModal
            distance={distance}
            setDistance={setDistance}
          ></LocationModal>
          <SellerListingsFilterOffcanvas></SellerListingsFilterOffcanvas>
          <CheapestSellerListingsOffcanvas></CheapestSellerListingsOffcanvas>
          <div className="container mt-lg-4">
            {listingLoading ? (
              // <LoadingSpinner></LoadingSpinner>
              <div className="row">
                <div className="col-md-6 mt-0 p-2">
                  <div class="card mx-1">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-0 p-2 d-none d-md-block">
                  <div class="card mx-1">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="mt-3 mt-md-0 text-capitalize">
                    {listingInformation.listingName}
                  </h3>

                  <div class="d-flex">
                    <a
                      // type="button"
                      className="d-none d-md-block btn btn-outline-dark flex-shrink-1"
                      // onClick={() => navigate(`/sell/${listingId}`)}
                      id="listingsAvailableScrollButton"
                      href="#scrollspyListingsAvailable"
                    >
                      Buy for ${Math.floor(listingInformation.lowestPrice)}
                    </a>
                  </div>

                  {/* <button
                    type="button"
                    className="d-md-none btn btn-primary btn-sm me-3"
                    onClick={() => navigate(`/sell/${listingId}`)}
                  >
                    Buy for
                  </button> */}
                  <button
                    type="button"
                    className="d-md-none btn btn-outline-dark btn-sm"
                    // onClick={() => navigate(`/sell/${listingId}`)}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#cheapestSellerListingsOffcanvas"
                  >
                    Buy for ${Math.floor(listingInformation.lowestPrice)}
                  </button>
                  <img
                    src={listingInformation.photo}
                    className="img-fluid border-md-bottom"
                    alt="..."
                  ></img>
                  <p className="text-muted border-bottom mb-1 pb-1">
                    Have one to sell?{" "}
                    <Link className="link-dark" to={`/sell/${listingId}`}>
                      Sell now
                    </Link>
                  </p>
                </div>
                <div className="d-none d-md-block col-lg-6">
                  <div className="container">
                    <h4 className="border-bottom mt-3 pb-1 mt-lg-0">
                      About This Shoe
                    </h4>
                    <div className="row justify-content-evenly">
                      <div className="col-3 pt-2 m-1 bg-light shadow">
                        <h3 className="fw-light mb-1">
                          {listingInformation.listingsAvailable}
                        </h3>
                        <p>Listings Available</p>
                      </div>
                      <div className="col-3 pt-2 m-1 bg-light shadow">
                        <h3 className="fw-light mb-1">
                          ${Math.floor(listingInformation.averagePrice)}
                        </h3>
                        <p>Average price</p>
                      </div>
                      <div className="col-3 pt-2 m-1 bg-light shadow">
                        <h3 className="fw-light mb-1">
                          ${Math.floor(listingInformation.priceDeviation)}
                        </h3>
                        <p>Price Deviation</p>
                      </div>
                    </div>
                    <div className="row justify-content-evenly">
                      {mostAvailableSizeLoading ? (
                        <LoadingSpinner></LoadingSpinner>
                      ) : (
                        <div className="col-3 pt-2 m-1 bg-light shadow">
                          <h3 className="fw-light mb-1">
                            {mostAvailableSize}{" "}
                            {listingInformation.sizeType == "Mens" ? "M" : "W"}
                          </h3>
                          <p>Most Available </p>
                        </div>
                      )}
                      <div className="col-3 pt-2 m-1 bg-light shadow">
                        <h3 className="fw-light mb-1">
                          ${Math.floor(listingInformation.highestPrice)}
                        </h3>
                        <p>Highest price</p>
                      </div>
                      <div className="col-3 pt-2 m-1 bg-light shadow">
                        <h3 className="fw-light mb-1">
                          ${Math.floor(listingInformation.lowestPrice)}
                        </h3>
                        <p>Lowest price</p>
                      </div>
                      {/* <label for="customRange3" className="form-label">
                      Example range
                      </label>
                      <input
                      type="range"
                      className="form-range"
                      min={Math.floor(listingInformation.lowestPrice)}
                      max={Math.floor(listingInformation.highestPrice)}
                      step={Math.floor(listingInformation.priceDeviation)}
                      id="customRange3"
                    ></input> */}
                    </div>
                    <h5 className="border-bottom mt-3 pb-1">Product Details</h5>
                    <div className="container">
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Brand:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.brand}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Make:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.make}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Colorway:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.colorway}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Retail Price:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">
                            ${listingInformation.retailPrice}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Release Date:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">
                            {listingInformation.dateString}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Style Id:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.styleId}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Size Type:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.sizeType}</p>
                        </div>
                      </div>
                    </div>
                    <h5 className="border-bottom mt-3 pb-1">
                      Product Description
                    </h5>
                    <p className="fw-light">{listingInformation.description}</p>
                  </div>
                </div>
                <div className="col d-md-none">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                        >
                          Product details
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Brand:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">{listingInformation.brand}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Make:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">{listingInformation.make}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Colorway:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">
                                {listingInformation.colorway}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Retail Price:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">
                                ${listingInformation.retailPrice}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Release Date:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">
                                {listingInformation.dateString}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Style Id:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">
                                {listingInformation.styleId}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <p className="text-muted mb-1">Size Type:</p>
                            </div>
                            <div className="col-7">
                              <p className="mb-1">
                                {listingInformation.sizeType}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                        >
                          Product description
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>
                            <small>{listingInformation.description}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                        >
                          About this shoe
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="row g-0">
                            <div className="d-flex border-bottom p-2 ">
                              <p className="me-auto mb-0">Listings available</p>
                              <p className="mb-0">
                                {listingInformation.listingsAvailable}
                              </p>
                            </div>

                            {mostAvailableSizeLoading ? (
                              <LoadingSpinner></LoadingSpinner>
                            ) : (
                              <div className="d-flex border-bottom p-2 ">
                                <p className="me-auto mb-0">
                                  Most Available Size
                                </p>
                                <p className="mb-0">
                                  {mostAvailableSize}{" "}
                                  {listingInformation.sizeType == "Mens"
                                    ? "M"
                                    : "W"}
                                </p>
                              </div>
                            )}
                            <div className="d-flex border-bottom p-2 ">
                              <p className="me-auto mb-0">Highest price</p>
                              <p className="mb-0">
                                ${Math.floor(listingInformation.highestPrice)}
                              </p>
                            </div>
                            <div className="d-flex border-bottom p-2 ">
                              <p className="me-auto mb-0">Lowest price</p>
                              <p className="mb-0">
                                ${Math.floor(listingInformation.lowestPrice)}
                              </p>
                            </div>

                            <div className="d-flex border-bottom p-2 ">
                              <p className="me-auto mb-0">Average price</p>
                              <p className="mb-0">
                                ${Math.floor(listingInformation.averagePrice)}
                              </p>
                            </div>

                            <div className="d-flex border-bottom p-2 ">
                              <p className="me-auto mb-0">Price deviation</p>
                              <p className="mb-0">
                                ${Math.floor(listingInformation.priceDeviation)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="">
              <hr
                class="border border-5 opacity-25"
                id="scrollspyListingsAvailable"
              ></hr>
              <div className="d-lg-none mt-3">
                <div className="d-flex">
                  <div className="me-auto">
                    <a
                      className="text-decoration-none text-reset dropdown-toggle d-lg-none"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasFilterSellerListings"
                      role="button"
                    >
                      Filters
                    </a>
                  </div>
                  <div className="me-2">
                    <ListingSortDropdownDesktop></ListingSortDropdownDesktop>
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                {/* <a
                  href=""
                  className="text-decoration-none link-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#locationModal"
                >
                  {location ? (
                    <p className="fs-5 mb-0">
                      <small>
                        Current Location - Within {distance} Miles{" "}
                        <i className="fas fa-thumbtack"></i>
                      </small>
                    </p>
                  ) : (
                    <p className="fs-5 mb-0">
                      <small>
                        Get Current Location{" "}
                        <i className="fas fa-thumbtack"></i>
                      </small>
                    </p>
                  )}
                </a> */}
                <p className="fs-5 mb-1">
                  <small>Available Listings</small>
                </p>
                {/* <h5 className="mb-1">Available Listings</h5> */}
              </div>
              <div className="row">
                <div className="col-lg-3 d-none d-lg-block">
                  <div class="position-sticky" style={{ top: "6rem" }}>
                    <SellerListingsFiltersDesktop></SellerListingsFiltersDesktop>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="">
                    <div className="row ms-1 mb-2 d-none d-lg-flex">
                      <div className="d-flex">
                        <div className="me-auto">
                          {/* <a
                            href=""
                            className="text-decoration-none link-dark"
                            data-bs-toggle="modal"
                            data-bs-target="#locationModal"
                          >
                            {location ? (
                              <h5>
                                Current Location - Within {distance} Miles{" "}
                                <i className="fas fa-thumbtack"></i>
                              </h5>
                            ) : (
                              <h5>
                                Get Current Location{" "}
                                <i className="fas fa-thumbtack"></i>
                              </h5>
                            )}
                          </a> */}

                          <p className="fs-4 mb-0">Available Listings</p>
                        </div>
                        <div className="me-2">
                          <ListingSortDropdownDesktop></ListingSortDropdownDesktop>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 ">
                        {filteredSellerListings.map((element, index) => {
                          if (filteredSellerListings.length === index + 1) {
                            return (
                              // <div ref={lastListingRef} key={index}>
                              //   <SellerCreatedListingCard
                              //     listingInformation={element}
                              //   ></SellerCreatedListingCard>
                              // </div>
                              <div
                                className="col mt-0 p-2"
                                key={"SellerListingCard" + index}
                                ref={lastListingRef}
                              >
                                <div className="card p-2 position-relative h-100">
                                  {element.productAvailable != null &&
                                    !element.productAvailable && (
                                      <span class="position-absolute top-0 start-0 badge text-bg-dark m-1">
                                        SOLD
                                      </span>
                                    )}
                                  <img
                                    src={element.photos[0]}
                                    className="card-img-top"
                                    alt="..."
                                  ></img>
                                  <div className="card-body mt-1 bg-light p-1">
                                    <h5 className="card-title">
                                      $ {element.price}
                                    </h5>
                                    <p
                                      className="card-text lh-sm mb-1"
                                      style={{ fontSize: 13 }}
                                    >
                                      {element.title}
                                    </p>
                                    <p
                                      className="card-text mb-1"
                                      style={{ fontSize: 13 }}
                                    >
                                      Size:{" "}
                                      <small className="text-muted">
                                        {element.size}
                                      </small>
                                    </p>
                                    <div className="d-flex flex-row">
                                      <img
                                        src={element.sellerInformation.photo}
                                        className="rounded-circle me-2"
                                        style={{
                                          height: 25,
                                          width: 25,
                                          zIndex: 30,
                                        }}
                                      ></img>
                                      <p
                                        className="card-text"
                                        style={{ fontSize: 13 }}
                                      >
                                        <small className="text-muted">
                                          {element.sellerInformation.name}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                  <Link
                                    to={`/sellerlisting/${element._id}`}
                                    className="stretched-link"
                                    onClick={() =>
                                      addToLocalStorage(
                                        "recentlyViewed",
                                        element
                                      )
                                    }
                                  ></Link>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <SellerCreatedListingCard
                                listingInformation={element}
                                key={"SellerListingCard" + index}
                              ></SellerCreatedListingCard>
                            );
                          }
                        })}

                        {sellerListingsLoading && (
                          <>
                            <div className="col mt-0 p-2">
                              <div class="card mx-1">
                                <div class="card-body">
                                  <h5 class="card-title placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                  </h5>
                                  <p class="card-text placeholder-glow">
                                    <span class="placeholder col-7"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-6"></span>
                                    <span class="placeholder col-8"></span>
                                  </p>
                                  <a
                                    class="btn btn-primary disabled placeholder col-6"
                                    aria-disabled="true"
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div className="col mt-0 p-2">
                              <div class="card mx-1">
                                <div class="card-body">
                                  <h5 class="card-title placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                  </h5>
                                  <p class="card-text placeholder-glow">
                                    <span class="placeholder col-7"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-6"></span>
                                    <span class="placeholder col-8"></span>
                                  </p>
                                  <a
                                    class="btn btn-primary disabled placeholder col-6"
                                    aria-disabled="true"
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div className="col mt-0 p-2">
                              <div class="card mx-1">
                                <div class="card-body">
                                  <h5 class="card-title placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                  </h5>
                                  <p class="card-text placeholder-glow">
                                    <span class="placeholder col-7"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-6"></span>
                                    <span class="placeholder col-8"></span>
                                  </p>
                                  <a
                                    class="btn btn-primary disabled placeholder col-6"
                                    aria-disabled="true"
                                  ></a>
                                </div>
                              </div>
                            </div>
                            <div className="col mt-0 p-2">
                              <div class="card mx-1">
                                <div class="card-body">
                                  <h5 class="card-title placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                  </h5>
                                  <p class="card-text placeholder-glow">
                                    <span class="placeholder col-7"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-4"></span>
                                    <span class="placeholder col-6"></span>
                                    <span class="placeholder col-8"></span>
                                  </p>
                                  <a
                                    class="btn btn-primary disabled placeholder col-6"
                                    aria-disabled="true"
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {filteredSellerListings.length === 0 &&
                        !sellerListingsLoading && (
                          <section class="py-5 text-center container">
                            <div class="row py-lg-5">
                              <div class="col-lg-6 col-md-8 mx-auto">
                                <p class="lead text-body-secondary">
                                  Looks like there is nothing matching your
                                  search.
                                </p>
                                <p>
                                  <Link
                                    to={`/listing/${listingId}`}
                                    class="btn btn-primary"
                                  >
                                    Go to home
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListingScreen;
