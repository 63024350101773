import React from "react";
import { Link } from "react-router-dom";
const SellerCreatedListingCard = ({ listingInformation }) => {
  const {
    _id,
    photos,
    price,
    size,
    title,
    sellerInformation,
    productAvailable,
  } = listingInformation;

  const addToLocalStorage = (key, value, limit = 6) => {
    if (localStorage) {
      const existingItems = localStorage.getItem(key);
      let items = existingItems ? JSON.parse(existingItems) : [];

      // Check if the item with the same _id already exists
      const existingItem = items.find((item) => item._id === value._id);

      if (!existingItem) {
        // Add the new item to the beginning of the list
        items.unshift(value);

        // Limit the list to the specified maximum number of items
        if (items.length > limit) {
          items = items.slice(0, limit);
        }

        localStorage.setItem(key, JSON.stringify(items));
      }
    }
  };

  return (
    <div className="col mt-0 p-2">
      <div className="card p-2 position-relative h-100">
        {productAvailable != null && !productAvailable && (
          <span class="position-absolute top-0 start-0 badge text-bg-dark m-1">
            SOLD
          </span>
        )}
        <img src={photos[0]} className="card-img-top" alt="..."></img>
        <div className="card-body mt-1 bg-light p-1">
          <h5 className="card-title">$ {price}</h5>
          <p className="card-text lh-sm mb-1" style={{ fontSize: 13 }}>
            {title}
          </p>
          <p className="card-text mb-1" style={{ fontSize: 13 }}>
            Size: <small className="text-muted">{size}</small>
          </p>
          <div className="d-flex flex-row">
            <img
              src={sellerInformation.photo}
              className="rounded-circle me-2"
              style={{ height: 25, width: 25, zIndex: 30 }}
            ></img>
            <p className="card-text" style={{ fontSize: 13 }}>
              <small className="text-muted">{sellerInformation.name}</small>
            </p>
          </div>
        </div>
        <Link
          to={`/sellerlisting/${_id}`}
          className="stretched-link"
          onClick={() =>
            addToLocalStorage("recentlyViewed", listingInformation)
          }
        ></Link>
      </div>
    </div>
  );
};

export default SellerCreatedListingCard;
