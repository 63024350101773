import React from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ListingSortDropdownDesktop = () => {
  const possibleSorts = {
    popular: "Popular",
    new: "Recently Added",
    "high-low": "Price (High-Low)",
    "low-high": "Price (Low-High)",
  };
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSortOption = (sort) => {
    var tempSearchParams = searchParams;
    tempSearchParams.set("sort", sort);
    setSearchParams(tempSearchParams);
  };
  return (
    <div className="dropdown">
      <Link
        to="#"
        className="text-decoration-none text-reset dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        Sort By: {possibleSorts[searchParams.get("sort")] || "Default"}
      </Link>
      <ul className="dropdown-menu text-small shadow">
        <li>
          <button
            className="dropdown-item"
            href="#"
            onClick={(event) => updateSortOption("default")}
          >
            Default
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={(event) => updateSortOption("new")}
          >
            Recently Added
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={(event) => updateSortOption("high-low")}
          >
            Price (High-Low)
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            to="#"
            onClick={(event) => updateSortOption("low-high")}
          >
            Price (Low-High)
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ListingSortDropdownDesktop;
