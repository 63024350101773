import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
import ProfileNavbar from "../components/Profile_Components/ProfileNavbar";
import ProfileOffers from "../components/Profile_Components/ProfileOffers";
import { getOffersForPurchase } from "../actions/offerActions";
import { getUserInformation } from "../actions/userActions";

const ProfileOffersScreen = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  useEffect(() => {
    if (isAuthenticated === false) {
      // dispatch(getUserInformation());
      navigate(`/login?redirect=${location.pathname}`);
    }
    if (isAuthenticated === true) {
      dispatch(getOffersForPurchase());
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Offers"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar></ProfileNavbar>
        <ProfileOffers></ProfileOffers>
      </div>
    </>
    // <div className="container">
    //   <div className="row justify-content-center">
    //     <div className="col-md-10">
    //       <ul
    //         className="nav nav-tabs justify-content-center mb-4"
    //         id="myTab"
    //         role="tablist"
    //       >
    //         <li className="nav-item" role="presentation">
    //           <button
    //             className="nav-link link-dark active"
    //             id="seller-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#seller"
    //             type="button"
    //             role="tab"
    //           >
    //             For purchase
    //           </button>
    //         </li>
    //         <li className="nav-item" role="presentation">
    //           <button
    //             className="nav-link link-dark"
    //             id="buyer-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#buyer"
    //             type="button"
    //             role="tab"
    //           >
    //             For sale
    //           </button>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    //   <div class="list-group mx-0">
    //     <Link to="#" class="list-group-item list-group-item-action">
    //       <div class="hstack gap-3">
    //         <div class="">
    //           <img
    //             src="https://res.cloudinary.com/dszd4owdm/image/upload/v1694592055/shupMarketplaceListings/DD1391-100.webp"
    //             class="img-fluid"
    //             alt="..."
    //             style={{ maxWidth: 140 }}
    //           ></img>
    //         </div>
    //         <div class="text-truncate">
    //           <h5 class="mb-1 text-truncate">
    //             Nike Dunk Low Retro White Black Panda (2021)
    //           </h5>

    //           <p class="mb-1">
    //             Offer price: <strong>$500</strong>
    //           </p>
    //           <small>Size: 11.5</small>
    //         </div>
    //       </div>
    //     </Link>
    //     <Link to="#" class="list-group-item list-group-item-action">
    //       <div class="hstack gap-3">
    //         <div class="">
    //           <img
    //             src="https://res.cloudinary.com/dszd4owdm/image/upload/v1694592055/shupMarketplaceListings/DD1391-100.webp"
    //             class="img-fluid"
    //             alt="..."
    //             style={{ maxWidth: 140 }}
    //           ></img>
    //         </div>
    //         <div class="text-truncate">
    //           <h5 class="mb-1 text-truncate">
    //             Nike Dunk Low Retro White Black Panda (2021)
    //           </h5>

    //           <p class="mb-1">
    //             Offer price: <strong>$500</strong>
    //           </p>
    //           <small>Size: 11.5</small>
    //         </div>
    //       </div>
    //     </Link>
    //     <Link to="#" class="list-group-item list-group-item-action">
    //       <div class="hstack gap-3">
    //         <div class="">
    //           <img
    //             src="https://res.cloudinary.com/dszd4owdm/image/upload/v1694592055/shupMarketplaceListings/DD1391-100.webp"
    //             class="img-fluid"
    //             alt="..."
    //             style={{ maxWidth: 140 }}
    //           ></img>
    //         </div>
    //         <div class="text-truncate">
    //           <h5 class="mb-1 text-truncate">
    //             Nike Dunk Low Retro White Black Panda (2021)
    //           </h5>

    //           <p class="mb-1">
    //             Offer price: <strong>$500</strong>
    //           </p>
    //           <small>Size: 11.5</small>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
  );
};

export default ProfileOffersScreen;
