import {
  CLEAR_ALERTS,
  DISPLAY_ERROR_REQUEST,
  DISPLAY_MESSAGE_REQUEST,
  HIDE_MESSAGE_REQUEST,
  MESSAGE_REQUEST_FAIL,
} from "../constants/alertConstants";

export const messageReducer = (
  state = { message: null, error: null },
  action
) => {
  switch (action.type) {
    case DISPLAY_MESSAGE_REQUEST:
      return { message: action.payload };
    case DISPLAY_ERROR_REQUEST:
      return { error: action.payload };
    case HIDE_MESSAGE_REQUEST:
      return {
        display: false,
        message: null,
      };

    case CLEAR_ALERTS:
      return {
        error: null,
        message: null,
      };
    default:
      return state;
  }
};
