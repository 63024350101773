import React, { useEffect, useRef, useState } from "react";
// Delete just for tesing
// Homepage carosel banner dimensions 1920x450 and mobile is 6912 × 3456 and both should be jpg
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  filterMarketplaceListings,
  getEditorsPicks,
} from "../actions/marketplaceActions";
import SellerCreatedListingCard from "../components/Listing_Components/SellerCreatedListingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faStore,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import SearchBar from "../components/SearchBar";
import ToastAlert from "../components/ToastAlert";
import DraggableComponent from "../components/DraggableComponent";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  // const tooltipRef = useRef();
  // const tooltipRef1 = useRef();
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const marketplaceListings = useSelector((state) => state.marketplaceListings);
  const { listings, listingsLoading } = marketplaceListings;
  const sellerListings = useSelector((state) => state.sellerListings);
  const { sellerListings: editorsPicks, listingsLoading: editorsPicksLoading } =
    sellerListings;
  useEffect(() => {
    // var myTooltip = tooltipRef.current;
    // var myTooltip1 = tooltipRef1.current;
    // new Tooltip(myTooltip);
    // new Tooltip(myTooltip1);
    dispatch(
      filterMarketplaceListings(
        "sneakers",
        null,
        ["date-new"],
        null,
        null,
        null,
        1
      )
    );
    dispatch(getEditorsPicks());
    const recents = getRecentlyViewedItems("recentlyViewed");
    setRecentlyViewed(recents);
  }, []);
  const getRecentlyViewedItems = (key) => {
    if (localStorage) {
      const items = localStorage.getItem(key);
      if (items) {
        return JSON.parse(items);
      }
    }
    return [];
  };

  return (
    <>
      {/* <h1>
        <i class="bi bi-bag-check text-primary"></i>
      </h1>
      <form class="row g-3 needs-validation" noValidate>
        <div class="col-md-4">
          <label htmlFor="validationCustom01" class="form-label">
            First name
          </label>
          <input
            type="text"
            class="form-control is-valid"
            id="validationCustom01"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          ></input>
          <div class="valid-feedback">Looks good!</div>
          <div className="invalid-feedback">Looks bad!</div>
        </div>
        <div class="col-md-4">
          <label htmlFor="validationCustom02" class="form-label">
            Last name
          </label>
          <input
            type="text"
            class="form-control"
            id="validationCustom02"
            required
          ></input>
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-4">
          <label htmlFor="validationCustomUsername" class="form-label">
            Username
          </label>
          <div class="input-group has-validation">
            <span class="input-group-text" id="inputGroupPrepend">
              @
            </span>
            <input
              type="text"
              class="form-control"
              id="validationCustomUsername"
              aria-describedby="inputGroupPrepend"
              required
            ></input>
            <div class="invalid-feedback">Please choose a username.</div>
          </div>
        </div>
        <div class="col-md-6">
          <label htmlFor="validationCustom03" class="form-label">
            City
          </label>
          <input
            type="text"
            class="form-control"
            id="validationCustom03"
            required
          ></input>
          <div class="invalid-feedback">Please provide a valid city.</div>
        </div>

        <div class="col-md-3">
          <label htmlFor="validationCustom05" class="form-label">
            Zip
          </label>
          <input
            type="text"
            class="form-control"
            id="validationCustom05"
            required
          ></input>
          <div class="invalid-feedback">Please provide a valid zip.</div>
        </div>
        <div class="col-12">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="invalidCheck"
              required
            ></input>
            <label class="form-check-label" htmlFor="invalidCheck">
              Agree to terms and conditions
            </label>
            <div class="invalid-feedback">
              You must agree before submitting.
            </div>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-primary" type="submit">
            Submit form
          </button>
        </div>
      </form>
      <form class="row g-3 needs-validation" noValidate>
        <div class="col-md-4 position-relative">
          <label htmlFor="validationTooltip01" class="form-label">
            First name
          </label>
          <input
            type="text"
            class="form-control"
            id="validationTooltip01"
            value="Mark"
            required
          ></input>
          <div class="valid-tooltip">Looks good!</div>
        </div>
        <div class="col-md-4 position-relative">
          <label htmlFor="validationTooltip02" class="form-label">
            Last name
          </label>
          <input
            type="text"
            class="form-control"
            id="validationTooltip02"
            value="Otto"
            required
          ></input>
          <div class="valid-tooltip">Looks good!</div>
        </div>
        <div class="col-md-4 position-relative">
          <label htmlFor="validationTooltipUsername" class="form-label">
            Username
          </label>
          <div class="input-group has-validation">
            <span
              class="input-group-text"
              id="validationTooltipUsernamePrepend"
            >
              @
            </span>
            <input
              type="text"
              class="form-control"
              id="validationTooltipUsername"
              aria-describedby="validationTooltipUsernamePrepend"
              required
            ></input>
            <div class="invalid-tooltip">
              Please choose a unique and valid username.
            </div>
          </div>
        </div>
        <div class="col-md-6 position-relative">
          <label htmlFor="validationTooltip03" class="form-label">
            City
          </label>
          <input
            type="text"
            class="form-control"
            id="validationTooltip03"
            required
          ></input>
          <div class="invalid-tooltip">Please provide a valid city.</div>
        </div>
        <div class="col-md-3 position-relative">
          <label htmlFor="validationTooltip04" class="form-label">
            State
          </label>
          <select class="form-select" id="validationTooltip04" required>
            <option selected disabled value="">
              Choose...
            </option>
            <option>...</option>
          </select>
          <div class="invalid-tooltip">Please select a valid state.</div>
        </div>
        <div class="col-md-3 position-relative">
          <label htmlFor="validationTooltip05" class="form-label">
            Zip
          </label>
          <input
            type="text"
            class="form-control"
            id="validationTooltip05"
            required
          ></input>
          <div class="invalid-tooltip">Please provide a valid zip.</div>
        </div>
        <div class="col-12">
          <button class="btn btn-primary" type="submit">
            Submit form
          </button>
        </div>
      </form>*/}

      <div class="nav-scroller py-1 mx-0 bg-light">
        <nav class="nav nav-underline justify-content-between d-md-none">
          <Link
            class="nav-item nav-link link-body-emphasis ms-3"
            to="/marketplace"
          >
            <strong>All</strong>
          </Link>
          <Link class="nav-item nav-link link-body-emphasis" to="/marketplace">
            <strong>Sneakers</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?sizeType=Mens"
          >
            <strong>Men's Shoes</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?sizeType=Womens"
          >
            <strong>Women's Shoes</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?brand=Jordan"
          >
            <strong>Jordan</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?brand=Nike"
          >
            <strong>Nike</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?brand=adidas"
          >
            <strong>Adidas</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis"
            to="/marketplace?price=0-200"
          >
            <strong>Under $200</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis me-3"
            to="/marketplace?price=200-500"
          >
            <strong>Under $500</strong>
          </Link>
        </nav>
        <nav class="nav nav-underline justify-content-center d-none d-md-flex">
          <Link
            class="nav-item nav-link link-body-emphasis ms-3"
            to="/marketplace"
          >
            <strong>All</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace"
          >
            <strong>Sneakers</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?sizeType=Mens"
          >
            <strong>Men's Shoes</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?sizeType=Womens"
          >
            <strong>Women's Shoes</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?brand=Jordan"
          >
            <strong>Jordan</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?brand=Nike"
          >
            <strong>Nike</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?brand=adidas"
          >
            <strong>Adidas</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis mx-2"
            to="/marketplace?price=0-200"
          >
            <strong>Under $200</strong>
          </Link>
          <Link
            class="nav-item nav-link link-body-emphasis me-3"
            to="/marketplace?price=200-500"
          >
            <strong>Under $500</strong>
          </Link>
        </nav>
      </div>
      <div className="container">
        <div
          id="homeScreenLargeCarosel"
          class="carousel slide d-none d-lg-block  my-3"
          data-bs-theme="dark"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#homeScreenLargeCarosel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#homeScreenLargeCarosel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <Link to="/marketplace">
                <img
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708655151/Reshup_has_a_wide_variety_of_shoe_models._Come_and_choose_for_yourself_a_suitable_pair_to_wear_anytime_and_anywhere._1920_x_450_px_2_lrgsky.jpg"
                  class="d-block w-100 rounded"
                  alt="..."
                ></img>
              </Link>
            </div>
            <div class="carousel-item">
              <Link to="/sell">
                <img
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1709084222/Reshup_has_a_wide_variety_of_shoe_models._Come_and_choose_for_yourself_a_suitable_pair_to_wear_anytime_and_anywhere._1920_x_450_px_3_eogbfl.jpg"
                  class="d-block w-100 rounded"
                  alt="..."
                ></img>
              </Link>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#homeScreenLargeCarosel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#homeScreenLargeCarosel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div
          id="homeScreenMobileCarosel"
          class="carousel slide d-lg-none my-3"
          data-bs-theme="dark"
          data-bs-interval="5000"
          data-bs-touch="true"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#homeScreenMobileCarosel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#homeScreenMobileCarosel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            {/* <button
              type="button"
              data-bs-target="#homeScreenMobileCarosel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button> */}
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active ">
              <Link to="/marketplace">
                <img
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708899573/Reshup_has_a_wide_variety_of_shoe_models._Come_and_choose_for_yourself_a_suitable_pair_to_wear_anytime_and_anywhere._2_p1pqm9.jpg"
                  class="d-block w-100 rounded"
                  alt="..."
                ></img>
              </Link>
            </div>
            <div class="carousel-item">
              <Link to="/sell">
                <img
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708910806/Reshup_has_a_wide_variety_of_shoe_models._Come_and_choose_for_yourself_a_suitable_pair_to_wear_anytime_and_anywhere._5_zg5lge.jpg"
                  class="d-block w-100 rounded"
                  alt="..."
                ></img>
              </Link>
            </div>
            {/* <div class="carousel-item">
              <Link to="/about">
                <img
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703993369/Banner3_r44sxw.jpg"
                  class="d-block w-100 rounded"
                  alt="..."
                ></img>
              </Link>
            </div> */}
          </div>
        </div>
        {/* <span
          class="badge rounded-circle text-bg-secondary"
          ref={tooltipRef1}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-custom-class="custom-tooltip"
          data-bs-title="This top tooltip is themed via CSS variables."
        >
          ?
        </span>
        <span
          class="badge rounded-pill text-bg-secondary"
          ref={tooltipRef}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-custom-class="custom-tooltip"
          data-bs-title="This top tooltip is themed via CSS variables."
        >
          ?
        </span> */}
        {/* <img
          src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703993369/Banner3_r44sxw.jpg"
          class="img-fluid d-sm-none rounded my-3"
          alt="..."
        ></img>
        <img
          src="https://res.cloudinary.com/dszd4owdm/image/upload/v1707097065/Jordan1Banner450x1920_mhu4oe.png"
          class="img-fluid d-none d-sm-block rounded my-3"
          alt="..."
        ></img> */}
        {/* <div id="carouselExampleCaptions" class="carousel slide mb-3 mt-3">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703993369/Banner3_r44sxw.jpg"
                class="img-fluid"
                alt="..."
                // style={{ width: 1920, height: 300 }}
              ></img>
              <div class="carousel-caption d-none d-md-block">
                <h5>Turn Your Passion into Profit</h5>
                <p>
                  Transform your sneaker passion into profit by becoming a
                  seller on ReShup.
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1704244032/Banner5_xaufkh.jpg"
                class="img-fluid"
                alt="..."
              ></img>
              <div class="carousel-caption d-none d-md-block">
                <h5>Step into style</h5>
                <p>
                  Join ReShup and explore sneakers in our passionate community.
                </p>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://picsum.photos/1920/550"
                class="img-fluid"
                alt="..."
              ></img>
              <div class="carousel-caption d-none d-md-block">
                <h5>Discover Authenticity</h5>
                <p>
                  explore and purchase only genuine, high-end sneakers at
                  ReShup.
                </p>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}
        {recentlyViewed && recentlyViewed.length > 5 && (
          <div>
            <div class="row align-items-center mb-2">
              <div class="col-auto me-auto">
                <h2 class="fw-light">Recently Viewed </h2>
              </div>
              <div class="col-auto me-3">
                <Link to="/marketplace" class="text-decoration-none">
                  See All <i class="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div class="d-flex flex-row flex-nowrap overflow-auto mb-2">
              {recentlyViewed.map((element, index) => (
                <div
                  className="col me-3"
                  key={"HomeScreenRecentlyViewed" + index}
                >
                  <div className="card p-2 h-100">
                    <img
                      src={element.photos[0]}
                      className="card-img-top"
                      alt="..."
                      style={{ minWidth: 150 }}
                    ></img>
                    <div className="card-body mt-1 bg-light p-1">
                      <h5 className="card-title">
                        $ {Math.round(element.price)}
                      </h5>
                      <p
                        className="card-text lh-sm mb-1"
                        style={{ fontSize: 13 }}
                      >
                        {element.title}
                      </p>
                      <p className="card-text mb-1" style={{ fontSize: 13 }}>
                        Size:{" "}
                        <small className="text-muted">{element.size}</small>
                      </p>
                      <div className="d-flex flex-row">
                        <img
                          src={element.sellerInformation.photo}
                          className="rounded-circle me-2"
                          style={{ height: 25, width: 25 }}
                        ></img>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            {element.sellerInformation.name}
                          </small>
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`/sellerlisting/${element._id}`}
                      className="stretched-link"
                    ></Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <div class="row align-items-center mb-2">
            <div class="col-auto me-auto">
              <h2 class="fw-light">Most Popular Listings </h2>
            </div>
            <div class="col-auto me-3">
              <Link to="/marketplace" class="text-decoration-none">
                See All <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div class="d-flex flex-row flex-nowrap overflow-auto mb-2">
            {listings &&
              listings.length > 5 &&
              listings.slice(0, 6).map((element, index) => (
                <div className="col me-3" key={"HomeScreenMostPopular" + index}>
                  <div className="card p-2 position-relative h-100">
                    {element.dateString && (
                      <small className="mt-1 me-1 position-absolute top-0 end-0 text-muted">
                        {element.dateString}
                      </small>
                    )}
                    <img
                      src={element["photo"]}
                      className="card-img-top"
                      alt="..."
                      style={{ minWidth: 150 }}
                    ></img>
                    <div className="card-body p-1">
                      <div className="d-flex justify-content-between">
                        <h5 className="card-title">
                          ${Math.floor(element["lowestPrice"])}
                        </h5>
                        {/* <h5 className="card-title">${["releaseDate"]}</h5> */}
                      </div>
                      <p
                        className="card-text lh-sm text-capitalize p-0 m-1 "
                        style={{ fontSize: "smaller" }}
                      >
                        {element["listingName"]}
                      </p>
                      <Link
                        to={`/listing/${element["_id"]}`}
                        className="stretched-link p-0 m-0"
                      ></Link>
                    </div>
                  </div>
                </div>
                // <SellerCreatedListingCard
                //   listingInformation={element}
                //   key={element._id + index}
                // ></SellerCreatedListingCard>
              ))}

            {listingsLoading && (
              <>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div>
          <div class="row align-items-center mb-2">
            <div class="col-auto me-auto">
              <h2 class="fw-light">Popular Brands </h2>
            </div>
            <div class="col-auto me-3">
              <Link to="/marketplace" class="text-decoration-none">
                See All <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div class="d-flex flex-row flex-nowrap overflow-auto mb-2">
            <div className="col me-3">
              <Link to="/marketplace?brand=Nike">
                <img
                  alt=""
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703722179/Component_4_xvhmbc.png"
                  className="img-fluid"
                  style={{ minWidth: 150 }}
                ></img>
              </Link>
            </div>
            <div className="col me-3">
              <Link to="/marketplace?brand=Jordan">
                <img
                  alt=""
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703724861/Component_10_okawjd.png"
                  className="img-fluid"
                  style={{ minWidth: 150 }}
                ></img>
              </Link>
            </div>
            <div className="col me-3">
              <Link to="/marketplace?brand=adidas">
                <img
                  alt=""
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703724861/Component_11_mq1hjd.png"
                  className="img-fluid"
                  style={{ minWidth: 150 }}
                ></img>
              </Link>
            </div>
            <div className="col me-3">
              <Link to="/marketplace?brand=adidas">
                <img
                  alt=""
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703722382/Component_8_mioh9b.png"
                  className="img-fluid"
                  style={{ minWidth: 150 }}
                ></img>
              </Link>
            </div>
            <div className="col me-3">
              <Link to="/marketplace?price=0-200">
                <img
                  alt=""
                  src="https://res.cloudinary.com/dszd4owdm/image/upload/v1703722382/Component_9_i4v08d.png"
                  className="img-fluid"
                  style={{ minWidth: 150 }}
                ></img>
              </Link>
            </div>
          </div>
        </div>

        <div>
          <div class="row align-items-center mb-2">
            <div class="col-auto me-auto">
              <h2 class="fw-light">Editors Picks </h2>
            </div>
            <div class="col-auto me-3">
              <Link to="/marketplace" class="text-decoration-none">
                See All <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div class="d-flex flex-row flex-nowrap overflow-auto mb-2">
            {editorsPicks &&
              editorsPicks.length > 5 &&
              editorsPicks.map((element, index) => (
                <div
                  className="col me-3"
                  key={"HomeScreenEditorsPicks" + index}
                >
                  <div className="card p-2 h-100">
                    <img
                      src={element.photos[0]}
                      className="card-img-top"
                      alt="..."
                      style={{ minWidth: 150 }}
                    ></img>
                    <div className="card-body mt-1 bg-light p-1">
                      <h5 className="card-title">$ {element.price}</h5>
                      <p
                        className="card-text lh-sm mb-1"
                        style={{ fontSize: 13 }}
                      >
                        {element.title}
                      </p>
                      <p className="card-text mb-1" style={{ fontSize: 13 }}>
                        Size:{" "}
                        <small className="text-muted">{element.size}</small>
                      </p>
                      <div className="d-flex flex-row">
                        <img
                          src={element.sellerInformation.photo}
                          className="rounded-circle me-2"
                          style={{ height: 25, width: 25 }}
                        ></img>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            {element.sellerInformation.name}
                          </small>
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`/sellerlisting/${element._id}`}
                      className="stretched-link"
                    ></Link>
                  </div>
                </div>
              ))}
            {editorsPicksLoading && (
              <>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="col me-3">
                  <div class="card mx-1" style={{ minWidth: 150 }}>
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a
                        class="btn btn-primary disabled placeholder col-6"
                        aria-disabled="true"
                      ></a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <hr class="border border-5 opacity-75"></hr>
        <div className="p-3">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3 offset-md-1 order-2 order-md-1 mt-3">
              <h4>Buying and selling authentic sneakers made easy.</h4>
              <p>ReShup makes it simple to buy and sell authentic sneakers.</p>
              <div className="row">
                <div className="col">
                  <Link className="btn btn-primary w-100" to="/sell">
                    Start selling
                  </Link>
                </div>
                <div className="col">
                  <Link
                    className="btn btn-outline-primary w-100"
                    to="/marketplace"
                  >
                    View listings
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-md-1 order-1 order-md-2">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708475658/Untitled_design_5_cfxpxw.png"
                class="img-fluid"
                alt="..."
              ></img>
            </div>
          </div>
        </div>
        {/* <div className=" py-4">
          <div className="row justify-content-center">
            <div className="col-lg-4 d-flex justify-content-center">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708398502/Untitled_design_1_myvkdv.png"
                className="img-fluid d-none d-md-block mb-2"
                style={{ maxWidth: 275 }}
                alt="..."
              ></img>
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1708398502/Untitled_design_1_myvkdv.png"
                className="img-fluid d-md-none mb-2"
                style={{ maxWidth: 200 }}
                alt="..."
              ></img>
            </div>
            <div className="col-lg-5 d-flex align-items-center">
              <div>
                <div class="vstack gap-3">
                  <div class="p-2 bg-light rounded">
                    <div className="d-flex align-items-start">
                      <h4 className="me-3 p-2">
                        <i class="bi bi-phone text-primary"></i>
                      </h4>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0 text-primary">Sign Up</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            Kickstart your selling journey by signing up on our
                            marketplace. Seamlessly link your Stripe account to
                            ensure secure and swift payouts directly into your
                            bank account.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bg-light rounded">
                    <div className="d-flex align-items-start">
                      <h4 className="me-3 p-2">
                        <i class="bi bi-bag-check text-primary"></i>
                      </h4>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0 text-primary">Sell It</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            Take some time to photograph your sneakers. Provide
                            prospective buyers with details such as the brand,
                            model, size, and condition to create a compelling
                            listing.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 bg-light rounded">
                    <div className="d-flex align-items-start">
                      <h4 className="me-3 p-2">
                        <i class="bi bi-cash text-primary"></i>
                      </h4>
                      <div className="flex-column ms-2">
                        <p className="fs-5 mb-0 text-primary">Get Paid</p>
                        <p className="card-text" style={{ fontSize: 13 }}>
                          <small className="text-muted">
                            Celebrate a successful sale! Your hard-earned
                            earnings will be deposited into your linked Stripe
                            account, providing you with a hassle-free and secure
                            payout experience.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className=" py-md-5">
         
          <div className="row justify-content-center">
            <div className="col-md-6 mb-3">
              <div className="p-3 bg-light-blue rounded h-100">
                <h4>Sellers</h4>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Find Your Sneakers</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Browse our curated database to find the exact shoes
                        you're selling. Our comprehensive collection ensures
                        authenticity and trust for every pair.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Take Some Photos</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Take high-quality photos of your sneakers to showcase
                        their unique features. Craft a compelling listing by
                        providing essential details and setting a competitive
                        price.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">
                      Customize Your Selling Experience
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        Engage with potential buyers by accepting offers and
                        negotiating securely. Manage your listings and
                        preferences effortlessly in your dashboard.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-light-blue rounded">
                <h4>Buyers</h4>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Explore Your Favorites</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Dive into our curated selection to discover the sneakers
                        you've been dreaming of. With a diverse range of
                        options, finding your perfect pair is just a few clicks
                        away.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Shop with Confidence</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Trust in our authentication process. Every pair listed
                        on our platform will undergo rigorous scrutiny,
                        guaranteeing that what you see is what you get.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Connect with Sellers</p>
                    <p className="card-text">
                      <small className="text-muted">
                        Engage directly with sellers to discover the perfect
                        pair of sneakers for you. Whether you want to make an
                        offer or purchase instantly.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="container col-md-11 mt-3">
          <h2 class="fw-light">How ReShup works </h2>
          <div class="row g-3 mt-3">
            <div className="col-md-6">
              <div className="p-3 bg-subtleprimary rounded h-100">
                <h3>Sellers</h3>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Find Your Sneakers</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Browse our curated database to find the exact shoes
                        you're selling. Our comprehensive collection ensures
                        authenticity and trust.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Take Some Photos</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Take high-quality photos of your sneakers to showcase
                        their unique features. Craft a compelling listing by
                        providing essential details and setting a competitive
                        price.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">
                      Customize Your Selling Experience
                    </p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Engage with potential buyers by accepting offers and
                        negotiating securely. Manage your listings and
                        preferences effortlessly in your dashboard.
                      </small>
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-body-secondary rounded">
                <h3>Buyers</h3>
                <div className="d-flex align-items-center">
                  <h1 className="me-3">1</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Explore Your Favorites</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Dive into our curated selection to discover the sneakers
                        you've been dreaming of. With a diverse range of
                        options, finding your perfect pair is just a few clicks
                        away.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">2</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Shop with Confidence</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Trust in our authentication process. Every pair listed
                        on our platform will undergo rigorous scrutiny,
                        guaranteeing that what you see is what you get.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h1 className="me-3">3</h1>
                  <div className="flex-column ms-2">
                    <p className="fs-5 mb-0">Connect with Sellers</p>
                    <p className="card-text" style={{ fontSize: 13 }}>
                      <small className="text-muted">
                        Engage directly with sellers to discover the perfect
                        pair of sneakers for you. Whether you want to make an
                        offer or purchase instantly.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row justify-content-center">
          <div className="col-md-9">
            <ul
              className="nav nav-tabs justify-content-center mb-4"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link link-dark active"
                  id="seller-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#seller"
                  type="button"
                  role="tab"
                >
                  For Sellers
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link link-dark"
                  id="buyer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#buyer"
                  type="button"
                  role="tab"
                >
                  For Buyers
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="seller"
            role="tabpanel"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <h3>
                          <span class="badge bg-light text-primary">
                            Sign Up
                          </span>
                        </h3>
                        <div className="bg-subtleprimary p-3">
                          <p>
                            Begin your selling journey by signing up on our
                            marketplace. Link your Stripe account to seamlessly
                            receive secure and quick payouts directly into your
                            bank account.
                          </p>
                        </div>
                        <h3 className="text-primary">
                          Sign Up{" "}
                          <span className="text-muted">
                            and Link Your Stripe Account
                          </span>
                        </h3>
                        <p className="lead">
                          Begin your selling journey by signing up on our
                          marketplace. Link your Stripe account to seamlessly
                          receive secure and quick payouts directly into your
                          bank account.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <div className="d-flex align-items-center">
                          <h2 className="me-3">
                            <i class="bi bi-shield-check"></i>
                          </h2>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Authenticity promised</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                All products will be sent to the ReShup
                                warehouse for verification.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <h2 className="me-3">
                            <i class="bi bi-box2-heart"></i>
                          </h2>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Buyer protection</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Get what you paid for or your money back its
                                that simple.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-2">
                          <h2 className="me-3">
                            <i class="bi bi-cash-coin"></i>
                          </h2>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Make your best offer</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Why pay more? Make your best offer and get
                                unbeatable deals.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="d-flex align-items-start">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-phone"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Sign Up</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Begin your selling journey by signing up on our
                                marketplace. Link your Stripe account to receive
                                secure and quick payouts.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="d-flex align-items-start">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-bag-check"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Sell It</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Take some photos. Provide essential details
                                about the brand, model, size, and condition.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="d-flex align-items-start">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-cash"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Get Paid</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Congratulations on a successful sale! Your
                                earnings will be swiftly deposited into your
                                linked Stripe account, providing a hassle-free
                                and secure payout experience.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <div className="col-md-7 order-md-2">
                        <h3 className="text-primary">
                          Create{" "}
                          <span className="text-muted">
                            a Listing in Three Simple Steps
                          </span>
                        </h3>
                        <p className="lead">
                          Engage with buyers by accepting offers and negotiating
                          securely. Customize your selling experience in your
                          dashboard, managing listings and preferences.
                        </p>
                      </div>
                      <div className="col-md-5 order-md-1">
                        <div className="d-flex align-items-center">
                          <h1 className="me-3">1</h1>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Find Your Sneakers</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Browse our curated database to find the exact
                                shoes you're selling. Our comprehensive
                                collection ensures authenticity and trust.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <h1 className="me-3">2</h1>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Take Some Photos</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Take high-quality photos of your sneakers to
                                showcase their unique features. Craft a
                                compelling listing by providing essential
                                details and setting a competitive price.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <h1 className="me-3">3</h1>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">
                              Customize Your Selling Experience
                            </p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Engage with potential buyers by accepting offers
                                and negotiating securely. Manage your listings
                                and preferences effortlessly in your dashboard.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <div className="col-md-7">
                        <h3 className="text-primary">
                          Ship{" "}
                          <span className="text-muted">
                            {" "}
                            with Confidence and Get Paid
                          </span>
                        </h3>
                        <p className="lead">
                          Finalize transactions, generate shipping labels, and
                          ship sneakers securely. ReShup ensures a transparent,
                          hassle-free selling experience.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <div className="d-flex align-items-center">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-box2-heart"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">
                              Generate Shipping Labels
                            </p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Once you've finalized a transaction,
                                effortlessly generate shipping labels through
                                our platform. This step ensures a smooth and
                                transparent shipping process.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-shield-check"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Get Verified</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Once verified, we'll take care of shipping your
                                sneakers directly to the buyer. Enjoy the peace
                                of mind knowing your item will reach its
                                destination safely.
                              </small>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <h4 className="me-3 text-bg-primary bg-gradient p-2 rounded-3">
                            <i class="bi bi-cash"></i>
                          </h4>
                          <div className="flex-column ms-2">
                            <p className="fs-5 mb-0">Get Paid</p>
                            <p className="card-text" style={{ fontSize: 13 }}>
                              <small className="text-muted">
                                Congratulations on a successful sale! Your
                                earnings will be swiftly deposited into your
                                linked Stripe account, providing a hassle-free
                                and secure payout experience.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="buyer" role="tabpanel">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <h3 className="text-primary">
                          Step 1.{" "}
                          <span className="text-muted">
                            Browse and Make Offers
                          </span>
                        </h3>
                        <p className="lead">
                          Explore ReShup's diverse sneaker collection. Make
                          offers on desired pairs, initiating secure
                          negotiations with sellers.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img
                          alt=""
                          src="https://picsum.photos/400/250"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <div className="col-md-7 order-md-2">
                        <h3 className="text-primary">
                          Step 2.{" "}
                          <span className="text-muted">
                            Negotiate and Purchase
                          </span>
                        </h3>
                        <p className="lead">
                          Engage in secure negotiations with sellers. Once an
                          agreement is reached, proceed to purchase confidently
                          through ReShup.
                        </p>
                      </div>
                      <div className="col-md-5 order-md-1">
                        <img
                          alt=""
                          src="https://picsum.photos/400/250"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <div className="col-md-7">
                        <h3 className="text-primary">
                          Step 3.{" "}
                          <span className="text-muted">
                            Enjoy Authentic Sneakers
                          </span>
                        </h3>
                        <p className="lead">
                          Relax as ReShup facilitates a smooth, secure
                          transaction. Receive your authentic sneakers and join
                          a community of enthusiasts who share your passion.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img
                          alt=""
                          src="https://picsum.photos/400/250"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container"></div>
        </div> */}
        <div className="container col-md-10 mt-4">
          <div className="row justify-content-center g-3">
            <div className="col-md-4 ">
              <div className="d-flex align-items-start">
                <h4 className=" p-2">
                  <i class="bi bi-phone"></i>
                </h4>
                <div className="flex-column ms-2">
                  <p className="fs-5 mb-0">Sign Up</p>
                  <p className="card-text" style={{ fontSize: 13 }}>
                    <small className="text-muted">
                      Kickstart your selling journey by signing up on our
                      marketplace. Seamlessly link your Stripe account to ensure
                      secure and swift payouts directly into your bank account.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="d-flex align-items-start">
                <h4 className=" p-2">
                  <i class="bi bi-bag-check"></i>
                </h4>
                <div className="flex-column ms-2">
                  <p className="fs-5 mb-0">Sell It</p>
                  <p className="card-text" style={{ fontSize: 13 }}>
                    <small className="text-muted">
                      Take some time to photograph your sneakers. Provide
                      prospective buyers with details such as the brand, model,
                      size, and condition to create a compelling listing.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="d-flex align-items-start">
                <h4 className=" p-2">
                  <i class="bi bi-cash"></i>
                </h4>
                <div className="flex-column ms-2">
                  <p className="fs-5 mb-0">Get Paid</p>
                  <p className="card-text" style={{ fontSize: 13 }}>
                    <small className="text-muted">
                      Celebrate a successful sale! Your hard-earned earnings
                      will be deposited into your linked Stripe account,
                      providing you with a hassle-free and secure payout
                      experience.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
