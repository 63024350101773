import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInformation } from "../actions/userActions";
import { getOffer } from "../actions/offerActions";
import ProfileOffer from "../components/Profile_Components/ProfileOffer";

const ProfileOfferScreen = () => {
  const params = useParams();
  const offerId = params.offerId;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
    dispatch(getUserInformation());
    dispatch(getOffer(offerId));
  }, [isAuthenticated, offerId]);
  return (
    <div className="container d-flex justify-content-center mt-4 ">
      <ProfileOffer></ProfileOffer>
    </div>
  );
};

export default ProfileOfferScreen;
