import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {
  getStripePaymentMethods,
  getSetupIntent,
} from "../actions/stripeActions";
import LoadingSpinner from "./LoadingSpinner";

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const stripeCustomer = useSelector((state) => state.stripeCustomer);
  const { loading: customerLoading, customer } = stripeCustomer;

  // useEffect(() => {}, [customer]);

  const closePaymentModal = () => {
    let paymentModal = document.getElementById("paymentModalCloseButton");
    paymentModal.click();
  };

  const handleSubmitV2 = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    setPaymentLoading(true);

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://reshup.com/profile/settings",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setPaymentLoading(false);
      return;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      closePaymentModal();
      setPaymentLoading(false);
      dispatch(getStripePaymentMethods());
      dispatch(getSetupIntent());
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setPaymentLoading(true);
    // const { error } = await stripe.confirmSetup({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: window.location.href,
    //   },
    //   redirect: "if_required",
    // });
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setPaymentLoading(false);
      return;
    }

    // Create the Setup Intent
    const response = await axios.post(
      "/api/stripe/create-confirm-setup-intent",
      {
        paymentMethod: paymentMethod.id,
      }
    );

    // const data = await response.json();

    // Handle any next actions or errors. See the Handle any next actions step for implementation.
    handleServerResponse(response);
    // else {
    //   setPaymentLoading(false);
    //   dispatch(getStripePaymentMethods());

    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };
  const handleServerResponse = async (response) => {
    // const { data, error } = response;
    if (response.error) {
      // Show error from server on payment form
      setErrorMessage(response.error.message);
      setPaymentLoading(false);
      return;
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const { error } = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      });

      if (error) {
        // Show error from Stripe.js in payment form
        setErrorMessage(error.message);
        setPaymentLoading(false);
        return;
      } else {
        // Actions handled, show success message
        setPaymentLoading(false);
        dispatch(getStripePaymentMethods());
        return;
      }
    } else {
      // No actions needed, show success message
      setPaymentLoading(false);
      dispatch(getStripePaymentMethods());
      return;
    }
  };
  return (
    <form onSubmit={handleSubmitV2}>
      {/* <h3>Billing Information</h3> */}
      {customerLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <>
          <AddressElement
            options={{
              mode: "billing",
              allowedCountries: ["US"],
              contacts:
                customer && customer.shipping ? [customer.shipping] : [],
              fields: {
                phone: "always",
              },
              validation: {
                phone: {
                  required: "always",
                },
              },
            }}
          />
          <PaymentElement />
        </>
      )}

      {paymentLoading ? (
        <button type="submit" className="btn btn-primary mt-3" disabled>
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
          ></span>
          Save Billing Information
        </button>
      ) : (
        <button
          disabled={!stripe}
          type="submit"
          className="btn btn-primary mt-3"
        >
          Save Billing Information
        </button>
      )}
      {/* Show error message to your customers */}
      <div className="row">
        {errorMessage && (
          <div
            class="alert alert-danger alert-dismissible fade show mt-2 mx-auto col-11"
            role="alert"
          >
            {errorMessage}
            <button
              type="button"
              class="btn-close"
              onClick={() => setErrorMessage(null)}
            ></button>
          </div>
        )}
      </div>
    </form>
  );
};

export default SetupForm;
