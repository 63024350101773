import React from "react";
import { Link, useLocation } from "react-router-dom";

const AboutNavigation = () => {
  const location = useLocation();

  return (
    <ul class="nav flex-column nav-underline d-none d-lg-block text-nowrap">
      <h4>About ReShup</h4>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/about" ? "active" : "link-secondary"
          }`}
          to="/about"
        >
          What is ReShup
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/reshup_authentication"
              ? "active"
              : "link-secondary"
          }`}
          to="/reshup_authentication"
        >
          ReShup Authentication
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/faq" ? "active" : "link-secondary"
          }`}
          to="/faq"
        >
          FAQs
        </Link>
      </li>
      {/* <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/guide" ? "active" : "link-secondary"
          }`}
          to="/guide"
        >
          Guide to ReShup
        </Link>
      </li> */}
      <li class="nav-item">
        <Link
          class={`nav-link ${
            location.pathname === "/contact" ? "active" : "link-secondary"
          }`}
          to="/contact"
        >
          Contact us
        </Link>
      </li>
    </ul>
  );
};

export default AboutNavigation;
