import {
  STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS,
  STRIPE_CREATE_PAYMENTINTENT_FAIL,
  STRIPE_CREATE_PAYMENTINTENT_REQUEST,
  STRIPE_CREATE_PAYMENTINTENT_SUCCESS,
  STRIPE_CREATE_SETUPINTENT_FAIL,
  STRIPE_CREATE_SETUPINTENT_REQUEST,
  STRIPE_CREATE_SETUPINTENT_SUCCESS,
  STRIPE_GET_ACCOUNT_FAIL,
  STRIPE_GET_ACCOUNT_REQUEST,
  STRIPE_GET_ACCOUNT_SUCCESS,
  STRIPE_GET_CUSTOMER_FAIL,
  STRIPE_GET_CUSTOMER_REQUEST,
  STRIPE_GET_CUSTOMER_SUCCESS,
  STRIPE_GET_PAYMENTMETHODS_FAIL,
  STRIPE_GET_PAYMENTMETHODS_REQUEST,
  STRIPE_GET_PAYMENTMETHODS_SUCCESS,
  STRIPE_PAYMENTINTENT_RESET,
  STRIPE_REMOVE_PAYMENTMETHOD_REQUEST,
  STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS,
  STRIPE_TRANSFER_SELLER_PAYOUT_FAIL,
  STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST,
  STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST,
  STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS,
  STRIPE_UPDATE_PAYMENTINTENT_FAIL,
  STRIPE_UPDATE_PAYMENTINTENT_REQUEST,
  STRIPE_UPDATE_PAYMENTINTENT_SUCCESS,
} from "../constants/stripeConstants";

export const stripeSetupIntentReducer = (
  state = { client_secret: null, loading: false },
  action
) => {
  switch (action.type) {
    case STRIPE_CREATE_SETUPINTENT_REQUEST:
      return {
        client_secret: null,
        loading: true,
      };
    case STRIPE_CREATE_SETUPINTENT_SUCCESS:
      return {
        client_secret: action.payload.client_secret,
        loading: false,
      };
    case STRIPE_CREATE_SETUPINTENT_FAIL:
      return {
        loading: false,
        client_secret: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const stripeTransferSellerPayoutReducer = (
  state = { message: null, loading: false },
  action
) => {
  switch (action.type) {
    case STRIPE_TRANSFER_SELLER_PAYOUT_REQUEST:
      return {
        message: null,
        loading: true,
      };
    case STRIPE_TRANSFER_SELLER_PAYOUT_SUCCESS:
      return {
        message: action.payload,
        loading: false,
      };
    case STRIPE_TRANSFER_SELLER_PAYOUT_FAIL:
      return {
        loading: false,
        message: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const stripePaymentIntentReducer = (
  state = { paymentIntent: null, clientSecret: null, loading: false },
  action
) => {
  switch (action.type) {
    case STRIPE_CREATE_PAYMENTINTENT_REQUEST:
    case STRIPE_UPDATE_PAYMENTINTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STRIPE_CREATE_PAYMENTINTENT_SUCCESS:
    case STRIPE_UPDATE_PAYMENTINTENT_SUCCESS:
      return {
        paymentIntent: action.payload.paymentIntent,
        clientSecret: action.payload.clientSecret,
        loading: false,
      };
    case STRIPE_CONFIRM_PAYMENTINTENT_SUCCESS:
      return {
        success: true,
        paymentIntent: action.payload.paymentIntent,
        clientSecret: action.payload.clientSecret,
        loading: false,
      };
    case STRIPE_CREATE_PAYMENTINTENT_FAIL:
    case STRIPE_UPDATE_PAYMENTINTENT_FAIL:
      return {
        loading: false,
        paymentIntent: null,
        clientSecret: null,
        error: action.payload,
      };
    case STRIPE_PAYMENTINTENT_RESET:
      return {
        paymentIntent: null,
        clientSecret: null,
        loading: false,
      };
    default:
      return state;
  }
};

export const stripeCustomerReducer = (state = { customer: null }, action) => {
  switch (action.type) {
    case STRIPE_GET_CUSTOMER_REQUEST:
      return {
        customer: null,
        loading: true,
      };
    case STRIPE_GET_CUSTOMER_SUCCESS:
      return {
        customer: action.payload,
        loading: false,
      };
    case STRIPE_GET_CUSTOMER_FAIL:
      return {
        loading: false,
        customer: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const stripePaymentMethodsReducer = (
  state = { paymentMethods: [], loading: false },
  action
) => {
  switch (action.type) {
    case STRIPE_GET_PAYMENTMETHODS_REQUEST:
      return {
        paymentMethods: [],
        loading: true,
      };
    case STRIPE_REMOVE_PAYMENTMETHOD_REQUEST:
      return {
        paymentMethods: state.paymentMethods,
        loading: true,
      };
    case STRIPE_GET_PAYMENTMETHODS_SUCCESS:
    case STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS:
      return {
        paymentMethods: action.payload,
        loading: false,
      };
    case STRIPE_GET_PAYMENTMETHODS_FAIL:
      return {
        loading: false,
        paymentMethods: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const stripeAccountReducer = (
  state = { stripeAccount: null, accountLink: null },
  action
) => {
  switch (action.type) {
    case STRIPE_GET_ACCOUNT_REQUEST:
      return {
        stripeAccount: null,
        accountLink: null,
        loading: true,
      };
    case STRIPE_GET_ACCOUNT_SUCCESS:
      return {
        stripeAccount: action.payload.stripeAccount,
        accountLink: action.payload.accountLink,
        loading: false,
      };
    case STRIPE_GET_ACCOUNT_FAIL:
      return {
        loading: false,
        stripeAccount: null,
        accountLink: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const stripeUpdatedCustomerShippingReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case STRIPE_UPDATE_CUSTOMER_SHIPPING_REQUEST:
      return {
        loading: true,
      };
    case STRIPE_UPDATE_CUSTOMER_SHIPPING_SUCCESS:
      return {
        loading: false,
        success: true,
        successMessage: action.payload.message,
      };
    case STRIPE_UPDATE_CUSTOMER_SHIPPING_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
