import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MARKETPLACE_FILTER_RESET } from "../constants/marketplaceConstants.js";
import {
  filterMarketplaceListings,
  filterMoreMarketplaceListings,
  getMarketplaceBrands,
} from "../actions/marketplaceActions.js";
import { useParams, Link, useSearchParams } from "react-router-dom";
import BreadcrumbLocation from "../components/BreadcrumbLocation.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
import PaginationComponent from "../components/PaginationComponent.js";
import InfiniteScroll from "react-infinite-scroll-component";

import MarketplaceSortDropdown from "../components/Marketplace_Components/MarketplaceSortDropdown.js";
import MarketplaceListingCard from "../components/Marketplace_Components/MarketplaceListingCard.js";
import FilterAndSortOffcanvas from "../components/Marketplace_Components/FilterAndSortOffcanvas.js";
import MarketplaceFilters from "../components/Marketplace_Components/MarketplaceFilters.js";
const MarketplaceScreen = () => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const marketplaceListingObserver = useRef();
  const [pageNumberMarketplace, setPageNumberMarketplace] = useState(1);

  const marketplaceBrands = useSelector((state) => state.marketplaceBrands);
  const marketplaceListings = useSelector((state) => state.marketplaceListings);
  const { brandsLoading, brands } = marketplaceBrands;
  const { listingsLoading, listings, totalDocuments } = marketplaceListings;
  const params = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  const marketplaceLastListingRef = useCallback(
    (listingNode) => {
      const hasMore = !(listings.length === totalDocuments);
      if (listingsLoading) return;
      if (marketplaceListingObserver.current)
        marketplaceListingObserver.current.disconnect();
      marketplaceListingObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumberMarketplace((prev) => prev + 1);
          }
        }
      );
      if (listingNode) marketplaceListingObserver.current.observe(listingNode);
    },
    [listingsLoading, listings]
  );

  useEffect(() => {
    if (pageNumberMarketplace > 1) {
      dispatch(
        filterMoreMarketplaceListings(
          "sneakers",
          searchParams.getAll("brand"),
          searchParams.getAll("sort"),
          searchParams.getAll("price"),
          searchParams.get("query"),
          searchParams.get("sizeType"),
          pageNumberMarketplace
        )
      );
    }
  }, [pageNumberMarketplace]);
  useEffect(() => {
    setPageNumberMarketplace(1);
    if (marketplaceListingObserver.current)
      marketplaceListingObserver.current.disconnect();
    if (brands.length === 0) {
      dispatch(getMarketplaceBrands("sneakers"));
    }
    dispatch({ type: MARKETPLACE_FILTER_RESET });
    dispatch(
      filterMarketplaceListings(
        "sneakers",
        searchParams.getAll("brand"),
        searchParams.getAll("sort"),
        searchParams.getAll("price"),
        searchParams.get("query"),
        searchParams.get("sizeType"),
        1
      )
    );
  }, [dispatch, searchParams]);

  return (
    <div>
      <div className="container pt-4 d-none d-md-block">
        <div className="p-5 mb-4 bg-light border rounded-3 marketplace-jumbotron-cover">
          <div className="container-fluid py-5">
            <h1 className="display-5 ">ReShup Marketplace</h1>
            <h4 className="col-md-7 fw-light">
              Browse our curated selection of premium sneakers and find your
              perfect pair today.
            </h4>
          </div>
        </div>
      </div>
      <div className="d-md-none bg-light p-3">
        <p class="fs-5 mb-2">ReShup Marketplace</p>
        <p class=" mb-1">
          <small>
            Browse our curated selection of premium sneakers and find your
            perfect pair today.
          </small>
        </p>
      </div>
      <BreadcrumbLocation
        location={["Home", "Marketplace"]}
      ></BreadcrumbLocation>

      <div className="container mt-lg-4 mt-2">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block">
            <div class="position-sticky" style={{ top: "6rem" }}>
              <MarketplaceFilters></MarketplaceFilters>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="">
              <div className="row ms-1">
                <div className="d-flex ps-0">
                  <div className="me-auto">
                    <a
                      className="text-decoration-none text-reset dropdown-toggle d-lg-none"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasFilter"
                      role="button"
                    >
                      Filters
                    </a>
                    {searchParams.get("query") ? (
                      <>
                        <p className="fs-5  mb-1 d-none d-lg-block">
                          Results for "{searchParams.get("query")}"
                        </p>
                      </>
                    ) : (
                      <p className="fs-5 mb-1 d-none d-lg-block">
                        Marketplace Results{" "}
                      </p>
                    )}
                  </div>
                  <div className="me-2">
                    <MarketplaceSortDropdown></MarketplaceSortDropdown>
                  </div>
                </div>
              </div>
              <div className="row ms-1 d-lg-none">
                <div className="d-flex ps-0">
                  <div className="me-auto">
                    {searchParams.get("query") ? (
                      <>
                        <p className="fs-5 mb-0">
                          <small>
                            Results for "{searchParams.get("query")}"
                          </small>
                        </p>
                      </>
                    ) : (
                      <p className="fs-5 mb-0">
                        <small>Marketplace Results</small>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4">
                  {listings.map((listingData, index) => {
                    if (listings.length === index + 1) {
                      return (
                        <div
                          className="col mt-0 p-2"
                          ref={marketplaceLastListingRef}
                          key={"MarketplaceListingCard" + index}
                        >
                          <div className="card p-2 position-relative h-100">
                            {listingData.dateString && (
                              <small className="mt-1 me-1 position-absolute top-0 end-0 text-muted">
                                {listingData.dateString}
                              </small>
                            )}
                            <img
                              src={listingData["photo"]}
                              className="card-img-top"
                              alt="..."
                            ></img>
                            <div className="card-body p-1">
                              <div className="d-flex justify-content-between">
                                <h5 className="card-title">
                                  ${Math.floor(listingData["lowestPrice"])}
                                </h5>
                                {/* <h5 className="card-title">${listingData["releaseDate"]}</h5> */}
                              </div>
                              <p
                                className="card-text lh-sm text-capitalize p-0 m-1 "
                                style={{ fontSize: "smaller" }}
                              >
                                {listingData["listingName"]}
                              </p>
                              <Link
                                to={`/listing/${listingData["_id"]}`}
                                className="stretched-link p-0 m-0"
                              ></Link>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <MarketplaceListingCard
                          listingData={listingData}
                          key={"MarketplaceListingCard" + index}
                        ></MarketplaceListingCard>
                      );
                    }
                  })}

                  {listingsLoading && (
                    <>
                      <div className="col mt-0 p-2">
                        <div class="card mx-1">
                          <div class="card-body">
                            <h5 class="card-title placeholder-glow">
                              <span class="placeholder col-6"></span>
                            </h5>
                            <p class="card-text placeholder-glow">
                              <span class="placeholder col-7"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-6"></span>
                              <span class="placeholder col-8"></span>
                            </p>
                            <a
                              class="btn btn-primary disabled placeholder col-6"
                              aria-disabled="true"
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div className="col mt-0 p-2">
                        <div class="card mx-1">
                          <div class="card-body">
                            <h5 class="card-title placeholder-glow">
                              <span class="placeholder col-6"></span>
                            </h5>
                            <p class="card-text placeholder-glow">
                              <span class="placeholder col-7"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-6"></span>
                              <span class="placeholder col-8"></span>
                            </p>
                            <a
                              class="btn btn-primary disabled placeholder col-6"
                              aria-disabled="true"
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div className="col mt-0 p-2">
                        <div class="card mx-1">
                          <div class="card-body">
                            <h5 class="card-title placeholder-glow">
                              <span class="placeholder col-6"></span>
                            </h5>
                            <p class="card-text placeholder-glow">
                              <span class="placeholder col-7"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-6"></span>
                              <span class="placeholder col-8"></span>
                            </p>
                            <a
                              class="btn btn-primary disabled placeholder col-6"
                              aria-disabled="true"
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div className="col mt-0 p-2">
                        <div class="card mx-1">
                          <div class="card-body">
                            <h5 class="card-title placeholder-glow">
                              <span class="placeholder col-6"></span>
                            </h5>
                            <p class="card-text placeholder-glow">
                              <span class="placeholder col-7"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-4"></span>
                              <span class="placeholder col-6"></span>
                              <span class="placeholder col-8"></span>
                            </p>
                            <a
                              class="btn btn-primary disabled placeholder col-6"
                              aria-disabled="true"
                            ></a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {listings.length === 0 && !listingsLoading && (
                  <section class="py-5 text-center container">
                    <div class="row py-lg-5">
                      <div class="col-lg-6 col-md-8 mx-auto">
                        <p class="lead text-body-secondary">
                          Looks like there is nothing matching your search.
                        </p>
                        <p>
                          <Link to="/marketplace" class="btn btn-primary">
                            Go to home
                          </Link>
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterAndSortOffcanvas></FilterAndSortOffcanvas>
    </div>
  );
};

//Can make default props here
MarketplaceScreen.defaultProps = {};

export default MarketplaceScreen;
