import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ToastAlert from "./ToastAlert";

const AlertsContainer = () => {
  const user = useSelector((state) => state.userLogin);
  const { message: userMessage, error: userError } = user;
  const marketplaceListings = useSelector((state) => state.marketplaceListings);
  const { error: marketplaceListingsError } = marketplaceListings;
  const marketplaceBrands = useSelector((state) => state.marketplaceListings);
  const { error: marketplaceBrandsError } = marketplaceBrands;
  const createdMarketplaceListing = useSelector(
    (state) => state.createdMarketplaceListing
  );
  const {
    error: createdMarketplaceListingError,
    successMessage: createdMarketplaceListingSuccessMessage,
    patch: createdMarketplaceListingPatch,
  } = createdMarketplaceListing;
  const deletedMarketplaceListing = useSelector(
    (state) => state.deletedMarketplaceListing
  );
  const {
    error: deletedMarketplaceListingError,
    successMessage: deletedMarketplaceListingSuccessMessage,
  } = deletedMarketplaceListing;

  const stripeUpdatedCustomerShipping = useSelector(
    (state) => state.stripeUpdatedCustomerShipping
  );
  const {
    error: stripeUpdatedCustomerShippingError,
    successMessage: stripeUpdatedCustomerShippingSuccessMessage,
  } = stripeUpdatedCustomerShipping;

  const stripePaymentIntent = useSelector((state) => state.stripePaymentIntent);
  const { error: stripePaymentIntentError } = stripePaymentIntent;

  const userInformation = useSelector((state) => state.userInformation);
  const { error: userInformationError } = userInformation;
  const alerts = useSelector((state) => state.alerts);
  const { error: errorAlert, message: messageAlert } = alerts;

  const userProfile = useSelector((state) => state.userProfile);
  const { error: userProfileError, successMessage: userProfileSuccessMessage } =
    userProfile;

  const userLikes = useSelector((state) => state.userLikes);
  const { error: userLikesError, message: userLikesMessage } = userLikes;

  const offerStatus = useSelector((state) => state.offerStatus);
  const { offerCanceled, offerAccepted, error: offerStatusError } = offerStatus;

  const stripeTransferSellerPayout = useSelector(
    (state) => state.stripeTransferSellerPayout
  );
  const {
    message: stripeTransferSellerPayoutMessage,
    error: stripeTransferSellerPayoutError,
  } = stripeTransferSellerPayout;

  return (
    <div className="toast-container position-fixed top-0 end-0 p-4">
      {userMessage && (
        <ToastAlert
          message={
            "You can edit and view your profile in the account settings menu"
          }
          header={"New account created!"}
        ></ToastAlert>
      )}
      {stripeTransferSellerPayoutMessage && (
        <ToastAlert
          message={stripeTransferSellerPayoutMessage}
          header={"Seller Payout Information"}
        ></ToastAlert>
      )}
      {stripeTransferSellerPayoutError && (
        <ToastAlert
          message={stripeTransferSellerPayoutError}
          header={"Seller Payout Information"}
        ></ToastAlert>
      )}
      {errorAlert && <ToastAlert message={errorAlert}></ToastAlert>}
      {messageAlert && <ToastAlert message={messageAlert}></ToastAlert>}
      {stripePaymentIntentError && (
        <ToastAlert message={stripePaymentIntentError}></ToastAlert>
      )}

      {marketplaceListingsError && (
        <ToastAlert message={marketplaceListingsError}></ToastAlert>
      )}
      {offerStatusError && <ToastAlert message={offerStatusError}></ToastAlert>}
      {marketplaceBrandsError && (
        <ToastAlert message={marketplaceBrandsError}></ToastAlert>
      )}
      {userError && (
        <ToastAlert
          message={
            "Your session has expired please log back in to your account"
          }
          header={"Session expired"}
        ></ToastAlert>
      )}
      {createdMarketplaceListingError && (
        <ToastAlert
          message={createdMarketplaceListingError}
          header={
            createdMarketplaceListingPatch
              ? "Listing could not be updated."
              : "Listing could not be created."
          }
        ></ToastAlert>
      )}
      {createdMarketplaceListingSuccessMessage && (
        <ToastAlert
          message={
            createdMarketplaceListingPatch
              ? "Successfully updated your ReShup listing"
              : "Successfully created a ReShup listing. You can edit your listings in the profile section"
          }
          header={
            createdMarketplaceListingPatch
              ? "Listing updated!"
              : "Listing created!"
          }
        ></ToastAlert>
      )}
      {deletedMarketplaceListingError && (
        <ToastAlert
          message={deletedMarketplaceListingError}
          header={"Listing could not be deleted."}
        ></ToastAlert>
      )}
      {deletedMarketplaceListingSuccessMessage && (
        <ToastAlert
          message={"Successfully deleted your ReShup listing"}
          header={"Listing deleted!"}
        ></ToastAlert>
      )}
      {userProfileError && (
        <ToastAlert
          message={userProfileError}
          header={"Profile could not be updated."}
        ></ToastAlert>
      )}
      {userProfileSuccessMessage && (
        <ToastAlert
          message={"Successfully updated your profile"}
          header={"Profile Updated!"}
        ></ToastAlert>
      )}
      {stripeUpdatedCustomerShippingError && (
        <ToastAlert
          message={stripeUpdatedCustomerShippingError}
          header={"Shipping Error."}
        ></ToastAlert>
      )}
      {stripeUpdatedCustomerShippingSuccessMessage && (
        <ToastAlert
          message={stripeUpdatedCustomerShippingSuccessMessage}
          header={"Shipping Updated!"}
        ></ToastAlert>
      )}
      {userInformationError && (
        <ToastAlert message={userInformationError}></ToastAlert>
      )}
      {userLikesError && <ToastAlert message={userLikesError}></ToastAlert>}
      {userLikesMessage && <ToastAlert message={userLikesMessage}></ToastAlert>}
    </div>
  );
};

export default AlertsContainer;
