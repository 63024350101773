import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadcrumbLocation from "../components/BreadcrumbLocation";
import ProfileNavbar from "../components/Profile_Components/ProfileNavbar";
import ProfileSettings from "../components/Profile_Components/ProfileSettings";
const ProfileSettingsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated } = userLogin;
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      <BreadcrumbLocation
        location={["ReShup", "Settings", "Profile Settings"]}
      ></BreadcrumbLocation>
      <div className="container d-flex flex-row mt-4">
        <ProfileNavbar></ProfileNavbar>
        <ProfileSettings></ProfileSettings>
      </div>
    </>
  );
};

export default ProfileSettingsScreen;
