import React, { useState, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortablePhoto from "./SortablePhoto";
import { Grid } from "./Grid";
import { Photo } from "./Photo";
import CloudinaryUploadWidget from "../CloudinaryUploadWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const DraggableGrid = ({
  photos,
  numPhotos,
  setPhotos,
  marketplaceListingId,
}) => {
  useEffect(() => {
    // setItems(photos);
    setBlankItems([...Array(6 - photos.length).keys()]);
  }, [photos]);
  // const [items, setItems] = useState([]);
  const [blankItems, setBlankItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPhotos((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={photos} strategy={rectSortingStrategy}>
        <Grid>
          {photos.map((url, index) => (
            <SortablePhoto
              key={Math.random()}
              url={url}
              index={index}
              setPhotos={setPhotos}
              numPhotos={numPhotos}
            ></SortablePhoto>
          ))}
          {blankItems.map((url, index) => (
            <div className="d-flex justify-content-center" key={Math.random()}>
              <div
                className="p-0 m-0 border d-flex justify-content-center align-items-center"
                style={{ height: 100, width: 140 }}
              >
                {index == 0 && (
                  <div>
                    <CloudinaryUploadWidget
                      returnPhoto={(photo) =>
                        setPhotos((photos) => [...photos, photo])
                      }
                      folderName={`shupSellerCreatedListings/${marketplaceListingId}`}
                      className={"btn btn-outline-dark"}
                      content={<i class="bi bi-plus-lg"></i>}
                      croppingAspectRatio={1.4}
                    ></CloudinaryUploadWidget>
                  </div>
                )}
              </div>
            </div>
          ))}
        </Grid>
      </SortableContext>

      <DragOverlay adjustScale={true}>
        {activeId ? (
          <Photo url={activeId} index={photos.indexOf(activeId)} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DraggableGrid;
