import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  cancelOffer,
  getOffersForSale,
  confirmOffer,
} from "../../actions/offerActions";
import { useNavigate } from "react-router-dom";
import { OFFER_STATUS_RESET } from "../../constants/offerConstants";

import LoadingSpinner from "../LoadingSpinner";

const ProfileOffer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offers = useSelector((state) => state.offers);
  const { offer, loading: offerLoading } = offers;
  const offerStatus = useSelector((state) => state.offerStatus);
  const {
    offerCanceled,
    offerAccepted,
    loading: offerStatusLoading,
  } = offerStatus;

  useEffect(() => {
    if (offerAccepted) {
      // Navigate to sold items for seller to show steps
      dispatch({ type: OFFER_STATUS_RESET });
      dispatch(getOffersForSale());
      navigate("/offeraccepted");
    }
    if (offerCanceled) {
      // Navigate to offers and show the canceled alert
      dispatch({ type: OFFER_STATUS_RESET });
      dispatch(getOffersForSale());
      navigate("/profile/offers");
    }
  }, [offerAccepted, offerCanceled]);

  const cancelOfferHandler = () => {
    dispatch(cancelOffer(offer._id, offer.updatedAt));
  };
  const confirmOfferHandler = () => {
    dispatch(confirmOffer(offer._id, offer.updatedAt));
  };

  const millisecondsToHoursAndMinutes = (milliseconds) => {
    const totalMinutes = Math.floor(milliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}h and ${minutes}m`;
  };
  return (
    <div className="col-md-5">
      {offerLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        offer && (
          <>
            {offer.offerAvailable ? (
              offer.timeTillExpires && (
                <span class="badge text-bg-dark mb-2">
                  Offer will expire in{" "}
                  {millisecondsToHoursAndMinutes(offer.timeTillExpires)}
                </span>
              )
            ) : offer.offerStatus == "Accepted" ? (
              <span class="badge text-bg-success mb-2">
                Offer was {offer.offerStatus}
              </span>
            ) : (
              <span class="badge text-bg-danger mb-2">
                Offer was {offer.offerStatus}
              </span>
            )}
            <div className="container text-center">
              <img className="img-fluid" src={offer.thumbnail}></img>
              <h5 className="mt-3">{offer.title}</h5>
              <p>
                US {offer.sizeType} Size: {offer.size} | Condition: New | ReShup
                Verified ✓
              </p>
            </div>
            {offer.isSeller ? (
              <>
                <div>
                  <div class="border-bottom my-3"></div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Buyer Offer Price:</p>
                    <p class="mb-1">
                      ${(offer.purchasePrice / 100).toFixed(2)}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Seller Fee:</p>
                    <p class="mb-1">${(offer.sellerFee / 100).toFixed(2)}</p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Shipping:</p>
                    <p class="mb-1">
                      ${(offer.sellerShippingPrice / 100).toFixed(2)}
                    </p>
                  </div>
                  <div class="border-bottom my-3"></div>
                  <div class="d-flex justify-content-between">
                    <p class="mb-1">If accepted, you recieve:</p>
                    <h5 class="mb-1">${(offer.netProfit / 100).toFixed(2)}</h5>
                  </div>
                </div>
                {offer.offerAvailable && (
                  <div className="row mt-2">
                    <div className="col-6">
                      <button
                        class="btn btn-outline-dark w-100"
                        type="button"
                        disabled={offerStatusLoading}
                        onClick={() => cancelOfferHandler()}
                      >
                        {offerStatusLoading && (
                          <span class="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Decline offer
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        class="btn btn-outline-primary w-100"
                        type="button"
                        disabled={offerStatusLoading}
                        onClick={() => confirmOfferHandler()}
                      >
                        {offerStatusLoading && (
                          <span class="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Accept offer
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div>
                  <div class="border-bottom my-3"></div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Your Offer Price:</p>
                    <p class="mb-1">
                      ${(offer.purchasePrice / 100).toFixed(2)}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Sales Tax:</p>
                    <p class="mb-1">${(offer.taxPrice / 100).toFixed(2)}</p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-1">Shipping:</p>
                    <p class="mb-1">
                      ${(offer.shippingPrice / 100).toFixed(2)}
                    </p>
                  </div>
                  <div class="border-bottom my-3"></div>
                  <div class="d-flex justify-content-between">
                    <p class="mb-1">If seller accepts, you pay:</p>
                    <h5 class="mb-1">${(offer.totalPrice / 100).toFixed(2)}</h5>
                  </div>
                </div>
                <div className="container text-center mt-2">
                  <p>
                    <strong>Ship to </strong>
                    {offer.shippingAddress.address.line1}{" "}
                    {offer.shippingAddress.address.city}{" "}
                    {offer.shippingAddress.address.state},{" "}
                    {offer.shippingAddress.address.postal_code}{" "}
                    {offer.shippingAddress.address.country}
                  </p>
                </div>
                {offer.offerAvailable && (
                  <div class="d-grid">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      disabled={offerStatusLoading}
                      onClick={() => cancelOfferHandler()}
                    >
                      {offerStatusLoading && (
                        <span class="spinner-border spinner-border-sm me-2"></span>
                      )}
                      Cancel offer
                    </button>
                  </div>
                )}
              </>
            )}
            {!offer.offerAvailable &&
              offer.offerStatus !== "Accepted" &&
              offer.isSeller == false && (
                <div class="d-grid">
                  <Link
                    class="btn btn-primary"
                    to={`/sellerlisting/${offer.listingId}`}
                    // class="btn btn-subtleprimary"
                  >
                    Create a new offer
                  </Link>
                </div>
              )}
          </>
        )
      )}
    </div>
  );
};

export default ProfileOffer;
