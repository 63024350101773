import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketplaceListingSelling,
  createMarketplaceListing,
  deleteMarketplaceListing,
  getSellerListing,
  clearSellerListing,
} from "../actions/marketplaceActions";
import {
  MARKETPLACE_PRODUCT_CREATE_RESET,
  MARKETPLACE_PRODUCT_DELETE_RESET,
} from "../constants/marketplaceConstants";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import DraggableGrid from "../components/Draggable_Components/DraggableGrid";
import LocationModal from "../components/Listing_Components/LocationModal";
import ShippingModal from "../components/ShippingModal";
import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget";
import { getStripeAccount, getStripeCustomer } from "../actions/stripeActions";
import { getUserInformation, setLocation } from "../actions/userActions";
import NotFoundScreen from "../screens/NotFoundScreen";

const UserSellingScreen = () => {
  //Note that when the token expires the application can be in a weird state from an error. Need ot make sure these states align and dont crash the application
  const params = useParams();
  const listingId = params.id;
  const sellerListingId = params.sid;
  const dispatch = useDispatch();
  const sellerListing = useSelector((state) => state.sellerListing);
  const {
    sellerListing: sellerListingInformation,
    listingLoading: sellerListingLoading,
    error: sellerListingError,
  } = sellerListing;
  //Values that will be added for new listing
  const [photos, setPhotos] = useState([]);
  const [title, setTitle] = useState("");
  const [productUpdatedAt, setProductUpdatedAt] = useState(null);
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [isSizeValid, setIsSizeValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [finalCheckOne, setFinalCheckOne] = useState(false);
  const [finalCheckTwo, setFinalCheckTwo] = useState(false);
  const [isFinalCheckOneValid, setIsFinalCheckOneValid] = useState(true);
  const [isFinalCheckTwoValid, setIsFinalCheckTwoValid] = useState(true);
  const [price, setPrice] = useState("");
  const [distance, setDistance] = useState("20");
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, token } = userLogin;
  const marketplaceListingSelling = useSelector(
    (state) => state.marketplaceListingSelling
  );
  const { listingLoading, listingInformation, error } =
    marketplaceListingSelling;
  const createdMarketplaceListing = useSelector(
    (state) => state.createdMarketplaceListing
  );
  const {
    redirect: createdMarketplaceListingRedirect,
    listingId: createdMarketplaceListingId,
    loading: createdMarketplaceListingLoading,
  } = createdMarketplaceListing;
  const deletedMarketplaceListing = useSelector(
    (state) => state.deletedMarketplaceListing
  );
  const {
    success: deletedMarketplaceListingSuccess,
    loading: deletedMarketplaceListingLoading,
  } = deletedMarketplaceListing;
  const locationInformation = useSelector((state) => state.location);
  const { location } = locationInformation;
  const userInformation = useSelector((state) => state.userInformation);
  const { loading: userInformationLoading, userInformation: userDetails } =
    userInformation;
  const stripeAccount = useSelector((state) => state.stripeAccount);
  const { stripeAccount: account, loading: stripeAccountLoading } =
    stripeAccount;
  const stripeCustomer = useSelector((state) => state.stripeCustomer);
  const { loading: customerLoading, customer } = stripeCustomer;
  const navigate = useNavigate();
  const browserLocation = useLocation();
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${browserLocation.pathname}`);
    }
    if (isAuthenticated === true) {
      dispatch(getMarketplaceListingSelling(listingId));
      dispatch(getUserInformation(token));
      dispatch(getStripeAccount());
      dispatch(getStripeCustomer());
      if (sellerListingId !== undefined) {
        dispatch(getSellerListing(sellerListingId));
      } else {
        dispatch(clearSellerListing());
      }

      // getLocation();
    }

    if (createdMarketplaceListingRedirect) {
      navigate(`/sellerlisting/${createdMarketplaceListingId}`);
      dispatch({ type: MARKETPLACE_PRODUCT_CREATE_RESET });
    }

    if (deletedMarketplaceListingSuccess) {
      navigate(`/profile/selling`);
      dispatch({ type: MARKETPLACE_PRODUCT_DELETE_RESET });
    }
  }, [
    userLogin,
    createdMarketplaceListingRedirect,
    deletedMarketplaceListingSuccess,
    dispatch,
  ]);

  useEffect(() => {
    setTitle(sellerListingInformation ? sellerListingInformation.title : "");
    setProductUpdatedAt(
      sellerListingInformation ? sellerListingInformation.updatedAt : null
    );
    setPrice(sellerListingInformation ? sellerListingInformation.price : "");
    setSize(sellerListingInformation ? sellerListingInformation.size : "");
    setDescription(
      sellerListingInformation ? sellerListingInformation.description : ""
    );
    setPhotos(sellerListingInformation ? sellerListingInformation.photos : []);
    if (sellerListingInformation && sellerListingInformation.location) {
      dispatch(setLocation(sellerListingInformation.location.coordinates));
    }
    setFinalCheckOne(sellerListingInformation ? true : false);
    setFinalCheckTwo(sellerListingInformation ? true : false);
  }, [sellerListingInformation]);

  const submitHandler = (event) => {
    event.preventDefault();

    const sizeValidation = validateSize();
    const priceValidation = validatePrice();
    const finalCheckValidation = validateFinalChecks();

    if (!sizeValidation || !priceValidation || !finalCheckValidation) {
      return;
    }
    //Create new Listing need to still add submit handle checks like making sure all the info is there.
    const shipping = customer && customer.shipping ? customer.shipping : null;
    const sellingInformation = {
      _id: sellerListingId,
      title: listingInformation.listingName,
      photos: photos,
      condition: "New",
      proofOfPurchase: "Yes",
      shipping: shipping,
      description: description,
      size: size,
      price: price,
      location: location,
      productUpdatedAt: productUpdatedAt,
      sizeType: listingInformation.sizeType,
    };
    dispatch(createMarketplaceListing(listingId, sellingInformation));
  };

  const deleteItem = () => {
    dispatch(
      deleteMarketplaceListing(
        listingId,
        sellerListingId,
        sellerListingInformation ? sellerListingInformation.updatedAt : null
      )
    );
  };

  const validateSize = () => {
    const valid = size.trim() !== "";
    setIsSizeValid(valid);
    return valid;
  };
  const validateFinalChecks = () => {
    setIsFinalCheckOneValid(finalCheckOne);
    setIsFinalCheckTwoValid(finalCheckTwo);
    return finalCheckOne && finalCheckTwo;
  };

  const validatePrice = () => {
    // Helper to make sure a number is passed and it is < 3000.00 and 25.00 < x
    const number = Number(price);
    // Check if it is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      setIsPriceValid(false);
      return false;
    }

    // Check if it is between 25 and 3000
    if (number < 25 || number > 3000) {
      setIsPriceValid(false);

      return false;
    }

    // Check if it has at most two decimal places
    const decimalString = number.toString().split(".")[1];
    if (decimalString && decimalString.length > 2) {
      setIsPriceValid(false);

      return false;
    }

    // If all checks pass, it's a valid number
    return true;
  };

  const sellerFeeDecimal = userDetails?.sellerFeeDecimal || 0.07;
  const sellerShippingCents = userDetails?.sellerShippingCents || 1500;

  const finalPriceCents = Math.round(
    price * 100 - price * 100 * sellerFeeDecimal - sellerShippingCents
  );

  const finalPriceDollars = (finalPriceCents / 100).toFixed(2);

  return (
    <>
      {stripeAccountLoading ||
      userInformationLoading ||
      listingLoading ||
      sellerListingLoading ? (
        <div className="container col-md-9">
          <div className=" mt-0 p-2">
            <div class="card mx-1">
              <div class="card-body">
                <h5 class="card-title placeholder-glow">
                  <span class="placeholder col-6"></span>
                </h5>
                <p class="card-text placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                </p>
                <a
                  class="btn btn-primary disabled placeholder col-6"
                  aria-disabled="true"
                ></a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <LocationModal
            distance={distance}
            setDistance={setDistance}
          ></LocationModal>
          {sellerListingError ||
          error ||
          (sellerListingInformation &&
            (listingId != sellerListingInformation.shupShoeId ||
              !userDetails ||
              userDetails._id != sellerListingInformation.userId)) ? (
            <NotFoundScreen></NotFoundScreen>
          ) : !account || (account && !account.charges_enabled) ? (
            <section class="py-5 text-center container">
              <div class="row py-lg-5">
                <div class="col-lg-6 col-md-8 mx-auto">
                  <h1 class="fw-light">Payout settings not set up</h1>
                  <p class="lead text-muted">
                    To list an item on ReShup, you need to set up a payment
                    method. You can do this by configuring your payout settings
                    in your user settings.
                  </p>
                  <p>
                    <Link
                      to="/profile/settings#scrollspyPayoutSettings"
                      class="btn btn-primary my-2"
                    >
                      Configure payout settings.
                    </Link>
                  </p>
                </div>
              </div>
            </section>
          ) : (
            <div className="mt-2 mt-lg-4">
              <div className="container col-md-9 mb-2 mb-lg-4">
                <div className="row justify-content-between">
                  <div className="col">
                    <nav className="">
                      <ol className="breadcrumb mb-0">
                        {/* <li className="breadcrumb-item active">ReShup</li> */}
                        <li className="breadcrumb-item active">Sell</li>
                        <li className="breadcrumb-item active">
                          {listingInformation.listingName}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="container col-md-9">
                <div className="border rounded p-3">
                  <div className="row">
                    <div className=" col-lg-4">
                      {/* <h4 className="text-capitalize">
                          {listingInformation.listingName}
                        </h4> */}

                      <img
                        src={listingInformation.photo}
                        className="img-fluid"
                        alt="..."
                      ></img>
                    </div>
                    <div className=" col-lg-7 ">
                      <h4 className="">{listingInformation.listingName}</h4>
                      {/* <h5 className="text-primary">
                          {listingInformation.make}
                        </h5> */}
                      <div className="d-none d-lg-block">
                        <p className="fw-bold mb-1">
                          Brand:
                          <span className="fw-normal">
                            {" "}
                            {listingInformation.brand}
                          </span>
                        </p>
                        <p className="fw-bold mb-1">
                          Make:
                          <span className="fw-normal">
                            {" "}
                            {listingInformation.make}
                          </span>
                        </p>
                        <p className="fw-bold mb-1">
                          Colorway:
                          <span className="fw-normal">
                            {" "}
                            {listingInformation.colorway}
                          </span>
                        </p>
                        <p className="fw-bold mb-1">
                          Retail Price:
                          <span className="fw-normal">
                            {" "}
                            ${listingInformation.retailPrice}
                          </span>
                        </p>
                        <p className="fw-bold mb-1">
                          Release Date:
                          <span className="fw-normal">
                            {" "}
                            {listingInformation.dateString}
                          </span>
                        </p>
                        <p className="fw-bold mb-1">
                          Style Id:
                          <span className="fw-normal">
                            {" "}
                            {listingInformation.styleId}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col d-lg-none">
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Brand:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.brand}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Make:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.make}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Colorway:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.colorway}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Retail Price:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">
                            ${listingInformation.retailPrice}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Release Date:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">
                            {listingInformation.dateString}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="text-muted mb-1">Style Id:</p>
                        </div>
                        <div className="col-7">
                          <p className="mb-1">{listingInformation.styleId}</p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col d-md-none">
                        <div
                          className="accordion accordion-flush"
                          id="userSellingScreenAccordian"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="userSellingScreenHeadingOne"
                            >
                              <button
                                className="accordion-button collapsed border-bottom"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#userSellingScreenCollapseOne"
                              >
                                Product details
                              </button>
                            </h2>
                            <div
                              id="userSellingScreenCollapseOne"
                              className="accordion-collapse collapse"
                              data-bs-parent="#userSellingScreenAccordian"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">Brand:</p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      {listingInformation.brand}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">Make:</p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      {listingInformation.make}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">Colorway:</p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      {listingInformation.colorway}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">
                                      Retail Price:
                                    </p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      ${listingInformation.retailPrice}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">
                                      Release Date:
                                    </p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      {listingInformation.dateString}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-5">
                                    <p className="text-muted mb-1">Style Id:</p>
                                  </div>
                                  <div className="col-7">
                                    <p className="mb-1">
                                      {listingInformation.styleId}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>

              <div className="container col-md-9 mt-4">
                <form onSubmit={(event) => submitHandler(event)} noValidate>
                  <h3 className="mt-2">Selling Details</h3>
                  <div className="row g-3 pb-3">
                    <div className="col-md-4">
                      <label className="form-label">Sizing:</label>
                      <select className="form-select" required>
                        {listingInformation &&
                        listingInformation.sizeType == "Mens" ? (
                          <option value="1">Adult Men</option>
                        ) : (
                          <option value="1">Adult Women</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Size:</label>
                      <select
                        className={`form-select ${
                          isSizeValid ? "" : "is-invalid"
                        }`}
                        onChange={(event) => {
                          setSize(event.target.value);
                          setIsSizeValid(true);
                        }}
                        value={size}
                        required
                      >
                        <option disabled value="">
                          Select Size
                        </option>
                        <option value="4">4</option>
                        <option value="4.5">4.5</option>
                        <option value="5">5</option>
                        <option value="5.5">5.5</option>
                        <option value="6">6</option>
                        <option value="6.5">6.5</option>
                        <option value="7">7</option>
                        <option value="7.5">7.5</option>
                        <option value="8">8</option>
                        <option value="8.5">8.5</option>
                        <option value="9">9</option>
                        <option value="9.5">9.5</option>
                        <option value="10">10</option>
                        <option value="10.5">10.5</option>
                        <option value="11">11</option>
                        <option value="11.5">11.5</option>
                        <option value="12">12</option>
                        <option value="12.5">12.5</option>
                        <option value="13">13</option>
                        <option value="13.5">13.5</option>
                        <option value="14">14</option>
                        {/* <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option> */}
                      </select>
                      <div className="invalid-feedback">Select size.</div>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Condition:</label>
                      <select
                        className="form-select"
                        id="floatingSelectGrid"
                        aria-label="Default select example"
                        required
                      >
                        <option value="1" defaultChecked>
                          New with box
                        </option>
                        <option value="1" disabled>
                          New without box
                        </option>
                        <option value="1" disabled>
                          New with defects
                        </option>
                        <option value="1" disabled>
                          Used
                        </option>
                        {/* <option value="2">Used - Like New</option>
                 <option value="3">Used - Good</option>
                 <option value="4">Used - Fair</option> */}
                      </select>
                      <p>
                        <small>
                          Note: All items for sale on the ReShup marketplace
                          must be new with box. Other item options will be
                          available for sale soon.
                        </small>
                      </p>
                    </div>

                    <div className="col-md-11">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        onBlur={(event) => setDescription(event.target.value)}
                        defaultValue={description}
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Final Checks</label>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${
                            isFinalCheckOneValid ? "" : "is-invalid"
                          }`}
                          type="checkbox"
                          checked={finalCheckOne}
                          id="genuineCheck"
                          onChange={() => {
                            setFinalCheckOne(!finalCheckOne);
                            setIsFinalCheckOneValid(true);
                          }}
                          // defaultChecked={sellerListingInformation}
                          required
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="genuineCheck"
                        >
                          My sneakers are new, unworn with the original,
                          undamaged box.
                        </label>
                        <div className="invalid-feedback">
                          You must agree to this.
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${
                            isFinalCheckTwoValid ? "" : "is-invalid"
                          }`}
                          type="checkbox"
                          id="shippingCheck"
                          checked={finalCheckTwo}
                          onChange={() => {
                            setFinalCheckTwo(!finalCheckTwo);
                            setIsFinalCheckTwoValid(true);
                          }}
                          required
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="shippingCheck"
                        >
                          I will ship within 2 business days of sale to avoid
                          penalties.
                        </label>
                        <div className="invalid-feedback">
                          You must agree to this.
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-4">Photos</h3>
                  <div className="container-fluid">
                    {/* {photos.length === 0 ? (
                      <div class="row justify-content-center">
                        <div className="col border rounded p-5">
                          <div class="vstack gap-2 col-md-4 mx-auto">
                            {userDetails && (
                              <CloudinaryUploadWidget
                                returnPhoto={(photo) =>
                                  setPhotos((photos) => [...photos, photo])
                                }
                                folderName={`shupSellerCreatedListings/${listingInformation._id}`}
                                className={"btn btn-primary"}
                                content={"Add Photo"}
                                croppingAspectRatio={1.4}
                              ></CloudinaryUploadWidget>
                            )}
                            <p class="fw-normal text-primary text-center">
                              Upload photos to improve buyer confidence. Up to
                              6.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="container">
                        {userDetails && (
                          <DraggableGrid
                            setPhotos={setPhotos}
                            photos={photos}
                            numPhotos={photos.length}
                            marketplaceListingId={listingInformation._id}
                          ></DraggableGrid>
                        )}
                      </div>
                    )} */}

                    <div className="container">
                      {userDetails && (
                        <DraggableGrid
                          setPhotos={setPhotos}
                          photos={photos}
                          numPhotos={photos.length}
                          marketplaceListingId={listingInformation._id}
                        ></DraggableGrid>
                      )}
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-3">
                    <div>
                      <h3>Delivery</h3>
                    </div>
                    <div className="ms-3 mt-1">
                      {/* <Link
                        to="/profile/settings/shipping"
                        className="btn btn-sm btn-outline-dark"
                      >
                        Edit
                      </Link> */}
                      {customer && customer.shipping && (
                        <a
                          // class="link-primary text-decoration-none"
                          class="btn btn-sm btn-outline-dark"
                          // style={{ cursor: "pointer" }}
                          data-bs-toggle="modal"
                          data-bs-target="#shippingModal"
                        >
                          Edit
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="row pb-3 border-bottom">
                    <div className="col-lg-6 my-3">
                      {customer && customer.shipping ? (
                        <div class="border rounded shadow-sm p-3">
                          <h6 class="mb-1">{customer.shipping.name}</h6>
                          <p class="text-body-secondary fw-light mb-0">
                            {customer.shipping.address.line1}
                          </p>
                          <p class="text-body-secondary fw-light mb-1">
                            {`${customer.shipping.address.city}, ${customer.shipping.address.state} ${customer.shipping.address.postal_code} US`}
                          </p>
                        </div>
                      ) : (
                        <>
                          <a
                            // class="link-primary text-decoration-none"
                            class="btn btn-primary"
                            // style={{ cursor: "pointer" }}
                            data-bs-toggle="modal"
                            data-bs-target="#shippingModal"
                          >
                            Add a shipping address
                          </a>
                          <p class="opacity-75 p-2">
                            No shipping information on file. You need this to
                            list an item.
                          </p>
                        </>
                      )}
                    </div>
                    {/* <div class="d-flex flex-column gap-4 align-items-center ">
                        <div class="list-group list-group-radio gap-2">
                          {paymentMethods.map((paymentMethod, index) => (
                            <div
                              class="position-relative rounded"
                              key={paymentMethod.id}
                            >
                              <input
                                class="form-check-input position-absolute top-50 end-0 me-3 fs-5"
                                type="radio"
                                name="listGroupRadioGrid"
                                value={paymentMethod.id}
                                id={`paymentScreenRadio${index}`}
                                checked={
                                  selectedPaymentMethodId == paymentMethod.id
                                }
                                onChange={(e) => {
                                  setSelectedPaymentMethodId(e.target.value);
                                }}
                              ></input>
                              <label
                                class="list-group-item py-3 pe-5"
                                htmlFor={`paymentScreenRadio${index}`}
                              >
                                <div class="hstack gap-3">
                                  <div>
                                    <img
                                      className="img-fluid"
                                      style={{ width: 48 }}
                                      src={
                                        cardBrandToUrl[paymentMethod.card.brand]
                                      }
                                    ></img>
                                  </div>
                                  <div>
                                    <strong class="fw-semibold text-capitalize">
                                      {paymentMethod.card.brand} ****
                                      {paymentMethod.card.last4}{" "}
                                      {paymentMethod.card.exp_month}/
                                      {paymentMethod.card.exp_year}
                                    </strong>
                                    <span class="d-block small opacity-75">
                                      {`${paymentMethod.billing_details.address.line1}, ${paymentMethod.billing_details.address.city} ${paymentMethod.billing_details.address.state} ${paymentMethod.billing_details.address.postal_code} US`}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div> */}
                    <div className="col-12 ">
                      <div class="list-group">
                        <label class="list-group-item d-flex gap-2">
                          <input
                            class="form-check-input flex-shrink-0"
                            type="radio"
                            name="listGroupRadios"
                            id="listGroupRadios1"
                            value=""
                            defaultChecked
                          ></input>

                          <span>
                            <strong>Standard Shipping</strong>
                            <small class="d-block opacity-75">
                              Best for items across the US. We'll email you a
                              label and when your item sells you'll ship to us
                              for verification.
                            </small>
                          </span>
                        </label>
                        <label class="list-group-item d-flex gap-2">
                          <input
                            class="form-check-input flex-shrink-0"
                            type="radio"
                            name="listGroupRadios"
                            id="listGroupRadios2"
                            disabled
                          ></input>
                          <span className="opacity-50">
                            <strong>Local selling</strong>
                            <small class="d-block opacity-75">
                              Skip the shipping and fees and meet up locally to
                              sell your kicks!{" "}
                              <em>
                                This option will be available in the future.
                              </em>
                            </small>
                          </span>
                        </label>
                        <label class="list-group-item d-flex gap-2">
                          <input
                            class="form-check-input flex-shrink-0"
                            type="radio"
                            name="listGroupRadios"
                            id="listGroupRadios3"
                            disabled
                          ></input>
                          <span className="opacity-50">
                            <strong>Local selling and shipping</strong>
                            <small class="d-block opacity-75">
                              Reach the widest range of buyers. Buyers will have
                              the choice of purchasing though shipping and also
                              will be able to locally meet.{" "}
                              <em>
                                This option will be available in the future.
                              </em>
                            </small>
                          </span>
                        </label>
                        <label class="list-group-item d-flex gap-2">
                          <input
                            class="form-check-input flex-shrink-0"
                            type="radio"
                            name="listGroupRadios"
                            id="listGroupRadios4"
                            disabled
                          ></input>
                          <span className="opacity-50">
                            <strong>ReShup verified partner</strong>
                            <small class="d-block opacity-75">
                              Skip the ReShup verification step shipping and
                              ship directly to your customer! Only the top and
                              most trusted sellers will have this option.{" "}
                              <em>
                                This option will be available in the future.
                              </em>
                            </small>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <h3 className="mt-4">Location</h3>
                  <label className="form-label">
                    Location will be used in the future for local buying and
                    selling.
                  </label>
                  <div className="row pb-3 border-bottom">
                    <div className="col-12">
                      <a
                        href=""
                        className="text-decoration-none link-dark"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        {location ? (
                          <h5>
                            Current Location - Within {distance} Miles{" "}
                            <i class="bi bi-geo-alt"></i>
                          </h5>
                        ) : (
                          <h5>
                            Get Current Location <i class="bi bi-geo-alt"></i>
                          </h5>
                        )}
                      </a>
                    </div>
                  </div>
                  <h3 className="mt-4">Price</h3>
                  {userDetails && (
                    <div className="pb-3 border-bottom">
                      <div className="row">
                        <div className="col">
                          <label className="form-label">
                            <strong>Set Price</strong>
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <input
                              type="number"
                              min="25"
                              max="3000"
                              placeholder="(min $25/max $3000)"
                              // className="form-control text-end "
                              className={`form-control text-end rounded-end ${
                                isPriceValid ? "" : "is-invalid"
                              }`}
                              onChange={(event) => {
                                setPrice(event.target.value);
                                setIsPriceValid(true);
                              }}
                              value={price}
                              required
                            ></input>
                            <div className="invalid-feedback">
                              Invalid price, Listing must be greater than $25.00
                              and less than $3000.00.
                            </div>
                          </div>
                        </div>
                      </div>
                      {price && (
                        <div className="row">
                          <div className="col-auto me-auto ">
                            <p className="text-secondary m-0">
                              ReShup fee{" "}
                              {`(${userDetails.sellerFeeString || "7"}%)`}
                            </p>
                          </div>
                          <div className="col-auto">
                            <p className="text-secondary m-0">
                              -$
                              {(
                                Number(price) *
                                (userDetails.sellerFeeDecimal || 0.07)
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-auto me-auto ">
                          <p className="text-secondary m-0">Shipping</p>
                        </div>
                        <div className="col-auto">
                          <p className="text-secondary m-0">{`-$${
                            userDetails.sellerShippingString || "15.00"
                          }`}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto me-auto ">
                          <p className="">
                            <strong>You earn</strong>
                          </p>
                        </div>
                        <div className="col-auto">
                          <p className="">
                            <strong>
                              {/* {price
                                ? `$${(
                                    Number(price) -
                                    Number(price) *
                                      (userDetails.sellerFeeDecimal || 0.07) -
                                    (Math.round(
                                      userDetails.sellerShippingCents / 100
                                    ) || 15.0)
                                  ).toFixed(2)}`
                                : `-$${
                                    userDetails.sellerShippingString || "15"
                                  }`} */}
                              {/* {`$${
                                Math.round(
                                  Number(price) * 100 -
                                    Number(price) *
                                      100 *
                                      (userDetails.sellerFeeDecimal || 0.07) -
                                    (userDetails.sellerShippingCents || 1500)
                                ) / 100
                              }`} */}
                              {finalPriceDollars}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="container text-center mt-4">
                    <div className="row justify-content-center g-2">
                      <div className="col-lg-6">
                        {createdMarketplaceListingLoading ? (
                          <button
                            className="btn btn-primary w-100"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {sellerListingInformation
                              ? "Save listing"
                              : "Create listing"}
                          </button>
                        )}
                      </div>

                      {sellerListingInformation &&
                        (deletedMarketplaceListingLoading ? (
                          <div className="col-lg-6">
                            <button
                              className="btn btn-dark w-100"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                              ></span>
                              Deleting...
                            </button>
                          </div>
                        ) : (
                          <div className="col-lg-6">
                            <button
                              type="button"
                              className="btn btn-dark w-100"
                              onClick={deleteItem}
                            >
                              Delete listing
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </form>
                {(!isSizeValid ||
                  !isPriceValid ||
                  !isFinalCheckOneValid ||
                  !isFinalCheckTwoValid) && (
                  <div className="d-flex justify-content-center">
                    <div
                      class="alert alert-danger alert-dismissible fade show mt-3 col-lg-6"
                      role="alert"
                    >
                      Please complete the missing fields.
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                )}
              </div>
              <ShippingModal></ShippingModal>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserSellingScreen;
