import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Circle,
  useLoadScript,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../config/apiKeys";

const GoogleMapComponent = ({ latitude, longitude }) => {
  const [center, setCenter] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  // const isLoaded = true;
  const mapContainerStyle = {
    width: "100%",
    height: "350px",
  };

  useEffect(() => {
    setCenter({
      lat: latitude,
      lng: longitude,
    });
  }, [latitude, longitude]);
  return (
    <>
      {!isLoaded && <h1>Loading</h1>}
      {isLoaded && center && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          // options={{
          //   apiKey: "AIzaSyAJdkGCNO6QZV4_b0b651RV47-_KUhLpvs",
          //   // Other options...
          // }}
        >
          <Circle
            center={center}
            radius={1609.34} // 1 mile in meters
            options={{
              strokeColor: "#007BFF",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#007BFF",
              fillOpacity: 0.35,
            }}
          />
        </GoogleMap>
      )}
    </>
  );
};

export default GoogleMapComponent;
