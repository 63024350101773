import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner.js";
import { updateProfile, login } from "../../actions/userActions.js";
import CloudinaryUploadWidget from "../../components/CloudinaryUploadWidget";

const ProfileInformation = () => {
  const [userId, setUserId] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [profileSellerbio, setProfileSellerbio] = useState("");
  const [profileInstagram, setProfileInstagram] = useState("");
  const [profileTwitter, setProfileTwitter] = useState("");
  const [offerNotifications, setOfferNotifications] = useState(true);
  const [generalNotifications, setGeneralNotifications] = useState(true);
  const [orderNotifications, setOrderNotifications] = useState(true);

  const userInformation = useSelector((state) => state.userInformation);
  const { userInformation: userDetails, loading: userInformationLoading } =
    userInformation;

  const userProfile = useSelector((state) => state.userProfile);
  const { profileUpdating: userProfileLoading } = userProfile;

  useEffect(() => {
    if (userDetails) {
      setUserId(userDetails._id);
      setProfilePhoto(userDetails.photo ? userDetails.photo : "");
      setProfileSellerbio(userDetails.sellerBio ? userDetails.sellerBio : "");
      setProfileInstagram(
        userDetails.sellerInstagram ? userDetails.sellerInstagram : ""
      );
      setProfileTwitter(
        userDetails.sellerTwitter ? userDetails.sellerTwitter : ""
      );
      setOfferNotifications(
        userDetails.offerNotifications ? userDetails.offerNotifications : false
      );
      setGeneralNotifications(
        userDetails.generalNotifications
          ? userDetails.generalNotifications
          : false
      );
    }
  }, [userDetails]);

  const dispatch = useDispatch();
  const submitHandler = async (event) => {
    event.preventDefault();
    await dispatch(
      updateProfile({
        photo: profilePhoto,
        sellerBio: profileSellerbio,
        sellerInstagram: profileInstagram,
        sellerTwitter: profileTwitter,
        offerNotifications: offerNotifications,
        generalNotifications: generalNotifications,
        orderNotifications: orderNotifications,
      })
    );
    dispatch(login());
  };
  return (
    <>
      {userInformationLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <div className="container ms-sm-5">
          <h4 className="mb-4">Edit Profile Information</h4>
          <form className="row" onSubmit={(event) => submitHandler(event)}>
            <div className="col-lg-7">
              <div className="p-0 p-lg-3">
                <div className="mb-3">
                  <img
                    src={profilePhoto}
                    className="bd-placeholder-img rounded-circle mx-auto"
                    alt=""
                    style={{ width: 100, height: 100 }}
                  ></img>
                  <CloudinaryUploadWidget
                    returnPhoto={setProfilePhoto}
                    folderName={`shupUserProfilePictures/${userId}`}
                    className={"btn btn-outline-dark ms-3"}
                    content={"Upload Photo"}
                    croppingAspectRatio={1}
                  ></CloudinaryUploadWidget>
                </div>
                <div className="mb-3">
                  <label htmlFor="profileBio" className="form-label">
                    Seller Bio
                  </label>
                  <textarea
                    className="form-control"
                    id="profileBio"
                    rows="3"
                    defaultValue={profileSellerbio}
                    onBlur={(event) => setProfileSellerbio(event.target.value)}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fab fa-instagram"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={profileInstagram}
                      onBlur={(event) =>
                        setProfileInstagram(event.target.value)
                      }
                    ></input>
                  </div>
                  <div id="emailHelp" className="form-text">
                    If you sell shoes on Instagram and would like buyers to
                    know.
                  </div>
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fab fa-twitter"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={profileTwitter}
                      onBlur={(event) => setProfileTwitter(event.target.value)}
                    ></input>
                  </div>
                  <div id="emailHelp" className="form-text">
                    If you sell shoes on Twitter and would like buyers to know.
                  </div>
                </div>
                <div className="mb-4">
                  <h5>Notifications</h5>
                  <hr className="my-2"></hr>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="orderNotificationsSwitch"
                      checked={orderNotifications}
                      onChange={() =>
                        setOrderNotifications(!orderNotifications)
                      }
                    ></input>
                    <label
                      class="form-check-label"
                      htmlFor="orderNotificationsSwitch"
                    >
                      I would like to recieve email updates on my purchases and
                      my sold listings.
                    </label>
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="offerNotificationsSwitch"
                      checked={offerNotifications}
                      onChange={() =>
                        setOfferNotifications(!offerNotifications)
                      }
                    ></input>
                    <label
                      class="form-check-label"
                      htmlFor="offerNotificationsSwitch"
                    >
                      I would like to recieve updates whenever an offer is made
                      on my listings.
                    </label>
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="generalNotificationsSwitch"
                      checked={generalNotifications}
                      onChange={() =>
                        setGeneralNotifications(!generalNotifications)
                      }
                    ></input>
                    <label
                      class="form-check-label"
                      htmlFor="generalNotificationsSwitch"
                    >
                      I would like to recieve the latest news, releases, and
                      offers.
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-grid">
                    {userProfileLoading ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                        Updating...
                      </button>
                    ) : (
                      <button className="btn btn-primary" type="submit">
                        Save changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ProfileInformation;
