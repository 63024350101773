import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logout } from "../actions/userActions";
import { useSelector, useDispatch } from "react-redux";

const AccountDropdown = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  useEffect(() => {}, [user]);

  return (
    <div>
      <Link
        to="#"
        className="nav-link dropdown-toggle m-0"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
      >
        My Account
      </Link>

      <ul className="dropdown-menu shadow p-2 px-4">
        <li>
          <Link to="/profile/information" className="dropdown-item ps-0">
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center me-1">
                <img
                  src={user && user.photo}
                  alt="profile"
                  className="rounded-circle me-2"
                  style={{ height: 32, width: 32 }}
                  referrerPolicy="no-referrer"
                ></img>
              </div>
              <div>
                <div>{user && user.name}</div>
                <p class="fw-light m-0" style={{ fontSize: "smaller" }}>
                  Edit Profile
                </p>
              </div>
            </div>
          </Link>
        </li>
        <hr className="dropdown-divider"></hr>
        <h6 class="dropdown-header p-0">Selling</h6>
        <li>
          <Link to="/profile/selling" className="dropdown-item ps-1">
            Current listings
          </Link>
        </li>
        <li>
          <Link to="/profile/selling/completed" className="dropdown-item ps-1">
            Selling
          </Link>
        </li>
        <li>
          <Link to="/sell" className="dropdown-item ps-1 ">
            List an item
          </Link>
        </li>
        <hr className="dropdown-divider"></hr>
        <h6 class="dropdown-header p-0">Buying</h6>
        <li>
          <Link to="/profile/buying" className="dropdown-item ps-1 ">
            My orders
          </Link>
        </li>
        <li>
          <Link to="/profile/favorites" className="dropdown-item ps-1 ">
            Favorites
          </Link>
        </li>
        <hr className="dropdown-divider"></hr>
        <li>
          <Link to="/profile/settings" className="dropdown-item ps-1 ">
            Settings
          </Link>
        </li>
        <li>
          <Link
            to="#"
            className="dropdown-item ps-1 "
            onClick={() => dispatch(logout())}
          >
            Sign Out
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AccountDropdown;
