import React from "react";
import { Link } from "react-router-dom";

const OfferCreatedScreen = () => {
  return (
    <section class="py-5 text-center container">
      <div class="row py-lg-5">
        <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="fw-light">Created new offer!</h1>
          <p class="lead text-muted">
            You have successfully created an offer. The seller has 24 hours to
            respond to this offer and then it will expire. You can view your
            current offers in profile settings.
          </p>
          <p>
            <Link to="/profile/offers" class="btn btn-primary my-2">
              View offers
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default OfferCreatedScreen;
